import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getNetPostions } from '../../actions/netpositions/netpostionrequest'
import PositionDataHolder from './PositionDataHolder'
import { stringSort } from '../../common/utils'
import { onOrderSuccess } from '../../actions/orderentry/orderentryaction'
import { progressBar } from '../../controls/progress'
import { getCommoditySelExchange } from '../../common/permissions'

class CommodityTabContent extends Component {
    tag = "NetpositionCommodity"
    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            producttypes: [{ name: "ALL PRODUCTS", key: "ALL" }, { name: "CARRY FORWARD", key: "carryForward" }, { name: "INTRADAY", key: "intraday" }],
            selectedproducttype: "all",
            fnooptions: [{ name: "ALL FnO", key: "all" }, { name: "FUTURES", key: "future" }, { name: "OPTIONS", key: "option" }],
            selectedfnooptions: "all",
            filterData: [],
            showTranfer: true,
            selectedexchange: getCommoditySelExchange(),
            isBuySell: true,
            selectedday: ''
        }
        this.onStreamCallback = this.onStreamCallback.bind(this);
        this.onStreamCallbackTR = this.onStreamCallbackTR.bind(this);
        this.onOrderSuccess = this.onOrderSuccess.bind(this);
    }

    componentDidMount() {
        this.setState({ selectedday: (this.props.selectedday) }, () => {
            this.requestCommodityNP();
            this.props.onOrderSuccess(this.onOrderSuccess);
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.selectedday !== this.props.selectedday) {
            this.setState({ selectedday: (newProps.selectedday) }, () => {
                this.requestCommodityNP();
            });
        }
    }

    onOrderSuccess() {
        this.requestCommodityNP();
    }

    // === Get fno details ======
    requestCommodityNP() {
        var requestData = {
            request: {
                data: {
                    filters: [{
                        key: "prdType",
                        value: this.state.selectedproducttype
                    },
                    {
                        key: "assetClass",
                        value: this.state.selectedfnooptions,
                    },
                    {
                        key: "positionDay",
                        value: this.props.selectedday,
                    },
                    {
                        key: "exc",
                        value: this.state.selectedexchange,
                    }
                    ],
                    tab: "commodity"
                }
            }
        };
        this.setState({ inProgress: true, error: "" });
        getNetPostions(requestData, this.parseRequestCommodityNPResponse.bind(this), this.parseCommodityNPError.bind(this));
    }

    parseRequestCommodityNPResponse(responseData) {
        let data = responseData.response ? responseData.response.data : {}

        this.setState(
            (state) => {
                let positions = data.positions ? data.positions : [];

                return Object.assign(state,
                    {},
                    {
                        inProgress: false,
                        positionsData: positions,
                        filterData: positions
                    })
            },
            () => {
                this.onSocketRegister(data.positions);
            });
    }

    parseCommodityNPError(error) {
        this.setState({ inProgress: false, error: error.message });
    }

    onToggleMcxChange(selected) {
        this.setState({ selectedexchange: selected === "1" ? "MCX" : "NCDEX" }, () => this.requestCommodityNP());
    }

    onOptionTypeSelected(selected) {
        var selectedOption = this.state.fnooptions.filter(data => data.name === selected)[0].key;
        this.setState({ selectedfnooptions: selectedOption }, function () { this.requestCommodityNP() })
    }

    onProductTypeSelected(selected) {
        var selectedproducttype = this.state.producttypes.filter(data => data.name === selected)[0].key;
        this.setState({ selectedproducttype: selectedproducttype }, function () { this.requestCommodityNP() })
    }

    sortSymbol() {
        var sortData = stringSort(this.state.filterData, "displaySym", this.state.sortType === "ASC" ? "DSC" : "ASC")
        this.setState({ filterData: sortData, sortType: this.state.sortType === "ASC" ? "DSC" : "ASC" })
    }

    //================  Stream Functions  =================//

    onSocketRegister(data) {
        data = data ? data : [];
       if (localStorage.getItem("TR_STREAM_FLAG") === "N") {
        data.map((value) => {
            return window.socketEvents.register(value.streamSym + value.sym.exc, { screenName: this.tag, sym: value.streamSym, exc: value.sym.exc }, this.onStreamCallback)
        });
        setTimeout(function () {
            window.socketEvents.subscribe();
        }, 1000)

        } else {            
            data.map(value => {
                if (value.sym.xSym && value.sym.exc_seg) {
                    return window.socketEventsTR.register(value.sym.xSym, { screenName: this.tag, sym: value.sym.xSym, exc: value.sym.exc_seg }, this.onStreamCallbackTR)
                }
            })
            setTimeout(function () {
                window.socketEventsTR.subscribe();
            }, 1000)

        }
    }

    onStreamCallback(streamData) {
        var updateCurrentList = this.state.filterData;
        updateCurrentList = updateCurrentList.map(data => {
            if (data.streamSym === streamData.data.sym && data.sym.exc === streamData.data.exc) {
                data.ltp = streamData.data.ltp ? streamData.data.ltp : data.ltp;
            }
            return data;
        })
        this.setState({ filterData: updateCurrentList })
    }

    onStreamCallbackTR(streamData) {
        var updateCurrentList = this.state.filterData;
        updateCurrentList = updateCurrentList.map(data => {
            if (data.sym.xSym === streamData.data.xSym) {
                data.ltp = streamData.data.ltp ? streamData.data.ltp : data.ltp;
            }
            return data;
        })
        this.setState({ filterData: updateCurrentList })
    }

    componentWillUnmount() {
        window.socketEvents.unregister(this.tag);
        window.socketEventsTR.unregister(this.tag);
    }

    transferCallback() {
        this.requestCommodityNP();
    }

    render() {

        var errorHolder = (
            <div className="error-holder">
                {this.state.error}
            </div>
        );

        return (
            <div id="commodity" className="np-tab-content tab-pane in active">

                <div className="fillheight">

                    <div style={{ marginTop: "20px", height: "calc(100% - 75px)" }}>
                        {this.state.error ? errorHolder :
                            this.state.inProgress ? progressBar() : (
                                <PositionDataHolder
                                    positionData={this.state.filterData}
                                    showTranfer={this.state.showTranfer}
                                    segment={"commodity"}
                                    qtylabel={"LOT"}
                                    sortCallback={this.sortSymbol.bind(this)}
                                    transferCallback={this.transferCallback.bind(this)}
                                    isBuySell={this.state.isBuySell}
                                    selectedday={this.state.selectedday}
                                />
                            )}
                    </div>
                </div>
            </div >
        )
    }

}


const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onOrderSuccess: (callback) => onOrderSuccess(dispatch, callback)
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(CommodityTabContent);