import React from "react";

class InputPlusMinus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: this.props.defaultValue ? this.props.defaultValue : "0",
      minValue: this.props.minValue ? this.props.minValue : 0,
      maxValue: this.props.maxValue ? this.props.maxValue : 9999999
    };

    this.getValue = this.getValue.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.minValue !== this.props.minValue) {
      this.setState({ minValue: nextProps.minValue })
    }
    if (nextProps.maxValue !== this.props.maxValue) {
      this.setState({ maxValue: nextProps.maxValue })
    }
  }

  getValue() {
    return this.state.inputText;
  }

  setValue(value) {
    this.setState({ inputText: value });
  }

  onChange(e) {
    var currentVal = parseFloat(e.target.value);
    var  currentValString= e.target.value;
    if (currentVal >= this.state.minValue) {
      this.btnMinus.removeAttribute("disabled");
    }
    if (currentVal <= this.state.maxValue) {
      this.btnPlus.removeAttribute("disabled");
    }
    if (currentValString === '') {
      this.setState({ inputText: "0" }, () => {
        if (this.props.onChange) {
          this.props.onChange();
        }
      });
      return;
    }
    if (currentValString[0] === '0' && currentValString[1] !== '.' && currentValString[1] && (currentVal <= this.state.maxValue)) {
      this.setState({ inputText: currentValString[1] }, () => {
        if (this.props.onChange) {
          this.props.onChange();
        }
      });
      return;
    }
    if ((currentVal <= this.state.maxValue) || e.target.value.length === 0) {
      this.setState({ inputText: currentValString }, () => {
        if (this.props.onChange) {
          this.props.onChange();
        }
      });
    }
  }

  onBlur(e) {
    var currentVal = parseFloat(e.target.value);
    if (isNaN(currentVal)) {
      this.setState({ inputText: "0" });
    }
  }

  onkeydown(e) {
    if (this.props.isnumber) {
      if (e.keyCode === 110 || e.keyCode === 190) {
        e.preventDefault();
      }
    }
    if (this.props.isdecimal) {
      var min_count = this.props.decimalplacescount ? this.props.decimalplacescount : 2;
      var value = e.target.value, cursor_position = e.target.selectionStart, dot_position = value.indexOf(".");
      if (((e.keyCode === 110 || e.keyCode === 190) && value.split('.').length >= 2) ||
        (value.indexOf(".") > -1 && cursor_position > dot_position && value.substring(dot_position, value.length).length > min_count && ![8, 37, 39].includes(e.keyCode))) {
        e.preventDefault();
      }
    }
    if (
      ([46, 8, 9, 27, 13, 190].includes(e.keyCode)) ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105) && (e.keyCode !== 110)
    ) {
      e.preventDefault();
    }
  }

  onBtnMinusClick(e, type) {
    e.preventDefault();
   
    var decrementvalue, currentVal = parseFloat(this.state.inputText);
    if (this.props.incrementvalue) {
      decrementvalue = parseFloat(this.props.incrementvalue);
    } else {
      decrementvalue = 1
    }
    if (!isNaN(currentVal)) {
      if (currentVal > this.state.minValue) {
        var dicimalPlaces = this.props.isnumber ? 0 : this.props.decimalplacescount ? this.props.decimalplacescount : 2;


        var multiplier = Math.pow(10, dicimalPlaces);
        var cv = Math.round(currentVal.toFixed(dicimalPlaces) * multiplier);
        var iv = Math.round(decrementvalue.toFixed(dicimalPlaces) * multiplier);
        console.log("cv: " + cv + "  iv: " + iv);
        var rem = (Math.round(cv % iv)) / multiplier;
        console.log(rem);
        var res;
        if (rem === 0) {
          res = ((cv - iv) / multiplier).toFixed(dicimalPlaces);
        }
        else {

          res = (cv / multiplier - rem).toFixed(dicimalPlaces);
        }
        if (res < this.state.minValue)
          res = 0;
        this.setState({ inputText: res }, () => {
          if (this.props.onChange) {
            this.props.onChange();
          }
        });
      }
      if (currentVal - decrementvalue <= this.state.minValue) {
        //this.btnMinus.setAttribute("disabled", true);
      }
      //this.btnPlus.removeAttribute("disabled")
    } else {
      this.setState({ inputText: "0" }, () => {
        if (this.props.onChange) {
          this.props.onChange();
        }
      });
    }
  }

  onBtnPlusClick(e, type) {
    e.preventDefault();
    var incrementvalue, currentVal = parseFloat(this.state.inputText);
    if (this.props.incrementvalue) {
      incrementvalue = parseFloat(this.props.incrementvalue);
    } else {
      incrementvalue = 1
    }

    if (!isNaN(currentVal)) {
      if (currentVal < this.state.maxValue) {
        var dicimalPlaces = this.props.isnumber ? 0 : this.props.decimalplacescount ? this.props.decimalplacescount : 2;

        var multiplier = Math.pow(10, dicimalPlaces);
        var cv = Math.round(currentVal.toFixed(dicimalPlaces) * multiplier);
        var iv = Math.round(incrementvalue.toFixed(dicimalPlaces) * multiplier);
        console.log("cv: " + cv + "  iv: " + iv);
        var rem = (Math.round(cv % iv)) / multiplier;
        console.log(rem);

        if (rem === 0) {
          let res = (cv + iv) / multiplier;
          console.log(res);
          if (res <= this.state.maxValue) {
            console.log('s')
            this.setState({ inputText: res.toFixed(dicimalPlaces) }, () => {
              if (this.props.onChange) {
                this.props.onChange();
              }
            });
          }
        }
        else {
          let res = (cv + iv) / multiplier - rem;
          console.log(res);
          if (res <= this.state.maxValue) {
            this.setState({ inputText: res.toFixed(dicimalPlaces) }, () => {
              if (this.props.onChange) {
                this.props.onChange();
              }
            });
          }
        }
      }

      if (currentVal + incrementvalue >= this.state.maxValue) {
        //this.btnPlus.setAttribute("disabled", true);
      }
      //this.btnMinus.removeAttribute("disabled")
    } else {
      this.setState({ inputText: "0" }, () => {
        if (this.props.onChange) {
          this.props.onChange();
        }
      });
    }
  }

  setFocus() {
    window.$("#" + this.props.id + " .form-control.input-number").focus();
  }

  render() {
    return (
      <div id={this.props.id} className={this.props.errorhighlight ? "input-plus-minus-error input-plus-minus input-group col-xs-12 nopadding" : "input-plus-minus input-group col-xs-12 nopadding"} style={{ position: 'relative' }}>
        <span className="input-group-btn">
          <button
            type="button"
            className="btn btn-default btn-number"
            tabIndex="-1"
            data-type="minus"
            data-field="quant[1]"
            onClick={e => this.onBtnMinusClick(e, "minus")}
            ref={btnMinus => {
              this.btnMinus = btnMinus;
            }}
          >
            <span className="fa fa-minus minus" />
          </button>
        </span>
        <input onPaste={(e)=>e.preventDefault()}
          type="text"
          name="quant[1]"
          tabIndex={this.props.tabIndex}
          className="form-control input-number"
          value={this.state.inputText}
          onChange={e => { this.onChange(e) }}
          onBlur={this.onBlur.bind(this)}
          onKeyDown={e => {
            this.onkeydown(e);
          }}
          autoComplete="off"
          disabled={this.props.isDisabled}
        />
        <span className="input-group-btn">
          <button
            type="button"
            className="btn btn-default btn-number"
            tabIndex="-1"
            data-type="plus"
            data-field="quant[1]"
            onClick={e => this.onBtnPlusClick(e, "plus")}
            ref={btnPlus => {
              this.btnPlus = btnPlus;
            }}
          >
            <span className="fa fa-plus plus" />
          </button>
        </span>
        {
          this.props.isDisabled ?
            <div style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', backgroundColor: '#ffffff55' }}> </div>
            : ''
        }
      </div>
    );
  }
}

export default InputPlusMinus;
