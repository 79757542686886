import React, { Component } from 'react';
import { connect } from 'react-redux';
import TabBar from "../../controls/TabBar";
import {
    ModuleKeys, ideasTabs, commonDropDownType, categoryTypes, TradeActions, orderEntryTypes
} from "../../common/constants"
import { getIdeas } from '../../actions/dashboard/dashboardrequests'
import Dropdown from '../../controls/Dropdown'
import SearchInput from '../../controls/SearchInput'
import CustomPopover from '../../controls/CustomPopover'
import RadioButtons from '../../controls/RadioButtons'
import { showOrderDialog } from '../../actions/orderentry/orderentryaction'
import SymbolWatchlistDialog from '../../controls/symbolsearch/SymbolWatchlistDialog'
import { comaSeparator } from '../../common/utils'
import { setChartSymbol } from '../../actions/chartiq/chartaction'
import { progressBar } from '../../controls/progress'

class ResearchBig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            error: '',
            ideasTabs: [ideasTabs[0], ideasTabs[1]],
            ideasAllData: [],
            ideasVisibleData: [],
            selectedsegment: "EQUITY",
            reqsegment: "EQUITY",
            selectedCategory: "technical",
            showWatchlistDlg: false,
            selectedFilter: ''
        };
        this.gotoTrade = this.gotoTrade.bind(this);
        this.onAddWatchlist = this.onAddWatchlist.bind(this);
    }

    componentDidMount() {
        if (this.props.activeWidget === ModuleKeys.RESEARCH) {
            this.placeRequest();
        }
    }

    onTabClicked(tabName) {
        switch (tabName) {
            case "Equity":
                this.setState({ selectedsegment: "EQUITY", reqsegment: "EQUITY", selectedCategory: "technical", selectedFilter: '' }, () => {
                    this.refs.rbCategoryTypes.setSelected(0, false);
                    this.refs.rbCategoryTypes.setDisabled(1, false);
                    this.placeRequest(); //TODO: include "CM" after confirmation
                });
                break;
            case "FNO":
                this.setState({ selectedsegment: "FNO", reqsegment: "DERIVATIVE", selectedCategory: "derivative", selectedFilter: '' }, () => {
                    this.placeRequest();
                });
                break;
            case "Currency":
                this.setState({ selectedsegment: "CDS", reqsegment: "CDS", selectedCategory: "technical", selectedFilter: '' }, () => {
                    this.refs.rbCategoryTypes.setSelected(1, false);
                    this.refs.rbCategoryTypes.setDisabled(1, false);
                    this.placeRequest();
                });
                break;
            case "Commodity":
                this.setState({ selectedsegment: "COM", reqsegment: "COM", selectedCategory: "technical", selectedFilter: '' }, () => {
                    this.refs.rbCategoryTypes.setSelected(1, false);
                    this.refs.rbCategoryTypes.setDisabled(1, false);
                    this.placeRequest();
                });
                break;
            default: break;
        }
    }

    onCategoryChanged(buttonName) {
        switch (buttonName) {
            case "technical":
                this.setState({ selectedCategory: "technical", selectedFilter: '' }, () => this.placeRequest());
                break;
            case "fundamental":
                this.setState({ selectedCategory: "fundamental", selectedFilter: '' }, () => this.placeRequest());
                break;
            default: break;
        }
    }

    onFilter(selected) {

    }

    onSortSelected(selected) {
        var temp;
        switch (selected) {
            case "Scrip (A-Z)":
                temp = [].concat(this.state.ideasAllData).sort(function (first, next) {
                    if (first.displaySym < next.displaySym) {
                        return -1;
                    } else if (first.displaySym > next.displaySym) {
                        return 1;
                    }
                    return 0;
                });
                this.setState({ ideasVisibleData: temp });
                break;
            case "Scrip (Z-A)":
                temp = [].concat(this.state.ideasAllData).sort(function (first, next) {
                    if (first.displaySym < next.displaySym) {
                        return 1;
                    } else if (first.displaySym > next.displaySym) {
                        return -1;
                    }
                    return 0;
                });
                this.setState({ ideasVisibleData: temp });
                break;
            case "BUY":
                temp = [].concat(this.state.ideasAllData).sort(function (first, next) {
                    if (first.calltype < next.calltype) {
                        return -1;
                    } else if (first.calltype > next.calltype) {
                        return 1;
                    }
                    return 0;
                });
                this.setState({ ideasVisibleData: temp });
                break;
            case "SELL":
                temp = [].concat(this.state.ideasAllData).sort(function (first, next) {
                    if (first.calltype < next.calltype) {
                        return 1;
                    } else if (first.calltype > next.calltype) {
                        return -1;
                    }
                    return 0;
                });
                this.setState({ ideasVisibleData: temp });
                break;
            default: break;
        }
    }

    onFilterSelected(selected) {
        this.setState({ selectedFilter: selected === 'ALL CALLS' ? '' : selected.toLowerCase() }, () => this.placeRequest());
    }

    onSearch(searchText) {

        const contains = (data, pattern) => data ? data.toLowerCase().indexOf(pattern.toLowerCase()) >= 0 : false;

        var result = []

        result = this.state.ideasAllData.filter((row) => contains(row.displaySym, searchText) || contains(row.sym.exc, searchText) || contains(row.calldescription, searchText));

        console.log({ result })
        this.setState({ ideasVisibleData: result });
    }

    gotoTrade(e) {
        var id = e.target.getAttribute("data-tag");
        var orderdetails = this.getOrderDetails(id);
        this.props.showOrderDialog(orderdetails);
    }

    getOrderDetails(id) {
        var item = this.state.ideasAllData.filter(function (i) {
            return i.callid === id;
        })[0];
        var taction = item.calltype === "BUY" ? TradeActions.BUY : TradeActions.SELL;
        var orderdetails = {
            orderentrytype: orderEntryTypes.NEW,
            action: taction,
            symobj: item.sym,
            displaySym: item.displaySym,
            company: item.companyName,
            last: "0.00",
            change: "0.00",
            changeper: "0.00"
        };

        return orderdetails;
    }


    placeRequest() {
        this.setState({ ideasAllData: [], ideasVisibleData: [] });
        var data = this.getRequestData(this.state.selectedCategory, this.state.reqsegment, "30");
        this.setState({ inProgress: true, error: '' });
        getIdeas(data, this.parseResponse.bind(this), this.parseError.bind(this));
    }

    getRequestData(category, segment, count) {
        var data = {
            request: {
                data: {
                    subcategory: category,
                    recordcount: count,
                    segment: segment,
                    category: this.state.selectedFilter
                    //TODO: include after confirmation
                    //callstatus: "closed" 
                }
            }
        };

        return data;
    }

    parseResponse(responseData) {
        this.setState({
            ideasAllData: responseData.response.data.ideas,
            ideasVisibleData: responseData.response.data.ideas,
            inProgress: false,
            error: ''
        });
    }

    parseError(error) {
        console.log(error);
        this.setState({
            inProgress: false,
            error: error.message
        });
    }

    //add watchlist symbol
    onAddWatchlist(selectedData) {
        this.setState({ showWatchlistDlg: true, selectedSymbol: selectedData })
    }

    callbackAddWacthlist() {
        this.setState({ showWatchlistDlg: false });
    }

    render() {

        // var progressindicator = (
        //     <div className="widget-progress-holder">
        //         <svg class="circular-progress" viewBox="25 25 50 50">
        //             <circle class="circular-progress_path" cx="50" cy="50" r="20"
        //                 fill="none" stroke-width="2" stroke-miterlimit="10" />
        //         </svg>
        //     </div>
        // );

        var errorHolder = (
            <div className="error-holder" style={{ marginTop: "5px" }}>
                <span> {this.state.error} </span>
            </div>
        );

        return (

            <div className="fillheight">
                <div className="row contentpanel">
                    <div className="col-8  contentpanel ideas-tab-bar">
                        <TabBar tabs={this.state.ideasTabs} highlight="above" expanded={true} nomargin={true} callbackHandler={this.onTabClicked.bind(this)} />
                    </div>
                    {this.state.selectedsegment !== 'FNO' ? <div className="col-4  contentpanel text-right">
                        <RadioButtons ref="rbCategoryTypes" buttons={categoryTypes} callbackHandler={this.onCategoryChanged.bind(this)} />
                    </div> : ''
                    }
                </div>

                <div className="tab-content expanded ideas-tab-content" style={{ height: 'calc(100% - 30px)' }}>
                    <div id="equity" className="tab-pane fade in active fillheight">
                        <div className="fillheight">
                            <div className="fillheight">
                                <div className="row contentpanel">
                                    <div className="col-3 contentpanel">
                                        <div className="ideas-sort-drop-down">
                                            <Dropdown
                                                dropdownType={commonDropDownType.CUSTOM}
                                                customeHeader={["SORT BY ",
                                                    <img src='assets/svg/sort_by.svg' alt="sort"></img>]}
                                                alignDefault={true}
                                                list={[{ name: 'Scrip (A-Z)' }, { name: 'Scrip (Z-A)' }]}
                                                onSelectionChanged={this.onSortSelected.bind(this)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6 contentpanel text-center">
                                        <div className="col-12 contentpanel nopadding btn-group">
                                            <SearchInput
                                                placeholder={"Search for keywords..."}
                                                callbackHandler={this.onSearch.bind(this)}
                                                searchText={this.state.searchText}
                                            />
                                        </div>
                                    </div>
                                    {this.state.selectedsegment === 'FNO' ? <div className="col-2 contentpanel text-right" style={{ float: "right" }} >
                                        <div className="ideas-filter-drop-down" style={{ width: 140 }}>
                                            <Dropdown style="gainIndicesDrop" key={1}
                                                dropdownType={commonDropDownType.DEFAULT}
                                                alignDefault={true}
                                                list={[{ name: 'ALL CALLS' }, { name: 'INTRADAY' }, { name: 'BTST' }, { name: 'STBT' }, { name: 'OPTION INTRADAY' },
                                                { name: 'OPTION POSITIONAL' }, { name: 'DERIVATIVE STRATEGY' }, { name: 'MOMENTUM' }, { name: 'SWING' }]}
                                                onSelectionChanged={this.onFilterSelected.bind(this)}
                                            />
                                        </div>
                                    </div> : this.state.selectedCategory === "technical" ?
                                            <div className="col-2 contentpanel text-right" style={{ float: "right" }} >
                                                <div className="ideas-filter-drop-down">
                                                    <Dropdown style="gainIndicesDrop" key={2}
                                                        dropdownType={commonDropDownType.DEFAULT}
                                                        alignDefault={true}
                                                        list={[{ name: 'ALL CALLS' }, { name: 'INTRADAY' }, { name: 'BTST' }, { name: 'MOMENTUM' },
                                                        { name: 'SWING' }, { name: 'STOCK VELOCITY' }, { name: 'LONGTERM IDEAS' }]}
                                                        onSelectionChanged={this.onFilterSelected.bind(this)}
                                                    />
                                                </div>
                                            </div> : <div className="col-2 contentpanel text-right" style={{ float: "right" }} >
                                                <div className="ideas-filter-drop-down">
                                                    <Dropdown style="gainIndicesDrop" key={3}
                                                        dropdownType={commonDropDownType.DEFAULT}
                                                        alignDefault={true}
                                                        list={[{ name: 'ALL CALLS' }, { name: 'MIDCAP IDEAS' }, { name: 'LONGTERM IDEAS' }]}
                                                        onSelectionChanged={this.onFilterSelected.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                    }
                                </div>
                                {this.state.error ? errorHolder :
                                    this.state.inProgress ? progressBar() : [
                                        <div className="row contentpanel">
                                            <div className="col-4 contentpanel">
                                            </div>
                                            <div className="col-6 contentpanel ideas-sub-header">

                                                {this.state.selectedCategory === "technical" ?
                                                    <div className="row">
                                                        <div className="col-2 contentpanel">
                                                            TYPE OF CALL
                                                        </div>
                                                        <div className="col-1 contentpanel text-center">
                                                            CALL STATUS
                                                        </div>
                                                        <div className="col-3 contentpanel text-center">
                                                            VALIDITY
                                                        </div>
                                                        <div className="col-2 contentpanel text-center">
                                                            TARGET 1
                                                        </div>
                                                        <div className="col-2 contentpanel text-center">
                                                            TARGET 2
                                                        </div>
                                                        <div className="col-2 contentpanel text-right">
                                                            STOPLOSS
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="row">
                                                        <div className="col-3 contentpanel">
                                                            TYPE OF CALL
                                                        </div>
                                                        <div className="col-3 contentpanel text-center">
                                                            CALL STATUS
                                                        </div>
                                                        <div className="col-3 contentpanel text-center">
                                                            VALIDITY
                                                        </div>
                                                        <div className="col-3 contentpanel text-center">
                                                            TARGET
                                                        </div>
                                                        {/* <div className="col-3 text-right">
                                                            UPSIDE POTENTIAL
                                        </div> */}
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-2 contentpanel text-right">
                                            </div>
                                        </div>,
                                        <div className="fillheight contentpanel" style={{ overflowY: 'auto', height: 'calc(100% - 95px)' }}>
                                            {this.state.ideasVisibleData.map(function (row, key) {
                                                return (
                                                    <div className="ideas-row fillwidth">
                                                        <div key={key} className="row contentpanel">
                                                            <div className="col-4 contentpanel ideas-col">
                                                                <div className="row">
                                                                    <div className={row.calltype === 'BUY' ? "col-1 contentpanel positive-change-blue" : "col-1 contentpanel negative-change"}>
                                                                        {row.calltype === 'BUY' ? 'B' : 'S'}
                                                                    </div>
                                                                    <div className="col-7 contentpanel">
                                                                        <div>
                                                                            {row.displaySym ? row.displaySym : row.sym.id} @ {row.entryprice1}
                                                                        </div>
                                                                        {/* <div className="ideas-date">
                                                                        {row.callsenttime}
                                                                    </div> */}
                                                                    </div>
                                                                    <div className="col-4 contentpanel ideas-description ideas-col">
                                                                        <CustomPopover placement="right" header="VIEW DESCRIPTION" popuptext={row.calldescription} popupstatus={row.callstatus} popupdate={row.callsenttime} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.state.selectedCategory === "technical" ?
                                                                <div className="col-6 contentpanel">
                                                                    <div className="row">
                                                                        <div className="col-2 contentpanel ideas-col">
                                                                            {row.category}
                                                                        </div>
                                                                        <div className="col-1 contentpanel text-center ideas-col">
                                                                            {row.callstatus}
                                                                        </div>
                                                                        <div className="col-3 contentpanel text-center ideas-col">
                                                                            {row.callexpiry}
                                                                        </div>
                                                                        <div className="col-2 text-center ideas-col">
                                                                            {comaSeparator(row.targetprice1)}
                                                                        </div>
                                                                        <div className="col-2 contentpanel text-center ideas-col">
                                                                            {comaSeparator(row.targetprice2)}
                                                                        </div>
                                                                        <div className="col-2 contentpanel text-right ideas-col">
                                                                            {row.stoplossprice1 && row.stoplossprice1 !== '230' ? comaSeparator(row.stoplossprice1) : 'NA'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="col-6 contentpanel">
                                                                    <div className="row">
                                                                        <div className="col-3 contentpanel ideas-col">
                                                                            {row.category}
                                                                        </div>
                                                                        <div className="col-3 contentpanel text-center ideas-col">
                                                                            {row.callstatus}
                                                                        </div>
                                                                        <div className="col-3 contentpanel text-center ideas-col">
                                                                            {row.callexpiry}
                                                                        </div>
                                                                        <div className="col-3 contentpanel text-center ideas-col">
                                                                            {comaSeparator(row.targetprice1)}
                                                                        </div>
                                                                        {/* <div className="col-3 text-right">

                                                                    </div> */}
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="col-2 contentpanel">
                                                                <div style={{ float: 'left', width: '34px', marginLeft: '15px' }} >
                                                                    {/* <img src='assets/svg/chart_icon.svg' className="watch_chartimg3" data-toggle="tooltip" title="Charts" onClick={() => { this.props.setChartSymbol(row.sym) }} /> */}
                                                                    <button type="button" className="topgainerslosers-btn chart" data-toggle="tooltip" title="Charts" onClick={() => { this.props.setChartSymbol(row.sym) }}>
                                                                        <img src='assets/svg/chart_icon_new2.svg' style={{ width: '100%', height: '100%' }} />
                                                                    </button>
                                                                </div>
                                                                <div style={{ float: 'left', marginLeft: '10px', width: '34px' }}>
                                                                    {/* <img src='assets/svg/add_watchlist_2.svg' className="watch_chartimg3" data-toggle="tooltip" title="Add to Watchlist" onClick={() => this.onAddWatchlist(row.sym)} /> */}
                                                                    <button type="button" className="topgainerslosers-btn watch2" data-toggle="tooltip" title="Add to Watchlist" onClick={() => this.onAddWatchlist(row.sym)}>
                                                                        <img src='assets/svg/add_watchlist_2_new3.svg' style={{ width: '100%', height: '100%' }} />
                                                                    </button>
                                                                </div>
                                                                <div style={{ float: 'left', marginLeft: '10px', width: '34px' }}>
                                                                    <button type="button" className={row.calltype === 'BUY' ? "topgainerslosers-btn buy" : "topgainerslosers-btn sell"}
                                                                        data-tag={row.callid} onClick={this.gotoTrade}>
                                                                        {row.calltype}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }, this)}
                                        </div>
                                    ]

                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showWatchlistDlg && <SymbolWatchlistDialog
                        showWatchlistDlg={this.state.showWatchlistDlg}
                        symAddWatchlist={this.state.selectedSymbol}
                        callbackHandler={this.callbackAddWacthlist.bind(this)}
                        title="Add Scrips" />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeWidget: state.dashboard.activeWidget,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showOrderDialog: (data) => showOrderDialog(dispatch, data),
        setChartSymbol: (data) => setChartSymbol(dispatch, data)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResearchBig);