import React from "react"
import { NavLink } from 'react-router-dom'
import { connect } from "react-redux";
import NavSubMenu from '../../../controls/NavSubMenu'
import { SubMenuDirections } from '../../../common/constants'
import { NavbarData } from '../../../common/navbardata'
import { setExpandWidget, setChartsExpandWidget } from '../../../actions/dashboard'
import { ModuleKeys } from '../../../common/constants'
import { isEquityPermitted, isFnOPermitted, isCurrencyPermitted, isCommodityPermitted , isBasketPermitted, newReportPermission } from '../../../common/permissions'
import { getConfigByKey } from '../../../common/utils'
class Navbar3 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            navbarData: NavbarData,
            activeTab: NavbarData[0].name,
        }
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    onNavbarClick(tab) {
        if (tab.disabled) return;
        this.setState({ activeTab: tab.name });

        if (tab.name === "DashBoard") {
            this.props.setExpandWidget(ModuleKeys.ALL);
        }
        else if (tab.name === "CHARTS") {
            this.props.setChartsExpandWidget(ModuleKeys.ALL, { prevWidget: undefined });
        }
        else if (tab.name === "NEWS") {
            this.props.setExpandWidget(ModuleKeys.ALL);
            this.props.history.push(tab.path);
        }
        else if (tab.name === "RESEARCH") {
            this.props.setExpandWidget(ModuleKeys.RESEARCH);
        }
        else if (tab.id === "switch") {
            window.open("https://trade.indiabulls.com/login/login.htm", "_blank");
        }
    }

    onDropdownClick(tab) {
        if (tab.disabled) return;

    }

    componentWillReceiveProps(nextprops) {
        window.document.onkeyup = this.onKeyPress;
    }

    onKeyPress(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 27 || e.keyCode === 'Escape') {
            this.setState({ activeTab: NavbarData[0].name })
        }
    }

    componentWillMount() {
        var navbarData = NavbarData;
        navbarData = navbarData.map(data => {
            if (data.id === "marketstats") {
                if (!isEquityPermitted()) {
                    data = this.removeElementByKey(data, "ms_eq")
                }
                if (!isFnOPermitted()) {
                    data = this.removeElementByKey(data, "ms_nfo")
                }
                if (!isCurrencyPermitted()) {
                    data = this.removeElementByKey(data, "ms_cur")
                }
                if (!isCommodityPermitted()) {
                    data = this.removeElementByKey(data, "ms_com")
                }
            } else if (data.id === "reports") {
                if (!isEquityPermitted()) {
                    data = this.removeElementByKey(data, "rprt_eq")
                }
                if (!isFnOPermitted()) {
                    data = this.removeElementByKey(data, "rprt_fno")
                }
            }else if(data.id === 'products')
            {
                if (!isBasketPermitted())
                {
                    data = this.removeElementByKey(data, "prdct_bo")
                }
                if(getConfigByKey("PROFIT_LOSS_NEW")){
                    data = this.removeElementByKey(data, "portfolioTracker")
                }
                if(!newReportPermission())
                {
                    data = this.removeElementByKey(data, "portfolioTrackerDion")
                }
            }
            return data;
        });
        this.setState({ navbarData: navbarData })
    }

    removeElementByKey(data, key) {
        var index = data.submenu.map(subData => subData.id).indexOf(key);
        index >= 0 && delete data.submenu.splice(index, 1);
        return data;
    }

    componentDidMount() {
         window.$(".nav-main-li .dropdown").hover(
             function () {
                 window.$('#nav-main-dropdown-menu', this).stop(true, true).fadeIn(300);
                 window.$(this).addClass('show');
             },
             function () {
                 window.$('#nav-main-dropdown-menu', this).stop(true, true).fadeOut(300);
                 window.$(this).removeClass('show');
             });
    }

    selectedTab(tab){
        this.setState({activeTab:tab})
    }
    generateItem(tab, key) {

        if (tab.submenu) {
            return (
                <li key={key} className={"nav-main-li " + (tab.id === "info" ? "info " : "") + (this.state.activeTab === tab.name ? "active " : "") + (tab.disabled ? ' navdisabled' : '')} onClick={() => this.onNavbarClick(tab)}>

                    <NavSubMenu tab={tab} header={tab.id === "info" ?
                        <span>
                            <img src={this.state.activeTab === tab.name ? "assets/svg/info_selected.svg" : "assets/svg/info.svg"} style={{ margin: "-4px 5px 0px 0px", height: '18px' }} />
                            {tab.name}
                        </span>
                        :
                        (tab.name)}
                        alignment={tab.alignment} list={tab.submenu} menudirection={SubMenuDirections.DOWN} url={`${this.props.match.path + tab.path}`} history={this.props.history} selectedTab={this.selectedTab.bind(this)}>
                    </NavSubMenu>

                </li>
            )
        }
        else {
            return (
                <li key={key} style={{ position: tab.id === "switch" ? "absolute" : "", right: tab.id === "switch" ? "70px" : "" }}
                    className={"nav-main-li " + (this.state.activeTab === tab.name && tab.id !== "switch" ? " active" : "") + (tab.disabled ? ' navdisabled' : '')}>
                    {tab.disabled ?
                        <span className="nav-main-link"> {tab.name} </span>
                        :
                        <NavLink  onClick={() => this.onNavbarClick(tab)} style={{ textDecoration: tab.id === "switch" ? "underline" : "", color: tab.id === "switch" ? "#1EBF8A" : "" }} className="nav-main-link" to={`${this.props.match.path + tab.path}`} exact>{tab.name}</NavLink>
                    }
                </li>
            )
        }
    }

    render() {
        return (
            <nav className="navbar navbar-custom">
                <ul className="navbar-nav secondaryMenu">
                    {this.state.navbarData.map(function (tab, key) {
                        return this.generateItem(tab, key)
                    }, this)}
                </ul>
            </nav>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setExpandWidget: (activeWidget) => setExpandWidget(activeWidget, dispatch),
        setChartsExpandWidget: (activeWidget, statedata) => setChartsExpandWidget(activeWidget, statedata, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar3);