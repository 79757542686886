import React, { Component } from 'react';
import BaseWidget from '../base/BaseWidget'
import { ModuleKeys, TradeActions, orderEntryTypes, } from '../../common/constants'
import { connect } from 'react-redux';
import ToggleSwitch from '../../controls/ToggleSwitch'
import Dropdown from '../../controls/Dropdown'
import { commonDropDownType } from "../../common/constants"
import { getTopGainers, getTopLoosers } from '../../actions/dashboard/dashboardrequests'
import { getConfigByKey, calcChange,calcChangeTR, calcChangePercentage, comaSeparator } from '../../common/utils'
import { showOrderDialog } from '../../actions/orderentry/orderentryaction'
import { commonDialog } from '../../actions/common'
import SymbolWatchlistDialog from '../../controls/symbolsearch/SymbolWatchlistDialog'
import { setScripInfo } from '../../actions/scripdetails/scripdetailsaction'
import { setChartSymbol } from '../../actions/chartiq/chartaction'
import { withRouter } from 'react-router-dom'

class TopGainersLoosersSmall extends Component {

    constructor(props) {
        super(props);
        this.tag = "TopGainersLosers"
        this.state = {
            inProgress: false,
            error: "",
            topGainersLosersData: [],
            configindices: [],
            //Logic: either NSE or BSE or one of the indices should get selected
            indices: [],  // grayout when NSE-BSE selected
            // periods: [{ value: 'FTD', name: 'Today' }, { value: 'WTD', name: '7 Days' }, { value: 'MTD', name: '30 Days' }],
            periods: [{ value: 'FTD', name: 'Today' }],
            selectedIndex: 'NIFTY 50',
            selectedExchange: "NSE",
            selectedPeriod: "FTD",
            showWatchlistDlg: false
        };
        this.onSocketRegister = this.onSocketRegister.bind(this);
        this.onStreamCallback = this.onStreamCallback.bind(this);
        this.onStreamCallbackTR = this.onStreamCallbackTR.bind(this);
        this.onAddWatchlist = this.onAddWatchlist.bind(this);
        this.onSymbolClick = this.onSymbolClick.bind(this);
        this.getLtpClass = this.getLtpClass.bind(this);
        this.count=0;      
        this.countTR=0;      
    }

    componentDidMount() {
        try {
            var list = this.props.getConfigByKey('indices');
            this.setState({ configindices: list }, () => {
                this.setDropdownData('NIFTY 50');
                this.placeRequest();
            });
        }
        catch (ex) { }
    }
    setDropdownData(selected) {
        var list2 = [];
        this.state.configindices.forEach(element => {
            if (element.exc === this.state.selectedExchange) {
                list2.push({ name: element.index });
            }
        });
        this.setState({ indices: list2 }, () => {
            if (this.refs.indicesDropdown)
                this.refs.indicesDropdown.setSelected(selected ? selected : 'KEY INDICES');
        });
    }

    isInExpandedView() {
        return (this.props.activeWidget === ModuleKeys.TOP_GAINERS || this.props.activeWidget === ModuleKeys.TOP_LOOSERS);
    }

    onPeriodChanged(selected) {
        let periods = this.state.periods;
        let selectedPeriod;
        if (selected === periods[0].name) {
            selectedPeriod = periods[0].value;
        } else if (selected === periods[1].name) {
            selectedPeriod = periods[1].value;
        } else if (selected === periods[2].name) {
            selectedPeriod = periods[2].value;
        }
        this.setState({ selectedPeriod: selectedPeriod }, () => {
            this.placeRequest();
        });
    }

    onIndicesChanged(selected) {
        // this.refs.exchangeSwitch.setSelected(0);
        var selectedExc = this.state.configindices.filter(data => data.index === selected)[0].exc;
        this.setState({ selectedExchange: selectedExc, selectedIndex: selected }, () => {
            this.placeRequest();
        });
    }

    onExchangeSelected(selected) {

        if (selected === "1")
            this.setState({ selectedExchange: "NSE", selectedIndex: "-" }, () => {
                this.refs.indicesDropdown.setSelected('KEY INDICES'); this.setDropdownData();
                this.placeRequest();
            });
        else if (selected === "2")
            this.setState({ selectedExchange: "BSE", selectedIndex: "-" }, () => {
                this.refs.indicesDropdown.setSelected('KEY INDICES'); this.setDropdownData();
                this.placeRequest();
            });
    }

    gotoBuy(e) {
        var id = e.target.getAttribute("data-tag");
        var orderdetails = this.getOrderDetails(id, TradeActions.BUY);
        this.props.showOrderDialog(orderdetails);
    }

    gotoSell(e) {
        var id = e.target.getAttribute("data-tag");
        var orderdetails = this.getOrderDetails(id, TradeActions.SELL);
        this.props.showOrderDialog(orderdetails);
    }

    getLtpClass(current, prev, prevclass) {

        var diff = (current - prev);

        // console.log(prevclass);
        // if(diff === 0)
        //     return prevclass;

        var toclass = prevclass;

        if (diff > 0)
            toclass = 'positive-change-bg';
        else if (diff < 0)
            toclass = 'negative-change-bg';
        else
            toclass = 'no-change-bg';

        // console.log(toclass);
        return toclass;
    }

    getOrderDetails(id, taction) {
        var item = this.state.topGainersLosersData.filter(function (i) {
            return i.displaySym === id;
        })[0];
        var orderdetails = {
            orderentrytype: orderEntryTypes.NEW,
            action: taction,
            symobj: item.sym,
            displaySym: item.displaySym,
            company: item.companyName,
            last: "0.00",
            change: "0.00",
            changeper: "0.00"
        };

        return orderdetails;
    }

    placeRequest() {
        var data = this.getRequestData();
        this.setState({ topGainersLosersData: [], inProgress: true, error: "" });

        if (this.props.componentType === ModuleKeys.TOP_GAINERS) {
            getTopGainers(data, this.parseGainersResponse.bind(this), this.parseError.bind(this));
        }
        else if (this.props.componentType === ModuleKeys.TOP_LOOSERS) {
            getTopLoosers(data, this.parseLoosersResponse.bind(this), this.parseError.bind(this));
        }
    }

    getRequestData() {
        var datapart = {};
        var reccount = this.isInExpandedView() ? "30" : "5";
        if (this.state.selectedIndex === "-") {
            datapart = {
                exc: this.state.selectedExchange,
                period: this.state.selectedPeriod,
                recordcount: reccount
            }
        }
        else {
            var temp = this.state.configindices.filter(i => i.index === this.state.selectedIndex)[0];
            datapart = {
                exc: temp.exc,
                period: this.state.selectedPeriod,
                //index: temp.index === "BSE" ? 'ALL' : temp.index, // as per pranav.
                index: temp.index,
                recordcount: reccount
            }
        }

        var requestdata = {
            request: {
                data: datapart
            }
        };
        return requestdata;
    }

    parseGainersResponse(responseData) {
        this.setState({
            topGainersLosersData: responseData.response.data.gainers,
            inProgress: false,
            error: ""
        });
        
        this.onSocketRegister(responseData.response.data.gainers);
      
    }

    parseLoosersResponse(responseData) {
        this.setState({
            topGainersLosersData: responseData.response.data.losers,
            inProgress: false,
            error: ""
        });
        this.onSocketRegister(responseData.response.data.losers);
      
    }

    parseError(err) {
        this.setState({
            inProgress: false,
            error: err.message
        });
    }

    //================  Stream Functions  =================//

    onSocketRegister(data) {
                       
        if (localStorage.getItem("TR_STREAM_FLAG") === "N") {                       
        data.map(value => {
                return window.socketEvents.register(value.streamSym + value.sym.exc, { screenName: this.tag, sym: value.streamSym, exc: value.sym.exc }, this.onStreamCallback);
        })
        setTimeout(function () {
            window.socketEvents.subscribe();
        }, 1000)
        } else {            
            data.map(value => {
                return window.socketEventsTR.register(value.sym.xSym, { screenName: this.tag, sym: value.sym.xSym, exc: value.sym.exc_seg }, this.onStreamCallbackTR)
            })
            setTimeout(function () {
                window.socketEventsTR.subscribe();
            }, 1000)
    	}
    }
   
   
    onStreamCallback(streamData) {
        var updateCurrentList = this.state.topGainersLosersData;
        updateCurrentList = updateCurrentList.map(data => {
            if (data.streamSym === streamData.data.sym && data.sym.exc === streamData.data.exc) {
                data.ltpclass = streamData.data.ltp ? this.getLtpClass(streamData.data.ltp, data.ltp, data.ltpclass) : data.ltpclass;
                data.ltp = streamData.data.ltp ? streamData.data.ltp : data.ltp;
                if (this.state.selectedPeriod === "FTD") {
                    data.chng = streamData.data.ltp && streamData.data.closePrice ? calcChange(streamData.data, data.sym.instr) : data.chng;
                    data.chngPer = streamData.data.ltp && streamData.data.closePrice ? calcChangePercentage(streamData.data) : data.chngPer;
                    data.vol = streamData.data.vol ? streamData.data.vol : data.vol;
                    data.bidQty = streamData.data.bidQty ? streamData.data.bidQty : data.bidQty;
                    data.bidPrice = streamData.data.bidPrice ? streamData.data.bidPrice : data.bidPrice;
                    data.askQty = streamData.data.askQty ? streamData.data.askQty : data.askQty;
                    data.askPrice = streamData.data.askPrice ? streamData.data.askPrice : data.askPrice;
                    data.ltq = streamData.data.ltq ? streamData.data.ltq : data.ltq;
                    data.totBuyQty = streamData.data.totBuyQty ? streamData.data.totBuyQty : data.totBuyQty;
                    data.totSellQty = streamData.data.totSellQty ? streamData.data.totSellQty : data.totSellQty;
                }
            }
            return data;
        })
        this.setState({ filterSymbols: updateCurrentList })
    }

    onStreamCallbackTR(streamData) { 
        var updateCurrentList = this.state.topGainersLosersData;
        updateCurrentList = updateCurrentList.map(data => {
         
            if(data.sym.xSym===streamData.data.xSym){
                data.ltpclass = streamData.data.ltp ? this.getLtpClass(streamData.data.ltp, data.ltp, data.ltpclass) : data.ltpclass;
                data.ltp = streamData.data.ltp ? streamData.data.ltp : data.ltp;
                if (this.state.selectedPeriod === "FTD") {
                    data.chng = streamData.data.change ? calcChangeTR(streamData.data.change, data.sym.instr) : data.chng;
                    data.chngPer = streamData.data.perchange ? Number(streamData.data.perchange).toFixed(2) : data.chngPer;
                    data.vol = streamData.data.vol ? streamData.data.vol : data.vol;
                    data.bidQty = streamData.data.bidQty ? streamData.data.bidQty : data.bidQty;
                    data.bidPrice = streamData.data.bidPrice ? streamData.data.bidPrice : data.bidPrice;
                    data.askQty = streamData.data.askQty ? streamData.data.askQty : data.askQty;
                    data.askPrice = streamData.data.askPrice ? streamData.data.askPrice : data.askPrice;
                    data.ltq = streamData.data.ltq ? streamData.data.ltq : data.ltq;
                    data.totBuyQty = streamData.data.totBuyQty ? streamData.data.totBuyQty : data.totBuyQty;
                    data.totSellQty = streamData.data.totSellQty ? streamData.data.totSellQty : data.totSellQty;
                    
                }
            }            
            return data;
        })

        this.setState({ filterSymbols: updateCurrentList })
    }


    componentWillUnmount() {
        window.socketEvents.unregister(this.tag);
       // window.socketEventsTR.unregister(this.tag);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.showChartDialog !== this.props.showChartDialog || nextProps.showOrderEntry !== this.props.showOrderEntry) {
            if (nextProps.showChartDialog || nextProps.showOrderEntry) {
                window.socketEvents.unregister(this.tag);
                window.socketEventsTR.unregister(this.tag);
            } else {
                this.onSocketRegister(this.state.topGainersLosersData);
            }
        }
    }

    //add watchlist symbol
    onAddWatchlist(selectedData) {
        this.setState({ showWatchlistDlg: true, selectedSymbol: selectedData })
    }

    callbackAddWacthlist() {
        this.setState({ showWatchlistDlg: false });
    }

    onSymbolClick(row) {
        this.props.setScripInfo(row.sym);
        this.props.history.push("/home/scripdetails");
    }

    render() {
        var smallWidget = (
            <div className="small-widget">
                <div className="row contentpanel topgainerslosers-header">
                    <div className="col-3 col-lg-3 col-md-3 contentpanel nopadding ">
                        SYMBOL
                    </div>
                    <div className="col-2 col-lg-2 col-md-2 contentpanel topgain-head-pad text-right">
                        LTP
                    </div>
                    <div className="col-2 col-lg-2 col-md-2 contentpanel topgain-head-pad text-right">
                        CHNG
                    </div>
                    <div className="col-2 col-lg-2 col-md-2 contentpanel topgain-head-pad text-right">
                        CHNG%
                    </div>
                    <div className="col-3 col-lg-3 col-md-3 contentpanel topgain-head-pad text-right">
                        VOLUME
                    </div>
                </div>
                {this.state.topGainersLosersData.map(function (row, key) {
                    return (
                        <div key={key} className="row contentpanel topgainerslosers-row">
                            <div className="col-3 col-lg-3 col-md-3 contentpanel nopadding">
                                <span className="symbol-link" onClick={() => this.onSymbolClick(row)}>{row.displaySym}</span>
                            </div>
                            <div className="col-2 col-lg-2 col-md-2 contentpanel topgain-head-pad text-right">
                                {comaSeparator(row.ltp)}
                            </div>
                            <div className={row.chng.indexOf('-') ? "col-2 col-lg-2 col-md-2 contentpanel topgain-head-pad text-right positive-change" : "col-2 col-lg-2 col-md-2 contentpanel topgain-head-pad text-right negative-change"}>
                                {row.chng}
                            </div>
                            <div className={row.chngPer.indexOf('-') ? "col-2 col-lg-2 col-md-2 contentpanel topgain-head-pad text-right positive-change" : "col-2 col-lg-2 col-md-2 contentpanel topgain-head-pad text-right negative-change"}>
                                {row.chngPer}%
                                    </div>
                            <div className="col-3 col-lg-3 col-md-3 contentpanel topgain-head-pad text-right">
                                {comaSeparator(row.vol)}
                            </div>
                        </div>
                    )
                }, this)}
            </div>
        );

        var largeWidget = (
            <div className="fillheight">
                <div className="row contentpanel topgainerslosers-header">
                    <div className="col-10 col-lg-10 col-md-10 contentpanel">
                    <div className="row">
                        <div className="col-1 col-lg-1 col-md-1 contentpanel">
                            SYMBOL
                        </div>
                        <div className="col-1 col-lg-1 col-md-1 contentpanel text-center">
                            LTP
                        </div>
                        <div className="col-1 col-lg-1 col-md-1 contentpanel text-center">
                            CHNG
                        </div>
                        <div className="col-1 col-lg-1 col-md-1 contentpanel text-center">
                            CHNG%
                        </div>
                        <div className="col-1 col-lg-1 col-md-1 contentpanel text-center">
                            VOLUME
                        </div>
                        <div className="col-1 col-lg-1 col-md-1 contentpanel text-center">
                            BID
                        </div>
                        <div className="col-1 col-lg-1 col-md-1 contentpanel text-center">
                            OFFER
                        </div>
                        <div className="col-1 col-lg-1 col-md-1 contentpanel text-center">
                            BID QTY
                        </div>
                        <div className="col-1 col-lg-1 col-md-1 contentpanel text-center">
                            OFFER QTY
                        </div>
                        <div className="col-1 col-lg-1 col-md-1 contentpanel text-center">
                            LTQ
                        </div>
                        <div className="col-1 col-lg-1 col-md-1 contentpanel text-center">
                            TBQ
                        </div>
                        <div className="col-1 col-lg-1 col-md-1 contentpanel text-center">
                            TSQ
                        </div>
                        </div>
                    </div>
                    <div className="col-2 col-lg-2 col-md-2 contentpanel">

                    </div>
                </div>
                <div style={{ height: "calc(100% - 30px)", overflowY: 'auto' }} >
                    {this.state.topGainersLosersData.map(function (row, key) {
                        return (
                            <div key={key} className="row contentpanel topgainerslosers-row2">
                                <div className="col-10 col-lg-10 col-md-10 contentpanel">
                                <div className="row">
                                    <div className="col-1 col-lg-1 col-md-1 contentpanel topgainerslosers-row2-cell">
                                        <span className="symbol-link" onClick={() => this.onSymbolClick(row)}>{row.displaySym}</span>
                                    </div>
                                    <div className={"col-1 col-lg-1 col-md-1 contentpanel text-center topgainerslosers-row2-cell " + row.ltpclass + " tgl-ltp"}  >
                                        {comaSeparator(row.ltp)}
                                    </div>
                                    <div className={row.chng.indexOf('-') ? "col-1 col-lg-1 col-md-1 contentpanel text-center topgainerslosers-row2-cell positive-change" : "col-1 col-lg-1 col-ms-1 contentpanel text-center topgainerslosers-row2-cell negative-change"}>
                                        {comaSeparator(row.chng)}
                                    </div>
                                    <div className={row.chngPer.indexOf('-') ? "col-1 col-lg-1 col-md-1 contentpanel text-center topgainerslosers-row2-cell positive-change" : "col-1 col-lg-1 col-ms-1 contentpanel text-center topgainerslosers-row2-cell negative-change"}>
                                        {comaSeparator(row.chngPer)}%
                                </div>
                                    <div className="col-1 col-lg-1 col-md-1 contentpanel text-center topgainerslosers-row2-cell">
                                        {comaSeparator(row.vol)}
                                    </div>
                                    <div className="col-1 col-lg-1 col-md-1 contentpanel text-center topgainerslosers-row2-cell">
                                        {comaSeparator(row.bidPrice)}
                                    </div>
                                    <div className="col-1 col-lg-1 col-md-1 contentpanel text-center topgainerslosers-row2-cell">
                                        {comaSeparator(row.askPrice)}
                                    </div>
                                    <div className="col-1 col-lg-1 col-md-1 contentpanel text-center topgainerslosers-row2-cell">
                                        {comaSeparator(row.bidQty)}
                                    </div>
                                    <div className="col-1 col-lg-1 col-md-1 contentpanel text-center topgainerslosers-row2-cell">
                                        {comaSeparator(row.askQty)}
                                    </div>
                                    <div className="col-1 col-lg-1 col-md-1 contentpanel text-center topgainerslosers-row2-cell">
                                        {comaSeparator(row.ltq)}
                                    </div>
                                    <div className="col-1 col-lg-1 col-md-1 contentpanel text-center topgainerslosers-row2-cell">
                                        {comaSeparator(row.totBuyQty)}
                                    </div>
                                    <div className="col-1 col-lg-1 col-md-1 contentpanel text-center topgainerslosers-row2-cell">
                                        {comaSeparator(row.totSellQty)}
                                    </div>
                                </div>
                                </div>
                                <div className="col-2 col-lg-2 col-md-2 contentpanel text-right">
                                <div className="row">
                                    <div className="col-3 col-lg-3 col-md-3 contentpanel topgainerslosers-btn-holder">
                                        {/* <img src='assets/svg/chart_icon.svg' className="generic_chartimg" data-toggle="tooltip" title="Chart" onClick={() => { this.props.setChartSymbol(row.sym) }} /> */}
                                        <button type="button" className="topgainerslosers-btn chart" data-toggle="tooltip" title="Charts" onClick={() => { this.props.setChartSymbol(row.sym) }}>
                                            <img src='assets/svg/chart_icon_new2.svg' style={{ width: '100%', height: '100%' }} alt="chart" />
                                        </button>
                                    </div>
                                    <div className="col-3 col-lg-3 col-md-3 contentpanel topgainerslosers-btn-holder">
                                        {/* <img src='assets/svg/add_watchlist_2_new.svg' className="watch_chartimg2" data-toggle="tooltip" title="Add to Watchlist" onClick={() => this.onAddWatchlist(row.sym)} /> */}
                                        <button type="button" className="topgainerslosers-btn watch2" data-toggle="tooltip" title="Add to Watchlist" onClick={() => this.onAddWatchlist(row.sym)}>
                                            <img src='assets/svg/add_watchlist_2_new3.svg' style={{ width: '100%', height: '100%' }} alt="watchlist" />
                                        </button>
                                    </div>
                                    <div className="col-3 col-lg-3 col-md-3 contentpanel topgainerslosers-btn-holder">
                                        <button type="button" data-tag={row.displaySym} className="topgainerslosers-btn buy" onClick={this.gotoBuy.bind(this)} >BUY</button>
                                    </div>
                                    <div className="col-3 col-lg-3 col-md-3 contentpanel topgainerslosers-btn-holder">
                                        <button type="button" data-tag={row.displaySym} className="topgainerslosers-btn sell" onClick={this.gotoSell.bind(this)} >SELL</button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }, this)}
                </div>

                {
                    this.state.showWatchlistDlg && <SymbolWatchlistDialog
                        showWatchlistDlg={this.state.showWatchlistDlg}
                        symAddWatchlist={this.state.selectedSymbol}
                        callbackHandler={this.callbackAddWacthlist.bind(this)}
                        title="Add Scrips" />
                }

            </div>
        );

        var additionalHeader;
        var styles = { style: "gainIndicesDrop" };
        if (this.isInExpandedView()) {
            additionalHeader = (
                <div>
                    <div className="widget-title-component marginleft toggle-border">
                        <ToggleSwitch ref="exchangeSwitch" button1="NSE" button2="BSE" onChange={this.onExchangeSelected.bind(this)} />
                    </div>
                    <div className="widget-title-component marginleft indicesDropdown_minwidth">
                        <Dropdown style={styles.style} ref="indicesDropdown" dropdownType={commonDropDownType.DEFAULT} list={this.state.indices}
                            initialSelected={this.state.selectedIndex} onSelectionChanged={this.onIndicesChanged.bind(this)}
                            alignDefault={true} />
                    </div>
                    <div className="widget-title-component marginleft indicesDropdown_minwidth">
                        <Dropdown dropdownType={commonDropDownType.DEFAULT} list={this.state.periods} onSelectionChanged={this.onPeriodChanged.bind(this)} />
                    </div>
                </div>
            );
        }
        return (
            <BaseWidget title={this.props.title} componentType={this.props.componentType} inProgress={this.state.inProgress}
                error={this.state.error} additionalHeader={additionalHeader}
                isExpanded={this.isInExpandedView()} >
                {this.isInExpandedView() ? largeWidget : smallWidget}
            </BaseWidget>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showOrderEntry: state.orderentry.showDialog,
        showChartDialog: state.charts.showChartDialog,
        activeWidget: state.dashboard.activeWidget,
        watchListGroups: state.watchlist.watchListGroups,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showOrderDialog: (data) => showOrderDialog(dispatch, data),
        commonDialog: (payload) => commonDialog(dispatch, payload),
        setScripInfo: (data) => setScripInfo(dispatch, data),
        setChartSymbol: (data) => setChartSymbol(dispatch, data),
        getConfigByKey: (key) => getConfigByKey(key, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopGainersLoosersSmall)); 
