import React from "react"

class TabBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: props.activeTab ? props.activeTab : props.tabs[0]
        }
        this.setActiveTab = this.setActiveTab.bind(this);
    }

    componentDidMount() {
        if (this.props.selectedTab) {
            this.setState({ activeTab: this.props.selectedTab });
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.activeTab && nextProps.activeTab!==this.state.activeTab){
            this.setState({activeTab:nextProps.activeTab})
        }
    }
    setActiveTab(tab) {
        this.setState({activeTab : tab});
    }

    onTabbarClick(tab) {

        if(this.props.disabled)
            return;

        if (this.props.dropParent) {
            this.props.dropParent.classList.add("open");
        }
        this.setState({ activeTab: tab });
        if(this.props.callbackHandler){
            this.props.callbackHandler(tab);
        }
    }

    render() {
        var addClass="";
        if (this.props.tabalign === "justify") {
            addClass = addClass+"justify ";
        } else if(this.props.tabalign === "equal") {
            addClass = addClass+"equal ";
        } 
        if (this.props.highlight === "above") {
            addClass = addClass+"highlight-above "
        } else if (this.props.highlight === "below") {
            addClass = addClass+"highlight-below "
        }
        return (
            <ul className={"nav tab-bar "+ addClass}>
                {this.props.tabs.map(function (tab, key) {
                    return <li key={key} style={{ marginRight: this.props.nomargin ? '0' : '40' }} className={this.state.activeTab && tab && this.state.activeTab.toLowerCase() === tab.toLowerCase() ? "active" : null}
                       
                        onClick={() => this.onTabbarClick(tab)} >
                        <a className={  (this.props.highlightTextColor?this.props.highlightTextColor:'') + (this.props.disabled ? ' tabbar-disabled' : '') }>{tab}</a>
                    </li>
                }, this)}
            </ul>
        );
    }
}

export default TabBar;