import {  StoreKeys } from '../common/constants';

const intialState = {
    msactiveWidget: 'ALL'
}

const marketstats = (state = intialState, action) => {
    if ( action.type ===  StoreKeys.MARKETSTATS_ACTIVE_WIDGET){
        return Object.assign({}, {}, {msactiveWidget : action.payload}) ; 
    } else {
        return state; 
    }
};

export default marketstats;