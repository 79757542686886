import React from "react"
import SymbolSearch from '../../components/home/SymbolSearch'
import { symbolSearchType } from '../../common/constants'

class SymbolSearchDialog extends React.Component {
    constructor(props) {
        super(props);
        this.resetInput = this.resetInput.bind(this);
    }

    componentDidMount(){
        this.setInputFocus();
    }

    resetInput() {
        //TODO: check this
        this.searchRef.getWrappedInstance().clearSearchState();
    }

    onClickSymbol(data) {
        if (this.props.onSymbolSelected) {
            this.props.onSymbolSelected(data);
        }
    }
    setInputFocus(){
        this.searchRef.getWrappedInstance().dropParent.classList.add("show");
        this.searchRef.getWrappedInstance().dropChild.classList.add("show");
        this.searchRef.getWrappedInstance().searchInput.setFocus();
    }

    render() {
        return (
            <div className={"modal fade" + (this.props.showDialog ? "in dialog-show disabledbackground " : " dialog-hide")} id={this.props.id} role="dialog"
                onClick={()=>{
                    this.setInputFocus();
                }} >
                <div className="modal-dialog">
                    <div className="modal-header">
                        Select Scrip
                        {/* <i className="fa fa-close closebtn" onClick={this.props.handleNegativeButton} /> */}
                        <img src='assets/svg/close.svg' className="closebtn" data-toggle="tooltip" title="Close" onClick={this.props.handleNegativeButton} alt="close"/>
                    </div>
                    <SymbolSearch searchType={symbolSearchType.watchlist} 
                        ref={searchRef => { this.searchRef = searchRef }} 
                        isForSelection={true}
                        basketFlag={this.props.myflag}
                        basketListData={this.props.basketListData}
                        callbackHandler={this.onClickSymbol.bind(this)} keepOpen={true}
                        spotdisable={this.props.spotdisable} 
                        watchlistSearch={this.props.watchlistSearch ? this.props.watchlistSearch : false}
                        finaldataHide = {this.props.finalfalse}
                        />
                </div>
            </div>
        )
    }

}

export default SymbolSearchDialog;