import { UrlExtensions, BaseUrl } from '../../common/constants';
import { httpPost } from '../base/api'

// === Groups ====
export const getWatchlistGroups = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.WATCHLIST_GROUPS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const addWatchlistGroup = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.ADD_WATCHLIST;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);    
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const deleteWatchlistGroup = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.DELETE_WATCHLIST;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}


export const renameWatchlistGroup = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.RENAME_WATCHLIST;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const setFavouriteWatchlist = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.WATCHLIST_UPDATE_FAVOURITE;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Symbols ====
export const getWatchlistSymbols = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.WATCHLIST_SYMBOL_LIST;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const addSymbolToWatchlist = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.ADD_SCRIP_TO_WATCHLIST;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const deleteSymbolFromWatchlist = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.DELETE_SCRIP_FROM_WATCHLIST;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}
