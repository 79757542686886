import React, { Component } from 'react';
import BaseWidget from '../base/BaseWidget'
import { ModuleKeys, UrlExtensions } from '../../common/constants'
import { connect } from 'react-redux';
import { getHotNews, getLiveNewsDetail, getDerivativeNews } from '../../actions/dashboard/dashboardrequests'
//import DatePicker from '../../controls/DatePicker'

class HotNewsSmall extends Component {

    constructor(props) {
        super(props);
        this.smallReqCount = "6";
        this.expReqCount = "30";
        this.state = {
            inProgress: false,
            error: '',
            data: [],
        };
        this.companyNewsType = { neutral: "0", bullish: "1", bearish: "2" }
        this.isGetDetail = !(this.props.componentType === ModuleKeys.COMPANY_NEWS ||
            this.props.componentType === ModuleKeys.CURRENCY_NEWS ||
            this.props.componentType === ModuleKeys.COMMODITY_NEWS ||
            this.props.componentType === ModuleKeys.SECTOR_NEWS ||
            this.props.componentType === ModuleKeys.DISCUSSION_CLOUD)
    }

    componentDidMount() {
        this.smallReqCount = this.props.componentType === ModuleKeys.DISCUSSION_CLOUD ? "4" : "6";
        var data = {
            request: {
                data: {
                    section: this.props.section,
                    recordcount: this.props.activeWidget !== ModuleKeys.ALL ? this.expReqCount : this.smallReqCount
                }
            }
        };

        this.setState({ inProgress: true, error: '' });
        if (this.props.componentType === ModuleKeys.COMPANY_NEWS) {
            getHotNews(UrlExtensions.COMAPANY_NEWS, data, this.parseNewsResponse.bind(this), this.parseError.bind(this));
        } else if (this.props.componentType === ModuleKeys.CURRENCY_NEWS) {
            getHotNews(UrlExtensions.CURRENCY_NEWS, data, this.parseNewsResponse.bind(this), this.parseError.bind(this));
        } else if (this.props.componentType === ModuleKeys.COMMODITY_NEWS) {
            getHotNews(UrlExtensions.COMMODITY_NEWS, data, this.parseNewsResponse.bind(this), this.parseError.bind(this));
        } else if (this.props.componentType === ModuleKeys.SECTOR_NEWS) {
            getHotNews(UrlExtensions.SECTOR_NEWS, data, this.parseNewsResponse.bind(this), this.parseError.bind(this));
        } else if (this.props.componentType === ModuleKeys.DISCUSSION_CLOUD) {
            getHotNews(UrlExtensions.DISCUSSION_NEWS, data, this.parseNewsResponse.bind(this), this.parseError.bind(this));
        } else if (this.props.componentType === ModuleKeys.DERIVATIVE_NEWS) {
            delete data.request.data.section
            data.request.data.symbol = this.props.scripInfo.id
            getDerivativeNews(data, this.parseNewsResponse.bind(this), this.parseError.bind(this));
        }
        else
            getHotNews(UrlExtensions.HOT_NEWS, data, this.parseNewsResponse.bind(this), this.parseError.bind(this));
    }

    parseNewsResponse(responseData) {
        this.setState({
            data: responseData.response.data.news.map(data => { data.progress = false; return data; }),
            inProgress: false,
        }, () => {
            //this is for arrow animation
            window.$('.panel-collapse').on('show.bs.collapse', function () {
                window.$(this).parent().find('.hot-news-arrow').addClass('up');
            });

            window.$('.panel-collapse').on('hide.bs.collapse', function () {
                window.$(this).parent().find('.hot-news-arrow').removeClass('up');
            });

            if (this.props.activeWidget !== ModuleKeys.ALL && this.state.data && this.state.data.length > 0) {
               
                var list = window.$('.hot-news-panel');
                list[0].click();
            }
        });
    }

    parseError(error) {
        this.setState({ inProgress: false, error: error.message });
    }

    getNewsDetails(currentRow) {
        if (!currentRow.detailNews) {
            var data = {
                request: {
                    data: {
                        sno: currentRow.sno
                    }
                }
            };

            var updateData = this.state.data.map(data => {
                if (data.sno === currentRow.sno) {
                    data.progress = true;
                }
                return data;
            });
            this.setState({ data: updateData });
            getLiveNewsDetail(data, this.parseDetailsResponse.bind(this, currentRow.sno), this.parseError.bind(this));
        }
    }

    parseDetailsResponse(sno, responseData) {
        var updateDate = [...this.state.data].map(data => {
            if (data.sno === sno) {
                data.progress = false;
                data.detailNews = responseData.response.data.arttext;
                this.refs["innerhtml" + sno].innerHTML = data.detailNews;
            }
            return data;
        });
        this.setState({ data: updateDate });
    }

    getAdditonalHeader(bulletChange) {
        if (ModuleKeys.COMPANY_NEWS === this.props.componentType || ModuleKeys.SECTOR_NEWS === this.props.componentType) {
            return <div className="news-type-parent">
                <span className={"news-type green " + (bulletChange)}>Bullish</span>
                <span className={"news-type red " + (bulletChange)}>Bearish</span>
                <span className={"news-type " + (bulletChange)}>Neutral</span>
            </div>
        } 
        // else if (ModuleKeys.CORPORATE_NEWS === this.props.activeWidget) {
        //     return <div className="corporatenews-date">
        //         <DatePicker />
        //     </div>
        // }
    }

    getBulletType(type) {
        if (this.companyNewsType.bullish === type)
            return "green"
        else if (this.companyNewsType.bearish === type)
            return "red"
    }
    render() {

        var styleColValues = [];
        styleColValues = this.props.activeWidget === ModuleKeys.ALL ?
            ["col-12 col-lg-12 col-md-12 contentpanel"] :
            ["col-9 col-lg-9 col-md-9 contentpanel", "col-3 col-lg-3 col-md-3 contentpanel text-right"];
        var bulletChange = ModuleKeys.COMPANY_NEWS === this.props.componentType || ModuleKeys.SECTOR_NEWS === this.props.componentType;
        var additionalHeader = this.getAdditonalHeader(this.props.activeWidget === ModuleKeys.ALL ? "small" : "");
        return (
            <BaseWidget title={this.props.title} componentType={this.props.componentType}
                inProgress={this.state.inProgress}
                error={this.state.error}
                additionalHeader={additionalHeader}
                alignRight={this.props.activeWidget === ModuleKeys.ALL}>
                <div className="panel-group hotnews-parent" id="accordion">
                    {this.state.data.map(function (row, key) {
                        return (
                            <div key={key} className="hot-panel">
                                <div className=" contentpanel hotnews-row">
                                    <div>
                                        {this.props.activeWidget === ModuleKeys.ALL || this.props.componentType === ModuleKeys.DISCUSSION_CLOUD || this.props.componentType === ModuleKeys.DERIVATIVE_NEWS ?
                                            <li className={styleColValues[0] + " hotnews-li"}   >
                                                <a className={"hot-news-panel small " + (bulletChange && this.getBulletType(row.sentiment))}>{
                                                    this.props.componentType === ModuleKeys.DERIVATIVE_NEWS ? row.title :
                                                        this.props.componentType === ModuleKeys.DISCUSSION_CLOUD ? row.companyname : row.heading
                                                }</a>
                                                {this.props.componentType === ModuleKeys.DISCUSSION_CLOUD && <div className="hot-news-discussion" >{row.keyword} </div>}
                                            </li>

                                            : [<li key={key} className={" hotnews-li"} onClick={() => {
                                                this.isGetDetail && this.getNewsDetails(row);
                                            }}
                                            >
                                                <div className="row contentpanel" style={{ cursor: 'pointer' }}
                                                    data-toggle="collapse" data-parent="#accordion" href={'#panel' + key} >
                                                    <a className={styleColValues[0] + " contentpanel hot-news-panel " + (bulletChange && this.getBulletType(row.sentiment))} >{row.heading}
                                                        <i className="fa fa-angle-down hot-news-arrow"></i>
                                                        {row.progress &&
                                                            <svg class="circular-progress tiny" viewBox="25 25 50 50">
                                                                <circle class="circular-progress_path" cx="50" cy="50" r="20"
                                                                    fill="none" stroke-width="2" stroke-miterlimit="10" />
                                                            </svg>}
                                                    </a>
                                                    <div className={styleColValues[1]}>
                                                        {row.datetime ? row.datetime : row.date}
                                                    </div>
                                                </div>

                                            </li>,

                                            ]
                                        }
                                    </div>
                                </div>
                                <div className={"panel-collapse " + (row.detailNews || row.description ? "collapsing" : "hide")} id={"panel" + key}>
                                    <div className="jumbotron panel-body hot-news-accordion" ref={"innerhtml" + row.sno} >
                                        {row.description && row.description}
                                    </div>
                                </div>
                            </div>
                        )
                    }, this)}
                </div>
            </BaseWidget>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeWidget: state.dashboard.activeWidget,
        scripInfo: state.scripdetails.scripInfo
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HotNewsSmall);