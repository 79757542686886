import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import Dropdown from '../../controls/Dropdown'
import { commonDropDownType} from "../../common/constants"
import { watchlistDownloadOptions, fileExportTypes } from '../../common/constants'
import SymbolSearchDialog from '../../controls/symbolsearch/SymbolSearchDialog'
import BasicDialog from '../../controls/watchlist/BasicDialog'
import {getBasketListrequest, BasketExecutionLogrequest} from '../../actions/products/productrequest'
import { Column } from '../../common/exporter';
import Dialog from "../../controls/Dialog";


class BasketOrderBaseWidget extends Component {

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedBasketOrder: '',           
            selecttodelete: false,
            showdeletescripsconfirmation: false,
            showaddscripsdialog: false,
            flag:'mybasket',
            basketList:[],
            selectedPin:{},
            DeleteBAsketID:[],
            selectedBasketId:[],
            BasketStatus:'N',
            Logflag:'',
            FirstId: [],
            showDialog:false,
            message:'',
            Dropdownflag:true
        };
        console.log("this.props.orderData");
        console.log(this.props.orderData);
        this.onCollapseClick = this.onCollapseClick.bind(this);
        this.onClickPrinter = this.onClickPrinter.bind(this);
        this.askAddScrips = this.askAddScrips.bind(this);
        this.onClickCreateBasket = this.onClickCreateBasket.bind(this)
    }

    // basket list
    componentDidMount() {
        this.props.setClick(this.askAddScrips);
        this.props.setClick2(this.onClickCreateBasket);
        this.requestBasketList();
        
    }

    requestBasketList(){
        var requestData = {
            request: {
                data: {
                    status: this.state.BasketStatus
                }
            }
        };
        getBasketListrequest(requestData, this.parseBasketListResponce.bind(this), this.parseBasketListError.bind(this));
    }
    parseBasketListResponce(responseData){
        if(responseData.response.data.data.length == 0){
            this.setState({
                basketList:[],
                Dropdownflag:false
            }, () =>{
                // this.BasketDpDn.setSelected('');
                if(this.props.onClickBasketExecution)
                this.props.onClickBasketExecution(this.state.Logflag, this.state.basketList);
            })
        }
        var basketList = responseData.response.data.data.map(data => {
            data.name = data.name,
            data.id = data.id
            return data;
        })
        var selectedPin = {};
        selectedPin = basketList[0]
        this.setState({
            basketList : basketList,
            selectedPin:selectedPin,
            FirstId: basketList[0].id
        }, () => {

            this.BasketDpDn.setSelected(this.state.selectedPin ? this.state.selectedPin.name : '');           
        })
        this.onBasketOrderSelected(this.state.selectedPin.name, 0 );
    }
    parseBasketListError(){

    }
    // end basket list

    componentWillReceiveProps(newflg){
        if(newflg.OnClickFinal == '123'){
            this.requestBasketList(),
            this.ResetProps();
        }
    }
    ResetProps(){
        if(this.props.ResetProps){
            this.props.ResetProps();
        }
    }

    onCollapseClick() {
        if(this.props.onCollapseCallback) {
            this.props.onCollapseCallback();
        }
        else
            this.props.history.push("/home/dashboard");
    }

    askRenameBasket(){
        if (this.props.askRenameBasket)
            this.props.askRenameBasket();
    }

    askDeleteBasket(){
        if (this.props.askDeleteBasket)
                this.props.askDeleteBasket();            
    }

    onWatchlistSelected(selected) {
    }

    onDownloadOptionSelected(selected) {
        //WARNING: considering values based on index
        if (selected == watchlistDownloadOptions[0].name) { // excel            
                this.props.onWatchlistSelected(this.state.selectedPin.name + ".xlsx", fileExportTypes.EXCEL);
        }
        else if (selected == watchlistDownloadOptions[1].name) { // csv
            this.props.onWatchlistSelected(this.state.selectedPin.name + ".csv", fileExportTypes.CSV);
        }
    }

    toggleFilterScrips() {
        
     if (this.props.toggleFilterScrips)
        this.props.toggleFilterScrips();
    }

    onClickExecuteBasket (){        
        if(this.state.basketList.length == '0'){
            this.setState({
                message : "You don't have any basket.",               
            }, () => {
                this.ontoggleButton();
            })
        }
        else if(this.props.orderData.length =='0'){
            this.setState({
                message : "You don't have any symbol.",               
            }, () => {
                this.ontoggleButton();
            })
        }
        else{
            if(this.props.onClickExecuteBasket)
            this.props.onClickExecuteBasket();
        }        
    }

    onClickCreateBasket(){
        this.setState({
            Dropdownflag:true
        })
        if(this.props.onClickCreateBasket)
            this.props.onClickCreateBasket();   
    }

    onClickBasketExecution(flag){   
        this.setState({
            BasketStatus:'Y',
            Logflag:'EXCUTIONLOG',
            Dropdownflag:true
        }, () => {
            if(this.props.onClickBasketExecution)
            this.props.onClickBasketExecution(this.state.Logflag);
            this.requestBasketList();
        })
    }

    confirmDeleteScrips() {
        this.props.deleteBasketOrderSymbols(this.props.selectedSymbolsToDelete, this.state.selectedBasketOrder);
        this.setState({ showdeletescripsconfirmation: false, selecttodelete: false }, () => {
            if (this.props.toggleDeletingScrips)
                this.props.toggleDeletingScrips(false);
        });
    }

    cancelDeleteScrips() {
        this.setState({ showdeletescripsconfirmation: false, selecttodelete: false }, () => {
            if (this.props.toggleDeletingScrips)
                this.props.toggleDeletingScrips(false);
        });
    }

    askAddScrips = () => {
        this.searchDialog.resetInput();
        this.setState({ showaddscripsdialog: true });
    }

    onAddScripCancel() {
        this.setState({ showaddscripsdialog: false });
    }

    askDeleteScrips(){
        if (!this.state.selecttodelete) {
            this.setState({ selecttodelete: true }, () => {
                if (this.props.toggleDeletingScrips)
                    this.props.toggleDeletingScrips(true);
            });
        }
        else {
            if (this.props.selectedSymbolsToDelete && this.props.selectedSymbolsToDelete.length > 0) {
                this.setState({ showdeletescripsconfirmation: true });
            }
            else {
                this.setState({ selecttodelete: false }, () => {
                    if (this.props.toggleDeletingScrips)
                        this.props.toggleDeletingScrips(false);
                });
            }
        }
    }
    

    onSymbolSelected(){

    }
   
    onClickPrinter(){

    }

    onBasketOrderSelected(selected, index) {

        if (this.props.onSelectedBasketOrder)
            this.props.onSelectedBasketOrder(selected, index, this.state.basketList[index], this.state.BasketStatus);
            
        this.setState({
            selectedPin: this.state.basketList[index],
            selectedBasketId: this.state.basketList[index].id
        }, () => {
        })
    }

// onClickBackBasket
    onClickBackBasket(){
        this.setState({
            BasketStatus:'N',
            Logflag:'',
            Dropdownflag:true
        }, () => {            
            if(this.props.onClickBasketExecution)
            this.props.onClickBasketExecution(this.state.Logflag);
            this.requestBasketList();
        })
    }

    showdialoFase =() =>{
        this.setState({
            showaddscripsdialog:false
        }, () =>{
            
        })
    }

    ontoggleButton(){
        this.setState({
                showDialog:true,
                message:<p>{this.state.message}</p>
        }, () => {
        });
    }
    closePopup() {
        this.setState({
            showDialog: false,
            error:false                  
        }, () => {
        });
    }


    render() {
        return (
            <div className="fillheight orderbookbase-content-holder">
                <div className="row contentpanel widget-title">
                    <div className="col-xs-6 watchlist-title-holder contentpanel"> 
                    {this.state.Dropdownflag ?
                        <div className="widget-title-component">                            
                            <Dropdown
                                ref={BasketDpDn => this.BasketDpDn = BasketDpDn}
                                dropdownType={commonDropDownType.DEFAULT} alignDefault={true} 
                                list={this.state.basketList} 
                                onSelectionChanged={this.onBasketOrderSelected.bind(this)}/>
                        </div>
                        :
                        ''
                        }
                        {this.state.Logflag == 'EXCUTIONLOG' ?
                        '' 
                        :   
                        <div className="row">
                            {this.state.basketList.length == 0  ?
                             ''
                             :
                                <div className="widget-title-component">                        
                                    <button type="button" className="nobackgroundbutton" onClick={() => {this.askRenameBasket()}} >
                                        <img src='assets/svg/edit_columns.svg' className="watchlist-title-icon" data-toggle="tooltip" title="Rename Basket" alt="watchlist"/>
                                    </button>                                
                                </div>
                            }
                            <div className="widget-title-component" style={ this.state.basketList.length == 0 ? {marginLeft: '15px', marginRight:"10px"} : {marginRight:"10px"}} 
                            onClick={() => {this.onClickCreateBasket()}}>
                                <input type='button' className="basket-btn" value='CREATE BASKET'/>
                            </div>
                            <div className="widget-title-component" style={{marginRight:"10px"}} 
                            onClick={() => {this.onClickExecuteBasket()}}>
                                <input type='button' className="basket-btn" value='EXECUTE BASKET'/>
                            </div>
                            {this.state.basketList.length == 0  ?
                             ''
                             :
                            <div className="widget-title-component" style={{marginRight:"10px"}} onClick={() => {this.askDeleteBasket()}}>
                                {/* <img src='assets/svg/add_new_watchlist.svg' className="watchlist-title-icon" onClick={() => {this.askDeleteBasket()}}
                                        data-toggle="tooltip" title="Delete Basket" alt="watchlist"/> */}
                                <input type="button" className="basket-btn" value='DELETE BASKET' />
                            </div>
                            }
                            
                        </div>
                        }   
                        <div className="widget-title-componen" style={this.state.Logflag == "EXCUTIONLOG" ? {marginLeft: '15px', marginRight:"10px"} :{marginRight:"10px"}  } 
                            onClick={() => {this.onClickBasketExecution(this.state.Logflag)}}>
                            <input type='button' className="basket-log-btn" value='BASKET EXECUTION LOG'/>
                        </div>
                    </div>
                    
                    {this.state.Logflag == 'EXCUTIONLOG' ?
                        <div className="widget-title-component" style={{marginRight:"10px"}} onClick={() => {this.onClickBackBasket()}}>
                            <input type='button' className="basket-log-btn" style={{marginTop: '4px', padding: '2px 30px'}} value='BACK'/>
                        </div>
                    :
                    <div className="col-xs-6 contentpanel">
                        <div className="watchlistoptions">
                            <Dropdown dropdownType={commonDropDownType.CUSTOM}
                                    customeHeader={(<img src='assets/svg/download.svg' className="watchlist-title-icon" data-toggle="tooltip" title="Export" alt="download"/>)}
                                    list={watchlistDownloadOptions}
                                    style={"watchlist-title-icon pull-right"}
                                    alignDefault={true} id={"watchlist-download-drdown"}
                                    onSelectionChanged={this.onDownloadOptionSelected.bind(this)}
                            />
                        </div>
                        <div className="watchlistoptions">
                            {/* <img src='assets/svg/printer.svg' className="watchlist-title-icon" onClick={this.onClickPrinter} alt="printer"/> */}
                            <img src='assets/svg/printer.svg' className="watchlist-title-icon" onClick={() => window.print()} alt="printer"/>
                        </div>
                        {/* <div className="watchlistoptions" onClick={() => { this.toggleFilterScrips() }}>
                            <img src='assets/svg/filter.svg' className="watchlist-title-icon" data-toggle="tooltip" title="Filter" alt="filter"></img>
                        </div> */}
                        <div className="watchlistoptions">
                            <button type="button" className="nobackgroundbutton" disabled={false/*!this.state.selectedWatchilist.isEditable*/} onClick={this.askDeleteScrips.bind(this)} >
                                <i className="fa fa-minus watchlist-title-icon negative-change" />
                                <span>{this.state.selecttodelete ? "Delete Scrip(s)" : "Scrip"}</span>
                            </button>

                            <BasicDialog id="deletescripsdialog" ref="deletescripsdialog" title="DELETE SCRIP(S)" message="Do you want to delete selected scrips?" type="message"
                                showDialog={this.state.showdeletescripsconfirmation} negativebuttontext="CANCEL" possitivebuttontext="DELETE"
                                onNegativeButtonClick={this.cancelDeleteScrips.bind(this)} onPossitiveButtonClick={this.confirmDeleteScrips.bind(this)} />

                        </div>
                        <div className="watchlistoptions">
                            <button type="button" className="nobackgroundbutton" disabled={false/*!this.state.selectedWatchilist.isEditable*/} onClick={this.askAddScrips}  >
                                <i className="fa fa-plus watchlist-title-icon positive-change" />
                                <span>Scrip</span>
                            </button>

                            <SymbolSearchDialog ref={(searchDialog) => { this.searchDialog = searchDialog }}
                                showDialog={this.state.showaddscripsdialog}
                                handleNegativeButton={this.onAddScripCancel.bind(this)}
                                myflag={this.state.flag}
                                basketListData={this.state.basketList}
                                onSymbolSelected={this.onSymbolSelected.bind(this)}
                                finalfalse={this.showdialoFase}
                                 />
                        </div>                        
                    </div>
                    }
                </div>
                <div className="row hdivider" > </div>
                <div className="contentpanel fillwidth orderbookbase-content-body" style={{overflowY:'scroll'}}>
                    {this.props.children}
                </div>
                {this.state.showDialog?
                <Dialog header="Message" 
                    message={this.state.message}
                    negativeButtonName="Ok"
                    showDialog={this.state.showDialog}
                    negativeClick={this.closePopup.bind(this)} /> :''}

                
            </div>
        );
    }
}

export default withRouter(BasketOrderBaseWidget);