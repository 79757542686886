import React, { Component } from 'react';
import {tConv24,  comaSeparator } from '../../common/utils'
import Dialog from '../../controls/Dialog';
import { blockProgressBar } from '../../controls/progress'
import { addIPO } from '../../actions/products/productrequest'
import {getItemByKey} from '../../common/utils'
import {localStorageKeys} from '../../common/constants'


class IPOOrderRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inProgress:false,
            showIPODetails:false,
            cancelBid:false,
            updateRow:'',
            messageDailog:false,
            header:'',
            message:'',
            atCutOff:'',
            multiBid:'',
            cbid:'0',
            lot:'0',
            lot2:'0',
            lot3:'0',
            price:'0.00',
            price2:'0.00',
            price3:'0.00',
            bidRefNum:'',
            bidRefNum2:'',
            bidRefNum3:'',
            activityType:'',
            activityType2:'',
            activityType3:'',
            selectedType:0,
            bidstatus:'',
            totalQty:'',
            payableAmt:'',
            status:''
        }
        this.onShowCancelBid=this.onShowCancelBid.bind(this);
        this.onShowDetailsClick=this.onShowDetailsClick.bind(this);
        this.closePopup=this.closePopup.bind(this);
    }

    componentDidMount(){
        this.setState({            
            bidstatus:this.getActivityType(this.props.row.bids)
        }, ()=>{
            if(this.state.bidstatus)
            {                
                this.getValues(this.props.row, this.props.row.lotSize);                                                
            }
            else
            {
                this.findHighestBidRef(this.props.row.bids);
            }
        });

        
    }


    checkDateExceeds(inputDate) {        
        var date=new Date();
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        var cDate=new Date(year,monthIndex,day);
        var splitDate = inputDate.split("-");
        return  (new Date("'" + splitDate[1] + "/" + splitDate[0] + '/' + splitDate[2] + "'") >=cDate) ? true : false;        
    }
    

    getValues = (data, lotSize) => {
        var bids=data.bids;
        var set1=false;
        var set2=false;
        var set3=false;
        var higPrice=0;
        bids.map((row) => {
            if(row.activityType!="Cancelled")
            {   
                if(this.state.atCutOff)
                {
                    this.setState({
                        cbid:row.quantity/ lotSize,
                        price:data.cutOffPrice ,
                        bidRefNum: row.bidReferenceNumber,
                        activityType: "modify",
                        totalQty:row.quantity,
                        payableAmt:row.amount
                    });
                }
                else
                {
                    if(row.amount>higPrice)
                        higPrice=row.amount;
                    if(!set1)
                    {
                        
                        this.setState({
                            lot:row.quantity/ lotSize,
                            price:row.price ,
                            bidRefNum: row.bidReferenceNumber,
                            activityType: "modify",
                            totalQty:row.quantity,
                            payableAmt:higPrice
                        });
                        set1=true;
                    }
                    else if(!set2)
                    {
                        this.setState({
                            lot2:row.quantity/ lotSize,
                            price2:row.price ,
                            bidRefNum2: row.bidReferenceNumber,
                            activityType2: "modify",
                            totalQty:row.quantity,
                            payableAmt:higPrice
                        });
                        set2=true;
                    }
                    else if(!set3)
                    {
                        this.setState({
                            lot3:row.quantity/ lotSize,
                            price3:row.price ,
                            bidRefNum3: row.bidReferenceNumber,
                            activityType3: "modify",
                            totalQty:row.quantity,
                            payableAmt:higPrice
                        });
                        set3=true;
                    }
                }
            }
        })
    }


    findHighestBidRef(bids)
    {
        
        var highest = 0;
        var qty=0;
        var payamt=0;
        var atCutOff=false;
        bids.map((row, key) => {
            if(row.bidReferenceNumber>highest)
            { 
                highest=row.bidReferenceNumber;
                qty=row.quantity;
                payamt = row.amount;
                atCutOff=row.atCutOff;
            }
        })
        
        this.setState({
            totalQty:qty,
            payableAmt:payamt,
            atCutOff:atCutOff
        });
    }

    checkTime(startTime, endTime){
        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if(startTime<=time && endTime>=time)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    modifyBid= (event, row) =>{        
        event.stopPropagation();
        if(this.checkDateExceeds(row.biddingEndDate) && this.checkTime(row.dailyStartTime, row.dailyEndTime))
        {
            var data= {
                symbol:row.symbol,    
                clientName:row.clientName,  
                applicationNumber:row.applicationNumber,
                selectedType:this.state.atCutOff?0:1,
                atCutOff:this.state.atCutOff,
                multiBid:!this.state.atCutOff,
                cbid:this.state.atCutOff?this.state.cbid:this.state.lot,            
                lotSize:row.lotSize,
                lot:this.state.atCutOff?this.state.cbid:this.state.lot,
                lot2:this.state.lot2,
                lot3:this.state.lot3,
                price:this.state.price,
                price2:this.state.price2,
                price3:this.state.price3,
                bidRefNum:this.state.bidRefNum,
                bidRefNum2:this.state.bidRefNum2,
                bidRefNum3:this.state.bidRefNum3,
                pan:row.pan,
                dpId:row.dpId,
                upi:row.upi,
                depository:row.depository,
                clientBenId:row.clientBenId,
                type:row.type,
                activityType:this.state.activityType,
                activityType2:this.state.activityType2,
                activityType3:this.state.activityType3,
                minPrice:row.minPrice,
                maxPrice:row.maxPrice,
                discount:row.discount,
                name:row.name,
                cutOffPrice:row.cutOffPrice,
                minBidQuantity:row.minBidQuantity,
                minimumInvestmentAmt:row.minimumInvestmentAmt,
                maximumInvestmentAmt:row.maximumInvestmentAmt,
                tickSize:row.tickSize,
                modified:true,
                category: row.category
            }
            this.props.onClickBid("placeOrder", data);
        }
        else
        {
            this.setState({ messageDailog:true, header:'Message', message:"Your bid could not be modified because the market is closed for now. Please try between "+tConv24(row.dailyStartTime)+" to "+tConv24(row.dailyEndTime)+"."});
        }
    }

    getActivityType = (bids) => {
        var atCutOff=false;
        var status="";
        var cancelCount=0;
        bids.map((row, key) => {
            if(row.activityType!="Cancelled")
            {
                atCutOff= row.atCutOff ;
                if(row.activityType=="Ordered" && status=="")
                {
                    status="Ordered";
                }
                else if(row.activityType=="Modified")
                {
                    status="Modified";
                }
                else
                {
                    status=row.activityType;
                }
            }
            else
            {
                cancelCount=cancelCount+1;
            }
        });
        console.log("cancelCount===>"+cancelCount);
        console.log("bids.length===>"+bids.length);
        var activityStatus = (bids.length!=cancelCount)?true:false;
        this.setState({atCutOff:atCutOff, status:activityStatus?status:"Cancelled"});  
        return activityStatus;
    }


    onShowDetailsClick = () => {
        this.setState({ showIPODetails: !(this.state.showIPODetails) });
    }


    onShowCancelBid = (event, row) =>{
        event.stopPropagation();
        this.setState({ cancelBid: !(this.state.cancelBid), updateRow:row });
    }

    closePopup = () => {
        this.setState({ cancelBid: !(this.state.cancelBid) });
    }

    cancelBidRequest = () => {
        if(this.checkDateExceeds(this.state.updateRow.biddingEndDate) && this.checkTime(this.state.updateRow.dailyStartTime, this.state.updateRow.dailyEndTime))
        {
            this.setState({ inProgress:true, cancelBid: false });
            var requestData = {
                request: {
                    data: {
                        symbol:this.state.updateRow.symbol,    
                        clientName:this.state.updateRow.clientName,  
                        applicationNumber:this.state.updateRow.applicationNumber,
                        atCutOff:this.state.atCutOff,
                        multiBid:!this.state.atCutOff,
                        lot:this.state.atCutOff?this.state.cbid:this.state.lot,
                        lot2:this.state.lot2,
                        lot3:this.state.lot3,                    
                        lotSize:this.state.updateRow.lotSize,
                        price:this.state.price,
                        price2:this.state.price2 === "0.00" ? "" : this.state.price2,
                        price3:this.state.price3 === "0.00" ? "" : this.state.price3,
                        bidRefNum:this.state.bidRefNum,
                        bidRefNum2:this.state.bidRefNum2,
                        bidRefNum3:this.state.bidRefNum3,
                        pan:this.state.updateRow.pan,
                        dpId:this.state.updateRow.dpId,
                        upi:this.state.updateRow.upi,
                        depository:this.state.updateRow.depository,
                        clientBenId:this.state.updateRow.clientBenId,
                        activityType:(this.state.bidRefNum!=null && this.state.bidRefNum!="")?"cancel":"",
                        activityType2:(this.state.bidRefNum2!=null && this.state.bidRefNum2!="")?"cancel":"",
                        activityType3:(this.state.bidRefNum3!=null && this.state.bidRefNum3!="")?"cancel":"",
                        category: this.state.updateRow.category,
                        appKey: getItemByKey(localStorageKeys.PIB_APP_KEY) !== "" ? JSON.parse(getItemByKey(localStorageKeys.PIB_APP_KEY)) : ""
                        
                    }
                }
            };
            addIPO(requestData, this.parseCancelIPOResponse.bind(this), this.parseCancelIPOError.bind(this));
        }
        else
        {
            this.setState({ messageDailog:true, header:'Message', message:"Your bid could not be cancelled because the market is closed for now. Please try between "+tConv24(this.state.updateRow.dailyStartTime)+" to "+tConv24(this.state.updateRow.dailyEndTime)+"."});
        }
    }

    parseCancelIPOResponse(responseData){
        this.setState({  updateRow:'', inProgress:false, messageDailog:true, header:'Success', message:'Bid cancelled successfully.' });
    }

    checkCategory(name){
        var categoryVal = "";  
   switch(name){
       case "IND":
           categoryVal = "Individual";
           break;

       case "EMP":
           categoryVal = "Employee";
           break;

       case "POL":
           categoryVal = "Policy Holder";
           break;

   }
   return categoryVal;
}

    closeDailog(){
        
        if(this.state.message!='Message')
        {
            this.props.reloadOrders();
        }
        this.setState({  messageDailog:false, inProgress:false, header:'', message:'' });
        
    }

    parseCancelIPOError(error){
        this.setState({  updateRow:'', messageDailog:true, inProgress:false, header:'Error', message:error.message });
    }

    render()
    {
        return (
            <div >
                {this.state.inProgress ? blockProgressBar(): ''}
                {this.state.cancelBid?
                <Dialog header="Review & Confirm" message="Do you want to cancel the Bid?"
                    positiveButtonName="OK" positiveClick={this.cancelBidRequest.bind(this)}
                    negativeButtonName="Cancel" negativeClick={this.closePopup.bind(this)}/>
                :''}
                {this.state.messageDailog?
                <Dialog header={this.state.header} message={this.state.message}
                    positiveButtonName="OK" positiveClick={this.closeDailog.bind(this)}/>
                :''}
                
                <div className="ipo-row" onClick={(e) => {
                    this.onShowDetailsClick()
                }} >
                
                <div className="row fillwidth"  style={{padding: '10px 0px'}}>
                    <div className="col-4">
                        <span>
                            {this.props.row.symbol} - {this.props.row.name}
                        </span>
                    </div>
                    <div className="col-4 text-center">
                        <span>
                            {this.state.totalQty}{isNaN(this.state.totalQty/this.props.row.lotSize) ? "" :"/"+(this.state.totalQty/this.props.row.lotSize) }
                        </span>
                    </div>
                    <div className="col-3 text-center">
                        {this.props.row.allotmentStatus!=""?
                                (
                                    this.props.row.allotmentStatus=="Alloted"?
                                    <span style={{color:'#1EBF8A', fontWeight:'bold', textTransform:"capitalize"}}>                            
                                        {this.props.row.allotmentStatus}
                                    </span>
                                    :
                                    <span style={{color:'#E5283F', fontWeight:'bold', textTransform:"capitalize"}}>                            
                                        {this.props.row.allotmentStatus}
                                    </span>
                                )
                                :
                                (this.state.bidstatus?
                                    <span style={{color:'#1EBF8A', fontWeight:'bold', textTransform:"capitalize"}}>                            
                                        {this.state.status}
                                    </span>
                                    :<span style={{color:'#E5283F', fontWeight:'bold', textTransform:"capitalize"}}>                            
                                        {this.state.status}
                                    </span>
                                )
                        }
                    </div>
                    <div className="col-1 text-center">
                        {this.state.showIPODetails?
                            <i className="fa fa-angle-up"></i>
                            :<i className="fa fa-angle-down"></i>
                        }
                    </div>
                </div>
                {this.state.showIPODetails?
                
                <div className="ipo-row">
                    <div className="row hdivider" style={{marginLeft:'0px', marginRight:'0px'}}> </div>
                    <div className="row"  style={{padding: '10px 0px'}}>
                        <div className="col-2 text-center">
                            <strong>
                                Application ID
                            </strong>
                            <br></br>
                            {this.props.row.applicationNumber}
                        </div>
                        <div className="col-2 text-center">
                            <strong>
                                Beneficiary ID
                            </strong>
                            <br></br>
                            {this.props.row.depository=="NSDL"?(this.props.row.dpId+this.props.row.clientBenId):(this.props.row.clientBenId)}
                        </div>
                        <div className="col-2 text-center">
                            <strong>
                                Issue Type
                            </strong>
                            <br></br>
                            {this.props.row.type}
                        </div>
                        <div className="col-2 text-center">
                            <strong>
                                Payable Amount
                            </strong>
                            <br></br>
                            <i className="fa fa-rupee"></i>{comaSeparator(this.state.payableAmt)}
                        </div>
                        <div className="col-2 text-center">
                            <strong>
                                Cut-Off
                            </strong>
                            <br></br>
                            {this.state.atCutOff?"YES":"NO"}
                        </div>
                        <div className="col-2 text-center">
                            <strong>
                                Bid Date Time
                            </strong>
                            <br></br>
                            {this.props.row.timestamp}
                        </div>
                    </div>
                    <div className="row"  style={{padding: '10px'}}>
                        <div className="col-2 text-center">
                            <strong>
                                BID - 1
                            </strong>
                            <br></br>
                            {this.state.price=='0.00'?'-':
                                <span><i className="fa fa-rupee"></i>
                                {comaSeparator(this.state.price)}</span>
                            }   
                        </div>
                        <div className="col-2 text-center">
                            <strong>
                                Bid - 2
                            </strong>
                            <br></br>
                            {this.state.price2=='0.00'?'-':
                                <span><i className="fa fa-rupee"></i>
                                {comaSeparator(this.state.price2)}</span>
                            }
                        </div>
                        <div className="col-2 text-center">
                            <strong>
                                Bid - 3
                            </strong>
                            <br></br>
                            {this.state.price3=='0.00'?'-':
                                <span><i className="fa fa-rupee"></i>
                                {comaSeparator(this.state.price3)}</span>
                            }
                        </div>
                        <div className="col-2 text-center">
                            <strong>
                                UPI ID
                            </strong>
                            <br></br>
                            {this.props.row.upi}
                        </div>
                         <div className="col-2 text-center">
                            <strong>
                                Category
                            </strong>
                            <br></br>
                            {this.checkCategory(this.props.row.category)}
                        </div> 
                        {/* <div className="col-2 text-center">
                            <strong>
                                Bank Account Number
                            </strong>
                            <br></br>
                            {this.props.row.bankAccNo}
                        </div> */}
                        <div className="col-2 text-center">
                            <strong>
                                Alloted Qty
                            </strong>
                            <br></br>
                            {this.props.row.allotmentQty}
                        </div>
                        <div className="col-2 text-center">
                            <strong>
                                Transaction Status
                            </strong>
                            <br></br>
                            <strong>
                            {this.props.row.allotmentStatus!=""?
                                (
                                    this.props.row.allotmentStatus=="Alloted"?
                                    <span style={{color:'#1EBF8A', fontWeight:'bold', textTransform:"capitalize"}}>                            
                                        {this.props.row.allotmentStatus}
                                    </span>
                                    :
                                    <span style={{color:'#E5283F', fontWeight:'bold', textTransform:"capitalize"}}>                            
                                        {this.props.row.allotmentStatus}
                                    </span>
                                )
                                :
                                (this.state.bidstatus?
                                    <span style={{color:'#1EBF8A', fontWeight:'bold', textTransform:"capitalize"}}>                            
                                        {this.state.status}
                                    </span>
                                    :<span style={{color:'#E5283F', fontWeight:'bold', textTransform:"capitalize"}}>                            
                                        {this.state.status}
                                    </span>
                                )
                            }
                            </strong>
                        </div>
                    </div>
                    <div className="row"  style={{padding: '10px'}}>
                        <div className="col-2 text-center">
                            <strong>
                                Exchange Order No. 1
                            </strong>
                            <br></br>
                            {this.state.bidstatus?this.state.bidRefNum:'-'}
                        </div>
                        <div className="col-2 text-center">
                            <strong>
                            Exchange Order No. 2
                            </strong>
                            <br></br>
                            {this.state.bidstatus?this.state.bidRefNum2:'-'}
                        </div>
                        <div className="col-2 text-center">
                            <strong>
                            Exchange Order No. 3
                            </strong>
                            <br></br>
                            {this.state.bidstatus?this.state.bidRefNum3:'-'}
                        </div>
                        
                        
                    </div>
                    {this.state.bidstatus?
                        this.checkDateExceeds(this.props.row.biddingEndDate)
                        ?
                        <div className="row"  style={{padding: '10px'}}>
                            <div className="col-5 text-right">
                                <button className="bidButton" style={{padding:'8px 30px'}} 
                                            onClick={(e) => {
                                                                this.modifyBid(e, this.props.row)
                                                            }}                                            
                                                >MODIFY BID</button>
                            </div>
                            <div className="col-2">

                            </div>
                            <div className="col-5 text-left">
                                <button className="bidButton" style={{padding:'8px 30px'}} 
                                onClick={(e) => {
                                    this.onShowCancelBid(e, this.props.row)
                                }} 
                                >CANCEL BID</button>
                            </div>
                        </div>
                        :''
                    :''}
                </div>
                :''}
                <div className="row hdivider" style={{marginLeft:'0px', marginRight:'0px'}}> </div>
            </div>
            
            </div>
        )

    }
}

export default IPOOrderRow;