import React from 'react'

export const progressBar = () => {
    return <div className="widget-progress-holder">
        <img className="subh-loader" src="assets/images/loader.gif" alt="loader"/>
    </div>
}

export const blockProgressBar = () => {
    return <div className="orderinput-progress-holder">
        <img className="subh-loader" src="assets/images/loader.gif" alt="loader"/>
    </div>
}