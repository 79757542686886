import { UrlExtensions, BaseUrl } from '../../common/constants';
import { httpPost } from '../base/api'

export const getNotifications = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_NOTIFICATIONS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('notify succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}