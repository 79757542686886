import { UrlExtensions, BaseUrl, localStorageKeys } from '../../common/constants';
import { httpPost } from '../base/api';
import {getItemByKey} from '../../common/utils';
import {encryptUrl,serialiseForm,generateRandomString} from '../base/api';

// ====================== exchange pledge-unpledge  request =================

export const exchangePledgeUnpledge = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.EXCHANGE_PLEDGE_UNPLEDGE;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log("inside pledge request method");
        console.log('request succeeded with JSON response', response); 
        responseCallback(response);
        // return response;
    }).catch(function (error) {
        console.log('request failed', error);
        errorCallback(error);
    });
}

