

export const isValidWatchlistName = function (name) {
    if(!name || name.trim() === '') {
        return "Please enter valid input";
    }
    //TODO: add other validations


    //finally
    return '';
}
