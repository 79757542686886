import React from 'react'
import  '../css/summary.css'
class DetailsHeader  extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            _DETAILS_HEADER : ['Client', 'Contract Description' ,'Market Price' ,'Prev Pos Qty','Prev Pos Avg Price','Current Buy Qty','Current Buy Avg Price','Current Sell Qty','Current Sell Avg Price','Net Qty','Net Avg Price', 'ANOV','Booked PL','Notional PL','Product Type']
        }
    }
    renderDetailsHeader(){
        return this.state._DETAILS_HEADER.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
         })
     }
    render(){
        return(
            <thead>
                <tr>{this.renderDetailsHeader()}</tr>
            </thead>
        )
    }
}
export default DetailsHeader;