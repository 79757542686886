import React from "react";
import { connect } from "react-redux";
import { showOrderDialog } from '../../actions/orderentry/orderentryaction'
import WatchlistRowItem from './WatchlistRowItem';
import { DropTarget } from 'react-dnd';
import WatchlistBaseWidget from '../base/WatchlistBaseWidget'
import { ModuleKeys, WatchlistColumns, TradeActions, orderEntryTypes} from '../../common/constants'
import { numericSort, calcChange,calcChangeTR, calcChangePercentage } from '../../common/utils'
import { Workbook } from '../../common/exporter'
import { getWatchlistSymbols, deleteSymbolFromWatchlist } from '../../actions/watchlist/watchlistrequests';
import { showWatchlistError } from "../../actions/watchlist/watchlistaction"
import { setChartSymbol } from '../../actions/chartiq/chartaction'

class FilterInput extends React.Component {

    componentWillReceiveProps(newProps) {
        if (!newProps.display) {
            this.refs.inputText.value = '';
        }
    }

    render() {
        return (

            <div className={"watch-filter-parent "} >
                {this.props.placeholder ?
                    <input ref="inputText" className="watch-input-filter" type="text"
                        placeholder={this.props.placeholder}
                        onChange={(e) => this.props.onChangeFilter(e.target.value.toLowerCase(), this.props.id)} />
                    :
                    ''
                }
            </div>
        )
    }
}

class WatchlistView2 extends React.Component {
    constructor(props) {
        super(props);
        this.tag = this.props.componentType;
        this.state = {
            inProgress: false,
            error: '',
            symbols: [],
            filterSymbols: [],
            exportabledata: [],
            selectedwatchlist: {},

            selecttodelete: false,
            selectedsymbolstodelete: [],
            showFilter: false,
            //onWatchListTabs: '',

            custom_cols_headers: [],
            default_custom_cols: ['4', '5', '6', '7', '58', '9', '10', '11', '12', '13'],

            right_default_custom_cols: ['4', '5', '6', '7', '9', '13', '12', '17', '18', '58'],

            dynrow_width: '240px',

            sortAsc: {
                sym_sort: false, exc_sort: false, ltp_sort: false,
                chng_sort: false, chngPer_sort: false, bidPrice_sort: false, askPrice_sort: false,
                bidQty_sort: false, askQty_sort: false, ltq_sort: false, tbq_sort: false, tsq_sort: false, lut_sort: false
            },
        }

        this.dyncol_width = 120;

        this.calculateDynRowWidth = this.calculateDynRowWidth.bind(this);
        this.onPaneMouseEnter = this.onPaneMouseEnter.bind(this);
        this.onPaneMouseLeave = this.onPaneMouseLeave.bind(this);
        this.onRightPanelScroll = this.onRightPanelScroll.bind(this);
        this.onLeftPanelScroll = this.onLeftPanelScroll.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.onSortClicked2 = this.onSortClicked2.bind(this);
        this.onSocketRegister = this.onSocketRegister.bind(this);
        this.onStreamCallback = this.onStreamCallback.bind(this);
        this.onStreamCallbackTR = this.onStreamCallbackTR.bind(this);
        this.onStreamCallbackOI = this.onStreamCallbackOI.bind(this);
        this.getDataContentHeight = this.getDataContentHeight.bind(this);
        this.getFilterPlaceHolder = this.getFilterPlaceHolder.bind(this);
        this.symbolSelectionChanged = this.symbolSelectionChanged.bind(this);
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        window.socketEvents.unregister(this.tag);
        window.socketEventsTR.unregister(this.tag);        
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.showChartDialog !== this.props.showChartDialog || nextProps.showOrderEntry !== this.props.showOrderEntry) {
            if (nextProps.showChartDialog || nextProps.showOrderEntry) {
                window.socketEvents.unregister(this.tag);
                window.socketEventsTR.unregister(this.tag);
            } else {
                this.onSocketRegister(this.state.filterSymbols);
            }
        }
    }

    onWatchlistGroupSelected(group) {
        this.setState({ selectedwatchlist: group }, () => {
            this.requestWatchlistSymbols(group);
            this.updateCustomCols(group);
        })
        console.log(this.updateCustomCols(group))
    }

    toggleDeletingScrips(todelete) {
        this.setState({ selecttodelete: todelete, selectedsymbolstodelete: [] });
    }

    toggleFilterScrips() {
        if (this.state.showFilter) {
            this.setState({ showFilter: false, filterSymbols: this.state.symbols });
        }
        else {
            this.setState({ showFilter: true });
        }
    }

    afterColsUpdated(group) {
        this.updateCustomCols(group);
    }

    updateCustomCols(group) {

        // if (watchlistTabs[0] === this.state.onWatchListTabs) {


        if (group.settings) {
            let list = group.settings.equityColumns ? group.settings.equityColumns : [];
            list = list.concat(group.settings.futureColumns);
            list = list.concat(group.settings.optionsColumns);

            let headers = WatchlistColumns.filter(i => list.indexOf(i.id) !== -1);
            // let headers = WatchlistColumns.filter(i => this.state.default_custom_cols.indexOf(i.id) !== -1);


            if (headers && headers.length > 0) {
                this.setState({ custom_cols_headers: headers }, () => {
                    this.calculateDynRowWidth();
                });
                return;
            }
        }
        // }

        // no custom columns. so, set default custom columns

        let right_headers = WatchlistColumns.filter(i => this.state.right_default_custom_cols.indexOf(i.id) !== -1);
        this.setState({ custom_cols_headers: right_headers }, () => {
            this.calculateDynRowWidth();
        });

    }

    // tabHabndlerClick 

    onTabClickHandler = (tab) => {
        this.setState({
            onWatchListTabs: tab

        });

    }
    calculateDynRowWidth() {
        this.setState({ dynrow_width: (this.dyncol_width * this.state.custom_cols_headers.length + 8) })
    }


    onExportOptionSelected(filename, filetype) {
        var list = this.state.symbols.slice();
        if (list.length > 0) {
            list.forEach(element => {
                element["scrip"] = element.sym.id;
                element["exc"] = element.sym.exc;
            });

            this.setState({ exportabledata: list }, () => {
                this.refs.workbook.download(filename, filetype);
            });
        } else {
            this.props.showWatchlistError({ header: "Watchlist", message: 'No data available in the watchlist' });
        }
    }

    getSymbolsCount() {
        return this.state.symbols.length;
    }

    getActiveState() {
        var statedata = {};
        statedata.showFilter = this.state.showFilter;


        return statedata;

    }

    symbolSelectionChanged(e, item) {
        if (e.target.checked) {
            this.state.selectedsymbolstodelete.push(item);
        }
        else {
            this.state.selectedsymbolstodelete.pop(item);
        }
    }

    gotoChart(sym) {
        this.props.setChartSymbol(sym);
    }

    gotoBuy(row) {
        var orderdetails = this.getOrderDetails(row, TradeActions.BUY);
        this.props.showOrderDialog(orderdetails);
    }

    gotoSell(row) {
        var orderdetails = this.getOrderDetails(row, TradeActions.SELL);
        this.props.showOrderDialog(orderdetails);
    }

    getOrderDetails(item, taction) {

        var orderdetails = {
            orderentrytype: orderEntryTypes.NEW,
            action: taction,
            symobj: item.sym,
            displaySym: item.displaySym,
            company: item.companyName,
            last: "0.00",
            change: "0.00",
            changeper: "0.00"
        };
        return orderdetails;
    }

    isInExpandedView() {
        return (this.props.activeWidget === ModuleKeys.WatchlistView1 || this.props.activeWidget === ModuleKeys.WatchlistView2);
    }


    // =================== Scrolling ==================

    onPaneMouseEnter(panel) {
        if (panel === 'right') {
            this.rightpanelscrolling = true;
        }
        else if (panel === 'left') {
            this.rightpanelscrolling = false;
        }
        else if (panel === 'top') {
            this.enabletopscrolling = true;
            this.rightpanelscrolling = false;
        }
    }

    onPaneMouseLeave(e) {
        e.preventDefault();
    }

    onRightPanelScroll() {
        if (!this.rightpanelscrolling) return;
        window.requestAnimationFrame(() => {
            this.refs.leftPanel.scrollTop = this.refs.rightPanel.scrollTop;
            this.refs.topPanel.scrollLeft = this.refs.rightPanel.scrollLeft;
        });

    }

    onLeftPanelScroll() {
        if (this.rightpanelscrolling) return;
        window.requestAnimationFrame(() => {
            this.refs.rightPanel.scrollTop = this.refs.leftPanel.scrollTop;
        });

    }

    onTopPanelScroll() {
        if (this.rightpanelscrolling) return;

        // if(this.refs.rightPanel.scrollLeft - this.refs.topPanel.scrollLeft === 0) {
        //     return;
        // }

        window.requestAnimationFrame(() => {
            this.refs.rightPanel.scrollLeft = this.refs.topPanel.scrollLeft;
        });

    }

    // ====== Filtering ==============================

    getDataContentHeight() {
        if (!this.state.showFilter)
            return 20;
        else
            return 40;
    }

    getFilterPlaceHolder(row) {
        if (row.type === 'num') {
            return "eg:<= or >= 50";
        } else if (row.type === 'string') {
            return "eg: abc";
        } else {
            return "";
        }
    }

    onChangeFilter(value, apikey) {
        var filterdata = this.state.symbols;

        if (apikey === 'sym') {
            filterdata = filterdata.filter(data => {
                return data.sym.id.toLowerCase().indexOf(value) >= 0 ||
                    (data.companyName && data.companyName.toLowerCase().indexOf(value) >= 0)
            });
        }
        else if (apikey === 'exc') {
            filterdata = filterdata.filter(data => {
                return data.sym.exc.toLowerCase().indexOf(value) >= 0
            });
        }
        else {
            var col = WatchlistColumns.filter(i => i.apikey === apikey);
            if (col[0].type === 'string') {
                filterdata = filterdata.filter(data => {
                    return data[apikey] ? data[apikey].toLowerCase().indexOf(value) >= 0 : false;
                });
            }
            else if (col[0].type === 'num') {
                if (value.charAt(0) === "<" && value.charAt(1) === "=" && value.length > 2) {
                    try {
                        filterdata = filterdata.filter(data => {
                            return parseFloat(data[apikey]) <= parseFloat(value.substr(2))
                        });
                    }
                    catch (ex) { }
                }
                else if (value.charAt(0) === ">" && value.charAt(1) === "=" && value.length > 2) {
                    try {
                        filterdata = filterdata.filter(data => {
                            return parseFloat(data[apikey]) >= parseFloat(value.substr(2))
                        });
                    }
                    catch (ex) { }
                }
                else if (value.charAt(0) === "<" && value.length > 1) {
                    try {
                        filterdata = filterdata.filter(data => {
                            return parseFloat(data[apikey]) < parseFloat(value.substr(1))
                        });
                    }
                    catch (ex) { }
                }
                else if (value.charAt(0) === "=" && value.length > 1) {
                    try {
                        filterdata = filterdata.filter(data => {
                            return parseFloat(data[apikey]) === parseFloat(value.substr(1))
                        });
                    }
                    catch (ex) { }
                }
                else if (value.charAt(0) === ">" && value.length > 1) {
                    try {
                        filterdata = filterdata.filter(data => {
                            return parseFloat(data[apikey]) > parseFloat(value.substr(1))
                        });
                    }
                    catch (ex) { }
                }

            }
        }

        this.setState({ filterSymbols: filterdata }, () => {
            if (this.isInExpandedView())
                this.refs.rightPanel.scrollLeft = this.refs.topPanel.scrollLeft;
        });
    }



    // ====== Sorting =================================

    onSortClicked(e) {
        var temp = this.state.filterSymbols, sortTemp = this.state.sortAsc;

        switch (e.currentTarget.id) {
            case "sym_sort":
                sortTemp.sym_sort = !sortTemp.sym_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.sym_sort) {
                    temp = temp.sort(function (first, next) {
                        if (first.sym.id < next.sym.id) {
                            return -1;
                        } else if (first.sym.id > next.sym.id) {
                            return 1;
                        }
                        return 0;
                    });
                } else {
                    temp = temp.sort(function (first, next) {
                        if (first.sym.id < next.sym.id) {
                            return 1;
                        } else if (first.sym.id > next.sym.id) {
                            return -1;
                        }
                        return 0;
                    });
                }
                break;
            case "exc_sort":
                sortTemp.exc_sort = !sortTemp.exc_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.exc_sort) {
                    temp = temp.sort(function (first, next) {
                        if (first.sym.exc < next.sym.exc) {
                            return -1;
                        } else if (first.sym.exc > next.sym.exc) {
                            return 1;
                        }
                        return 0;
                    });
                } else {
                    temp = temp.sort(function (first, next) {
                        if (first.sym.exc < next.sym.exc) {
                            return 1;
                        } else if (first.sym.exc > next.sym.exc) {
                            return -1;
                        }
                        return 0;
                    });
                }
                break;
            case "ltp_sort":
                sortTemp.ltp_sort = !sortTemp.ltp_sort;
                this.setState({ sortAsc: sortTemp });
                temp = numericSort(temp, "ltp", this.state.sortAsc.ltp_sort ? "ASC" : "DSC")
                break;
            case "chng_sort":
                sortTemp.chng_sort = !sortTemp.chng_sort;
                this.setState({ sortAsc: sortTemp });
                temp = numericSort(temp, "chng", this.state.sortAsc.chng_sort ? "ASC" : "DSC")
                break;
            case "chngPer_sort":
                sortTemp.chngPer_sort = !sortTemp.chngPer_sort;
                this.setState({ sortAsc: sortTemp });
                temp = numericSort(temp, "chngPer", this.state.sortAsc.chngPer_sort ? "ASC" : "DSC")
                break;
            case "bidPrice_sort":
                sortTemp.bidPrice_sort = !sortTemp.bidPrice_sort;
                this.setState({ sortAsc: sortTemp });
                temp = numericSort(temp, "bidPrice", this.state.sortAsc.bidPrice_sort ? "ASC" : "DSC")
                break;
            case "askPrice_sort":
                sortTemp.askPrice_sort = !sortTemp.askPrice_sort;
                this.setState({ sortAsc: sortTemp });
                temp = numericSort(temp, "askPrice", this.state.sortAsc.askPrice_sort ? "ASC" : "DSC")
                break;
            case "bidQty_sort":
                sortTemp.bidQty_sort = !sortTemp.bidQty_sort;
                this.setState({ sortAsc: sortTemp });
                temp = numericSort(temp, "bidQty", this.state.sortAsc.bidQty_sort ? "ASC" : "DSC")
                break;
            case "askQty_sort":
                sortTemp.askQty_sort = !sortTemp.askQty_sort;
                this.setState({ sortAsc: sortTemp });
                temp = numericSort(temp, "askQty", this.state.sortAsc.askQty_sort ? "ASC" : "DSC")
                break;
            case "ltq_sort":
                sortTemp.ltq_sort = !sortTemp.ltq_sort;
                this.setState({ sortAsc: sortTemp });
                temp = numericSort(temp, "ltq", this.state.sortAsc.ltq_sort ? "ASC" : "DSC")
                break;
            case "tbq_sort":
                sortTemp.tbq_sort = !sortTemp.tbq_sort;
                this.setState({ sortAsc: sortTemp });
                temp = numericSort(temp, "totBuyQty", this.state.sortAsc.tbq_sort ? "ASC" : "DSC")
                break;
            case "tsq_sort":
                sortTemp.tsq_sort = !sortTemp.tsq_sort;
                this.setState({ sortAsc: sortTemp });
                temp = numericSort(temp, "totSellQty", this.state.sortAsc.tsq_sort ? "ASC" : "DSC")
                break;
            case "lut_sort":
                sortTemp.lut_sort = !sortTemp.lut_sort;
                this.setState({ sortAsc: sortTemp });
                temp = numericSort(temp, "lTradedTime", this.state.sortAsc.lut_sort ? "ASC" : "DSC")
                break;
            default: break;

        }
        this.setState({ filterSymbols: temp });
    }

    onSortClicked2(e) {
        var apikey = e.target.getAttribute('id');
        try {
            if (this.state.cursort === 'DSC') {
                let temp = numericSort(this.state.symbols, apikey, 'ASC');
                this.setState({ filterSymbols: temp, cursort: 'ASC' });
            }
            else {
                let temp = numericSort(this.state.symbols, apikey, 'DSC');
                this.setState({ filterSymbols: temp, cursort: 'DSC' });
            }
        }
        catch (ex) {

        }

    }
    // ====== Drag Sort =================================

    moveCard(dragIndex, hoverIndex) {

        const dragCard = this.state.filterSymbols[dragIndex];

        var list = this.state.filterSymbols;
        list.splice(dragIndex, 1);
        list.splice(hoverIndex, 0, dragCard);
        this.setState({ filterSymbols: list });

    }

    onDragStart(index) {

        const dragCard = this.state.filterSymbols[index];
        this.setState({ draggingrowkey: dragCard.uniqueId });
    }

    onDragEnd() {
        this.setState({ draggingrowkey: '' });
    }

    // === Get symbols ======
    requestWatchlistSymbols(selectedwatchlist) {
        window.socketEvents.unregister(this.tag);
        window.socketEventsTR.unregister(this.tag);
        var requestData = {
            request: {
                data: {
                    isEditable: selectedwatchlist.isEditable,
                    name: selectedwatchlist.name,
                    channel: selectedwatchlist.channel,
                    id: selectedwatchlist.id,
                    type: selectedwatchlist.type
                }
            }
        };
        this.setState({ symbols: [], showFilter: false, inProgress: true, error: "" });
        getWatchlistSymbols(requestData, this.parseRequestWatchlistSymbolsResponse.bind(this, selectedwatchlist), this.parseRequestWatchlistSymbolsError.bind(this, selectedwatchlist));
    }

    parseRequestWatchlistSymbolsResponse(selected, responseData) {
        if (selected.id === this.state.selectedwatchlist.id) {
            if (responseData.response.data.symbols && responseData.response.data.symbols.length > 0) {
                this.setState({
                    inProgress: false, error: "",
                    symbols: responseData.response.data.symbols,
                    filterSymbols: responseData.response.data.symbols
                });
                this.onSocketRegister(responseData.response.data.symbols);
            }
            else {
                this.setState({
                    inProgress: false, error: "No scrips found"
                });
            }
        }
    }

    parseRequestWatchlistSymbolsError(selected, error) {
        if (selected.id === this.state.selectedwatchlist.id) {
            this.setState({ inProgress: false, error: error.message });
        }
    };
    // ======================   
    // === delete symbols ======
    deleteWatchlistSymbols(selectedSymbolsToDelete, selectedWatchlist) {
        this.setState({ selectedwatchlist: selectedWatchlist });
        if (this.state.selectedsymbolstodelete.length < this.state.symbols.length) {
            let symbolGroup = this.state.selectedsymbolstodelete;
            var data = {
                request: {
                    data: {
                        watchId: selectedWatchlist.id,
                        symGroup: symbolGroup
                    }
                }
            };

            this.setState({ symbols: [], inProgress: true, error: "" });
            deleteSymbolFromWatchlist(data, this.parseDeleteWatchlistSymbolsResponse.bind(this), this.parseDeleteWatchlistSymbolsError.bind(this));
        } else {
            this.props.showWatchlistError({ header: 'Delete Watchlist', message: "Cannot delete the last Scrip" });
        }
    }


    parseDeleteWatchlistSymbolsResponse(responseData) {
        this.requestWatchlistSymbols(this.state.selectedwatchlist);
        this.setState({ inProgress: false, error: "" });
        console.log('watch', responseData)
    }

    parseDeleteWatchlistSymbolsError(error) {
        this.setState({ inProgress: false, error: error.message });
        console.log(error)
    }
    //================  Stream Functions  =================//

    onSocketRegister(data) {
        if (localStorage.getItem("TR_STREAM_FLAG") === "N") {            
        data.map(value => {
            window.socketEvents.register(value.streamSym + value.sym.exc, { screenName: this.tag, sym: value.streamSym, exc: value.sym.exc }, this.onStreamCallback);
            window.socketEvents.register(value.streamSym + value.sym.exc + "ext", { screenName: this.tag, sym: value.streamSym, exc: value.sym.exc }, this.onStreamCallbackOI);
            return '';
        })
        setTimeout(function () {
            window.socketEvents.subscribe();
        }, 1000)

        } else {            
            data.map(value => {
                return window.socketEventsTR.register(value.sym.xSym, { screenName: this.tag, sym: value.sym.xSym, exc: value.sym.exc_seg }, this.onStreamCallbackTR)
            })
            setTimeout(function () {
                window.socketEventsTR.subscribe();
            }, 1000)
        }

    }

    onStreamCallback(streamData) {
        this.count=this.count+1;
     
        var updateCurrentList = this.state.filterSymbols;

        updateCurrentList = updateCurrentList.map(data => {
            if (data.streamSym === streamData.data.sym && data.sym.exc === streamData.data.exc) {
                data.ltp = streamData.data.ltp ? streamData.data.ltp : data.ltp;
                data.chng = streamData.data.ltp && streamData.data.closePrice ? calcChange(streamData.data, data.sym.instr) : data.chng;
                data.chngPer = streamData.data.ltp && streamData.data.closePrice ? calcChangePercentage(streamData.data) : data.chngPer;
                if (this.isInExpandedView()) {
                    data.askPrice = streamData.data.askPrice ? streamData.data.askPrice : data.askPrice;
                    data.askQty = streamData.data.askQty ? streamData.data.askQty : data.askQty;
                    data.avgTradedPrice = streamData.data.avgTradedPrice ? streamData.data.avgTradedPrice : data.avgTradedPrice;
                    data.bidPrice = streamData.data.bidPrice ? streamData.data.bidPrice : data.bidPrice;
                    data.bidQty = streamData.data.bidQty ? streamData.data.bidQty : data.bidQty;
                    data.closePrice = streamData.data.closePrice ? streamData.data.closePrice : data.closePrice;
                    data.highPrice = streamData.data.highPrice ? streamData.data.highPrice : data.highPrice;
                    data.lTradedTime = streamData.data.lTradedTime != null ? streamData.data.lTradedTime : data.lTradedTime;
                    // hack for demo
                    // data.lTradedTime = new Date().toJSON().slice(0, 10) + ' ' + data.lTradedTime;

                    data.lUpdatedTime = streamData.data.lUpdatedTime != null ? streamData.data.lUpdatedTime : data.lUpdatedTime;
                    data.lowPrice = streamData.data.lowPrice ? streamData.data.lowPrice : data.lowPrice;
                    data.ltq = streamData.data.ltq ? streamData.data.ltq : data.ltq;
                    data.openPrice = streamData.data.openPrice ? streamData.data.openPrice : data.openPrice;
                    data.totBuyQty = streamData.data.totBuyQty ? streamData.data.totBuyQty : data.totBuyQty;
                    data.totSellQty = streamData.data.totSellQty ? streamData.data.totSellQty : data.totSellQty;
                    data.vol = streamData.data.vol ? streamData.data.vol : data.vol;
                    data.dpr = (streamData.data.lDPR != null && streamData.data.hDPR != null) ? streamData.data.lDPR + " - " + streamData.data.hDPR : data.dpr;
                }
            }
            return data;
        })
        this.setState({ filterSymbols: updateCurrentList })
    }


    onStreamCallbackTR(streamData) {
        var updateCurrentList = this.state.filterSymbols;
        updateCurrentList = updateCurrentList.map(data => {       
        
        if(data.sym.xSym===streamData.data.xSym){
                data.ltp = streamData.data.ltp ? streamData.data.ltp : data.ltp;
                data.chng = streamData.data.change ? calcChangeTR(streamData.data.change, data.sym.instr) : data.chng;
                data.chngPer = streamData.data.perchange  ? Number(streamData.data.perchange).toFixed(2) : data.chngPer;
                if (this.isInExpandedView()) {
                    data.askPrice = streamData.data.askPrice ? streamData.data.askPrice : data.askPrice;
                    data.askQty = streamData.data.askQty ? streamData.data.askQty : data.askQty;
                    data.avgTradedPrice = streamData.data.avgTradedPrice ? streamData.data.avgTradedPrice : data.avgTradedPrice;
                    data.bidPrice = streamData.data.bidPrice ? streamData.data.bidPrice : data.bidPrice;
                    data.bidQty = streamData.data.bidQty ? streamData.data.bidQty : data.bidQty;
                    data.closePrice = streamData.data.closePrice ? streamData.data.closePrice : data.closePrice;
                    data.highPrice = streamData.data.highPrice ? streamData.data.highPrice : data.highPrice;
                    data.lTradedTime = streamData.data.lTradedTime? streamData.data.lTradedTime : data.lTradedTime;
                    
                    data.lUpdatedTime = streamData.data.lUpdatedTime? (streamData.data.lUpdatedTime).split(" ")[1] : data.lUpdatedTime;
                    data.lowPrice = streamData.data.lowPrice ? streamData.data.lowPrice : data.lowPrice;
                    data.ltq = streamData.data.ltq ? streamData.data.ltq : data.ltq;
                    data.openPrice = streamData.data.openPrice ? streamData.data.openPrice : data.openPrice;
                    data.totBuyQty = streamData.data.totBuyQty ? streamData.data.totBuyQty : data.totBuyQty;
                    data.totSellQty = streamData.data.totSellQty ? streamData.data.totSellQty : data.totSellQty;
                    data.vol = streamData.data.vol ? streamData.data.vol : data.vol;
                    data.dpr = (streamData.data.lDPR != null && streamData.data.hDPR != null) ? streamData.data.lDPR + " - " + streamData.data.hDPR : data.dpr;
                    data.openInterest = streamData.data.OI;                   
                
                }
            }
            return data;
        })
        this.setState({ filterSymbols: updateCurrentList })
    }


    onStreamCallbackOI(streamData) {

        var updateCurrentList = this.state.filterSymbols;

        updateCurrentList = updateCurrentList.map(data => {
            if (data.streamSym === streamData.data.sym && data.sym.exc === streamData.data.exc) {
                if (this.isInExpandedView() && streamData.data.OI) {
                    data.openInterest = streamData.data.OI;
                }
            }
            return data;
        })
        this.setState({ filterSymbols: updateCurrentList })
    }

    // ===============================================
    symbolPresentCheck(scripdata, watchistid, callback) {
        var checkPresent = false;
        
        for (let sym = 0; sym < this.state.symbols.length; sym++) {
            let currentSym = this.state.symbols[sym];
            if (currentSym.sym.id === scripdata.id &&
                currentSym.sym.exc === scripdata.exc && currentSym.sym.assetClass === scripdata.assetClass &&
                currentSym.sym.trSymbol === scripdata.trSymbol) {
                checkPresent = true;
                break;
            }
        }
        if (checkPresent) {
            this.props.showWatchlistError({ header: "Add Scrips", message: 'Symbol is already added in the watchlist' });
        } else {
            this.props.confirmAddSymbol(scripdata, watchistid, callback);
        }

    }

    render() {

        const { connectDropTarget } = this.props;


        return connectDropTarget(
            <div className="fillheight">
                <WatchlistBaseWidget ref="baseWidget" componentType={this.props.componentType}
                    inProgress={this.state.inProgress} error={this.state.error}
                    onWatchlistGroupSelected={this.onWatchlistGroupSelected.bind(this)}
                    toggleDeletingScrips={this.toggleDeletingScrips.bind(this)}
                    toggleFilterScrips={this.toggleFilterScrips.bind(this)}
                    selectedSymbolsToDelete={this.state.selectedsymbolstodelete}
                    startAddNewWatchlist={this.props.startAddNewWatchlist}
                    startDeleteWatchlist={this.props.startDeleteWatchlist}
                    showWatchlistEditOptions={this.props.showWatchlistEditOptions}
                    afterColsUpdated={this.afterColsUpdated.bind(this)}
                    showFilter={this.state.showFilter}
                    onDownloadOptionSelected={this.onExportOptionSelected.bind(this)}
                    confirmAddSymbol={this.symbolPresentCheck.bind(this)}
                    deleteWatchlistSymbols={this.deleteWatchlistSymbols.bind(this)}
                    getSymbolsCount={this.getSymbolsCount.bind(this)}
                    setFavourite={this.props.setFavourite}
                    getActiveState={this.getActiveState.bind(this)}
                    isInExpandedView={this.isInExpandedView()}
                // tabHandler={this.onTabClickHandler}
                >
                    {!this.isInExpandedView() ?
                        <div className="row contentpanel fillwidth">
                            <div className="row customRow contentpanel watchlist-header">
                                <div className="col-7 col-lg-7 col-md-7 contentpanel">
                                    <div class="container-fluid">
                                        <div className="row">
                                            <div className="col-7 col-lg-7 col-md-7 contentpanel">
                                                <div className="row">
                                                    <div className="col-8 col-lg-8 col-md-8 contentpanel  cursor-pointer" id="sym_sort" onClick={(e) => this.onSortClicked(e)} >
                                                        <span style={{ marginRight: "5px" }}>

                                                        </span>
                                                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                                                        SYMBOL
                                                    </div>
                                                    <div className="col-4 col-lg-4 col-md-4 contentpanel text-center cursor-pointer" id="exc_sort" onClick={(e) => this.onSortClicked(e)}>
                                                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                                                        EXCH
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-5 col-lg-5 col-md-5 contentpanel">
                                                <div className="row">
                                                    <div className="col-4 col-lg-4 col-md-4 contentpanel  text-center">

                                                    </div>
                                                    <div className="col-4 col-lg-4 col-md-4 contentpanel text-center">

                                                    </div>
                                                    <div className="col-4 col-lg-4 col-md-4 contentpanel text-center">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 col-lg-5 col-md-5 contentpanel">
                                    <div className="row">
                                        <div className="col-4 col-lg-4 col-md-4  contentpanel text-center cursor-pointer" id="ltp_sort" onClick={(e) => this.onSortClicked(e)}>
                                            <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                                            LTP

                                    </div>
                                        <div className="col-4 col-lg-4 col-md-4  contentpanel text-center cursor-pointer" id="chng_sort" onClick={(e) => this.onSortClicked(e)}>
                                            <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                                            CHANGE

                                    </div>
                                        <div className="col-4 col-lg-4 col-md-4  contentpanel text-center contentpanel cursor-pointer" id="chngPer_sort" onClick={(e) => this.onSortClicked(e)}>
                                            <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                                            CHANGE%

                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"row contentpanel watchlist-filter-holder2 " + (!this.state.showFilter && " collapse")}>

                                <div className="col-7 col-lg-7 col-md-7 contentpanel">
                                    <div className="row">
                                        <div className="col-7 col-lg-7 col-md-7 contentpanel">
                                            <div className="row">
                                                <div className="col-8 col-lg-8 col-md-8 contentpanel watch-filter-col" id="sym-filter">
                                                    <FilterInput placeholder="eg:INFY" id="sym" display={this.state.showFilter} onChangeFilter={this.onChangeFilter.bind(this)} />
                                                </div>
                                                <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">
                                                    <FilterInput placeholder="eg:NSE/BSE" id="exc" display={this.state.showFilter} onChangeFilter={this.onChangeFilter.bind(this)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-5 col-lg-5 col-md-5 contentpanel">
                                        <div className="row">
                                            <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">

                                            </div>
                                            <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">

                                            </div>
                                            <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 col-lg-5 col-md-5 contentpanel">
                                    <div className="row">
                                        <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">
                                            <FilterInput placeholder="eg:<= or >= 50" id="ltp" display={this.state.showFilter} onChangeFilter={this.onChangeFilter.bind(this)} />
                                        </div>
                                        <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">
                                            <FilterInput placeholder="eg:<= or >= 50" id="chng" display={this.state.showFilter} onChangeFilter={this.onChangeFilter.bind(this)} />
                                        </div>
                                        <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">
                                            <FilterInput placeholder="eg:<= or >= 50" id="chngPer" display={this.state.showFilter} onChangeFilter={this.onChangeFilter.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        :

                        <div className={"row contentpanel watchlist-header fillwidth"} style={{ paddingRight: '0px' }} >
                            <div className="col-8 col-lg-8 col-md-8 contentpanel" style={{ paddingRight: '8px' }} >
                                <div className="watchlist-header-margin">
                                    <div className="row">
                                        <div className="col-8 col-lg-8 col-md-8 contentpanel">
                                            <div className="row">
                                                <div className="col-7 col-lg-7 col-md-7 contentpanel">
                                                    <div className="row">
                                                        <div className="col-8 col-lg-8 col-md-8 cursor-pointer" id="sym_sort" onClick={(e) => this.onSortClicked(e)} >
                                                            <span style={{ marginRight: "4px" }}>

                                                            </span>
                                                            <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                                                            SYMBOL
                                            </div>
                                                        <div className="col-4 col-lg-4 col-md-4 contentpanel text-center cursor-pointer" id="exc_sort" onClick={(e) => this.onSortClicked(e)}>
                                                            <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                                                            EXCH
                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-5 col-lg-5 col-md-5 contentpanel contentpanel">
                                                    <div className="row">
                                                        <div className="col-4 col-lg-4 col-md-4  text-center">

                                                        </div>
                                                        <div className="col-4 col-lg-4 col-md-4  text-center">

                                                        </div>
                                                        <div className="col-4 col-lg-4 col-md-4  text-center">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-lg-4 col-md-4 contentpanel">
                                            <div className="row">
                                                <div className="col-4 col-lg-4 col-md-4 text-center cursor-pointer" id="ltp_sort" onClick={(e) => this.onSortClicked(e)}>
                                                    <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                                                    LTP

                                        </div>
                                                <div className="col-4 col-lg-4 col-md-4 contentpanel text-center cursor-pointer" id="chng_sort" onClick={(e) => this.onSortClicked(e)}>
                                                    <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                                                    CHANGE

                                        </div>
                                                <div className="col-4 col-lg-4 col-md-4 contentpanel text-center contentpanel cursor-pointer" id="chngPer_sort" onClick={(e) => this.onSortClicked(e)}>
                                                    <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                                                    CHANGE%

                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"row contentpanel watchlist-filter-holder2 " + (!this.state.showFilter && ' collapse')}>

                                    <div className="col-8 col-lg-8 col-md-8 contentpanel">
                                        <div className="row">
                                            <div className="col-7 col-lg-7 col-md-7 contentpanel">
                                                <div className="row">
                                                    <div className="col-8 col-lg-8 col-md-8 contentpanel watch-filter-col">
                                                        <FilterInput placeholder="eg:INFY" id="sym" display={this.state.showFilter} onChangeFilter={this.onChangeFilter.bind(this)} />
                                                    </div>
                                                    <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">
                                                        <FilterInput placeholder="eg:NSE/BSE" id="exc" display={this.state.showFilter} onChangeFilter={this.onChangeFilter.bind(this)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-5 col-lg-5 col-md-5  contentpanel">
                                                <div className="row">
                                                    <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">

                                                    </div>
                                                    <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">

                                                    </div>
                                                    <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4 col-lg-4 col-md-4 contentpanel">
                                        <div className="row">
                                            <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">
                                                <FilterInput placeholder="eg:<= or >= 50" id="ltp" display={this.state.showFilter} onChangeFilter={this.onChangeFilter.bind(this)} />
                                            </div>
                                            <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">
                                                <FilterInput placeholder="eg:<= or >= 50" id="chng" display={this.state.showFilter} onChangeFilter={this.onChangeFilter.bind(this)} />
                                            </div>
                                            <div className="col-4 col-lg-4 col-md-4 contentpanel watch-filter-col">
                                                <FilterInput placeholder="eg:<= or >= 50" id="chngPer" display={this.state.showFilter} onChangeFilter={this.onChangeFilter.bind(this)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div ref="topPanel" className="col-4 col-lg-4 col-md-4 watchlist-cust-header"
                                onScroll={() => this.onTopPanelScroll()}
                            >
                                <div style={{ width: (this.state.dynrow_width) }}>
                                    <div className="row contentpanel">
                                        {this.state.custom_cols_headers.map(function (rw, ky) {
                                            return (
                                                <div key={ky} className="watchlist-custom-col wl-txt cursor-pointer"
                                                    id={rw.apikey}
                                                    onClick={(e) => this.onSortClicked2(e)} >
                                                    <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                                                    {rw.name}
                                                </div>
                                            )
                                        }, this)
                                        }
                                    </div>
                                    {this.state.showFilter ?
                                        <div className="row contentpanel">
                                            {this.state.custom_cols_headers.map(function (rw, ky) {
                                                return (
                                                    <div key={ky} className="watchlist-custom-col wl-txt  watch-filter-col" >
                                                        {/* <FilterInput placeholder={this.getFilterPlaceHolder(rw)} id={rw.apikey} display={this.state.showFilter} onChangeFilter={this.onChangeFilter.bind(this)} /> */}
                                                    </div>
                                                )
                                            }, this)
                                            }
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>



                    }


                    {!this.isInExpandedView() ?
                        <div className={"watchlist-container2 " + (this.state.showFilter && "filter")}>
                            {this.state.filterSymbols.map(function (row, key) {
                                return (
                                    <WatchlistRowItem
                                        key={row.uniqueId}
                                        index={key}
                                        listId="1"
                                        row={row}
                                        isbeendragged={this.state.draggingrowkey === row.uniqueId}
                                        selecttodelete={this.state.selecttodelete}
                                        symbolSelectionChanged={(e) => this.symbolSelectionChanged(e, row.sym)}
                                        onSymbolClick={this.props.onSymbolClick}
                                        gotoChart={this.gotoChart.bind(this)}
                                        gotoBuy={this.gotoBuy.bind(this)}
                                        gotoSell={this.gotoSell.bind(this)}
                                        moveCard={this.moveCard.bind(this)}
                                        onDragStart={this.onDragStart.bind(this)}
                                        onDragEnd={this.onDragEnd.bind(this)}
                                        isExpanded={false}
                                    />

                                )
                            }, this)}



                        </div>
                        :
                        <div className="row contentpanel " style={{ height: 'calc(100% - ' + this.getDataContentHeight() + 'px)', marginTop: '5px', marginRight: '-18px', width: '100%' }} >
                            <div ref="leftPanel" className={"col-8 col-lg-8 col-md-8 watchlist-left-container"}
                                onScroll={() => this.onLeftPanelScroll()}
                                onMouseEnter={() => this.onPaneMouseEnter('left')}
                            >

                                {this.state.filterSymbols.map(function (row, key) {

                                    return (

                                        <WatchlistRowItem
                                            key={row.uniqueId}
                                            index={key}
                                            listId="1"
                                            row={row}
                                            isbeendragged={this.state.draggingrowkey === row.uniqueId}
                                            selecttodelete={this.state.selecttodelete}
                                            symbolSelectionChanged={(e) => this.symbolSelectionChanged(e, row.sym)}
                                            onSymbolClick={this.props.onSymbolClick}
                                            gotoChart={this.gotoChart.bind(this)}
                                            gotoBuy={this.gotoBuy.bind(this)}
                                            gotoSell={this.gotoSell.bind(this)}
                                            moveCard={this.moveCard.bind(this)}
                                            onDragStart={this.onDragStart.bind(this)}
                                            onDragEnd={this.onDragEnd.bind(this)}
                                            isExpanded={true}

                                        />
                                    )
                                }, this)

                                }



                            </div>

                            <div ref="rightPanel" className="col-4 col-lg-4 col-md-4 dynamic-columns2"
                                onScroll={() => this.onRightPanelScroll()}
                                onMouseEnter={() => this.onPaneMouseEnter('right')}
                            >

                                {this.state.filterSymbols.map(function (row, key) {
                                    return (
                                        <div key={key} className={"watchlist-row2"} style={{ width: this.state.dynrow_width }} >
                                            {this.state.custom_cols_headers.map(function (rw, ky) {
                                                return (

                                                    <div key={ky} className="watchlist-custom-col wl-txt ">
                                                        {row[rw.apikey]}

                                                    </div>

                                                )
                                            }, this)
                                            }
                                            {
                                                this.state.draggingrowkey === row.uniqueId ?
                                                    <div style={{ position: "absolute", top: "0", bottom: "0", right: "0", left: "0", backgroundColor: "#131314ff", borderTop: '1px dashed #393944', borderBottom: '1px dashed #393944' }}>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>
                                    )
                                }, this)
                                }
                            </div>
                        </div>
                    }
                    <Workbook ref="workbook">
                        <Workbook.Sheet data={this.state.exportabledata} name="Watchlist">
                            {WatchlistColumns.map(function (row, key) {
                                return (
                                    <Workbook.Column label={row.name} value={row.apikey} key={key} />
                                );
                            }, this)}
                        </Workbook.Sheet>
                    </Workbook>
                </WatchlistBaseWidget>
            </div>
        )
    }



}


const cardTarget = {
    drop(props, monitor, component) {
        const { id } = props;
        //const sourceObj = monitor.getItem();

        // move done

        return {
            listId: id
        };
    }
}

const mapStateToProps = (state) => {
    return {
        activeWidget: state.watchlist.activeWidget,
        watchListGroups: state.watchlist.watchListGroups,
        showOrderEntry: state.orderentry.showDialog,
        showChartDialog: state.charts.showChartDialog,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showOrderDialog: (data) => showOrderDialog(dispatch, data),
        setChartSymbol: (data) => setChartSymbol(dispatch, data),
        showWatchlistError: (errordata) => showWatchlistError(dispatch, errordata),
    }
}


export default DropTarget("WatchlistRowItem", cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
}))(connect(mapStateToProps, mapDispatchToProps)(WatchlistView2));