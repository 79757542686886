


// DEV
// const COMPLETE_CONFIG  = {
//     dev : {
//         BaseUrl: "http://172.25.219.145",
//         wsInteractiveUri: "ws://172.25.219.72:35001/", 
//         wsBroadcastUri: "ws://172.25.219.72:35000/"
//     }, 
//     sit: {
//         BaseUrl: "http://172.25.219.60:82",
//         wsInteractiveUri: "ws://172.25.219.72:35001/",
//         wsBroadcastUri: "ws://172.25.219.72:35000/"
//     }, 
//     uat : {
//         BaseUrl: "https://uatots.indiabulls.com",
//         wsInteractiveUri: "wss://uatstreaming.indiabulls.com:444/",
//         wsBroadcastUri: "wss://uatstreaming.indiabulls.com:443/"
//     }, 
//     staging : {
//         BaseUrl: "https://shubhweb.indiabulls.com",
//         wsInteractiveUri: "wss://strots.indiabulls.com:444/",
//         wsBroadcastUri: "wss://strots.indiabulls.com:443/"
//     },
//     prod : {
//         BaseUrl: "https://shubhweb.indiabulls.com",
//         wsInteractiveUri: "wss://strots.indiabulls.com:444/",
//         wsBroadcastUri: "wss://strots.indiabulls.com:443/"
        
//     }
// }

export const getBaseUrl = function (releaseenv) {
    return process.env.REACT_APP_BASE_URL;
}

export const getwsInteractiveUri = function (releaseenv) {
    return process.env.REACT_APP_INTERACTIVE_URI;
}

export const getwsInteractiveUriNetMagic = function(releaseenv){
    return process.env.REACT_APP_INTERACTIVE_URI_NET_MAGIC;
}

export const getwsBroadcastUri = function (releaseenv) {
    return process.env.REACT_APP_BROADCAST_URI; 
}
export const getwsBroadcastUriNetMagic = function (releaseenv) {
    return process.env.REACT_APP_BROADCAST_URI_NET_MAGIC;
}

export const getwsBroadcastUriTR = function (releaseenv) {	
	return process.env.REACT_APP_BROADCAST_URI_TR;	
}

export const getwsBroadcastUriTRNetMagic = function(releaseenv){
    return process.env.REACT_APP_BROADCAST_URI_TR_NET_MAGIC;
}
export const getIsEncryption = function () {
    return process.env.REACT_APP_ENV_ETOE==='true'; 
}
export const getInactivityTimeout = function (){
    return process.env.REACT_APP_INACTIVITY_TIMEOUT;
}

// export const getBaseUrlShweta = function (releaseenv) {
//     return process.env.RECT_APP_SHWETA_URL;
// }
