import React from 'react';
import { comaSeparator } from '../../common/utils'



const RowWidget = props => {
    return (
      <div className="">
       
          <div className="accordion" id="accordion">
            <div className="card">
              <div className="card-heading cardHeader collapsed" id="card-header" data-toggle="collapse" data-target={"#collapse" + props.index} data-parent="#accordion">
             
              <span>{props.data.continentName}</span>
                <span className="float-right">
                  <i className="fa fa-angle-down" />
                </span>
          
               
              </div>
            
          <div className="card-body collapse cardBody"  id={"collapse" + props.index} data-parent="#accordion" style={{padding:"5px"}} >
            {/*check for large widget */}
            {props.isLarge ? (
              <div className="row contentpanel worldmarketsummary-header">
                <span className="col-2 col-lg-2 col-md-2 contentpanel">INDEX</span>
                <span className="col-2 col-lg-2 col-md-2 contentpanel">COUNTRY</span>
                <span className="col-2 col-lg-2 col-md-2 contentpanel">LTP</span>
                <span className="col-2 col-lg-2 col-md-2 contentpanel">CHANGE</span>
                <span className="col-2 col-lg-2 col-md-2 contentpanel">CHANGE%</span>
                <span className="col-2 col-lg-2 col-md-2 contentpanel">LAST UPDATE TIME</span>
              </div>
            ) : (
                <div className="row contentpanel worldmarketsummary-header">
                  <span className="col-5 col-lg-5 col-md-5 contentpanel">INDEX</span>
                  <span className="col-3 col-lg-3 col-md-3 contentpanel">LTP</span>
                  <span className="col-2 col-lg-2 col-md-2 contentpanel">CHANGE</span>
                  <span className="col-2 col-lg-2 col-md-2 contentpanel">CHANGE%</span>
                </div>
              )}
            {props.data.indices.map(function (row, key) {
              //check for large widget
              return props.isLarge ? (
                <div
                  key={key}
                  className="row contentpanel worldmarketsummary-row"
                >
                  <span className="col-2 col-lg-2 col-md-2 contentpanel">{row.indexName}</span>
                  <span className="col-2 col-lg-2 col-md-2 contentpanel">{row.country}</span>
                  <span className="col-2 col-lg-2 col-md-2 contentpanel">{comaSeparator(row.ltp)}</span>
                  <span className={"col-2 col-lg-2 col-md-2 contentpanel " + (row.chng >= 0 ? "positive-change" : "negative-change")}>{comaSeparator(row.chng)}</span>
                  <span className={"col-2 col-lg-2 col-md-2 contentpanel " + (row.chngPer >= 0 ? "positive-change" : "negative-change")}>{comaSeparator(row.chngPer)}%</span>
                  <span className="col-2 col-lg-2 col-md-2 contentpanel">{row.LUpdatedTime}</span>
                </div>
              ) : (
                  <div
                    key={key}
                    className="row contentpanel worldmarketsummary-row"
                  >
                    <span className="col-5 col-lg-5 ol-md-5 contentpanel">{row.indexName}</span>
                    <span className="col-3 col-lg-3 col-md-3 contentpanel">{comaSeparator(row.ltp)}</span>
                    <span className={"col-2 col-lg-2 col-md-2 contentpanel " + (row.chng >= 0 ? "positive-change" : "negative-change")}>{row.chng}</span>
                    <span className={"col-2 col-lg-2 col-md-2 contentpanel " + (row.chngPer >= 0 ? "positive-change" : "negative-change")}>{row.chngPer}%</span>
                  </div>
                );
            })}
          </div>
        </div>
            </div>
      </div>
    );
  };

  export default RowWidget;