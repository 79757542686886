import React from "react"

class CustomDialog extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div className={"modal fade " + (this.props.showDialog !== undefined && (this.props.showDialog ? "in dialog-show" : "dialog-hide"))} id={this.props.id} role="dialog">
                <div className={"modal-dialog " + this.props.customStyle}>
                    <div className="modal-content">
                        {this.props.header && <div className="modal-header">
                            {this.props.isDismiss && <button type="button" className="close" onClick={() => this.props.cancelDialog()}>&times;</button>}
                            <h4 className="modal-title">{this.props.header}</h4>
                        </div>
                        }
                        <div className="modal-body">
                            <p>{this.props.message}</p>
                        </div>
                        <div className="modal-footer">
                            {this.props.buttons && this.props.buttons.map((data) => {
                                return <button type="button" className="btn btn-default" onClick={() => { data.action && data.action() }}>{data.name}</button>;
                            })}
                            {this.props.isDismiss && <button type="button" className="btn btn-default" onClick={() => this.props.cancelDialog()}>{this.props.defaultBtnText? this.props.defaultBtnText :"Close"}</button>}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}


export default CustomDialog;
