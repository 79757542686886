import React, { Component } from 'react';
import { connect } from "react-redux";
import { toggleLoginWarning } from '../actions/login/submitLogin'
class LoginWarningDialog extends Component {
    redirectPage = ()=>{
        window.location = this.props.redirectLink;
    }
    render() {
        return (
            this.props.showLoginWarningDialog ? <div className={"modal fade " + (this.props.showLoginWarningDialog ? "dialog-show in" : "dialog-hide")} id="myModal" role="dialog">
                <div className={"modal-dialog appdialogmodal "}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={() => this.props.toggleLoginWarning()}><img src="assets/svg/close.svg" alt="close" /></button>
                            <h4 className="modal-title">Redirection Warning</h4>
                        </div>
                        <div className="modal-body">
                            <p>{this.props.loginwarningMsg}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default commonbtn" onClick={() => this.redirectPage()}>Continue</button>
                            <button ref="btnClose" type="button" className="btn btn-default commonbtn" onClick={() => this.props.toggleLoginWarning()}>Close</button>
                        </div>
                    </div>

                </div>
            </div> : null
        );
    }
};
const mapStateToProps = state => {
    return {
        showLoginWarningDialog: state.loginWarning.showLoginWarningDialog,
        loginwarningMsg: state.loginWarning.loginwarningMsg,
        redirectLink: state.loginWarning.redirectLink
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        toggleLoginWarning: () => toggleLoginWarning(dispatch,false)
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(LoginWarningDialog);