import React, { Component } from 'react';
import { getTradingPlan, getTradingMasterPlan, getSubscriptionRequest } from '../../actions/MyTradingPlan/mytradingplanrequest'
import NumberFormat from 'react-number-format'
import { progressBar } from '../../controls/progress'
import {getCookie, setItemByKey} from '../../common/utils'
import PropTypes from 'prop-types';

class MyTradingPlan extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    }
    
    constructor(props) {
        
        super(props);
        this.state = {
            colorStyle:{},
            flag:this.props.flag,
            title:'My Trading Plan',
            data:[],
            error :'',
            inProgress: false,
            FnoPlan:[],
            masterdata:[],
            masterFnoPlan:[],
            GST_percent:'',
            intraday_exposure_factor_FO:'',
            intraday_exposure_factor_EQ:'',
            EQplan:'',
            Fqplan:'',
            PlanId:'',
            updowngrade:'',
            PlanAmountFq:0,
            PlanAmountEq:0,
            finalmessage:'',
            result:'',
            totalChargeMsg:'',
            InfoMessage:{},
            condition: false,
            AutoRenwal:true,
            isActive: {},
            requestData : {
                request: {
                    data: {
                        
                    }
                }
            } ,
            cssFlag:getCookie("REDIRECT")=="MyTradingPlan" ? '1' : '0',
            eaccFlag:''
        }
        this.handleCheck = this.handleCheck.bind(this);
    }

    handleCheck(event){
        console.log(event);
        console.log("event-------");
        this.setState({ isActive: event.target.checked });
      }
      
    infobox=() =>{
        this.setState({
            InfoMessage:{display:'block'}
        })
    }

    onclose=()=>{
        this.setState({
            InfoMessage:{display:'none'}
        })
    }

    colorChange(plan_id,flag,plan_amount){ 
        if(flag=='1')
        { 
            if(plan_id==this.state.Fqplan)
            {
                this.setState({
                    Fqplan:'',
                    PlanAmountFq:0
                }, ()=>{
                });
            }
            else
            {
                this.setState({
                    Fqplan:plan_id,
                    PlanAmountFq:plan_amount
                }, ()=>{
                });
            }
        }
        else
        {
            if(plan_id==this.state.EQplan)
                {
                    this.setState({
                        EQplan:'',
                        PlanAmountEq:0,
                        updowngrade:'O'
                    }, ()=>{
                    });
            }
            else
            {
                this.setState({
                    EQplan:plan_id,
                    PlanAmountEq:plan_amount,
                    updowngrade:
                    (this.state.data.plan_amount==null || this.state.data.plan_amount=="")
                    ? 'I' :
                    (
                        (
                            (this.state.data.plan_amount!=null && this.state.data.plan_amount!="") 
                            && (Number(this.state.data.plan_amount)>Number(plan_amount))
                        )? 'D'
                        :
                        (
                            (this.state.data.plan_amount!=null && this.state.data.plan_amount!="") 
                            && (Number(this.state.data.plan_amount)==Number(plan_amount))?'I':'U'
                        )
                    )
                    
                }, ()=>{

                })
            }
        }
        
    }
    // terms condition 
    TermsCondition = () =>{
        window.open("https://www.indiabullssecurities.com/shubhplan/Shubh_plan_tc.html", "_blank");
    }
// upgrade downgrade
    DownUpgrade(flag, updowngrade){
        this.setState({
            flag:flag,
            updowngrade:updowngrade
        }, ()=>{
            if(flag=='6' || flag == '7')
            {
                this.callApi();
            }      
            else if(flag=='3')
            {
                this.toggleButton(flag);
            }      
        })
                
    }

    callApi=()=>{

        var requestData ={
            request: {
                data: {
                    planId:''
                }
            }
        } 
            if((this.state.data.plan_id=="" || this.state.data.plan_id==null)
                && (this.state.FnoPlan.plan_id=="" || this.state.FnoPlan.plan_id==null))
            {
                if((this.state.EQplan!=null && this.state.EQplan!="")
                || (this.state.Fqplan!=null && this.state.Fqplan!=""))
                {
                    if(this.state.Fqplan!=null && this.state.Fqplan!="" )
                    {
                        requestData.request.data.planId=this.state.Fqplan+"^I^"+(this.state.AutoRenwal?'Y':'N');
                    }
                    if(this.state.EQplan!=null && this.state.EQplan!=""
                    && this.state.Fqplan!=null && this.state.Fqplan!="")
                    {
                        requestData.request.data.planId =  requestData.request.data.planId + "|";
                    }
                    if(this.state.EQplan!=null && this.state.EQplan!="")
                    {
                        requestData.request.data.planId =  requestData.request.data.planId +this.state.EQplan+"^"+this.state.updowngrade+"^"+(this.state.AutoRenwal?'Y':'N');
                    }
                    this.setState({
                        inProgress: true, error: '',
                    });
                    getSubscriptionRequest(requestData, this.parseSubResponse.bind(this), this.parseTradingPlanError.bind(this));   
                }
                else
                {
                }
            }
            else
            {
                if(!(this.state.FnoPlan.plan_id=="" || this.state.FnoPlan.plan_id==null) && !(this.state.data.plan_id=="" || this.state.data.plan_id==null))
                {
                    if((!(this.state.Fqplan!=null && this.state.Fqplan!="")))
                    {   
                        requestData.request.data.planId=this.state.FnoPlan.plan_id+"^O^N|";
                    }
                    else
                    {
                        requestData.request.data.planId=this.state.Fqplan+"^I^"+(this.state.AutoRenwal?'Y':'N')+"|";
                    }
                    if(!(this.state.EQplan!=null && this.state.EQplan!=""))
                    {
                        requestData.request.data.planId=requestData.request.data.planId + this.state.data.plan_id +"^"+this.state.updowngrade+"^N";
                    }
                    else{
                        
                        
                        requestData.request.data.planId=requestData.request.data.planId+this.state.EQplan+"^"+this.state.updowngrade+"^"+(this.state.AutoRenwal?'Y':'N');
                        
                    }
                }
                else if(!(this.state.FnoPlan.plan_id=="" || this.state.FnoPlan.plan_id==null))
                {
                    if(!(this.state.Fqplan!=null && this.state.Fqplan!=""))
                    {
                        requestData.request.data.planId = this.state.FnoPlan.plan_id+"^O^N";
                    }
                    else
                    {
                        requestData.request.data.planId=this.state.Fqplan+"^I^"+(this.state.AutoRenwal?'Y':'N');
                    }
                    if(this.state.EQplan!=null && this.state.EQplan!="")
                    {
                        requestData.request.data.planId= requestData.request.data.planId+"|"+this.state.EQplan+"^"+this.state.updowngrade+"^"+(this.state.AutoRenwal?'Y':'N');
                    }
                }
                else if(!(this.state.data.plan_id=="" || this.state.data.plan_id==null))
                {
                    if(this.state.Fqplan!=null && this.state.Fqplan!="")
                    {
                        requestData.request.data.planId=this.state.Fqplan+"^I^"+(this.state.AutoRenwal?'Y':'N')+"|";
                    }
                    if(!(this.state.EQplan!=null && this.state.EQplan!=""))
                    {
                        requestData.request.data.planId=requestData.request.data.planId+ this.state.data.plan_id +"^"+this.state.updowngrade+"^N";
                    }
                    else
                    {
                        requestData.request.data.planId= requestData.request.data.planId+this.state.EQplan+"^"+this.state.updowngrade+"^"+(this.state.AutoRenwal?'Y':'N');
                        
                    }
                   
                }  
                this.setState({
                    inProgress: true, error: '',
                });
                getSubscriptionRequest(requestData, this.parseSubResponse.bind(this), this.parseTradingPlanError.bind(this));   
            }
           
        
    }
    dashboard = () =>{
        this.props.history.push("/home/dashboard");
    }
    toggleButton = (flag) =>{
        
        if (flag == '4'){
            this.setState({
                InfoMessage:{display:'block'}
            })
        }
          
        if(flag=='0')
        {
            this.setState({
                colorStyle:{},
                flag:'0',
                title:'My Trading Plan',
                data:[],
                error :'',
                inProgress: false,
                FnoPlan:[],
                masterdata:[],
                masterFnoPlan:[],
                GST_percent:'',
                intraday_exposure_factor_FO:'',
                intraday_exposure_factor_EQ:'',
                EQplan:'',
                Fqplan:'',
                PlanId:'',
                updowngrade:'',
                PlanAmountFq:0,
                PlanAmountEq:0,
                finalmessage:'',
                result:'',
                totalChargeMsg:'',
                InfoMessage:{},
                condition: false,
                AutoRenwal:true,
                requestData : {
                    request: {
                        data: {
                            
                        }
                    }
                } 
            }, ()=> getTradingPlan(this.state.requestData, this.parseTradingPlnResponse.bind(this), this.parseTradingPlanError.bind(this)));

        }
        // if(flag=='10')
        // {
        //     window.location.reload();
        // }
        if(flag=='A')
        { 
            this.setState({
                AutoRenwal:!this.state.AutoRenwal
            })
        }
    else
    {
        this.setState({
            flag:flag
        });
        
        if(flag=='2')
        {
            this.setState({
            title:'₹500 Best Value Subscription Plan',
            inProgress: true, error: '',
            Fqplan:(this.state.Fqplan==null || this.state.Fqplan=="")?((this.state.FnoPlan.plan_id!=null && this.state.FnoPlan.plan_id!="")?this.state.FnoPlan.plan_id:this.state.Fqplan):this.state.Fqplan
          });
          getTradingMasterPlan(this.state.requestData, this.parseTradingMasterPlnResponse.bind(this), this.parseTradingPlanError.bind(this));         
        }
        // if(flag=='2')
        // {
        //     this.setState({
        //         title:'Trade In Equity',
        //         EQplan:(this.state.EQplan==null || this.state.EQplan=="")?((this.state.data.plan_id!=null && this.state.data.plan_id!="")?this.state.data.plan_id:this.state.EQplan):this.state.EQplan
        //     })
        // }
        else if(flag=='3')
        {
            this.setState({
                title:'Total Charges'
              });
            if(this.state.data.plan_id!="" && this.state.data.plan_id!=null && this.state.FnoPlan.plan_id!="" && this.state.FnoPlan.plan_id!=null )
            {
               if((this.state.Fqplan==null || this.state.Fqplan==""))
                {
                    if(this.state.EQplan==null || this.state.EQplan=="")
                    {
                        this.setState({
                            totalChargeMsg:<p className="totalChargeMsg"><strong>You have unsubscribed from the <span style={{color: '#1EBF8A'}}>F&O Subscription Plan</span> and <span style={{color: '#1EBF8A'}}>Equity Subscription Plan</span></strong></p>
                        });
                    }
                    else
                    {
                        if(this.state.PlanAmountEq!=this.state.data.plan_amount)
                        {
                            this.setState({
                                totalChargeMsg:<p className="totalChargeMsg"><strong>You have unsubscribed from the <span style={{color: '#1EBF8A'}}>F&O Subscription Plan</span> and modified <span style={{color: '#1EBF8A'}}>Equity Subscription Plan</span> </strong></p>
                            });
                        }
                        else
                        {
                            this.setState({
                                totalChargeMsg:<p className="totalChargeMsg"><strong>You have unsubscribed from the <span style={{color: '#1EBF8A'}}>F&O Subscription Plan</span></strong></p>
                            });
                        }
                    }
                }
                else
                {
                    if(this.state.EQplan==null || this.state.EQplan=="")
                    {
                        this.setState({
                            totalChargeMsg:<p className="totalChargeMsg"><strong>You have unsubscribed from the <span style={{color: '#1EBF8A'}}>Equity Subscription Plan</span></strong></p>
                        });
                    }
                    else
                    {
                        if(this.state.PlanAmountEq!=this.state.data.plan_amount)
                        {
                            this.setState({
                                totalChargeMsg:<p className="totalChargeMsg"><strong>You have modified the <span style={{color: '#1EBF8A'}}>Equity Subscription Plan</span> </strong></p>
                            });
                        }
                        else
                        {
                            this.setState({
                                totalChargeMsg:<p className="totalChargeMsg"><strong>You have made no changes in the plan </strong></p>
                            });
                        }
                    }
                }
            }
            else if(this.state.FnoPlan.plan_id!="" && this.state.FnoPlan.plan_id!=null && (this.state.data.plan_id=="" || this.state.data.plan_id==null))
            {
                if((this.state.Fqplan==null || this.state.Fqplan==""))
                {
                    if(this.state.EQplan==null || this.state.EQplan=="")
                    {
                        this.setState({
                            totalChargeMsg:<p className="totalChargeMsg"><strong>You have unsubscribed from the <span style={{color: '#1EBF8A'}}>F&O Subscription Plan</span></strong></p>
                        });
                    }
                    else
                    {
                        this.setState({
                            totalChargeMsg:<p className="totalChargeMsg"><strong>You have unsubscribed from the <span style={{color: '#1EBF8A'}}>F&O Subscription Plan</span> and subscribed for the <span style={{color: '#1EBF8A'}}>Equity Subscription Plan</span></strong></p>
                        });
                    }
                }
                else
                {
                    if(this.state.EQplan==null || this.state.EQplan=="")
                    {
                        this.setState({
                            totalChargeMsg:<p className="totalChargeMsg"><strong>You have made no changes in the plan </strong></p>
                        });
                    }
                    else
                    {
                        this.setState({
                            totalChargeMsg:<p className="totalChargeMsg"><strong>You have subscribed for the <span style={{color: '#1EBF8A'}}><i class="fa fa-rupee"></i> 500 Best Value Subscribtion Plan</span></strong></p>
                        });
                    }
                }
            }
            else if((this.state.FnoPlan.plan_id=="" || this.state.FnoPlan.plan_id==null) && (this.state.data.plan_id!="" && this.state.data.plan_id!=null))
            {
                if((this.state.Fqplan==null || this.state.Fqplan==""))
                {
                    if(this.state.EQplan==null || this.state.EQplan=="")
                    {
                        this.setState({
                            totalChargeMsg:<p className="totalChargeMsg"><strong>You have unsubscribed from the <span style={{color: '#1EBF8A'}}><i class="fa fa-rupee"></i> 500 Best Value Subscribtion Plan</span></strong></p>
                        });
                    }
                    else
                    {
                        if(this.state.PlanAmountEq!=this.state.data.plan_amount)
                        {
                            this.setState({
                                totalChargeMsg:<p className="totalChargeMsg"><strong>You have modified the <span style={{color: '#1EBF8A'}}>Equity Subscription Plan</span></strong></p>
                            });
                        }
                        else
                        {
                            this.setState({
                                totalChargeMsg:<p className="totalChargeMsg"><strong>You have made no changes in the plan </strong></p>
                            });
                        }
                    }
                }
                else
                {
                    if(this.state.EQplan==null || this.state.EQplan=="")
                    {
                        this.setState({
                            totalChargeMsg:<p className="totalChargeMsg"><strong>You have subscribed for the <span style={{color: '#1EBF8A'}}>F&O Subscription Plan</span> and unsubscribed for the <span style={{color: '#1EBF8A'}}>Equity Subscription Plan</span></strong></p>
                        });
                    }
                    else
                    {
                        if(this.state.PlanAmountEq!=this.state.data.plan_amount)
                        {
                            this.setState({
                                totalChargeMsg:<p className="totalChargeMsg"><strong>You have subscribed for the <span style={{color: '#1EBF8A'}}>F&O Subscription Plan</span> and modified for the <span style={{color: '#1EBF8A'}}>Equity Subscription Plan</span></strong></p>
                            });
                        }
                        else
                        {
                            this.setState({
                                totalChargeMsg:<p className="totalChargeMsg"><strong>You have subscribed for the <span style={{color: '#1EBF8A'}}>F&O Subscription Plan</span></strong></p>
                            });
                        }
                    }
                }
            }
            else
            {
                if((this.state.Fqplan==null || this.state.Fqplan==""))
                {
                    if(this.state.EQplan==null || this.state.EQplan=="")
                    {
                            this.setState({
                                totalChargeMsg:<p className="totalChargeMsg"><strong>You have made no changes in the plan </strong></p>
                            });
                    }
                    else
                    {
                        this.setState({
                                totalChargeMsg:<p className="totalChargeMsg"><strong>You have subscribed for the <span style={{color: '#1EBF8A'}}><i class="fa fa-rupee"></i> 500 Best Value Subscribtion Plan</span></strong></p>
                            });
                    }
                }
                else
                {
                    if(this.state.EQplan==null || this.state.EQplan=="")
                    {
                        this.setState({
                        totalChargeMsg:<p className="totalChargeMsg"><strong>You have subscribed for the <span style={{color: '#1EBF8A'}}>F&O Subscription Plan</span></strong></p>
                        });
                    }
                    else
                    {
                        this.setState({
                            totalChargeMsg:<p className="totalChargeMsg"><strong>You have subscribed for the <span style={{color: '#1EBF8A'}}>F&O Subscription Plan</span> and <span style={{color: '#1EBF8A'}}>Equity Subscription Plan</span></strong></p>
                            });
                    }
                }
            }
        }
        else if(flag=='4'){
            if(this.state.data.plan_id!="" && this.state.data.plan_id!=null)
              {
                    if(this.state.EQplan!=null && this.state.EQplan!="")
                    {
                        if(this.state.PlanAmountEq>this.state.data.plan_amount)
                        {
                            this.setState({
                                flag:'7'
                            })
                        }
                        if(this.state.PlanAmountEq<this.state.data.plan_amount){
                            this.setState({
                                flag:'8'
                            })
                        }
                    }
              }

        }
        if(flag=='6')
        {
            this.callApi();
        }

        
    }
    }

    //subscription request
    parseSubResponse(responseData){
        this.setState({
            finalmessage:responseData.response.data.data.message,
            result:responseData.response.data.data.result,
            inProgress: false
        })
        
    }
    //master plans response
    parseTradingMasterPlnResponse(responseData){
        this.setState({
            masterdata: responseData.response.data.data.EQ_plan_detail,
            masterFnoPlan:  responseData.response.data.data.FO_plan_detail, 
            GST_percent: responseData.response.data.data.GST_percent, 
            intraday_exposure_factor_FO: responseData.response.data.data.intraday_exposure_factor_FO, 
            intraday_exposure_factor_EQ: responseData.response.data.data.intraday_exposure_factor_EQ,
            inProgress: false
        });

        if((this.state.EQplan==null || this.state.EQplan=="") && (this.state.data.plan_id==null || this.state.data.plan_id==""))
        {
            this.state.masterdata.map(value => {
                if(value.popularity_flag=="Y")
                {
                    this.setState({
                        EQplan:value.plan_id,
                        PlanAmountEq:value.plan_amount,
                        updowngrade:'I'
                    })
                }
            });
        }
        if((this.state.Fqplan==null || this.state.Fqplan=="")&& (this.state.FnoPlan.plan_id==null || this.state.FnoPlan.plan_id==""))
        {
            this.state.masterFnoPlan.map(value => {
                if(value.popularity_flag=="Y")
                {
                    this.setState({
                        Fqplan:value.plan_id,
                        PlanAmountFq:value.plan_amount
                    })
                }
            });
        }
        
    }
    // request==
    componentDidMount() {
        this.requestMytradingplan();
    }

    requestMytradingplan() {
        this.setState({
            inProgress: true, error: '',
        });
        getTradingPlan(this.state.requestData, this.parseTradingPlnResponse.bind(this), this.parseTradingPlanError.bind(this));
    } 

// responce data==========
parseTradingPlnResponse(responseData) {
    console.log("eq responce data .responseData");
    console.log(responseData);
    this.setState({
        data: responseData.response.data.data.plan_detail.EQ_plan_detail,
        FnoPlan:responseData.response.data.data.plan_detail.FO_plan_detail,
        EQplan:responseData.response.data.data.plan_detail.EQ_plan_detail.plan_id,
        updowngrade:(responseData.response.data.data.plan_detail.EQ_plan_detail.plan_id!=null && responseData.response.data.data.plan_detail.EQ_plan_detail.plan_id!='')?'I':'',
        Fqplan:responseData.response.data.data.plan_detail.FO_plan_detail.plan_id,
        PlanAmountEq:responseData.response.data.data.plan_detail.EQ_plan_detail.plan_amount,
        PlanAmountFq:responseData.response.data.data.plan_detail.FO_plan_detail.plan_amount,
        inProgress:false,
        eaccFlag:responseData.response.data.data.eacc_flag
    });

}
// error====
parseTradingPlanError( error) {
        this.setState({
            inProgress: false,
            error: error.message,
        });

        setItemByKey('DATA_PARAM',null);
}

clickFNO = (DivId) =>
{
    var classes = this.refs[DivId].classList;

    if(classes.contains("up"))
    {
        classes.remove("up");
    }
    else
    {
        classes.add("up");
    }
}

    render(){

        var errorHolder = (
            <div className="error-holder" style={{ marginTop: "5px" }}>
                <span> {this.state.error} </span>                
            </div>
        );
        return(
           <div class="fillheight">
                {this.state.error ? errorHolder :
               <div className="fillheight orderbookbase-content-holder fillwidth">
                    <div className="row contentpanel widget-title">
                       <div className="col-6 col-6 col-6  watchlist-title-holder contentpanel">{this.state.title}</div>
                       <div className="col-6 col-6 col-6 text-right">{this.state.cssFlag=='1' ? <span>Client Id: {this.props.userId}</span>:null}</div>
                    </div>
                    <div className="row hdivider"> </div>

                    {this.state.inProgress ? progressBar() :
                    this.state.data.error ? this.state.errorHolder :
                    
                   <div className={(this.state.cssFlag=='1'?"mytradingplan fullPageTradingPlan" : "mytradingplan")}>                   
                    {this.state.flag=='0' || this.state.flag=='4' || this.state.flag=='7'? 
                    <div className="panel-group" id="accordion">
                        <div className="panel-group accordion-small" id="accordion" aria-multiselectable="true">                           
                            {/* equity plan */}
                            <div class="panel panel-default">
                                {/* <div onClick={() => this.clickFNO("EQClass")} class="text-left panel-title row nopadding wms_accordion_header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse2" aria-controls="collapse2" aria-expanded="false">
                                    <span class="col-10">Subscription Plan Details</span>
                                    <span class="col-2 text-right"><i id="EQClass" ref="EQClass" class="fa fa-angle-down hot-news-arrow"></i></span>
                                </div> */}
                                <div id="collapse2" className="panel-collapse ">
                                    <div className="panel-body" >
                                        <div className="row contentpanel">
                                            <div className="col-12 margin-equity-row text-center">
                                                <div className="row">
                                                {this.state.data.plan_id==null || this.state.data.plan_id=='' ? 
                                                    <div className="col-12">{this.state.data.message}</div>:
                                                    <div className="col-12 plans-box">
                                                        <p className="text-left" style={{fontSize:'18px'}}><span class="col-10"><b>Subscription Plan Details</b></span></p>
                                                        <div className="row">
                                                            <div className="col text-left">
                                                               <p>Equity intraday & Delivery Brokerage</p>
                                                            </div>
                                                            <div className="col text-right">
                                                               Zero*
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="row hdivider"> </div>
                                                        <div className="row">
                                                            <div className="col text-left">
                                                                <p>F&O Intra Day & Carry forward Brokerage</p>
                                                            </div>
                                                            <div className="col text-right">
                                                             Zero*
                                                            </div>
                                                        </div>
                                                        <div className="row hdivider"> </div>
                                                        <div className="row">
                                                            <div className="col text-left">
                                                                <p>Currency Intra Day & Carry forward Brokerage</p>
                                                            </div>
                                                            <div className="col text-right">
                                                                Zero*
                                                            </div>
                                                        </div>                                                        
                                                        <div className="row hdivider"> </div>
                                                        <div className="row">
                                                            <div className="col text-left">
                                                                <p>Plan Start Date</p>
                                                            </div>
                                                            <div className="col text-right">
                                                                <strong> {this.state.data.plan_start_date}</strong>
                                                            </div>
                                                        </div>
                                                        <div className="row hdivider"> </div>
                                                        <div className="row">
                                                            <div className="col text-left">
                                                                <p>Plan End Date</p>
                                                            </div>
                                                            <div className="col text-right">
                                                                <strong> {this.state.data.plan_end_date}</strong>
                                                            </div>
                                                        </div>
                                                        <div className="row hdivider"> </div>
                                                        <div className="row">
                                                            <div className="col text-left">
                                                                <p>Next Renewal Date</p>
                                                            </div>
                                                            <div className="col text-right">
                                                                <strong> {this.state.data.plan_renew_date}</strong>
                                                            </div>
                                                        </div>
                                                        <div className="row hdivider"> </div>
                                                        <div className="row">
                                                            <div className="col text-left">
                                                                <p>Auto Renewal</p>
                                                            </div>
                                                            <div className="col text-right">
                                                                <strong>{this.state.data.auto_renewal_flag}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                    
                                                </div>
                                            </div>
                                            <div className="col-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-center" style={{marginTop:'20px'}}>
                            {(this.state.data.plan_id!=null && this.state.data.plan_id!='')?
                                <div>
                                     <p>*Brokerage at Rs. 0.01 per executed order</p>
                                    <button className="continue-button" onClick={this.toggleButton.bind(this,'4')} >unsubscribe</button>
                                </div>
                                :
                                //<button className="continue-button" onClick={this.toggleButton.bind(this,'2')}>Subscribe Now</button>
                                null}
                            </div>
                        </div>
                    </div>
                    :null}
                    
                    {/* choose equity plns */}
                    {this.state.flag=='2'  || this.state.flag=='5' || this.state.flag=='6'?
                        <div className="col-12 plans-box" style={{overflow:'auto'}}>
                            <p className="Planeheading"><b>Unlimited Trades, Incredible Value</b></p>
                            <ul className="trading-planoption">
                                <li className="unlimitiedTrading">
                                    <img src="assets/images/ico-infinity.png" className="img-responsive center-block" alt="ico-infinity" style={{width:'40px'}} />
                                    <b>Unlimited trading</b> <br/> <i>In equity, F&O and currency segments.</i>
                                </li>
                                <li className="unlimitiedTrading">
                                    <img src="assets/images/ico-zero-brokerage.png" className="img-responsive center-block" alt="ico-zero-brokerage" />
                                    <b>Zero* Brokerage</b><br/> <i>For Intraday and Delivery Transactions.</i>
                                </li>  
                                <li className="unlimitiedTrading">
                                    <img src="assets/images/ico-zero-commitment.png" className="img-responsive center-block" alt="ico-zero-commitment" />
                                    <b>Zero* Commitment</b><br/><i>Opt-out anytime if you find a cheaper plan.</i>
                                </li>
                            </ul>
                            <div className="trading-plans">
                                <div className="row">
                                {this.state.masterdata.map((data, index) => 
                                    <div className="col-12 Equity-plandata">
                                         <div className="row">
                                            <div className="col-12 plans-padding text-center">
                                                <img src="assets/images/coupon.png" className="img-responsive center-block" alt="coupon" style={{width:'200px'}}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                { this.state.isActive ?  
                                    <button className="continue-button" style={{marginTop: '15px'}} 
                                    onClick={this.toggleButton.bind(this,
                                        (
                                            ((this.state.Fqplan==null || this.state.Fqplan=='') && (this.state.FnoPlan.plan_id==null || this.state.FnoPlan.plan_id==''))
                                            && ((this.state.EQplan==null || this.state.EQplan=='') && (this.state.data.plan_id==null || this.state.data.plan_id==''))
                                        )
                                        ?'0':(this.state.AutoRenwal?'6':'5'))}>Confirm</button>
                                    : 
                                    <button className="disable-button" style={{marginTop: '15px'}}  disabled
                                    onClick={this.toggleButton.bind(this,
                                        (
                                            ((this.state.Fqplan==null || this.state.Fqplan=='') && (this.state.FnoPlan.plan_id==null || this.state.FnoPlan.plan_id==''))
                                            && ((this.state.EQplan==null || this.state.EQplan=='') && (this.state.data.plan_id==null || this.state.data.plan_id==''))
                                        )
                                        ?'0':(this.state.AutoRenwal?'6':'5'))}>Confirm</button>
                                    
                                }
                                </div>
                                <div className="col-12 text-center">
                                    <p className="Planeheading"><b>Why Opt In ?</b></p>
                                    <ul className="trading-planoption">
                                        <li>The Most Cost-effective plan any broker can offer</li>
                                        <li>No Hidden Charges Involved</li>  
                                        <li>Transparent fee structure</li>
                                    </ul>
                                </div>
                            </div>  
                            <div className="row">
                                <div className="col-12 text-center">
                                        <p className="Planeheading"><b>FAQ'S</b></p>
                                        <div className="panel-group" id="accordion">
                                            <div className="panel-group accordion-small" id="accordion" aria-multiselectable="true">                           
                                                {/* equity plan */}
                                                <div class="panel panel-default">
                                                    <div onClick={() => this.clickFNO("first")} class="text-left panel-title row nopadding wms_accordion_header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse3" aria-controls="collapse3" aria-expanded="false">
                                                        <span class="col-10">What segments can I trade in for Rs 500?</span>
                                                        <span class="col-2 text-right"><i id="first" ref="first" class="fa fa-angle-down hot-news-arrow"></i></span>
                                                    </div>                                
                                                    <div id="collapse3" className="panel-collapse collapse">
                                                        <div className="panel-body" >
                                                            <div className="row contentpanel">
                                                                <div className="col-12 margin-equity-row text-center">
                                                                    <div className="row">
                                                                        <p>- You can trade unlimited in  Equity, F&O, and Currency at just Rs. 500 per month.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="panel panel-default">                                
                                                    <div onClick={() => this.clickFNO("second")} class="text-left panel-title row nopadding wms_accordion_header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-controls="collapse1" aria-expanded="false">
                                                        <span class="col-10">What type of transactions can I do if I subscribe to the subscription plan?</span>
                                                        <span class="col-2 text-right"><i id="second" ref="second" class="fa fa-angle-down hot-news-arrow"></i></span>
                                                    </div>
                                                    <div id="collapse1" className="panel-collapse collapse">
                                                        <div className="panel-body" >
                                                            <div className="row contentpanel">
                                                                <div className="col-12 margin-equity-row text-center">
                                                                    <div className="row">
                                                                        <p>- You can do unlimited Intraday, Delivery and Carry forward transactions if you subscribe to the subscription plan.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="panel panel-default">                                
                                                    <div onClick={() => this.clickFNO("third")} class="text-left panel-title row nopadding wms_accordion_header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse4" aria-controls="collapse4" aria-expanded="false">
                                                        <span class="col-10">How much do I save with this Subscription plan?</span>
                                                        <span class="col-2 text-right"><i id="third" ref="third" class="fa fa-angle-down hot-news-arrow"></i></span>
                                                    </div>
                                                    <div id="collapse4" className="panel-collapse collapse">
                                                        <div className="panel-body" >
                                                            <div className="row contentpanel">
                                                                <div className="col-12 margin-equity-row text-center">
                                                                    <div className="row">
                                                                        <p>- If you execute 2 buy and 2 sell orders* each day with the biggest discount broker of India, you will end up paying Rs. 1760 per month. With subscription plan, you just pay Rs. 500 per month. You save around 71 % on brokerage changes. The more you trade, the more you save.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="panel panel-default">                                
                                                    <div onClick={() => this.clickFNO("forth")} class="text-left panel-title row nopadding wms_accordion_header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse5" aria-controls="collapse5" aria-expanded="false">
                                                        <span class="col-10">What will be the Validity of this Subscription plan?</span>
                                                        <span class="col-2 text-right"><i id="forth" ref="forth" class="fa fa-angle-down hot-news-arrow"></i></span>
                                                    </div>
                                                    <div id="collapse5" className="panel-collapse collapse">
                                                        <div className="panel-body" >
                                                            <div className="row contentpanel">
                                                                <div className="col-12 margin-equity-row text-center">
                                                                    <div className="row">
                                                                        <p>- The Subscription plan will be valid for a calendar month. No matter whatever day you subscribe to the plan, the plan will end on the last day of the month and has to be renewed.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 plans-padding  auto-renwal-plan">
                                {this.state.masterdata.map((data, index) => 
                                    <div className="col-12 plans-padding">
                                        <input type="checkbox" id={data.plan_id} name="planeradio-1" value={data.plan_amount} 
                                        onChange={(event) => this.handleCheck(event)}
                                        checked={(this.state.EQplan==data.plan_id)? true :false} 
                                        onClick={this.colorChange.bind(this,data.plan_id,'0',data.plan_amount)}/>
                                        <label for={data.plan_id}>I want to Opt for subscription plan</label>
                                        
                                    </div>
                                    )}
                                    <input type="radio" id="planeradio10"  name="autorenwal-check" onClick={this.toggleButton.bind(this,'A')} checked={this.state.AutoRenwal} value="planeradio" />
                                    <label style={{marginLeft:'-4px'}} for="planeradio10">Auto renew my plans on expiry </label><span><i className="fa fa-info-circle info-circel"  aria-hidden="true" 
                                    onClick={this.infobox.bind(this)}></i></span>
                                    <p className="info-trading-popup" style={this.state.InfoMessage}>
                                        By selecting <span style={{color: '#1EBF8A'}}>Auto Renewal</span> you will get freedom from renewing the plan every month. This will help you enjoy a continuous validity of your selected plan and an uninterrupted 
                                        service by Dhanistocks<br/>
                                        <span onClick={this.onclose.bind(this)} className="tradingclosebutton">OK</span>
                                    </p>
                                    <p style={{paddingTop: '10px', marginBottom:'0px'}}>By clicking on Confirm you agree to the <span onClick={this.TermsCondition.bind(this)} style={{color: '#1EBF8A', cursor:'pointer', textDecoration:'underline'}}>Terms & Conditions</span></p>
                                    <p>*Brokerage at Rs. 0.01 per executed order.</p>
                                </div>
                            </div>
                            <div className="col-12 text-center">
                            { this.state.isActive ?  
                            <button className="continue-button" style={{marginTop: '15px'}} 
                                onClick={this.toggleButton.bind(this,
                                    (
                                        ((this.state.Fqplan==null || this.state.Fqplan=='') && (this.state.FnoPlan.plan_id==null || this.state.FnoPlan.plan_id==''))
                                        && ((this.state.EQplan==null || this.state.EQplan=='') && (this.state.data.plan_id==null || this.state.data.plan_id==''))
                                    )
                                    ?'0':(this.state.AutoRenwal?'6':'5'))} >Confirm</button>
                                    :
                            <button className="disable-button" style={{marginTop: '15px'}}  disabled
                                onClick={this.toggleButton.bind(this,
                                    (
                                        ((this.state.Fqplan==null || this.state.Fqplan=='') && (this.state.FnoPlan.plan_id==null || this.state.FnoPlan.plan_id==''))
                                        && ((this.state.EQplan==null || this.state.EQplan=='') && (this.state.data.plan_id==null || this.state.data.plan_id==''))
                                    )
                                    ?'0':(this.state.AutoRenwal?'6':'5'))} >Confirm</button>
                            } 
                            </div>
                                              
                        </div>
                    :null}
                    {/* total charges */}
                    {this.state.flag=='3' || this.state.flag=='5' || this.state.flag=='6'?
                        <div className="row total-plancharges">
                            {this.state.totalChargeMsg!=''?
                            <div className="col-12 plans-box">
                                {this.state.totalChargeMsg}
                            </div>
                            :null}
                            <div className="col-12 plans-box">
                                {/* <div className="row trading-lineH">
                                    <div className="col plans-padding text-left">
                                        <span>Account Opening Charges (include GST)</span>
                                    </div>
                                    <div className="col text-right">
                                        <strong><i className="fa fa-rupee"></i> 500</strong>
                                    </div>
                                </div>
                                <div className="row hdivider"> </div> */}
                            {this.state.Fqplan!=null && this.state.Fqplan!='' && (this.state.Fqplan!=this.state.FnoPlan.plan_id)?
                                <div>
                                <div className="row trading-lineH">
                                
                                    <div className="col plans-padding text-left">
                                    <i class="fa fa-check-square"></i><span>Futures and options</span>
                                    </div>
                                    <div className="col text-right">
                                        <strong><i className="fa fa-rupee"></i>
                                        <NumberFormat value={this.state.PlanAmountFq} displayType={'text'} thousandSeparator={true}  thousandsGroupStyle="lakh" renderText={value => <span> {value}</span>} />
                                         </strong>
                                    </div>
                                </div>
                                <div className="row hdivider"> </div>
                                </div>
                                :''}
                                {this.state.EQplan!=null && this.state.EQplan!='' && (this.state.EQplan!=this.state.data.plan_id) ?                                
                                <div>
                                <div className="row trading-lineH">
                                    <div className="col plans-padding text-left">
                                        <i class="fa fa-check-square"></i><span>  </span>
                                    </div>
                                    <div className="col text-right">
                                        <strong><i className="fa fa-rupee"></i> 
                                            <NumberFormat value={this.state.PlanAmountEq} displayType={'text'} thousandSeparator={true}  thousandsGroupStyle="lakh" renderText={value => <span> {value}</span>} />
                                        </strong>
                                    </div>
                                </div>
                                <div className="row hdivider"> </div>
                                </div>
                                : ''}
                                {(this.state.Fqplan!=null && this.state.Fqplan!='' && this.state.Fqplan!=this.state.FnoPlan.plan_id)
                                 || (this.state.EQplan!=null && this.state.EQplan!='' && this.state.EQplan!=this.state.data.plan_id)?
                                <div>
                                <div className="row trading-lineH">
                                    <div className="col plans-padding text-left">
                                        <b>GST charges applicable @ {this.state.GST_percent}%</b>
                                    </div>
                                    <div className="col text-right">
                                        <strong><i className="fa fa-rupee"></i> 
                                        <NumberFormat value={
                                            (((this.state.Fqplan!=null && this.state.Fqplan!='' && this.state.Fqplan!=this.state.FnoPlan.plan_id)?Number(this.state.PlanAmountFq):0)
                                            +
                                            ((this.state.EQplan!=null && this.state.EQplan!='' && this.state.EQplan!=this.state.data.plan_id)?Number(this.state.PlanAmountEq):0))
                                            *
                                            Number(this.state.GST_percent)/Number(100)
                                            } displayType={'text'} thousandSeparator={true}  thousandsGroupStyle="lakh" renderText={value => <span> {value}</span>} />
                                        </strong>
                                    </div>
                                </div>
                                <div className="row hdivider"> </div>
                                <div className="row trading-lineH">
                                    <div className="col plans-padding text-left">
                                        <strong>Total Amount</strong>
                                    </div>
                                    <div className="col text-right">
                                    <i class="fa fa-rupee"></i><span className="font-size"> 
                                    <NumberFormat value={
                                        ((this.state.Fqplan!=null && this.state.Fqplan!='' && this.state.Fqplan!=this.state.FnoPlan.plan_id)?Number(this.state.PlanAmountFq):0)
                                        +
                                        ((this.state.EQplan!=null && this.state.EQplan!='' && this.state.EQplan!=this.state.data.plan_id)?Number(this.state.PlanAmountEq):0)
                                        +
                                    //    Number(500)
                                    //    +
                                        (
                                            ((this.state.Fqplan!=null && this.state.Fqplan!='' && this.state.Fqplan!=this.state.FnoPlan.plan_id)?Number(this.state.PlanAmountFq):0)
                                                +
                                            ((this.state.EQplan!=null && this.state.EQplan!='' && this.state.EQplan!=this.state.data.plan_id)?Number(this.state.PlanAmountEq):0)
                                        )
                                        *Number(this.state.GST_percent)/Number(100)} displayType={'text'} thousandSeparator={true}  thousandsGroupStyle="lakh" renderText={value => <span> {value}</span>} />
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 plans-padding  auto-renwal-plan">
                                        <input type="radio" id="planeradio10"  name="autorenwal-check" onClick={this.toggleButton.bind(this,'A')} checked={this.state.AutoRenwal} value="planeradio" />
                                        <label for="planeradio10">Auto renew my plans on expiry </label><span><i className="fa fa-info-circle info-circel"  aria-hidden="true" 
                                        onClick={this.infobox.bind(this)}></i></span>
                                        <p className="info-trading-popup" style={this.state.InfoMessage}>
                                            By selecting Auto Renewal you will get freedom from renewing the plan every 30 days. This will help you enjoy a continuous validity of your selected plan and an uninterrupted 
                                            service by Indiabulls Dhanistocks.<br/>
                                            <span onClick={this.onclose.bind(this)} className="tradingclosebutton">Close</span>
                                        </p>
                                        <p style={{paddingTop: '10px'}}>By clicking on Review & Confirm you accept to the <span onClick={this.TermsCondition.bind(this)} style={{color: '#1EBF8A', cursor:'pointer'}}>Terms & Conditions</span></p>
                                    </div>
                                </div>
                                
                                </div>
                                :<div className="row">
                                {(this.state.Fqplan==null || this.state.Fqplan=='') && (this.state.EQplan==null || this.state.EQplan=='')?<div className="col-12" style={{marginTop:'20%'}}></div>:
                                <div className="col-12 plans-padding  auto-renwal-plan" style={{marginTop:'10%'}}>
                                    <input type="radio" id="planeradio10"  name="autorenwal-check" onClick={this.toggleButton.bind(this,'A')} checked={this.state.AutoRenwal} value="planeradio" />
                                    <label for="planeradio10">Auto renew my plans on expiry </label><span><i className="fa fa-info-circle info-circel"  aria-hidden="true" 
                                    onClick={this.infobox.bind(this)}></i></span>
                                    <p className="info-trading-popup" style={this.state.InfoMessage}>
                                        By selecting Auto Renewal you will get freedom from renewing the plan every 30 days. This will help you enjoy a continuous validity of your selected plan and an uninterrupted 
                                        service by Indiabulls Dhanistocks.<br/>
                                        <span onClick={this.onclose.bind(this)} className="tradingclosebutton">Close</span>
                                    </p>
                                    <p style={{paddingTop: '10px'}}>By clicking on Review & Confirm you accept to the <span onClick={this.TermsCondition.bind(this)} style={{color: '#1EBF8A', cursor:'pointer'}}>Terms & Conditions</span></p>
                                </div>}
                        </div>}
                                <div className="row">
                                    <div className="col-6 text-left">
                                        <button className="continue-button" style={{marginTop: '15px'}} onClick={this.toggleButton.bind(this,'2')}>Back</button>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button className="continue-button" style={{marginTop: '15px'}} 
                                        onClick={this.toggleButton.bind(this,
                                            (
                                                ((this.state.Fqplan==null || this.state.Fqplan=='') && (this.state.FnoPlan.plan_id==null || this.state.FnoPlan.plan_id==''))
                                                && ((this.state.EQplan==null || this.state.EQplan=='') && (this.state.data.plan_id==null || this.state.data.plan_id==''))
                                            )
                                            ?'0':(this.state.AutoRenwal?'6':'5'))}>Review & confirm</button>
                                    </div>
                                </div>

                               
                            </div>
                        </div>   
                    :null}
                    {/* first review and confirm */}
                    {this.state.flag=='11'? 
                    <div className="review-confirm">
                    <div className="row review-text">
                        <div className="col-lg-12">
                            <div className="review-heading">REVIEW & CONFIRM</div>
                        </div>                        
                        <div className="col-lg-12 text-center" style={{margin:'auto'}}>
                            <p>You have choose to opt out of the F&O Plan.<br/>F&O charges will be <i className="fa fa-rupee"></i> 15 per executed order.</p>
                        </div>
                        <div className="fillwidth">
                            <div className="col text-center button-back-confirm">
                            <span onClick={this.toggleButton.bind(this,'1')} style={{paddingRight: '50px'}}>BACK</span>
                               
                                {
                                (
                                    (this.state.FnoPlan.plan_id!=null && this.state.FnoPlan.plan_id!='') 
                                    ? <span onClick={this.toggleButton.bind(this, '2')}>Change On Expiry</span>
                                    : (<span onClick={this.toggleButton.bind(this, '2')}>Confirm</span>)
                                )
                                }
                               
                            </div>
                        </div>
                    </div>
                    </div>
                    :null}
                    {/* review and confirm */}
                    {this.state.flag=='4'? 
                    <div className="review-confirm" style={this.state.InfoMessage}>
                    <div className="review-confirm">
                    
                    <div className="row review-text" style={{paddingTop:'40px', paddingBottom:'40px'}} >
                    <span onClick={this.onclose.bind(this)} className="tradingclosebutton unsubClose"><i class="fa fa-times"></i></span>
                        <div className="col-lg-12">
                            <div className="review-heading">CONFIRMATION</div>
                        </div>                      
                        <div className="confirm-options col-lg-12">
                            <p>You have choose to opt out of subscription plan</p>
                        </div>

                        <div className="col text-center button-back-confirm">
                            <span onClick={this.DownUpgrade.bind(this,'7', 'OI')} style={{paddingRight: '20px', paddingLeft:'20px'}}>Unsubscribe Now</span>
                            <span onClick={this.DownUpgrade.bind(this,'7', 'O')}>Unsubscribe On Expiry</span>
                        </div>
                    </div>
                    </div>
                    </div>
                    :null}
                    {/* confirm message */}
                    {this.state.flag=='5'? 
                    <div className="review-confirm">
                    <div className="row review-text" style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <div className="col-lg-12">
                            <div className="review-heading">CONFIRMATION</div>
                        </div>                      
                        <div className="confirm-options col-lg-12">
                            <p>By not selecting <strong><span style={{color: '#1EBF8A'}}>Auto Renewal</span></strong>, you will have to renew the plan manually on expiry.</p> 
                            <p>If you do not renew on timely basis, you will be moved back to your original plan.</p>
                        </div>

                        <div className="col text-center button-back-confirm">
                            <span onClick={this.toggleButton.bind(this,'2')} style={{paddingRight: '20px', paddingLeft:'20px'}}>BACK</span>
                            <span onClick={this.toggleButton.bind(this,'6', )}>Confirm</span>
                        </div>
                    </div>
                    </div>
                    :null}
                    {/* final message */}
                    {this.state.flag=='6'? 
                    <div className="review-confirm">
                    <div className="row review-text" style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <div className="col-lg-12">
                            <div className="review-heading">{this.state.result=='S'?"CONFIRMATION":(this.state.result=='F'?"REQUEST FAILED!":"")}</div>
                        </div>                      
                        <div className="confirm-options col-lg-12">
                            {/* <p>Thank you for sending the request.</p>  */}
                            <p>{this.state.finalmessage}</p>
                        <div className="col button-back-confirm">
                            <span style={{paddingLeft:'20px', paddingRight:'20px'}} onClick={this.dashboard.bind(this,'0')}>OK</span>
                            
                        </div>
                    </div>
                    </div>
                    </div>
                    :null}
                    {/* Expiry */}
                    {this.state.flag=='7'? 
                    <div className="review-confirm">
                    <div className="row review-text" style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <div className="col-lg-12">
                            <div className="review-heading">{this.state.result=='S'?"CONFIRMATION":(this.state.result=='F'?"REQUEST FAILED!":"")}</div>
                        </div>                      
                        <div className="confirm-options col-lg-12">
                            {/* <p>Thank you for sending the request.</p>  */}
                            <p>{this.state.finalmessage}</p>
                        <div className="col button-back-confirm">
                            <span  style={{paddingLeft:'20px', paddingRight:'20px'}} onClick={this.dashboard.bind(this,'0')}>OK</span>
                            
                        </div>
                    </div>
                    </div>
                    </div>
                    :null}
                    {/* downgrade plan */}
                    {this.state.flag=='8'? 
                    <div className="review-confirm">
                    <div className="row review-text">
                        <div className="col-lg-12">
                            <div className="review-heading">REVIEW & CONFIRM</div>
                        </div>                      
                        <div className="review-optuions col-lg-12">
                            <p>Your new plan with lower Margin Funding limit will be effective after the end of existing plan.</p>                        
                            <p>Do you wish to confirm?</p>
                            </div>
                        <div className="col text-center button-back-confirm">
                            <span onClick={this.toggleButton.bind(this,'2')} style={{paddingRight: '50px'}}>BACK</span>
                            {/* <span onClick={this.DownUpgrade.bind(this,(this.state.AutoRenwal?'6':'5'), 'D')}>Change On Expiry</span> */}
                            <span onClick={this.toggleButton.bind(this,'3')} style={{paddingRight: '50px'}}>Change On Expiry</span>
                        </div>
                    </div>
                    </div>
                    :null}
                </div>
                
                   }
                </div>
                }
            </div>
        )
    }
}   

export default MyTradingPlan;

