import React from 'react';
import { Marketopen } from '../../actions/login/preloginrequests'
import {setCookie} from '../../common/utils'


class MarketOpen extends React.Component {

    constructor(props) {
        super(props);
        this.state ={
            data:[],
            inputtext:'MCX',
            error:''
        }
        this.handleChange = this.handleChange.bind(this);
    }


    componentWillMount(){
        setCookie('REDIRECT', null, 0);
        var requestData = {
            request: {
                data: {
                    exc: this.state.inputtext
                }
            }
        }
        Marketopen(requestData, this.marketopenresponce.bind(this), this.marketopenerror.bind(this));
    }

    marketopenresponce(responseData){
        console.log("market open responce data");
        console.log(responseData);
        this.setState({
            data: responseData.response.data.data,
            error: ''
        }, () =>{
            console.log("this.state.data=========================");
            console.log(this.state.data);
        })
    }

    marketopenerror(error){
       this.setState({
           error: error.message
       })
    }
    handleChange(e) {
        this.setState(
            { 
                inputtext: e.target.value
            },() =>{
                console.log("this.state.inputtext=================");
                console.log(this.state.inputtext);
                var requestData = {
                    request: {
                        data: {
                            exc: this.state.inputtext
                        }
                    }
                }
                Marketopen(requestData, this.marketopenresponce.bind(this), this.marketopenerror.bind(this));
            });

        

    }

    
    render() {

        var errorHolder = (
            <div className="error-holder" style={{ marginTop: "5px", color:'#000'}}>
                <span style={{color:'#000'}}> {this.state.error} </span>
            </div>
        );


        return (
            <div style={{padding:15}}>
                {
                    this.state.error ? 
                    errorHolder :
                    <div>
                <div style={{ paddingTop: 35, color: '#37bf8a' }}>
                    <div style={{ textAlign: 'left', fontSize: 18, padding: '5px' }}>Market Status</div>
                </div>
                <div style={{ marginTop: 30 }} className="row">
                    <div className="col-6">
                        <div className='icon_style'>
                            {/* <span className="fa fa-user-o login_icon "></span> */}
                            <input className="" type="radio" id="exc" name="exc" value="MCX" onClick={this.handleChange}  checked={this.state.inputtext == 'MCX'} />
                            <label for="exc" style={{ fontSize:14, paddingLeft:10, color:'#000' }}> MCX</label>
                        </div>
                    </div>
                    {/* <div className="col-6">
                        <div className='icon_style'>
                            <input className="" type="radio" id="nsc" name="exc" value="NCDEX" onClick={this.handleChange} checked={this.state.inputtext == 'NCDEX'}/>
                            <label for="nsc" style={{ fontSize:14, paddingLeft:10, color:'#000' }}> NCDEX</label>
                        </div>
                    </div> */}
                
                    {/* <button type="button" className="form-control login_btn " onClick={props.callbackHandler}>CONTINUE</button> */}
                </div>
                <div className="row" style={{ marginTop:30, color:'#37bf8a', padding:'7px' }}>
                    <div className="col-2">
                        <div className="portfolio-header-title">Exchange</div>
                    </div>
                    <div className="col text-center">
                        <div className="portfolio-header-title">Status</div>
                    </div>
                    <div className="col">
                        <div className="portfolio-header-title">Status Update Time</div>
                    </div>
                </div>
                <div className="row" style={{color:'#000', padding:'7px', borderBottom:'1px solid #cdcdcd' }}>
                    <div className="col-2">
                        <div className="portfolio-header-title">{this.state.inputtext}</div>
                    </div>
                    <div className="col text-center">
                        <div className="portfolio-header-title">{this.state.data.MktStatus}</div>
                    </div>
                    <div className="col">
                        <div className="portfolio-header-title">{this.state.data.exchfeedtime}</div>
                    </div>
                </div>
                </div>
                }
            </div>
        )
    }
}

export default MarketOpen;