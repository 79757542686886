import React from "react"
import { isValidWatchlistName } from '../../validators/watchlistValidations'
import ButtonTab from '../ButtonTab'

class EditListDialog extends React.Component {
    constructor(props) {
        super(props);
        this.buttonTab = ["EQUITY", "FUTURE", "OPTIONS"]
        this.editColCount = 4
        this.state = {
            columnscount: 6,
            allitems: [],
            parseditems: [],
            selecteditems: [],
            watchlist: '',
            newname: '',
            error: '',
            iseditingname: false,
            selectedAssetClass: this.buttonTab[0],
            editDefaultList: [],
            editCustomList: [],
            currentTabKey: "eq",
            symbolscount: 0,
            default_custom_cols: ['4', '5', '6', '7', '9', '10', '11', '12', '13'],
        }
        this.handleNegativeButton = this.handleNegativeButton.bind(this);
        this.handlePossitiveButton = this.handlePossitiveButton.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.isInSelected = this.isInSelected.bind(this);
        this.getSelectedItems = this.getSelectedItems.bind(this);
        this.getEditingWatchlist = this.getEditingWatchlist.bind(this);
        this.getSelectedAssetClass = this.getSelectedAssetClass.bind(this);

        this.startEditingName = this.startEditingName.bind(this);
        this.cancelEditingName = this.cancelEditingName.bind(this);
        this.confirmEditingName = this.confirmEditingName.bind(this);
    }

    setItems(list, callback) {
        if (this.props.type === "deletewatchlist")
            this.setState({ allitems: list, parseditems: this.parseInput(list) })
        else if (this.props.type === "editwatchlist") {
            this.editParseInput(list, callback);
        }
    }

    setEditingWatchlist(selectedwatchlist, symbolcount) {
        this.setState({ watchlist: selectedwatchlist, symbolscount: symbolcount, newname: selectedwatchlist.name, error: '' });

        if (selectedwatchlist.settings && selectedwatchlist.settings.assetClass) {
            var assetclass = selectedwatchlist.settings.assetClass;

            var list = selectedwatchlist.settings.equityColumns;
            list = list.concat(selectedwatchlist.settings.futureColumns);
            list = list.concat(selectedwatchlist.settings.optionsColumns);

            if(list.length === 0)
                list = this.state.default_custom_cols;

            this.setSelected(assetclass, list);

            // if (assetclass === "EQUITY") {
            //     this.setSelected(assetclass, selectedwatchlist.settings.equityColumns);
            // } else if (assetclass === "FUTURE") {
            //     this.setSelected(assetclass, selectedwatchlist.settings.futureColumns);
            // } else if (assetclass === "OPTIONS") {
            //     this.setSelected(assetclass, selectedwatchlist.settings.optionsColumns);
            // }
        }
        else {
            // its initial, none selected
            this.setSelected("EQUITY", this.state.default_custom_cols);
        }
    }

    setSelected(assetclass, selectedcolumns) {
        var list = this.state.allitems.filter(i => (selectedcolumns.filter(j => j === i.id).length > 0));
        this.setState({ selectedAssetClass: assetclass, selecteditems: list });

        if (assetclass === "EQUITY") {
            this.setState({ currentTabKey: "eq" });
        } else if (assetclass === "FUTURE") {
            this.setState({ currentTabKey: "fut" });
        } else if (assetclass === "OPTIONS") {
            this.setState({ currentTabKey: "opt" });
        }
    }

    setError(err) {
        this.setState({ error: err });
    }

    startEditingName() {
        this.setState({ iseditingname: true });
    }

    cancelEditingName() {
        this.setState({ newname: this.state.watchlist.name, iseditingname: false, error: '' });
    }

    confirmEditingName() {
        if (this.state.newname !== this.state.watchlist.name) {
            // name changed
            var err = isValidWatchlistName(this.state.newname);
            if (err !== '') {
                this.setState({ error: err });
                return;
            }


            if (this.props.confirmRenameWatchlist)
                this.props.confirmRenameWatchlist(this.state.watchlist, this.state.newname);

            //this.setState({ iseditingname: false });
        }
        else {
            // name unchanged
            // doing nothing
        }
    }

    handleNegativeButton() {
        if (this.props.onNegativeButtonClick)
            this.props.onNegativeButtonClick();
    }

    handlePossitiveButton() {

        if (this.props.onPossitiveButtonClick)
            this.props.onPossitiveButtonClick();
    }

    handleNameChange(e) {
        this.setState({ newname: e.target.value, error: '' });
    }

    handleCheckChange(e) {
        var itemid = (e.target.getAttribute("data-tag"));
        var existing = this.state.selecteditems.filter(function (i) {
            return i.id === itemid;
        });

        if (existing && existing.length > 0) {
            if (!e.target.checked) {
                // exising in selected and user unchecked
                let items = this.state.selecteditems;
                let indx = items.indexOf(existing[0]);
                items.splice(indx, 1);
                this.setState({ selecteditems: items });
            }
        }
        else {
            if (e.target.checked) {
                // non-exising in selected and user checked               

                if (this.props.type === "editwatchlist") {
                    if (this.state.selecteditems.length <= 10) {
                        let neww = this.state.allitems.filter(i => i.id === itemid)[0];
                        let items = this.state.selecteditems;
                        items.push(neww);
                        this.setState({ selecteditems: items });
                    }
                }
                else {
                    let neww = this.state.allitems.filter(i => i.id === itemid)[0];
                    let items = this.state.selecteditems;
                    items.push(neww);
                    this.setState({ selecteditems: items });
                }
            }
        }
    }

    isInSelected(item) {

        if (!item.editable)
            return true;

        var existing = this.state.selecteditems.filter(function (i) {
            return i.id === item.id;
        });
        return (existing.length > 0)
    }

    getSelectedAssetClass() {
        return this.state.selectedAssetClass;
    }

    getEditingWatchlist() {
        return this.state.watchlist;
    }

    getSelectedItems() {
        return this.state.selecteditems;
    }

    unselectItems() {
        this.setState({ selecteditems: [] });
    }

    resetEdit() {
        this.setState({ iseditingname: false });
    }

    parseInput(data) {
        var matrix = [], i, k;

        for (i = 0, k = -1; i < data.length; i++) {
            if (i % this.state.columnscount === 0) {
                k++;
                matrix[k] = [];
            }
            matrix[k].push(data[i]);
        }
        return matrix;
    }

    editParseInput(data, callback) {
        var defaultList = [], customList = [], i, k, j;
        for (i = 0, k = -1, j = 0; i < data.length; i++) {
            if (!data[i].default) {
                if (j % this.editColCount === 0) {
                    k++;
                    customList[k] = [];
                }
                j++;
                customList[k].push(data[i]);
            }
        }

        for (i = 0, k = -1, j = 0; i < data.length; i++) {
            if (data[i].default) {
                if (j % customList.length === 0) {
                    k++;
                    defaultList[k] = [];
                }
                j++;
                defaultList[k].push(data[i]);
            }
        }

        this.setState({ allitems: data, editCustomList: customList, editDefaultList: defaultList }, () => {
            if (callback) callback();
        })
    }

    onHandleButtonTab(selected) {
        if (this.state.selectedAssetClass === selected)
            return;

        //this.unselectItems();
        this.setState({ selectedAssetClass: selected });
        if (selected === "EQUITY") {
            this.setState({ currentTabKey: "eq" });
        } else if (selected === "FUTURE") {
            this.setState({ currentTabKey: "fut" });
        } else if (selected === "OPTIONS") {
            this.setState({ currentTabKey: "opt" });
        }
    }


    render() {

        var checklist = this.props.type === "editwatchlist" ? (
            <div className="container-fluid fillwidth contentpanel">
                <div className="row edit-watch-btn-parent">
                    <ButtonTab
                        tabs={this.buttonTab}
                        callbackHandler={this.onHandleButtonTab.bind(this)}
                        activeTab={this.state.selectedAssetClass}
                        customStyle="col-6 nopadding"
                    />
                </div>
                <div className="row contentpanel" >
                    <div className="col-lg-4 col-md-4 col-sm-4 contentpanel" >
                        <span className="col-12 contentpanel">DEFAULT COLUMNS</span>
                        <div className="col-12 contentpanel edit-check-parent" >
                            {this.state.editDefaultList.map(function (row, key) {
                                return (
                                    <div key={key} className="col-6 contentpanel" >
                                        {
                                            row.map(function (rw, ky) {
                                                return (
                                                    <div key={ky} className="col-12 contentpanel">
                                                        <label className="custom-check edit-dialog-check">
                                                            <input className="custom-checkbox" type="checkbox" onChange={this.handleCheckChange} checked={this.isInSelected(rw)}
                                                                data-tag={rw.id} disabled={!rw.editable} />
                                                            <span className={!rw.editable ? "custom-check-label disabled" : "custom-check-label"}>{rw.name}</span>
                                                        </label>
                                                    </div>
                                                );
                                            }, this)}
                                    </div>
                                );
                            }, this)
                            }
                        </div>
                    </div>
                    <div className="col-8 contentpanel edit-custom-padding" >
                        <span className="col-12 contentpanel">CUSTOM COLUMNS</span>
                        <div className="col-12 contentpanel edit-check-parent padding" >
                            {this.state.editCustomList.map(function (row, key) {
                                return (
                                    <div key={key} className="row contentpanel" >
                                        {row.map(function (rw, ky) {
                                            return (
                                                <div key={ky} className="col-3 contentpanel">
                                                    <label className="custom-check edit-dialog-check">
                                                        <input className="custom-checkbox" type="checkbox" onChange={this.handleCheckChange} checked={this.isInSelected(rw)}
                                                            data-tag={rw.id} disabled={!rw.editable || (rw.activefor.indexOf(this.state.currentTabKey) === -1)} />
                                                        <span className={!rw.editable || (rw.activefor.indexOf(this.state.currentTabKey) === -1) ? "custom-check-label disabled" : "custom-check-label"}>{rw.name}</span>
                                                    </label>
                                                </div>
                                            );
                                        }, this)}
                                    </div>
                                );
                            }, this)
                            }
                        </div>
                    </div>
                </div>
            </div>
        ) :
            (<div className="container-fluid fillwidth">
                {this.state.parseditems.map(function (row, key) {
                    return (
                        <div key={key} className="row" >
                            {row.map(function (rw, ky) {
                                return (
                                    <div key={ky} className="col-2">
                                        <label className="custom-check edit-dialog-check">
                                            <input className="custom-checkbox" type="checkbox" onChange={this.handleCheckChange} checked={this.isInSelected(rw)}
                                                data-tag={rw.id} />
                                            <span className="custom-check-label">{rw.name}</span>
                                        </label>
                                    </div>
                                );
                            }, this)}
                        </div>
                    );
                }, this)
                }
            </div>
            );

        var mbody = (<div />);
        var mhead = ("");
        var mfooternote = ("");
        if (this.props.type === "deletewatchlist") {
            mbody = (
                <div className="fillwidth">
                    <ul>
                        <li>
                            <p> Custom </p>
                            {checklist}
                        </li>
                    </ul>
                </div>
            );
        }
        else if (this.props.type === "editwatchlist") {
            mbody = checklist;
            mfooternote = (<p className="note">NOTE: Maximum columns allowed for watchlist is 16.</p>);
            mhead = (
                <div >
                    <div>
                        {this.state.watchlist.isEditable ?
                            <span>
                                {this.state.iseditingname ?
                                    <span className="edit-right-icon">
                                        <img src='assets/svg/close.svg' className="rename-closebtn" data-toggle="tooltip" title="Close" onClick={this.cancelEditingName} alt="close"/>
                                        <i className="fa fa-check fa-2x" onClick={this.confirmEditingName} />
                                    </span>
                                    : <i className="edit-right-icon" onClick={this.startEditingName} >Rename</i>
                                }
                            </span>
                            :
                            <span />
                        }
                        <input type="text" className="form-control search-input modal-header-textinput" placeholder="Enter Watchlist name" value={this.state.newname}
                            onChange={this.handleNameChange} readOnly={!this.state.iseditingname} />
                    </div>
                    <div className="error-holder"> {this.state.error} </div>
                </div>
            );
        }


        return (
            <div className={"modal fade" + (this.props.showDialog ? "in dialog-show disabledbackground" : " dialog-hide")} id={this.props.id} role="dialog" >
                <div className={"modal-dialog big " + (this.props.type === "deletewatchlist" && "delete")}>
                    <div className={"modal-header " + (this.props.type === "deletewatchlist" && "delete")}>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 contentpanel"> {this.props.title} </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 contentpanel"> {mhead} </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 contentpanel">
                            {this.props.type === "editwatchlist" ?
                                <div className="col-10 col-lg-10 col-md-10 text-right">
                                    NO. OF SCRIPS: {this.state.symbolscount}
                                </div>
                                : ""
                            }
                            <img src='assets/svg/close.svg' className="closebtn" data-toggle="tooltip" title="Close"  onClick={this.handleNegativeButton} alt="close"/>
                        </div>
                    </div>
                    </div>
                    <div className={"modal-body " + (this.props.type === "editwatchlist" ? "edit" : "delete")}>
                        {mbody}
                    </div>
                    <div className="modal-footer">
                    <div className="row">
                        <div className="col-6 col-lg-6 col-md-6">
                            {mfooternote}
                        </div>
                        <div className="col-3 col-lg-3 col-md-3">
                            <button type="button" className="footerbtn" onClick={this.handleNegativeButton} >{this.props.negativebuttontext}</button>
                        </div>
                        <div className="col-3 col-lg-3 col-md-3">
                            <button type="button" className="footerbtn" onClick={this.handlePossitiveButton} >{this.props.possitivebuttontext}</button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditListDialog;