import React from "react";
import { comaSeparator } from '../common/utils'

class MarketDepth extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            asks: this.props.asks,
            bids: this.props.bids,
            symDetails: this.props.symDetails,
            tbuyQty: this.props.totalbuyquantity,
            tsellQty: this.props.totalsellquantity,

            inProgress: false,
            error: '',
        };
        this.onStreamCallback = this.onStreamCallback.bind(this);
        this.onStreamCallbackTR = this.onStreamCallbackTR.bind(this);
        this.tag = this.props.screenName ? this.props.screenName : "OrderMarketDepth";
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.symDetails !== nextProps.symDetails) {
            window.socketEvents.unregister(this.tag);
            window.socketEventsTR.unregister(this.tag,"2");
            this.setState({
                symDetails: nextProps.symDetails,
                tbuyQty: nextProps.totalbuyquantity,
                tsellQty: nextProps.totalsellquantity,
                asks: nextProps.asks,
                bids: nextProps.bids,
            }, () => {
                if (nextProps.inProgress) {
                    this.setState({ inProgress: true });
                }
                // else if (!this.state.asks || this.state.asks.length === 0) {
                //     this.setState({ inProgress: false, error: 'No Data found' });
                // }
                else {
                    this.setState({ inProgress: false, error: '' });
                }
                this.onSocketRegister(this.state.symDetails)
            });
        }
        //TODO: check if streaming has to be subscribed here
    }

    componentDidMount() {
        this.onSocketRegister(this.state.symDetails)
    }

    //================  Stream Functions  =================//

    onSocketRegister(data) {
        if (!this.props.symDetails || !this.props.symDetails.sym) return;

        if (localStorage.getItem("TR_STREAM_FLAG") === "N") {            
        window.socketEvents.register(this.props.symDetails.streamSym + this.props.symDetails.sym.exc, { screenName: this.tag, sym: this.props.symDetails.streamSym, exc: this.props.symDetails.sym.exc }, this.onStreamCallback, "2")
        setTimeout(function () {
            window.socketEvents.subscribe();
        }, 1000)
        } else {            
            window.socketEventsTR.register(this.props.symDetails.sym.xSym, { screenName: this.tag, sym: this.props.symDetails.sym.xSym, exc: this.props.symDetails.sym.exc_seg }, this.onStreamCallbackTR, "2")
            setTimeout(function () {
                window.socketEventsTR.subscribe("2");
            }, 1000)
    	}

    }

    onStreamCallback(streamData) {
    
        var data = this.state.symDetails;
        if (data.streamSym === streamData.data.sym && data.sym.exc === streamData.data.exc) {
            var bids = streamData.data.bids ? streamData.data.bids : data.bids;
            var asks = streamData.data.asks ? streamData.data.asks : data.asks;
            var tbuyQty = streamData.data.totBuyQty? Number(streamData.data.totBuyQty):data.tbuyQty;
            var tsellQty = streamData.data.totSellQty ? Number(streamData.data.totSellQty):data.tsellQty;
            var brem = 5 - bids.length;
            var aasks = 5 - asks.length;

            for (let i = 0; i < brem; i++) {
                bids[4 - i] = { bidNoOrders: "-", bidPrice: '-', bidQty: '-' }
            }

            for (let i = 0; i < aasks; i++) {
                asks[4 - i] = { askNoOrders: "-", askPrice: '-', askQty: '-' }
            }

            this.setState({ bids: bids, asks: asks, tbuyQty: '' + tbuyQty, tsellQty: '' + tsellQty })
        }
    }

    onStreamCallbackTR(streamData) {
        var data = this.state.symDetails;        
        if(data.sym.xSym===streamData.data.xSym){           
           
            var data = this.state.symDetails;
            var bids = streamData.data.bids ? streamData.data.bids : data.bids;
            var asks = streamData.data.asks ? streamData.data.asks : data.asks;
            var tbuyQty = streamData.data.totBuyQty? Number(streamData.data.totBuyQty) : (data.tbuyQty ? data.tbuyQty : 0);
            var tsellQty = streamData.data.totSellQty ? Number(streamData.data.totSellQty) : (data.tsellQty? data.tsellQty : 0);
            var brem = 5 - bids.length;
            var aasks = 5 - asks.length;

            for (let i = 0; i < brem; i++) {
                bids[4 - i] = { bidNoOrders: "-", bidPrice: '-', bidQty: '-' }
            }

            for (let i = 0; i < aasks; i++) {
                asks[4 - i] = { askNoOrders: "-", askPrice: '-', askQty: '-' }
            }

            this.setState({ bids: bids, asks: asks, tbuyQty: '' + tbuyQty, tsellQty: '' + tsellQty })
         

        }
    }


    componentWillUnmount() {
        window.socketEvents.unregister(this.tag);
        window.socketEventsTR.unregister(this.tag,"2");
    }
    //======================================================

    render() {

        var errorHolder = (
            <div className="error-holder">
                {this.state.error}
            </div>
        );

        var progressindicator = (
            <div className="widget-progress-holder">
                <svg class="circular-progress" viewBox="25 25 50 50">
                    <circle class="circular-progress_path" cx="50" cy="50" r="20"
                        fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
            </div>
        );

        if (this.state.inProgress) {
            return progressindicator;
        }
        else if (this.state.error)
            return errorHolder;
        else
            return (
                <div className="market-depth">
                    <div>
                        <div className="row">
                            <div className="col-6 contentpanel marketdepth-row buy">
                            <div className="row">
                                <div className="col-3">Total Orders </div>
                                <div className="col-4"> Buy Qty </div>
                                <div className="col-5"> Buy Price </div>
                            </div>
                            </div>
                            <div className="col-6 contentpanel marketdepth-row sell">
                            <div className="row">
                                <div className="col-5"> Sell Price </div>
                                <div className="col-4"> Sell Qty </div>
                                <div className="col-3"> Total Orders </div>
                                </div>
                            </div>
                            </div>

                            <div className="row">
                            <div className="col-6 contentpanel marketdepth-data">
                            <div class="row">
                                {this.state.bids && this.state.bids.map(function (row, key) {
                                    return (
                                        <div key={'bids-' + key} className="col-12 contentpanel marketdepth-row buy-data">
                                        <div className="row">
                                            <div className="col-3"> {row.bidNoOrders ? comaSeparator(row.bidNoOrders) : '0'} </div>
                                            <div className="col-4"> {row.bidQty ? comaSeparator(row.bidQty) : '0'} </div>
                                            <div className="col-5"> {row.bidPrice ? comaSeparator(row.bidPrice) : '0.00'} </div>
                                            </div>
                                        </div>
                                    )
                                })} 
                                </div>
                            </div>
                            <div className="col-6 contentpanel marketdepth-data">
                            <div className="row">
                                {this.state.asks && this.state.asks.map(function (row, key) {
                                    return (
                                        <div key={'asks-' + key} className="col-12 contentpanel marketdepth-row">
                                        <div className="row">
                                            <div className="col-5"> {row.askPrice ? comaSeparator(row.askPrice) : '0.00'} </div>
                                            <div className="col-4"> {row.askQty ? comaSeparator(row.askQty) : '0'} </div>
                                            <div className="col-3"> {row.askNoOrders ? comaSeparator(row.askNoOrders) : '0'} </div>
                                        </div>
                                        </div>
                                    )
                                })}
                                </div>
                            </div>
                            </div>

                    </div>
                    <div className="row contentpanel">
                        <div className="col-6 marketdepth-footer buy">TBQ  {comaSeparator(this.state.tbuyQty)}</div>
                        <div className="col-6 marketdepth-footer sell">TSQ  {comaSeparator(this.state.tsellQty)}</div>
                    </div>
                </div>
            );
    }

}

export default MarketDepth;