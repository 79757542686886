import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChartsBaseWidget from '../base/ChartsBaseWidget';
import ChartBaseView from '../../components/charts/chartiq/containers/ChartBaseView'
import { BaseUrl, ModuleKeys } from '../../common/constants'

class ChartView extends React.Component {

    constructor(props) {
        super(props);
        var symbol = this.getSymbol();
        this.state = {
            url: '',
            inProgress: false,
            error: '',
            symbol: symbol ? symbol : this.props.symbol
        };
    }

    getSymbol() {
        if (this.props.chartcontent === 'Chart 1') {
            return this.props.chart1;
        } else if (this.props.chartcontent === 'Chart 2') {
            return this.props.chart2;
        } else if (this.props.chartcontent === 'Chart 3') {
            return this.props.chart3;
        } else if (this.props.chartcontent === 'Chart 4') {
            return this.props.chart4;
        } else {
            return undefined;
        }
    }

    componentDidMount() {
        //for mock base url used...need to remove base url
        this.loadChart(BaseUrl + '/chartiq/scrippagetemplate.html?symbol=' + this.props.symbol);
    }

    componentWillReceiveProps(newProps) {
        if (this.props.chartcontent) {
            switch (this.props.chartcontent) {
                case 'Chart 1':
                    if (newProps.chart1 && this.state.symbol !== newProps.chart1) {
                        this.setState({ symbol: newProps.chart1 })
                    }
                    break;
                case 'Chart 2':
                    if (newProps.chart2 && this.state.symbol !== newProps.chart2) {
                        this.setState({ symbol: newProps.chart2 })
                    }
                    break;
                case 'Chart 3':
                    if (newProps.chart3 && this.state.symbol !== newProps.chart3) {
                        this.setState({ symbol: newProps.chart3 })
                    }
                    break;
                case 'Chart 4':
                    if (newProps.chart4 && this.state.symbol !== newProps.chart4) {
                        this.setState({ symbol: newProps.chart4 })
                    }
                    break;
            }
        } else {
            if (this.state.symbol !== newProps.symbol) {
                this.setState({ symbol: newProps.symbol })
            }
        }
    }

    loadChart(charturl) {
        this.setState({ url: charturl });
    }

    onIframeLoad(e) {

    }

    render() {
        return (

            <ChartsBaseWidget
                componentType={this.props.componentType}
                inProgress={this.state.inProgress}
                error={this.state.error}
            >

                <div className="chart-iframe ciq-night fillheight">
                    <ChartBaseView symbol={this.state.symbol} id={this.props.id}
                        componentType={this.props.componentType}
                        showSearch={this.props.showSearch}
                        isChartDialog={this.props.isChartDialog}
                        chartcontent={this.props.chartcontent} />
                </div>
            </ChartsBaseWidget>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        activeWidget: state.charts.activeWidget,
        chart1: state.charts.chart1,
        chart2: state.charts.chart2,
        chart3: state.charts.chart3,
        chart4: state.charts.chart4,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartView);