import { UrlExtensions, BaseUrl, localStorageKeys, StoreKeys} from '../../common/constants';
import { httpPost } from '../base/api';
import { setItemByKey, getItemByKey } from '../../common/utils';

// === Reset Password ====
export const resetPassword = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.RESET_PASSWORD;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('imp succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Verify PAN ====
export const verifyPAN = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.PAN_VERIFICATION;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('imp succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Generate OTP ====
export const generateOTP = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GENERATE_OTP;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('imp succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Verify OTP ====
export const verifyOTP = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.VERIFY_OTP;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('imp succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Unlock Account ====
export const unlockAccount = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.UNLOCK_ACCOUNT;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('imp succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Get Client Id ====
export const getClientId = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_CLIENT_ID;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('imp succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Reset TPIN ====
export const resetTpin = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.RESET_TPIN;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('imp succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Reactivate Dormant ClientID ====
export const dormantClientID = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.DORMANT_CLIENTID;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('imp succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Reactivate Dormant regenerate OTP ====
export const dormantRegenerateOTP = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.DORMANT_REGENERATE_OTP;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('imp succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Reactivate Dormant Verify OTP ====
export const dormantVerifyOTP = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.DORMANT_VERIFY_OTP;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('imp succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}


// === SOSSErvice====
export const ssoService = function (requestData, responseCallback, errorCallback) {
    console.log("ssoService**********************");
    var target = BaseUrl + UrlExtensions.SSOService;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('imp succeeded with JSON response', response);        
        responseCallback(response);
        setItemByKey(localStorageKeys.ACCOUNT_ID, JSON.stringify({ accID: response.response.data.userId }));
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// market open

export const Marketopen = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.MARKETOPEN;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('imp succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}