import React, { Component } from 'react';
import TabBar from "../../controls/TabBar";
import { getIPORequest, getIPODataRequest, getDPDetails } from '../../actions/products/productrequest'
import { progressBar } from '../../controls/progress'
import IPOPlaceOrder from './IPOPlaceOrder';
import IPOOrders from './IPOOrders';
import IPORow from './IPORow';
import ForthcomingTabContent from './ForthcomingTabContent';
import {getItemByKey} from '../../common/utils'
import {localStorageKeys} from '../../common/constants'

class IPO extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showScreen:"tabView",
            inProgress: false,
            error: '',
            // tabs: ["Ongoing", "Upcoming",  "Order Book"],
            tabs: ["Ongoing", "Order Book"],
            selectedsegment: (this.props.selectedsegment==undefined || this.props.selectedsegment=='')?"Ongoing":this.props.selectedsegment,
            reqsegment: (this.props.reqsegment==undefined || this.props.reqsegment=='')?"ongoing":this.props.reqsegment,
            ipoData: [],
            data:[],
            placeOrderData:{},
            dematAccList:[],
            allowedUPI:[]
        }
        this.prospectus = "https://nseindia.com/products/content/equities/ipos/homepage_ipo.htm";
        this.changeScreen=this.changeScreen.bind(this);
        this.onClickBid=this.onClickBid.bind(this);
        this.showOrders=this.showOrders.bind(this);
    }

    componentDidMount() {
        if(window.location.href.indexOf("/home/ipo") != -1){
            window.sessionStorage.setItem(localStorageKeys.PIB_APP_KEY, "");
          }
        this.placeGetIPODataRequest();
    }

    componentWillMount() {
        this.getDPAccountDetails();
    }

    getDPAccountDetails() {
        this.setState({ dematAccList: [] });
        var requestData = {
            request: {
                data: {}
            }
        };
        getDPDetails(requestData, this.parseDPDetailsResponse.bind(this), this.parseDPDetailsError.bind(this));
    }

    parseDPDetailsResponse(responseData) {
        this.setState({ dematAccList: responseData.response.data.dematAccList }); 
    }

    
    parseDPDetailsError(error) {
        this.setState({ dematAccList: [], });
    }


    onTabClicked(tabName) {
        switch (tabName) {
            case "Upcoming":
                this.setState({ selectedsegment: "Upcoming", reqsegment: 'upcoming' }, () => this.placeGetIPORequest());
                break;
            case "Ongoing":
                this.setState({ selectedsegment: "Ongoing", reqsegment: 'ongoing' }, () => this.placeGetIPODataRequest());
                break;
            case "Order Book":
                this.setState({ selectedsegment: "Order Book", error: '', inProgress:false});
                break;
            default:break;
        }
    }


    placeGetIPORequest() {
        this.setState({ inProgress: true, error: '', equityResponseData: [] });
        var requestData = {
            request: {
                data: {
                    tab: this.state.reqsegment,
                }
            }
        };
        getIPORequest(requestData, this.parseIPOResponse.bind(this), this.parseIPOError.bind(this));
    }

    parseIPOResponse(responseData) {
        this.setState({ inProgress: false, data: responseData.response.data.ipoList });
    }

    parseIPOError(error) {
        this.setState({ inProgress: false, error: error.message });
    }


    placeGetIPODataRequest() {
        this.setState({ inProgress: true, error: ''});
        let appKey = getItemByKey(localStorageKeys.PIB_APP_KEY) !== "" ? JSON.parse(getItemByKey(localStorageKeys.PIB_APP_KEY)) : "";
        var requestData = {
            request: {
                data: {
                    tab: this.state.reqsegment,
                    appKey: appKey
                }
            }
        };
        getIPODataRequest(requestData, this.parseIPODataResponse.bind(this), this.parseIPODataError.bind(this));
    }

    parseIPODataResponse(responseData) {
        this.setState({ inProgress: false, 
            ipoData: responseData.response.data.ipoList.ipoMaster, 
            allowedUPI: responseData.response.data.ipoList.ipoUpiHandler});
    }

    parseIPODataError(error) {
        this.setState({ inProgress: false, error: error.message });
    }

    changeScreen(name) {
        this.setState({showScreen:name});
    }

    showOrders(){
        this.setState({showScreen:"tabView", selectedsegment: "Order Book"});
    }

    showIPOList(){
        this.setState({showScreen:"tabView" }, () => this.onTabClicked("Ongoing"));
    }

    onClickBid(name, data)
    {
        this.setState({placeOrderData:data});
        this.setState({showScreen:name});
    }

    render() {

        var errorHolder = (
            <div className="error-holder" style={{ marginTop: "20px" }}>
                {this.state.error}
            </div>
        );


        return (
            <div className="fillheight"> 
            {this.state.showScreen === "tabView" ? 
                <div className="fillheight orderbookbase-content-holder fillwidth">
                    <div className="row contentpanel widget-title">
                        <div className="col-xs-6 col-lg-6 col-md-6  watchlist-title-holder contentpanel">
                            IPO
                        </div>
                        <div className="col-xs-6 col-lg-6 col-md-6 contentpanel text-right">
                            {this.props.userId!=undefined ? <span>Client Id: {this.props.userId}</span>:null}
                        </div>
                        
                    </div>
                    <div className="row hdivider" > </div>
                    <div className="orderbookbase-content-body" >
                        <div className="fillheight fillwidth" >
                            <div className="row contentpanel">
                                <div className="col-sm-8 col-lg-8 col-md-8 ideas-tab-bar">
                                    <TabBar tabs={this.state.tabs} 
                                    activeTab={this.state.selectedsegment}
                                    highlight="above" expanded={true} nomargin={true} callbackHandler={this.onTabClicked.bind(this)} />
                                </div>
                            </div>
                            <div className="profile-tab-content">
                                {this.state.selectedsegment === "Upcoming" ? this.state.error ? errorHolder :
                                    this.state.inProgress ? progressBar() : (
                                    <ForthcomingTabContent data={this.state.data} prospectus={this.prospectus} />) : ''}                                

                                {this.state.selectedsegment === "Ongoing" ? this.state.error ? errorHolder :
                                    this.state.inProgress ? progressBar() : (
                                        <div className="row contentpanel" style={{ position: "relative" }} >
                                            <div className="fillwidth panel-group" id="accordion">
                                                {(this.state.ipoData).map(function (row, index){
                                                    return (<IPORow key={index} 
                                                            ipoData={row} bid={true} 
                                                            onClickBid={(name, data)=>{this.onClickBid(name, data)}}/> )
                                                }, this)}
                                            </div>
                                        </div> ) :''}                                
                                {this.state.selectedsegment === "Order Book" ? this.state.error ? errorHolder :
                                    this.state.inProgress ? progressBar() : (
                                        <IPOOrders dematAccList={this.state.dematAccList}
                                        onClickBid={(name, data)=>{this.onClickBid(name, data)}}></IPOOrders>) : ''
                                }
                            </div>
                        </div>
                    </div> 
                </div>
                :
                (this.state.showScreen === "placeOrder"?
                <IPOPlaceOrder dematAccList={this.state.dematAccList} 
                    placeOrderData={this.state.placeOrderData} 
                    showOrders={()=>this.showOrders()}
                    showIPOList={()=>this.showIPOList()}
                    allowedUPI={this.state.allowedUPI}
                    userId={this.props.userId}
                    >
                </IPOPlaceOrder>:'')
                
                }
            
            </div>
        )
    }

}

export default IPO;