import React from 'react';

import { ModuleKeys, WatchlistColumns, localStorageKeys} from '../../../common/constants'
import WatchlistView2 from '../../../components/watchlist/WatchlistView2'
import { connect } from 'react-redux';
import { addWatchlistGroup, deleteWatchlistGroup, renameWatchlistGroup, setFavouriteWatchlist } from '../../../actions/watchlist/watchlistrequests'
import { getWatchlists, addWatchlistSymbols } from '../../../actions/watchlist/watchlsitmodel'
import { watchListGroupAction } from '../../../actions/watchlist/watchlistaction'
import BasicDialog from '../../../controls/watchlist/BasicDialog'
import DeleteListDialog from '../../../controls/watchlist/DeleteListDialog'
import EditListDialog from '../../../controls/watchlist/EditListDialog'
import '../../../css/theme-dark/watchlist.css'
import { showWatchlistError } from "../../../actions/watchlist/watchlistaction"
import { setScripInfo } from '../../../actions/scripdetails/scripdetailsaction'
import { withRouter } from 'react-router-dom'
import { commonDialog, toggleDialog } from '../../../actions/common'
import { setWatchlist1Selected, setWatchlistExpandWidget, setWatchlistPreSelected } from '../../../actions/dashboard'
import { progressBar } from '../../../controls/progress'
import {getTrStreamFlag} from '../../../actions/common'
import { getItemByKey, setItemByKey } from '../../../common/utils';

class Watchlist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showaddwatchlistdialog: false,
            showdeletewatchlistdialog: false,
            showeditwatchlistdialog: false,
            editingwatchlist: '',
            afteraddwatchlistcallback: undefined,
            aftereditwatchlistcallback: undefined,
            afterdeletewatchlistcallback: undefined,
            afterupdatefavouritecallback: undefined,
            customwatchlistgroups: [],
            groupsInProgress: false,
            inProgress: false,
            error: "",
        };
    }

    componentArray = {
        WatchlistView1: <WatchlistView2 title="Watchlist 1" componentType={ModuleKeys.WatchlistView1}
            startAddNewWatchlist={this.startAddNewWatchlist.bind(this)}
            startDeleteWatchlist={this.startDeleteWatchlist.bind(this)}
            showWatchlistEditOptions={this.showWatchlistEditOptions.bind(this)}
            confirmAddSymbol={this.confirmAddSymbol.bind(this)}
            setFavourite={this.setFavourite.bind(this)}
            onSymbolClick={this.onSymbolClick.bind(this)}
        />,
        WatchlistView2: <WatchlistView2 title="Watchlist 2" componentType={ModuleKeys.WatchlistView2}
            startAddNewWatchlist={this.startAddNewWatchlist.bind(this)}
            startDeleteWatchlist={this.startDeleteWatchlist.bind(this)}
            showWatchlistEditOptions={this.showWatchlistEditOptions.bind(this)}
            confirmAddSymbol={this.confirmAddSymbol.bind(this)}
            setFavourite={this.setFavourite.bind(this)}
            onSymbolClick={this.onSymbolClick.bind(this)}
        />
    };
    //---------------------

    componentWillMount() {
        var requestData = {
            request: {
                data: {}
            }
        }
        getTrStreamFlag(requestData, this.parseTrStreamFlagResponse.bind(this), this.parseTrStreamFlagError.bind(this));
    }
    parseTrStreamFlagResponse(responseData){
        console.log("StreamerFlag-->"+responseData.response.data.trStreamStatus)     
        setItemByKey(localStorageKeys.TR_STREAM_FLAG, responseData.response.data.trStreamStatus);
        
    }
    parseTrStreamFlagError(){
        console.log("StreamerFlag error data ===")
    }
    //----------------

    componentDidMount() {

        this.setState({ inProgress: true, groupsInProgress: true });
        getWatchlists(() => {
            this.props.watchListGroupAction();
            this.setState({ inProgress: false, groupsInProgress: false });
            if (this.refs.addwatchlistdialog)
                this.refs.addwatchlistdialog.handleError(null);

            if (this.props.watchlistpreselected) {
                var item = this.props.watchListGroups.filter(i => i.id === this.props.watchlistpreselected);
                if (item.length > 0) {
                    var indx = this.props.watchListGroups.indexOf(item[0]);

                    var statedata = {
                        selectedwatchlistindex: indx,
                        selecttodelete: false,
                        showfilter: false,

                    };

                    this.props.setWatchlistExpandWidget(ModuleKeys.WatchlistView1, statedata);
                    this.props.setWatchlistPreSelected(undefined);
                }
            }
        });
    }


    onSymbolClick(row) {
        this.props.setScripInfo(row.sym);
        this.props.history.push("/home/scripdetails");
    }


    // ======================
    // === add watchlist ======

    startAddNewWatchlist(callback) {

        this.setState({ afteraddwatchlistcallback: callback, showaddwatchlistdialog: true });

    }
    confirmAddWatchlist() {
        var name = this.refs.addwatchlistdialog.getInputText();
        this.addWatchlist(name);
    }
    cancelAddWatchlist() {
        this.setState({ showaddwatchlistdialog: false });
    }

    addWatchlist(watchlistname) {
        var data = {
            request: {
                data: {
                    name: watchlistname
                }
            }
        };
        let customlistname = this.props.watchListGroups.filter((list) => {
            if (list.name.toLowerCase() === watchlistname.toLowerCase()) {
                return true;
            }

        });
        if (customlistname.length !== 0) {
            this.props.showWatchlistError({ header: 'Add Custom Watchlist', message: 'Given Name is already in list' });
        }
        else {
            this.setState({ inProgress: true, error: '' });
            addWatchlistGroup(data, this.parseAddWatchlistResponse.bind(this), this.parseAddWatchlistError.bind(this));

        }

    }

    parseAddWatchlistResponse(responseData) {
        console.log(responseData);
        getWatchlists(() => {
            this.props.watchListGroupAction();
            this.setState({ inProgress: false, error: "", showaddwatchlistdialog: false });
            this.refs.addwatchlistdialog.handleError(null);
            if (this.state.afteraddwatchlistcallback)
                this.state.afteraddwatchlistcallback(responseData.response.data.id);
        });
    }

    parseAddWatchlistError(error) {
        this.setState({ inProgress: false, error: error.message });
        if (this.refs.addwatchlistdialog)
            this.refs.addwatchlistdialog.setError(error.message);
        if (this.state.afteraddwatchlistcallback)
            this.state.afteraddwatchlistcallback();
    }

    // ======================
    // === delete watchlist ======
    startDeleteWatchlist(callback) {
        if (this.props.watchListGroups) {
            var items = this.props.watchListGroups.filter(function (i) {
                return i.isEditable === true;
            });

            this.refs.deletewatchlistdialog.setItems(items);
            this.refs.deletewatchlistdialog.unselectItems();
            this.setState({ customwatchlistgroups: items, showdeletewatchlistdialog: true, afterdeletewatchlistcallback: callback });
        }
    }

    cancelDeleteWatchlist() {
        this.setState({ showdeletewatchlistdialog: false });

    }

    confirmDeleteWatchlist() {
        var checked_items = this.refs.deletewatchlistdialog.getSelectedItems();
        if (checked_items.length > 0) {
            var payload = {
                showDialog: true, closeBtn: false, message: "Are you sure you want to delete ?", header: "Delete Watchlist",
                buttons: [
                    {
                        name: "Cancel", action: function () {
                            this.props.toggleDialog(false);
                        }.bind(this)
                    },
                    {
                        name: "Delete", action: function () {
                            this.deleteWatchlist(checked_items);
                            this.props.toggleDialog(false);
                        }.bind(this)
                    }
                ]
            }

            this.props.commonDialog(payload);
        } else {
            this.props.showWatchlistError({ header: "Delete Watchlist", message: "Please select atleast one watchlist" });
        }

    }

    deleteWatchlist(checked_items) {
        var dataArray = [];
        for (var i = 0; i < checked_items.length; i++) {
            dataArray.push({
                name: checked_items[i].name,
                watchId: checked_items[i].id
            });
        }
        var data = {
            request: {
                data: {
                    watchGroup: dataArray
                }
            }
        };
        this.setState({ inProgress: true, error: "" });
        deleteWatchlistGroup(data, this.parseDeleteWatchlistResponse.bind(this), this.parseDeleteWatchlistError.bind(this));
    }

    parseDeleteWatchlistResponse(responseData) {
        this.props.showWatchlistError({ header: "Delete Watchlist", message: "Watchlist deleted successfully" });
        getWatchlists(() => {
            this.props.watchListGroupAction();
            this.setState({ inProgress: false, error: "", showdeletewatchlistdialog: false });
            if (this.state.afterdeletewatchlistcallback)
                this.state.afterdeletewatchlistcallback();
        });
    }

    parseDeleteWatchlistError(error) {
        this.setState({ inProgress: false, error: error.message });
        this.props.showWatchlistError({ header: "Delete Watchlist", message: error.message });
        this.props.history.push("/home/watchlist");
    }

    // ======================
    // === rename watchlist ======


    showWatchlistEditOptions(selectedwatchlist, symbolscount, callback) {
        this.setState({ aftereditwatchlistcallback: callback });
        this.refs.editwatchlistdialog.setItems(WatchlistColumns, () => {
            this.refs.editwatchlistdialog.setEditingWatchlist(selectedwatchlist, symbolscount);
        });
        this.refs.editwatchlistdialog.resetEdit();
        this.setState({ showeditwatchlistdialog: true, editingwatchlist: selectedwatchlist });
    }
    cancelEditWatchlist() {
        this.setState({ showeditwatchlistdialog: false });
    }

    confirmRenameWatchlist(watchlist, newname) {
        watchlist.name = newname;
        this.setState({ inProgress: false, error: "" });
        this.renameWatchlist(watchlist);
    }

    renameWatchlist(editedwatchlist) {
        var dt = {
            request: {
                data: {
                    name: editedwatchlist.name,
                    channel: editedwatchlist.channel,
                    watchId: editedwatchlist.id,
                }
            }
        };
        this.setState({ inProgress: true, error: "" });
        renameWatchlistGroup(dt, this.parseRenameWatchlistResponse.bind(this), this.parseRenameWatchlistError.bind(this));
    }

    parseRenameWatchlistResponse(responseData) {
        getWatchlists(() => {
            this.props.watchListGroupAction(() => {
                this.setState({ inProgress: false, error: "", showeditwatchlistdialog: false });
                if (this.state.aftereditwatchlistcallback)
                    this.state.aftereditwatchlistcallback();
            });
        });
    }

    parseRenameWatchlistError(error) {
        this.setState({ inProgress: false, error: error.message });
        //this.refs.editwatchlistdialog.setError("failed");
        this.props.showWatchlistError({ header: "Rename Watchlist", message: error.message });
    }

    // ======================
    // === edit watchlist columns ======

    saveWatchlistColumns() {
        var checked_items = this.refs.editwatchlistdialog.getSelectedItems();
        var watchlist = this.refs.editwatchlistdialog.getEditingWatchlist();
        var assetclass = this.refs.editwatchlistdialog.getSelectedAssetClass();

        var columnslist = checked_items.map(i => i.id);

        var eqlist = [], futlist = [], optlist = [];

        var temp = checked_items.filter(i => (i.activefor.indexOf('eq') !== -1));

        if (temp.length > 0)
            eqlist = temp.map(i => i.id);

        temp = checked_items.filter(i => (i.activefor.indexOf('fut') !== -1));
        if (temp.length > 0)
            futlist = temp.map(i => i.id);

        temp = checked_items.filter(i => (i.activefor.indexOf('opt') !== -1));
        if (temp.length > 0)
            optlist = temp.map(i => i.id);

        futlist = futlist.filter(i => eqlist.indexOf(i) === -1);
        optlist = optlist.filter(i => eqlist.indexOf(i) === -1 && futlist.indexOf(i) === -1);

        this.editWatchlist(watchlist, assetclass, eqlist, futlist, optlist);

        // if (assetclass === "EQUITY")
        //     this.editWatchlist(watchlist, assetclass, columnslist, [], []);
        // else if (assetclass === "FUTURE")
        //     this.editWatchlist(watchlist, assetclass, [], columnslist, []);
        // else if (assetclass === "OPTIONS")
        //     this.editWatchlist(watchlist, assetclass, [], [], columnslist);
    }

    editWatchlist(editedwatchlist, assetclass, equitycolumns, futurecolumns, optionscolumns) {
        var dt = {
            request: {
                data: {
                    name: editedwatchlist.name,
                    channel: editedwatchlist.channel,
                    watchId: editedwatchlist.id,
                    settings: {
                        assetClass: assetclass,
                        equityColumns: equitycolumns,
                        futureColumns: futurecolumns,
                        optionsColumns: optionscolumns
                    }

                }
            }
        };
        this.setState({ inProgress: true, error: "" });
        renameWatchlistGroup(dt, this.parseEditWatchlistResponse.bind(this), this.parseEditWatchlistError.bind(this));
    }

    parseEditWatchlistResponse(responseData) {
        getWatchlists(() => {
            this.props.watchListGroupAction(() => {
                this.setState({ inProgress: false, error: "", showeditwatchlistdialog: false });
                if (this.state.aftereditwatchlistcallback)
                    this.state.aftereditwatchlistcallback();
            });
        });
    }

    parseEditWatchlistError(error) {
        this.setState({ inProgress: false, error: error.message });
        this.props.showWatchlistError({ header: "Edit Watchlist", message: error.message });
    }

    // ======================
    // add symbols ==========


    confirmAddSymbol(scripdata, watchlistid, callback) {
        this.setState({ inProgress: true });
        addWatchlistSymbols(scripdata, watchlistid, (error) => {
            this.setState({ inProgress: false });

            if (!error) {
                //success
            }
            else {
                //failed
                this.props.showWatchlistError({ header: "Add Scrips", message: error.message });
            }

            if (callback)
                callback(error);
        });
    }

    //=====================================================
    // Set Favourite

    setFavourite(watchlistid, favid, callback) {
        var data = {
            request: {
                data: {
                    watchId: watchlistid,
                    favId: favid
                }
            }
        };
        this.setState({ inProgress: true, error: "", afterupdatefavouritecallback: callback });
        setFavouriteWatchlist(data, this.parseSetFavouriteResponse.bind(this), this.parseSetFavouriteError.bind(this));
    }

    parseSetFavouriteResponse(responseData) {
        getWatchlists(() => {
            this.props.watchListGroupAction(() => {
                this.setState({ inProgress: false, error: "" });
                if (this.state.afterupdatefavouritecallback)
                    this.state.afterupdatefavouritecallback();
            });
        });
    }

    parseSetFavouriteError(error) {
        this.setState({ inProgress: false, error: error.message });
        this.props.showWatchlistError({ header: "Set Favourite", message: error.message });
    }


    //=====================================================

    onKeyDown(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 27 || e.keyCode === 'Escape') {
            if (this.state.showeditwatchlistdialog) {
                this.setState({ showeditwatchlistdialog: false })
                e.nativeEvent.stopImmediatePropagation();
            }
        }
    }

    render() {

        // var progressindicator = (
        //     <div className="progress_block_background">

        //         <svg class="circular-progress" viewBox="25 25 50 50">
        //             <circle class="circular-progress_path" cx="50" cy="50" r="20"
        //                 fill="none" stroke-width="2" stroke-miterlimit="10" />
        //         </svg>

        //     </div>
        // );

        return (
            <div className="watchlist-body" tabIndex="1" style={{ outline: 'none' }} onKeyDown={(e) => { this.onKeyDown(e) }} >
                {this.props.activeWidget === ModuleKeys.ALL ?
                    <div className="watchlist-body row">
                        <div className="col-6 col-lg-6 col-md-6 watchlist-body" style={{ paddingRight: "10px" }}>
                            <div className="dashboard-allholders watchlist_small_widget">
                                {!this.state.groupsInProgress ? this.componentArray.WatchlistView1 : ''}
                            </div>
                        </div>
                        <div className="col-6 col-lg-6 col-md-6 watchlist-body" style={{ paddingLeft: "10px" }}>
                            <div className="dashboard-allholders watchlist_small_widget">
                                {!this.state.groupsInProgress ? this.componentArray.WatchlistView2 : ''}
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-12 col-lg-12 col-md-12 watchlist-body">
                        <div className="dashboard-allholders watchlist_small_widget2">
                            {this.componentArray[this.props.activeWidget]}
                        </div>
                    </div>
                }

                <BasicDialog id="addwatchlistdialog" ref="addwatchlistdialog" title="Add New Watchlist" message="" type="textinput"
                    showDialog={this.state.showaddwatchlistdialog} negativebuttontext="CANCEL" possitivebuttontext="ADD"
                    onNegativeButtonClick={this.cancelAddWatchlist.bind(this)} onPossitiveButtonClick={this.confirmAddWatchlist.bind(this)} />

                <DeleteListDialog id="deletewatchlistdialog" ref="deletewatchlistdialog" title="DELETE WATCHLIST(S)" type="deletewatchlist"
                    showDialog={this.state.showdeletewatchlistdialog} negativebuttontext="CANCEL" possitivebuttontext="DELETE"
                    onNegativeButtonClick={this.cancelDeleteWatchlist.bind(this)} onPossitiveButtonClick={this.confirmDeleteWatchlist.bind(this)} />
                <EditListDialog id="editwatchlistdialog" ref="editwatchlistdialog" title="EDIT WATCHLIST" type="editwatchlist"
                    showDialog={this.state.showeditwatchlistdialog} negativebuttontext="CANCEL" possitivebuttontext="APPLY"
                    onNegativeButtonClick={this.cancelEditWatchlist.bind(this)} onPossitiveButtonClick={this.saveWatchlistColumns.bind(this)}
                    confirmRenameWatchlist={this.confirmRenameWatchlist.bind(this)} />


                {this.state.inProgress ? progressBar() : ''}

            </div>


        )
    }
}

const mapStateToProps = (state) => {
    return {
        activeWidget: state.watchlist.activeWidget,
        watchListGroups: state.watchlist.watchListGroups,
        watchlistpreselected: state.watchlist.watchlistpreselected,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        watchListGroupAction: (callback) => watchListGroupAction(dispatch, callback),
        showWatchlistError: (errordata) => showWatchlistError(dispatch, errordata),
        setScripInfo: (data) => setScripInfo(dispatch, data),
        commonDialog: (payload) => commonDialog(dispatch, payload),
        toggleDialog: (payload) => toggleDialog(payload, dispatch),
        setWatchlist1Selected: (selected) => setWatchlist1Selected(selected, dispatch),
        setWatchlistExpandWidget: (activeWidget, statedata) => setWatchlistExpandWidget(activeWidget, statedata, dispatch),
        setWatchlistPreSelected: (selected) => setWatchlistPreSelected(selected, dispatch),
    };
};



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Watchlist));