import React from "react";
import { connect } from "react-redux";
import { getSymbolSearch } from "../../actions/header/headerrequests";
import {
    symbolSearchButtonTabs, BasketsymbolSearchButtonTabs,
    orderEntryTypes, TradeActions, symbolSearchType, assetClasses
} from "../../common/constants";
import TabBar from "../../controls/TabBar";
import ButtonTab from "../../controls/ButtonTab";
import SearchInput from "../../controls/SearchInput";
import { showOrderDialog } from '../../actions/orderentry/orderentryaction'
import { setScripInfo } from '../../actions/scripdetails/scripdetailsaction'
import SymbolWatchlistDialog from '../../controls/symbolsearch/SymbolWatchlistDialog'
import { dateSort, comaSeparator } from '../../common/utils'
import { getAssetTabs_W_Index, isFnOSpreadPermitted } from '../../common/permissions'
import { progressBar } from '../../controls/progress'

const SearchRow = props =>
    props.data.map(function (row, key) {
        return [
            <div key={key} className="row contentpanel search-result-row" role="button" onKeyDown={(e) => {
                if (e.keyCode === 13) {
                    if (window.$(e.target).hasClass("search-select")) {
                        props.callbackHandler(row.sym, e)
                    }
                }
            }} onClick={(e) => { props.callbackHandler(row.sym, e) }} tabIndex="-1" >
                <div className={props.basketFlag == 'mybasket' ? "col-sm-7 col-lg-7 col-md-7 contentpanel" : "col-sm-6 col-lg-6 col-md-6 contentpanel"} style={{ paddingRight: row.isResearchAvail ? "20px" : "10px" }} >
                    <span> {row.displaySym} </span>{" - "}
                    <span className="search-result-quote-span span-wrap"> {row.sym.exc} </span>
                    <br />
                    <span className="search-result-quote-span">{row.companyName}</span>
                    {/* <img className={"search-research " + (row.isResearchAvail ? "" : "hide")} src="assets/svg/research.svg" alt="research" /> */}
                </div>
                <div className={props.basketFlag == 'mybasket' ? "col-sm-5 col-lg-5 col-md-5 contentpanel search-result-btn-holder" : "col-sm-6 col-lg-6 col-md-6 contentpanel search-result-btn-holder"}>
                    <div className={"col-lg-" + (props.isForSelection ? props.basketFlag == 'mybasket' ? "6" : "12" : "6 search-chg-pad") + " contentpanel text-right"}>
                        <span className="">{comaSeparator(row.ltp)}</span> <br />
                        {
                            row.chng !== '-' ? <span
                                className={
                                    "search-result-quote-span span-wrap " +
                                    (row.chng >= 0 ? "positive-change" : "negative-change")
                                }
                            >
                                {row.chng + " (" + row.chngPer + "%)"}
                            </span> :
                                <span className="search-result-quote-span grayout">
                                    -
                                </span>
                        }

                    </div>
                    <div className="col-sm-6 col-lg-6 col-md-6 contentpanel text-right">
                        {props.basketFlag == 'mybasket' ?
                            <div className="row">
                                {!props.isForSelection || props.basketFlag == 'mybasket' &&
                                    <div style={{ padding: '3px' }} className="col-sm-6 col-lg-6 col-md-6 contentpanel" onClick={(e) => { props.gotoBuy(e, props.disabled, row) }}>
                                        <button type="button" disabled={props.disabled} className={"search-result-btn buy-btn"} data-toggle="tooltip" title="Buy">
                                            B
                                </button>
                                    </div>
                                }
                                {!props.isForSelection || props.basketFlag == 'mybasket' &&
                                    <div style={{ padding: '3px' }} className="col-sm-6 col-lg-6 col-md-6 contentpanel" onClick={(e) => { props.gotoSell(e, props.disabled, row) }}>
                                        <button type="button" disabled={props.disabled} className={"search-result-btn sell-btn"} data-toggle="tooltip" title="Sell">
                                            S
                                </button>
                                    </div>
                                }
                            </div>
                            :
                            <div className="row">
                                {!props.isForSelection &&
                                    <div style={{ padding: '3px' }} className="col-sm-4 col-lg-4 col-md-4 contentpanel text-right" onClick={(e) => { props.onAddWatchlist(e, props.watchdisabled, row.sym) }}>
                                        {/* <button type="button" disabled={props.disabled} className={"search-result-watch"} data-toggle="tooltip" title="Add to Watchlist">
                                    +W
                                </button> */}
                                        <button type="button" className="topgainerslosers-btn watch2-symbolsearch" data-toggle="tooltip" title="Add to Watchlist" >
                                            <img src='assets/svg/add_watchlist_2_new3.svg' style={{ width: '100%', height: '100%' }} alt="watchlist" />
                                        </button>
                                    </div>
                                }
                                {!props.isForSelection &&
                                    <div style={{ padding: '3px' }} className="col-sm-4 col-lg-4 col-md-4 contentpanel" onClick={(e) => { props.gotoBuy(e, props.disabled, row) }}>
                                        <button type="button" disabled={props.disabled} className={"search-result-btn buy-btn"} data-toggle="tooltip" title="Buy">
                                            B
                                </button>
                                    </div>
                                }
                                {!props.isForSelection &&
                                    <div style={{ padding: '3px' }} className="col-sm-4 col-lg-4 col-md-4 contentpanel" onClick={(e) => { props.gotoSell(e, props.disabled, row) }}>
                                        <button type="button" disabled={props.disabled} className={"search-result-btn sell-btn"} data-toggle="tooltip" title="Sell">
                                            S
                                </button>
                                    </div>
                                }
                            </div>
                        }
                    </div>

                </div>
            </div>,
            <div
                key={row.displaySym}
                role="separator"
                className="col-sm-12 col-lg-12 col-md-12 contentpanel common-dropdown-divider"
            />
        ];
    }, this);

const SearchDropDown = props => (
    <div tabIndex="-1" className={props.display} id={props.id} onKeyDown={(e) => {
        props.onkeyDownPress && props.onkeyDownPress(e);
        e.stopPropagation()
    }
    }>
        {props.customHeader ? props.customHeader : <div
            className="col-sm-12 col-lg-12 col-md-12 nopadding search-ft-dropdown-row"
            data-toggle="dropdown"
        >
            <span>{props.header}</span>
            <i className="fa fa-angle-down" />
        </div>
        }
        <ul role="menu" className={"col-sm-12 col-lg-12 col-md-12 search-ft-dropdown dropdown-menu " + (props.isDropEnabled ? "show" : "")}>
            {props.list.map((data, key) => {
                return [
                    <li key={key} tabIndex="-1" className="search-drdn-row" onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            if (window.$(e.target).hasClass("search-select")) {
                                props.handleClick(data, key, e);
                                window.$("#" + props.id) && window.$("#" + props.id)[0].classList.remove("show");
                            }
                            e.stopPropagation();
                            props.id === "search-stk-drpdwn-id" && window.$(".search-parent .search-input").focus();
                        }
                    }}>
                        <a
                            onClick={e => {
                                props.handleClick(data, key, e);
                                window.$("#" + props.id + " search-drdn-row") && window.$("#" + props.id + " .search-drdn-row").removeClass("search-select");
                                window.$(e.target.parentNode) && window.$(e.target.parentNode).addClass("search-select");
                                props.id === "search-stk-drpdwn-id" && window.$(".search-parent .search-input").focus();
                            }}
                        >
                            {data}
                        </a>
                    </li>,
                    <li
                        key={data}
                        role="separator"
                        className="search-ft-dropdown-divider divider"
                    />
                ];
            }, this)}
        </ul>
    </div>
);

class SymbolSearch extends React.Component {
    constructor(props) {
        super(props);

        var tabs = getAssetTabs_W_Index();

        this.defaultInstruments = ["FUTURE", "OPTION", "SPREAD"];
        if (tabs[0] === assetClasses.currency || assetClasses.commodity) {
            if (this.props.spotdisable)
                this.defaultInstruments = ["FUTURE", "OPTION"];
            else
                this.defaultInstruments = ["FUTURE", "OPTION", "SPOT"];
        }
        else {
            if (!isFnOSpreadPermitted()) {
                this.defaultInstruments = ["FUTURE", "OPTION"];
            }
        }


        this.searchBtnConst = { future: 'FUTURE', option: 'OPTION', spread: 'SPREAD', spot: 'SPOT' }
        this.searchTabConst = { equity: "EQUITY", fno: "FnO", index: "INDEX", currency: "CURRENCY", commodity: "COMMODITY" };

        this.state = {
            searchText: "",
            preSearch: "",
            searchList: [],
            optionList: [],
            inProgress: false,
            searchResultHtml: "",
            error: "Search by Scrip name or Company name",
            optionData: [],
            expiryData: [],
            strikeData: [],
            tabs: tabs,
            tabName: tabs[0],
            buttonTab: this.defaultInstruments,
            buttonName: this.defaultInstruments[0],
            expiryDate: "",
            strikePrice: "STRIKE PRICE",
            optionStock: "",
            optRadio: "put",
            showWatchlistDlg: false
        };
        this.onChangeText = this.onChangeText.bind(this);
        this.onHandleTab = this.onHandleTab.bind(this);
        this.onHandleButtonTab = this.onHandleButtonTab.bind(this);
        this.onExpiryClick = this.onExpiryClick.bind(this);
        this.onStrikeClick = this.onStrikeClick.bind(this);
        this.onOptionStockClick = this.onOptionStockClick.bind(this);
        this.clearSearchState = this.clearSearchState.bind(this);
        this.setPermitted = this.setPermitted.bind(this);
        this.onkeyDownPress = this.onkeyDownPress.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onOptionRadioClick = this.onOptionRadioClick.bind(this);
    }


    componentDidMount() {
        this.setPermitted();
        if (this.props.keepOpen) {
            this.dropParent.classList.add("show");
            this.dropChild.classList.add("show");
            this.searchInput.setFocus();
            window.$('.search-parent.dropdown.keep-open').on('hide.bs.dropdown', function (e) {
                if ((e.relatedTarget.className + '').includes("search-ft-dropdown-row"))
                    return true
                else
                    return false;
            });
        }
        document.addEventListener('mousedown', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }
    handleClick(event) {
        if (!this.dropParent.contains(event.target)) {
            this.dropParent.classList.remove("show");
            this.dropChild.classList.remove("show");
        }
    }
    setPermitted() {


    }


    /**
     * @param {* event trigger from search input field} e
     */
    onChangeText(searchValue) {
        this.setState({ searchText: searchValue });
        var charLength = this.state.tabName === "FnO" ? 1 : 2; 
        console.log(this.state.tabName);
        console.log("SHruti ---- " + charLength);
        if (searchValue.length > charLength) {
            if (
                searchValue.toLowerCase().indexOf(this.state.preSearch) === 0 &&
                this.state.searchList.length > 0
            ) {
                this.renderSearchData(this.state.searchList, searchValue);
            } else {
                this.setState({
                    inProgress: true,
                    error: "",
                    searchResultHtml: "",
                    preSearch:
                        searchValue.length === 3
                            ? searchValue.toLowerCase()
                            : this.state.preSearch
                });
                getSymbolSearch(
                    this.getRequestData(searchValue),
                    this.parseSearchResponse.bind(this, searchValue, this.state.tabName, this.state.buttonName),
                    this.parseError.bind(this, this.state.tabName, this.state.buttonName)
                );
            }
        } else {
            this.setState({
                inProgress: false,
                error: "Search by Scrip name or Company name",
                searchResultHtml: "",
                expiryData: [],
                strikeData: [],
                strikePrice: "STRIKE PRICE",
                expiryDate: "",
                optionData: [],
                optionStock: "",
                searchList: []
            });
        }

        if (searchValue.length > 0) {
            this.dropParent.classList.add("show");
            this.dropChild.classList.add("show");
        }
    }

    getRequestData(searchValue) {
        var requestData = {
            request: {
                data: {
                    search: searchValue,
                    tab: this.state.tabName.toLowerCase(),
                    filters: [],
                    sorting: [{ name: "sym", value: "desc" }]
                }
            }
        };
        if (this.state.tabName !== this.searchTabConst.equity) {
            if (this.state.buttonName === this.searchBtnConst.future) {
                requestData.request.data.filters = [
                    { name: "assetClass", value: "future" }
                ];
            } else if (this.state.buttonName === this.searchBtnConst.option) {
                requestData.request.data.filters = [
                    { name: "assetClass", value: "option" }
                ];
            } else {
                requestData.request.data.filters = [
                    {
                        name: "assetClass",
                        value:
                            this.state.buttonName.toLowerCase() === this.searchBtnConst.spot.toLowerCase() ? this.state.tabName.toLowerCase() : this.searchBtnConst.spread.toLowerCase()
                    }
                ];
            }
        }
        return requestData;
    }

    parseSearchResponse(searchValue, tabName, buttonName, responseData) {
        if (this.state.tabName === tabName && this.state.buttonName === buttonName) {
            if (responseData.response.data.searchResults.length > 0 &&
                searchValue === this.state.searchText) {
                this.renderSearchData(
                    responseData.response.data.searchResults,
                    searchValue
                );
            } else if (searchValue === this.state.searchText) {
                this.setState({
                    inProgress: false,
                    error: "No results found"
                });
            }
        }
    }

    parseError(tabName, buttonName, error) {
        console.log(error);
        if (this.state.tabName === tabName && this.state.buttonName === buttonName) {
            this.setState({ inProgress: false, error: error.message });
        }
    }

    onScripSelected() {

    }


    /**
     * @param {*response data or local state data based on search} searchData
     * @param {* search text enter in the text box} searchValue
     */
    renderSearchData(searchData, searchValue) {
        var filterData;
        if (this.state.buttonName === this.searchBtnConst.option) {
            var optionData = [];
            var optionList = [];
            filterData = searchData.filter(data => {
                optionList.push(data.underlier);
                if (data.underlier.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
                    (data.companyName && data.companyName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)) {
                    optionData.push({ underlier: data.underlier, companyName: data.companyName });
                    return true;
                }
                return false;
            });
            if (filterData.length > 0) {

                this.setState({
                    optionData: optionData,
                    optionStock: "",
                    optionList: optionList,
                    expiryData: Object.keys(filterData[0].expiries),
                    expiryDate: "",
                    searchList: searchData,
                    inProgress: false,
                    searchResultHtml: " ",
                    strikeData: [],
                    strikePrice: "STRIKE PRICE"
                });
                //this.expDropParent.classList.add("open");
            } else {
                //this.expDropParent.classList.remove("open");
                this.setState({
                    error: "No results found",
                    searchResultHtml: "",
                    expiryData: [],
                    expiryDate: "",
                    optionData: [],
                    optionStock: "",
                    strikeData: [],
                    strikePrice: "STRIKE PRICE",
                    inProgress: false
                });
            }
            return;
        } else {
            filterData = searchData.filter(data => {
                return (
                    //FIXME --Sometimes it is crashing due to undefined
                    data.displaySym.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
                    (data.companyName && data.companyName.toLowerCase().indexOf(searchValue.toLowerCase())) >= 0
                );
            });
        }

        if (filterData.length > 0) {
            var htmlResult = <SearchRow data={filterData}
                gotoBuy={this.gotoBuy.bind(this)}
                gotoSell={this.gotoSell.bind(this)}
                callbackHandler={!this.props.watchlistSearch && (this.state.buttonName === "SPOT") ? this.emptyClick.bind(this) : this.symbolRowClick.bind(this)}
                isForSelection={this.props.isForSelection}
                basketFlag={this.props.basketFlag}
                disabled={this.state.buttonName === "SPOT" ? true : false}
                //   watchdisabled={this.state.buttonName === "SPREAD" ? true : false}                                                                                                //To enable Addtowatchlist alone in spot button
                onAddWatchlist={this.onAddWatchlist.bind(this)} />;

            this.setState({
                inProgress: false,
                searchList: searchData,
                searchResultHtml: htmlResult,
                error: ""
            });
        } else {
            this.setState({
                inProgress: false,
                error: "No results found",
                searchResultHtml: ""
            });
        }
    }

    onOptionStockClick(optionStock, index, e) {
        var expires = Object.keys(
            this.state.searchList[index]
                .expiries
        );
        expires = dateSort(expires);
        this.setState({
            optionStock: this.state.optionData[index].underlier,
            expiryData: expires,
            searchText: this.state.optionData[index].companyName,
            expiryDate: expires && expires.length > 0 ? expires[0] : "",
            searchResultHtml: " ",
            strikeData: [],
            strikePrice: "STRIKE PRICE"
        }, () => {
            this.onExpiryClick(this.state.expiryDate, 0, null);
        });
    }

    onExpiryClick(expiryDate, index, e) {
        var filterData = this.state.searchList[
            this.state.optionList.indexOf(this.state.optionStock)
        ].expiries[expiryDate][this.state.optRadio === "put" ? "puts" : "calls"];
        filterData = filterData.map(data => {
            return data.sym.strike;
        });
        this.setState({ expiryDate: expiryDate, strikeData: filterData, strikePrice: "STRIKE PRICE" });
    }

    onStrikeClick(strikePrice, index, e) {
        var filterData = this.state.searchList[
            this.state.optionList.indexOf(this.state.optionStock)
        ].expiries[this.state.expiryDate][
            this.state.optRadio === "put" ? "puts" : "calls"
        ];
        filterData = filterData.filter(data => {
            return data.sym.strike === strikePrice;
        });
        var htmlResult = <SearchRow data={filterData}
            callbackHandler={!this.props.watchlistSearch && (this.state.buttonName === "SPOT" || this.state.buttonName === "SPREAD") ? this.emptyClick.bind(this) : this.symbolRowClick.bind(this)}
            gotoBuy={this.gotoBuy.bind(this)}
            gotoSell={this.gotoSell.bind(this)}
            isForSelection={this.props.isForSelection}
            basketFlag={this.props.basketFlag}
            disabled={this.state.buttonName === "SPOT" || this.state.buttonName === "SPREAD" ? true : false}
            watchdisabled={this.state.buttonName === "SPREAD" ? true : false}
            onAddWatchlist={this.onAddWatchlist.bind(this)} />;

        this.setState({
            strikePrice: strikePrice,
            searchResultHtml: htmlResult,
            error: ""
        });
    }

    /**
     * on radio click option
     * @param {*valu will be put or call} value
     */
    onOptionRadioClick(value) {
        this.setState({
            optRadio: value,
            searchResultHtml: this.state.searchList.length > 0 ? " " : "",
            error:
                this.state.searchList.length > 0
                    ? ""
                    : "Search by Scrip name or Company name",
            strikeData: [],
            strikePrice: "STRIKE PRICE",
            expiryDate: ""
        }, () => {
            if (this.state.expiryData && this.state.expiryData.length > 0)
                this.onExpiryClick(this.state.expiryData[0], 0)
        });
    }

    /**
     * on tab bar click
     * @param {*value will be tab name} tabName
     */
    onHandleTab(tabName) {
        if (this.state.tabName !== tabName) {
            if (this.props.basketFlag == "mybasket") {
                var updatedButtonList = [...BasketsymbolSearchButtonTabs];
            }
            else {
                var updatedButtonList = [...symbolSearchButtonTabs];
            }

            if (tabName === this.searchTabConst.currency || tabName === this.searchTabConst.commodity) {
                //equals of currency or commodity change spread to spot
                if (!this.props.spotdisable)
                    updatedButtonList[2] = "SPOT";
                else
                    updatedButtonList.splice(2, 1);
            }
            else {
                if (!isFnOSpreadPermitted()) {
                    updatedButtonList = ["FUTURE", "OPTION"];
                }
            }

            this.setState({
                tabName: this.searchTabConst[tabName.toLowerCase()],
                buttonTab: updatedButtonList,
                buttonName: this.searchBtnConst.future,
                searchResultHtml: "",
                error: "Search by Scrip name or Company name",
                expiryDate: "",
                strikePrice: "STRIKE PRICE",
                searchList: [],
                expiryData: [],
                strikeData: [],
                optionData: [],
                optionStock: ""
            }, () => this.onChangeText(this.state.searchText));
            this.searchInput.setFocus();
        }
    }

    /**
     * on tab bar click
     * @param {*value will be tab name} buttonName
     */
    onHandleButtonTab(buttonName) {
        if (this.state.buttonName !== buttonName) {
            this.setState({
                buttonName: buttonName,
                searchResultHtml: "",
                error: "Search by Scrip name or Company name",
                // searchText: "",
                expiryDate: "",
                strikePrice: "STRIKE PRICE",
                optRadio: "call",
                searchList: [],
                expiryData: [],
                strikeData: [],
                optionData: [],
                optionStock: ""
            }, () => this.onChangeText(this.state.searchText));
            this.searchInput.setFocus();
        }
    }

    /**
     * to show the message of search result is empty or contains error
     * @param {*message to show} msg
     * @param {*image for particular message } imgSrc
     */
    getSearchMessage(msg, imgSrc) {
        return [
            <span key={msg}>{msg}</span>,
            <img
                key={msg + 1}
                src={imgSrc}
                className="img-responsive search-empty-img"
                alt="search"
            />
        ];
    }

    clearSearchState() {
        this.setState({
            tabName: this.state.tabs[0],
            buttonTab: this.defaultInstruments,
            buttonName: this.defaultInstruments[0],
            searchResultHtml: "",
            error: "Search by Scrip name or Company name",
            searchText: "",
            expiryDate: "",
            strikePrice: "STRIKE PRICE",
            searchList: [],
            expiryData: [],
            strikeData: [],
            optionData: [],
            optionStock: ""
        });
        this.refs.instrumentTab.setActiveTab(this.searchTabConst.equity);
    }

    gotoBuy(e, isDisable, data) {

        if (this.props.finaldataHide) {
            this.props.finaldataHide();
        }
        if (isDisable) {
            this.dropParent.classList.add("show");
            this.dropChild.classList.add("show");
            e.stopPropagation();
        } else {
            var orderdetails = this.getOrderDetails(data, TradeActions.BUY);
            this.clearSearchState();
            this.dropParent.classList.remove("show");
            this.dropChild.classList.remove("show");
            e.stopPropagation();
            this.props.showOrderDialog(orderdetails);

        }
    }



    gotoSell(e, isDisable, data) {
        if (this.props.finaldataHide) {
            this.props.finaldataHide();
        }
        if (isDisable) {
            this.dropParent.classList.add("show");
            this.dropChild.classList.add("show");
            e.stopPropagation();
        } else {
            var orderdetails = this.getOrderDetails(data, TradeActions.SELL);
            this.clearSearchState();
            this.dropParent.classList.remove("show");
            this.dropChild.classList.remove("show");
            e.stopPropagation();
            this.props.showOrderDialog(orderdetails);
            console.log("Shruti symbolsearch");
        }

    }

    getOrderDetails(data, taction) {
        var orderdetails = {
            orderentrytype: orderEntryTypes.NEW,
            action: taction,
            symobj: data.sym,
            company: data.companyName,
            last: data.ltp,
            change: data.chng,
            changeper: data.chngPer,
            basketFlagNew: this.props.basketFlag,
            basketListDataNew: this.props.basketListData
        };

        return orderdetails;
    }

    symbolRowClick(data, e) {

        if (this.props.searchType === symbolSearchType.header) {
            this.props.setScripInfo(data);
            this.props.history.push("/home/scripdetails");
            this.clearSearchState();
            e.stopPropagation();
            this.dropParent.classList.remove("show");
            this.dropChild.classList.remove("show");
        } else if (this.props.searchType === symbolSearchType.watchlist) {
            this.props.callbackHandler && this.props.callbackHandler(data);
        } else if (this.props.searchType === symbolSearchType.orderentry || this.props.searchType === symbolSearchType.chart) {
            this.props.callbackHandler && this.props.callbackHandler(data);
            this.clearSearchState();
            e.stopPropagation();
            this.dropParent.classList.remove("show");
            this.dropChild.classList.remove("show");
        }
    }

    emptyClick(data, e) {

    }

    onAddWatchlist(e, isDisable, selectedData) {
        if (isDisable) {
            this.dropParent.classList.add("show");
            this.dropChild.classList.add("show");
            e.stopPropagation();
        } else {
            e.stopPropagation();
            this.dropParent.classList.add("show");
            this.dropChild.classList.add("show");
            this.setState({ showWatchlistDlg: true, selectedSymbol: selectedData })
        }
    }

    callbackAddWacthlist(status) {
        this.setState({ showWatchlistDlg: false })
        if (status) {
            this.dropParent.classList.remove("show");
            this.dropChild.classList.remove("show");
            this.clearSearchState();
        } else {
            this.dropParent.classList.add("show");
            this.dropChild.classList.add("show");
        }

    }

    onkeyDownPress(e) {
        //up and down arrow for select the search row
        if ((e.keyCode === 40 || e.keyCode === 38)) {

            if (this.state.searchResultHtml !== " ") {
                let parent = window.$(".search-result-parent .search-result");
                let curIndex = parent && parent.children().index(window.$(".search-select"))
                parent && this.addSelectClass(e, parent, curIndex, 2);
            } else if (window.$("#search-exp-drpdwn-id").hasClass("show")) {
                let parent = window.$("#search-exp-drpdwn-id .search-ft-dropdown");
                let curIndex = parent && parent.children().index(window.$(".search-select"))
                parent && this.addSelectClass(e, parent, curIndex, 2);
            } else if (window.$("#search-stk-drpdwn-id").hasClass("show")) {
                let parent = window.$(".search-result-parent .search-ft-dropdown");
                let curIndex = parent && parent.children().index(window.$(".search-select"))
                parent && this.addSelectClass(e, parent, curIndex, 2);
            } else if (window.$("#option-drpdwn-id").hasClass("show")) {
                let parent = window.$(".search-result-parent .search-ft-dropdown");
                let curIndex = parent && parent.children().index(window.$(".search-select"))
                parent && this.addSelectClass(e, parent, curIndex, 2);
            }
        }
    }

    addSelectClass(e, parent, curIndex, add) {
        if (curIndex === -1) {
            parent.children().first().addClass("search-select");
            parent.children().first().focus();
        } else {
            parent.children()[curIndex].classList.remove("search-select")
            if (e.keyCode === 40 && curIndex + add < parent.children().length) {
                parent.children()[curIndex + add].classList.add("search-select")
                parent.children()[curIndex + add].focus();
            } else if (e.keyCode === 38 && curIndex - add >= 0) {
                parent.children()[curIndex - add].classList.add("search-select")
                parent.children()[curIndex - add].focus();
            } else {
                parent.children()[curIndex].classList.add("search-select")
                parent.children()[curIndex].focus();
            }
        }
    }

    render() {
        return (
            <div
                className={"pageheader-component row search-parent dropdown " + (this.props.keepOpen ? "keep-open" : "")}
                ref={dropParent => {
                    this.dropParent = dropParent;
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    if (this.searchInput && !window.$(this.searchInput.input).is(":focus")) {
                        this.searchInput.setFocus();
                    }
                    this.dropParent.classList.add("show");
                    this.dropChild.classList.add("show");
                    if (this.state.optionData.length > 0 && this.optionDropRef) {
                        this.optionDropRef.getElementsByClassName("dropdown")[0].classList.add("show")
                    }
                }}

                onKeyDown={(e) => { this.onkeyDownPress(e) }}
            >
                <SearchInput ref={(searchInput) => { this.searchInput = searchInput }}
                    placeholder={"Search INFY, TATA MOTORS, NIFTY..."}
                    callbackHandler={this.onChangeText.bind(this)}
                    searchText={this.state.searchText}
                />

                {/* search drop down */}
                <div className="search-result-box">
                    <div ref={dropChild => {
                        this.dropChild = dropChild;
                    }} className="search-result-parent col-lg-12 col-md-12 col-sm-12 nopadding dropdown-menu header-popper">
                        {/* search button bar filter */}
                        <div className="search-tab-parent col-lg-12 col-md-12 col-sm-12 nopadding">
                            <TabBar ref="instrumentTab"
                                tabs={this.state.tabs}
                                callbackHandler={this.onHandleTab}
                                tabalign={"equal"}
                                highlight="below"
                            />
                        </div>
                        {this.state.tabName !== this.searchTabConst.equity && (
                            <div className="search-btn-bar-parent col-lg-12 col-md-12 col-sm-12 nopadding">
                                <div className="search-btn-bar col-lg-12 col-md-12 col-sm-12 nopadding">
                                    <ButtonTab
                                        tabs={this.state.buttonTab}
                                        callbackHandler={this.onHandleButtonTab}
                                        activeTab={this.state.buttonName}
                                    />
                                </div>

                                {this.state.buttonName === this.searchBtnConst.option &&
                                    this.state.optionStock !== "" && [
                                        <span className="col-lg-7 col-md-7 col-sm-7 contentpanel search-exp-span">
                                            Expiry date
                                    </span>,
                                        <div className="col-sm-12 col-md-12 col-lg-12 contentpanel search-ft-opt">
                                            <div className="col-sm-5 col-md-5 col-lg-5 contentpanel">
                                                <label
                                                    id="search-result-radio"
                                                    className="radio-inline ideas"
                                                >
                                                    <input
                                                        type="radio"
                                                        name="optradio"
                                                        value="PUT"
                                                        checked={this.state.optRadio === "call"}
                                                        onChange={e => this.onOptionRadioClick("call")}
                                                    />
                                                    <span className="ideas-radio" />
                                                    <span className="ideas-radio-label">CALL</span>
                                                </label>

                                                <label
                                                    id="search-result-radio"
                                                    className="radio-inline ideas nopadding"
                                                >
                                                    <input
                                                        type="radio"
                                                        name="optradio"
                                                        value="PUT"
                                                        checked={this.state.optRadio === "put"}
                                                        onChange={e => this.onOptionRadioClick("put")}
                                                    />
                                                    <span className="ideas-radio" />
                                                    <span className="ideas-radio-label">PUT</span>
                                                </label>
                                            </div>
                                            <div className="col-sm-7 col-lg-7 col-md-7 search-exp-stk-parent contentpanel text-right">
                                                <SearchDropDown
                                                    id={"search-exp-drpdwn-id"}
                                                    display={"col-sm-6 col-lg-6 col-md-6 contentpanel dropdown"}
                                                    list={this.state.expiryData}
                                                    header={this.state.expiryDate}
                                                    handleClick={this.onExpiryClick}
                                                    onkeyDownPress={this.onkeyDownPress}
                                                />
                                                <SearchDropDown
                                                    id={"search-stk-drpdwn-id"}
                                                    display={"col-sm-6 col-lg-6 col-md-6 contentpanel dropdown"}
                                                    list={this.state.strikeData}
                                                    header={this.state.strikePrice}
                                                    handleClick={this.onStrikeClick}
                                                    onkeyDownPress={this.onkeyDownPress}
                                                />
                                            </div>
                                        </div>,
                                        <div
                                            role="separator"
                                            className="col-sm-12 col-lg-12 col-md-12 contentpanel common-dropdown-divider"
                                        />
                                    ]}

                                {this.state.optionData.length > 0 &&
                                    this.state.optionStock === "" && (
                                        <div className="col-sm-12 col-lg-12 col-md-12 nopadding" ref={(optionDropRef => { this.optionDropRef = optionDropRef })}>
                                            <SearchDropDown
                                                id={"option-drpdwn-id"}
                                                display={"col-sm-12 col-lg-12 col-md-12 contentpanel dropdown show"}
                                                customHeader={<span className='col-sm-12 col-lg-12 col-md-12 nopadding' data-toggle="dropdown">Select company</span>}
                                                list={this.state.optionData.map(data => {
                                                    return data.companyName;
                                                })}
                                                header={this.state.optionStock}
                                                handleClick={this.onOptionStockClick}
                                                isDropEnabled={true}
                                            />
                                        </div>
                                    )}
                            </div>
                        )}

                        {/* search Result */}
                        <div>
                            {this.state.searchResultHtml === "" || this.state.inProgress ? (
                                <div className="col-sm-12 col-lg-12 col-md-12 search-empty-screen">
                                    {this.state.inProgress ? progressBar() : (
                                        this.getSearchMessage(
                                            this.state.error,
                                            this.state.error === "Search by Scrip name or Company name" ? "assets/svg/symbol_search_logo.svg" : "assets/svg/no_results_found.svg"
                                        )
                                    )}
                                </div>
                            ) : (
                                    <div className="col-sm-12 col-lg-12 col-md-12 nopadding search-result" >
                                        {this.state.searchResultHtml}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                {
                    this.state.showWatchlistDlg && <SymbolWatchlistDialog
                        showWatchlistDlg={this.state.showWatchlistDlg}
                        symAddWatchlist={this.state.selectedSymbol}
                        callbackHandler={this.callbackAddWacthlist.bind(this)}
                        title="Add Scrips" />
                }

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        watchListGroups: state.watchlist.watchListGroups,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showOrderDialog: (data) => showOrderDialog(dispatch, data),
        setScripInfo: (data) => setScripInfo(dispatch, data),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(SymbolSearch);
