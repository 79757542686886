import React, { Component } from 'react';
import { TopGainersLoosersSmall, PendingOrdersSmall, ResearchSmall, HotNewsSmall, WorldMarketSummarySmall, InvestmentOverview }
    from '../../../components/dashboard/exporter'
import { ModuleKeys } from '../../../common/constants'
import { connect } from 'react-redux';
import { setExpandWidget } from '../../../actions/dashboard';
import { getCookie } from '../../../common/utils';
import {getTrStreamFlag} from '../../../actions/common'

class Dashbaord extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDialog:false
          }

    }

    componentWillMount() {
        var requestData = {
            request: {
                data: {}
            }
        }
        getTrStreamFlag(requestData, this.parseTrStreamFlagResponse.bind(this), this.parseTrStreamFlagError.bind(this));
    }
    parseTrStreamFlagResponse(responseData){
        console.log("StreamerFlag-->"+responseData.response.data.trStreamStatus)
        localStorage.setItem("TR_STREAM_FLAG", responseData.response.data.trStreamStatus);
    }
    parseTrStreamFlagError(){
        console.log("StreamerFlag error data ===")
    }

    componentArray = {
        TOP_GAINERS: <TopGainersLoosersSmall title="TOP GAINERS" componentType={ModuleKeys.TOP_GAINERS} isSmall={true} streamerflag={localStorage.getItem("TR_STREAM_FLAG")} />,
        TOP_LOOSERS: <TopGainersLoosersSmall title="TOP LOSERS" componentType={ModuleKeys.TOP_LOOSERS} isSmall={true} />,
        RESEARCH: <ResearchSmall title="IDEAS" componentType={ModuleKeys.RESEARCH} isSmall={true} />,
        PENDING_ORDER: <PendingOrdersSmall title="OUTSTANDING ORDERS" componentType={ModuleKeys.PENDING_ORDER} isSmall={true} />,
        WORLD_MARKET: <WorldMarketSummarySmall title="WORLD MARKET SUMMARY" componentType={ModuleKeys.WORLD_MARKET} isSmall={true} />,
        HOT_NEWS: <HotNewsSmall title="TOP NEWS" componentType={ModuleKeys.HOT_NEWS} isSmall={true} section="hot-pursuit" />
    };

    _onFocus()
    {
        var userId=window.sessionStorage.getItem('userId');
        if(userId != getCookie("userId"))
        {
            this.setState({
            showDialog:true
            });
        }
    }


    render() {
        return (
            <div className="fillheight" onFocus={this._onFocus.bind(this)} >
                {this.props.activeWidget === ModuleKeys.ALL ?
                    <div className="fillheight">
                        <div className="row dashboard-row-holder">
                            <div className="dashboard-row-content2">
                                <div className="dashboard-allholders2">
                                    <InvestmentOverview history={this.props.history}/>
                                </div>
                            </div>
                        </div>
                                           
                        <div className="row dashboard-row-holder">
                            <div className="col-6 col-sm-6 col-lg-6 col-md-6 dashboard-row-content dashboard-padding">
                                <div className="dashboard-componentholder dashboard-allholders small-widget">
                                    {this.componentArray.TOP_GAINERS}
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-lg-6 col-md-6 dashboard-row-content dashboard-padding">
                                <div className="dashboard-componentholder dashboard-allholders small-widget">
                                    {this.componentArray.TOP_LOOSERS}
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-lg-6 col-md-6 dashboard-row-content dashboard-padding">
                                <div className="dashboard-componentholder dashboard-allholders small-widget">
                                    {this.componentArray.PENDING_ORDER}
                                </div>
                            </div>
                   
                        </div>
                        <div className="row dashboard-row-holder"  >
                            {/* <div className="col-4 col-sm-4 col-lg-4 col-md-4 dashboard-row-content dashboard-padding">
                                <div className="dashboard-componentholder dashboard-allholders small-widget">
                                    {this.componentArray.RESEARCH}
                                </div>
                            </div> */}
                            {/* <div className="col-4 col-sm-4 col-lg-4 col-md-4 dashboard-row-content dashboard-padding">
                                <div className="dashboard-componentholder dashboard-allholders small-widget">
                                    {this.componentArray.HOT_NEWS}
                                </div>
                            </div>
                            <div className="col-4 col-sm-4 col-lg-4 col-md-4 dashboard-row-content dashboard-padding">
                                <div className="dashboard-componentholder dashboard-allholders small-widget">
                                    {this.componentArray.WORLD_MARKET}
                                </div>
                            </div> */}
                        </div>
                    </div>
                    :
                    <div className="col-12 fillheight contentpanel">
                        <div className="dashboard-parent-holder dashboard-content-holder fillheight">
                            {this.componentArray[this.props.activeWidget]}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    if (getCookie("userId") != null) 
    {
        window.sessionStorage.setItem('userId', getCookie("userId"));
    }

    return {
        activeWidget: state.dashboard.activeWidget,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setExpandWidget: (activeWidget) => setExpandWidget(activeWidget, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashbaord); 
