import React from 'react';
import { Popover, OverlayTrigger } from "react-bootstrap"

class ProductsPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focussed: true,
        }
    }


    render() {

        const popoverHoverFocus = (

            <Popover className="products-popover" id="marginutil-positioned-top" >
                <div style={{ fontSize: '12px' }} >
                    <div className="row contentpanel">
                        <div className="col-11">
                            Setlement number displays the list of trades done by you during the given settlement.
                        </div>
                        <div className="col-1">
                            <img src='assets/svg/close.svg' className="closebtn" data-toggle="tooltip" title="Close" onClick={() => { this.refs.productsPopover.hide() }} alt="close"/>
                        </div>
                    </div>
                    <div className="row hdivider products-popup-divider"> </div>
                    <div className="row contentpanel">
                        <div className="col-11">
                            Thank you for showing interest in applying IPO through Dhani Stocks Limited.
                            <br /> <br />
                            Please contact your nearest Dhani Stocks branch to submit the IPO application form or you reach out to our customer care at 022-61446300 between 09:00AM - 06:00PM from Monday to Friday(except trading holidays) or write to helpdesk@dhani.com
                        </div>
                    </div>
                </div>
            </Popover>
        );

        return (
            <OverlayTrigger ref="productsPopover" trigger={['click']} placement="bottom" container={this.props.parentRef} containerPadding={25} overlay={popoverHoverFocus} rootClose>
                <div className="watchlistoptions text-right">
                    <img src="assets/svg/info.svg" className="info-icon"  alt="info"/>
                </div>
            </OverlayTrigger>
        )
    }
}

export default ProductsPopup;