import { UrlExtensions, StoreKeys, BaseUrl, localStorageKeys } from '../../common/constants';
import { setItemByKey, clearSessionStorage, getItemByKey } from '../../common/utils'
import { httpPost } from '../base/api'
import { getWatchlists } from '../watchlist/watchlsitmodel'

export const submitLogout = function () {
    console.log('Logout');
    var requestData = {
        request: {
            data: {}
        }
    };
    var target = BaseUrl + UrlExtensions.LOGOUT;
    httpPost(target, requestData, true)
        .then(function (response) {
        }).catch(function (error) {
        });
}

export const submitLogin = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.PAN_LOGIN;

    console.log(BaseUrl)
    httpPost(target, requestData)
        .then(function (response) {
            console.log('dddrequest succeeded with JSON response', response);
            responseCallback(response);
            setItemByKey(localStorageKeys.ACCOUNT_ID, JSON.stringify({ accID: requestData.request.data.userid }));
        }).catch(function (error) {
            console.log('dddrequest failed', error);
            errorCallback(error);
        });
}
export const sendLoginOTP = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.LOGIN_OTP;

    console.log(BaseUrl)
    httpPost(target, requestData)
        .then(function (response) {
            console.log('dddrequest succeeded with JSON response', response);
            responseCallback(response);
            setItemByKey(localStorageKeys.ACCOUNT_ID, JSON.stringify({ accID: requestData.request.data.userid }));
        }).catch(function (error) {
            console.log('dddrequest failed', error);
            errorCallback(error);
        });
}
export const validateLoginOTP = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.LOGIN_VALIDATE_OTP;

    console.log(BaseUrl)
    httpPost(target, requestData)
        .then(function (response) {
            console.log('dddrequest succeeded with JSON response', response);
            responseCallback(response);
            setItemByKey(localStorageKeys.ACCOUNT_ID, JSON.stringify({ accID: requestData.request.data.userid }));
        }).catch(function (error) {
            console.log('dddrequest failed', error);
            errorCallback(error);
        });
}

export const checkOTP = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.VERIFY_OTP;

    console.log(BaseUrl)
    httpPost(target, requestData)
        .then(function (response) {
            console.log('otprequest succeeded with JSON response', response);
            responseCallback(response);
            //setItemByKey(localStorageKeys.ACCOUNT_ID, JSON.stringify({ accID: requestData.request.data.userid }));
        }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const afterSuccessLogin = function (dispatch, responseData) {
    getWatchlists(function (watchListGroups) {
        dispatch({ type: StoreKeys.WATCHLIST_GROUPS, payload: watchListGroups });
        dispatch({ type: StoreKeys.ACCOUNT_DETAILS, payload: responseData.response.data.accountDetails });
        setItemByKey(localStorageKeys.LOGIN_STATUS, "success");
        setItemByKey(localStorageKeys.ACCOUNT_DETAILS, JSON.stringify(responseData.response.data.accountDetails));
        setItemByKey(localStorageKeys.CUG_FLAG, responseData.response.data.cugFlag);
        setItemByKey(localStorageKeys.LIMITAGAINSTHOLDINGFLAG, responseData.response.data.LIMITAGAINSTHOLDINGFLAG);        
		setItemByKey(localStorageKeys.MergeEqCom, responseData.response.data.MergeEqCom);
		setItemByKey(localStorageKeys.USERSESSION_ID, responseData.response.data.userSessionId);
        dispatch({ type: StoreKeys.LOGIN_STATUS, payload: { loginStatus: true } });
        if(responseData.response.data.accountDetails.fno_permission==='Y')
        {dispatch({ type: StoreKeys.SHOW_RISK_DISCLOSURE, payload: { showRisk: true}});}
    });
}

export const afterFailedLogin = function (dispatch, errordata) {
    dispatch({ type: StoreKeys.SHOW_DIALOG, payload: { showDialog: true, header: errordata.header, message: errordata.message } });
    setItemByKey(localStorageKeys.LOGIN_STATUS, "");
    dispatch({ type: StoreKeys.LOGIN_STATUS, payload: { loginStatus: false } });
}

export const setLoginStatus = function (dispatch, status) {
    dispatch({ type: StoreKeys.LOGIN_STATUS, payload: { loginStatus: status !== undefined ? status : true } });
    dispatch({ type: StoreKeys.ACCOUNT_DETAILS, payload: JSON.parse(getItemByKey(localStorageKeys.ACCOUNT_DETAILS)) });
}

export const setToLogout = function (dispatch, tologout) {
    dispatch({ type: StoreKeys.TO_LOGOUT, payload: tologout });
}

export const setSessionCheck = function (dispatch, data) {
    dispatch({ type: StoreKeys.CHECK_SESSION, payload: data });
}

export const logout = function (dispatch, dontshowdialog) {
    console.log(dontshowdialog);
    if (dontshowdialog) {
        Promise.all([
            // dispatch({ type: StoreKeys.TO_LOGOUT, payload: true }),         
            clearSessionStorage(),
            setItemByKey(localStorageKeys.LOGIN_STATUS, ""),
            dispatch({ type: StoreKeys.LOGIN_STATUS, payload: { loginStatus: false } })
        ])

    }
    else {
        dispatch({
            type: StoreKeys.SHOW_DIALOG, payload: {
                showDialog: true, closeBtn: false, message: "Are you sure want to logout?", header: "Logout",
                buttons: [
                    {
                        name: "cancel", action: function () {
                            dispatch({ type: StoreKeys.SHOW_DIALOG, payload: { showDialog: false } })
                        }
                    },
                    {
                        name: "ok", action: function () {
                            Promise.all([
                                dispatch({ type: StoreKeys.TO_LOGOUT, payload: true }),
                                clearSessionStorage(),
                                setItemByKey(localStorageKeys.LOGIN_STATUS, ""),
                                dispatch({ type: StoreKeys.SHOW_DIALOG, payload: { showDialog: false } }),
                                dispatch({ type: StoreKeys.LOGIN_STATUS, payload: { loginStatus: false } })
                            ])

                        }
                    }
                ]
            }
        });
    }
}
export const loginWarning = function (dispatch, warningData) {
    dispatch({ type: StoreKeys.SHOW_LOGIN_WARNING, payload: warningData });
}
export const toggleLoginWarning = function (dispatch, showLoginWarningDialog) {
    dispatch({ type: StoreKeys.SHOW_LOGIN_WARNING, payload: showLoginWarningDialog })
}


export const getConfirmLimitAgainstHolding = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.LIMIT_AGAINST_HOLDING;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('request succeeded with JSON response', response); 
        responseCallback(response);
    }).catch(function (error) {
        console.log('request failed', error);
        errorCallback(error);
    });
}


