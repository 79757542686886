import React, {Component} from "react";
import { commonDropDownType, watchlistTabs } from "../../common/constants"
import TabBar from "../TabBar";


class WatchlistGroupsDropdown extends Component {

    constructor(props) {
        super(props);
        var temp = '';
      
        
        
        if (this.props.list && this.props.list.length > 0 )
            temp = (this.props.initialSelected && this.props.initialSelected === '-') ?
                '' : this.props.list[0].name;


        this.state = {
            selected: temp,
            selectedtab: "Pre-defined",
            showingeditable: false,
            selectedindex: 0,
            disableid: undefined,
        };

        this.setSelected = this.setSelected.bind(this);

    }

    


    componentDidMount() {
        

    }

    componentWillReceiveProps(nextprops) {
        if (nextprops.disabledItem !== this.props.disabledItem) {
            this.setState({ disableid: nextprops.disabledItem.id });
        }
    }

    setSelected(index, throwcallback) {

        var val = this.props.list[index].name;
        this.setState({ selected: val, selectedindex: index }, () => {

            // if(this.props.componentType === ModuleKeys.WatchlistView1) {
            //     console.log(this.props.watchSelected2);
            // }
            // else if(this.props.componentType === ModuleKeys.WatchlistView2) {
            //     console.log(this.props.watchSelected1);
            // }

            if (throwcallback && this.props.onSelectionChanged) {
                this.props.onSelectionChanged(this.props.list[index].id);
            }
        });
    }

    onDropDownClick(e, data) {
        e.preventDefault();
       
        if (this.state.selected !== data.name) {
            var id = data.id;
            var group = this.props.list.filter(i => i.id === id)[0];
            var index = this.props.list.indexOf(group);
            if (this.props.dropdownType === commonDropDownType.DEFAULT){
                this.setState({
                    selected: data.name,
                    selectedindex: index
                });
            }
           

            if (this.props.onSelectionChanged) {
                this.props.onSelectionChanged(id);
            }
        }
    }

    onHandleTab(selectedTab) {
        this.setState({ selectedtab: selectedTab });
        switch (selectedTab) {
            case "Pre-defined":
                this.setState({ showingeditable: false,  });
                break;
            case "Custom":
                this.setState({ showingeditable: true });
                break;
            default:break;
        }
        // this.props.tabHandler(selectedTab)
        this.props.tab(selectedTab)
    }

    updateSelected() {
        try {
            this.setState({ selected: this.props.list[this.state.selectedindex].name });
        }
        catch (e) { }
    }

    filterItem(i) {
        if (this.state.showingeditable) {
            return (i.isEditable || i.isMobile);
        }
        else {
            return (!i.isEditable && !i.isMobile);
        }
    }

    dropdownHandler = () => {  
        this.dropdownParent.classList.add('show')
        this.dropParent.classList.add('show')
        
    }
   
   

    render() {
        let {list} = this.props; 

        list = list ?  list : [];
      
        return (
            <div className={"dropdown col-12 col-lg-12 col-md-12 nopadding" + this.props.style} ref={dropParent => { this.dropParent = dropParent; }} 
             onClick={  this.dropdownHandler}>
                <button className="btn dropdown-toggle drop-down" style={this.props.disabled ? { opacity: '0.3' } : {}}  type="button" id={this.props.id ? this.props.id : "drop-down"}
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    disabled={this.props.disabled} >
                    <div>
                        <i className="fa fa-angle-down arrow-icon"></i>
                        {this.state.selected}
                    </div>
                </button>
                <ul className={"dropdown-menu watchlist-dropdown-menu "  + (this.props.alignDefault ? '' : "col-12 col-lg-12 col-md-12") } 
                    aria-labelledby={this.props.id ? this.props.id : "drop-down"} id="dropdown-menu" ref={dropdownParent => { this.dropdownParent = dropdownParent; }}>
                    <TabBar tabs={watchlistTabs}
                        dropParent={this.dropParent} 
                        tabalign={"justify"}
                        highlight="below"
                        callbackHandler={this.onHandleTab.bind(this)}
                        // callbackHandler = {() => this.onHandleTab(this)}
                    />
                    {list.filter(i => this.filterItem(i)).map(function (row, key) {
                        /* sub menu drop down check */
                        return (
                            !row.isMobile ?
                                [
                                    <li key={row.name} ref={dropdownli => { this.dropdownli = dropdownli; }} 
                                    onClick={(e) => { e.stopPropagation();}}>
                                        <a data-tag={row.id} onClick={row.id === this.state.disableid ? '' : (e) => this.onDropDownClick(e, row)}>
                                            <span className={row.id === this.state.disableid ? 'watchlist-dropdown-disabled' : ''} >
                                            {row.name}</span>
                                        </a>
                                    </li>,
                                    key < this.props.list.length - 1 && <li key={row.name + 1} role="separator" className="common-dropdown-divider dropdown-divider" ></li>
                                ] : ""
                        )
                    }, this)}
                    {list.filter(i => this.filterItem(i) && i.isMobile).length > 0 ? <li className="common-dropdown-divider dropdown-divider mobile-group-separator"></li> : ""}
                    {list.filter(i => this.filterItem(i)).map(function (row, key) {
                        /* sub menu drop down check */
                        return (
                            row.isMobile ?
                                [
                                    <li key={row.name} >
                                        <a data-tag={row.id} onClick={row.id === this.state.disableid ? '' : (e) => this.onDropDownClick(e, row)} >
                                            <div className={row.id === this.state.disableid ? "row contentpanel watchlist-dropdown-disabled" : "row contentpanel"}>
                                                <div className="col-10 col-lg-10 col-md-10 contentpanel "> {row.name} </div>
                                                <div className="col-2 col-lg-2 col-md-2 contentpanel"> <img src='assets/svg/mobile.svg' alt="mobile"/> </div>
                                            </div>
                                        </a>
                                    </li>,
                                    key < this.props.list.length - 1 && <li key={row.name + 1} role="separator" className="common-dropdown-divider dropdown-divider" ></li>
                                ] : ""
                        )
                    }, this)}
                </ul>
            </div>
        );
    }
}

// const mapStateToProps = (state) => {
//     return {
//         activeWidget: state.watchlist.activeWidget,
//         watchSelected1: state.watchlist.watchSelected1,
//         watchSelected2: state.watchlist.watchSelected2
//     }
// }

// const mapDispatchToProps = (dispatch, ownProps) => {
//     return {

//     };
// };


export default (WatchlistGroupsDropdown);
