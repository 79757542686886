import React from "react"
import { connect } from 'react-redux'
import { addWatchlistSymbols } from '../../actions/watchlist/watchlsitmodel'
import { commonDialog, toggleDialog } from '../../actions/common'
//import { addSymbolWatchlist } from '../../actions/watchlist/watchlistaction'
import { setWatchlistPreSelected } from '../../actions/dashboard'
import { withRouter } from 'react-router-dom'
import { blockProgressBar } from '../../controls/progress'

class SymbolWatchlistDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allitems: [],
            selectedId: '',
            error: '',
        }
        this.handleNegativeButton = this.handleNegativeButton.bind(this);
        this.handlePossitiveButton = this.handlePossitiveButton.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
    }

    componentDidMount() {
        var data = this.props.watchListGroups.filter(function (i) {
            i.isChecked = false;
            return i.isEditable === true;
        });
        if (data && data.length > 0) {
            this.setState({ allitems: data, selectedId: '' })
        } else {
            this.props.commonDialog({
                showDialog: true, type: "small", closeBtn: false, message: "Please add watchlist and try again.", header: "Add Scrips",
                buttons: [{
                    name: "ok", action: function () {
                        this.props.callbackHandler && this.props.callbackHandler();
                        this.props.toggleDialog(false);
                    }.bind(this)
                }]
            });
        }
    }


    handleNegativeButton() {
        this.props.callbackHandler && this.props.callbackHandler();
    }

    handlePossitiveButton() {
        if (this.state.selectedId !== '') {
            this.setState({ error: '', inProgress: true })
            addWatchlistSymbols(this.props.symAddWatchlist, this.state.selectedId, (error) => {
                this.setState({ inProgress: false }, function () {
                    if (!error) {
                        this.props.commonDialog({
                            showDialog: true, type: "small", closeBtn: false, message: "Scrip added to watchlist successfully", header: "Add Scrips",
                            buttons: [{
                                name: "ok", action: function () {
                                    this.props.callbackHandler && this.props.callbackHandler(true);
                                    this.props.toggleDialog(false);
                                }.bind(this)
                            }]
                        });

                        this.props.setWatchlistPreSelected(this.state.selectedId);
                        this.props.history.push("/home/watchlist");
                    }
                    else {
                        this.props.commonDialog({
                            showDialog: true, type: "small", closeBtn: false, message: error.message, header: "Add Scrips",
                            buttons: [{
                                name: "ok", action: function () {
                                    this.props.callbackHandler && this.props.callbackHandler(false);
                                    this.props.toggleDialog(false);
                                }.bind(this)
                            }]
                        });
                    }
                }.bind(this))
                this.props.callbackHandler && this.props.callbackHandler();
            });
        } else {
            this.setState({ error: 'Please Select Watchlist' })
        }
    }

    handleCheckChange(selected) {
        var selectedId = '';
        var existing = this.state.allitems.map(row => {
            if (selected.id === row.id) {
                row.isChecked = true;
                selectedId = selected.id;
            } else {
                row.isChecked = false;
            }
            return row;
        })
        this.setState({ allitems: existing, selectedId: selectedId, error: '' })
    }

    getSelectedItems() {
        return this.state.selecteditems;
    }

    render() {

        // var progressindicator = (
        //     <div className="orderinput-progress-holder">
        //         <svg class="circular-progress" viewBox="25 25 50 50">
        //             <circle class="circular-progress_path" cx="50" cy="50" r="20"
        //                 fill="none" stroke-width="2" stroke-miterlimit="10" />
        //         </svg>
        //     </div>
        // );

        var checklist = (
            <div className="container-fluid fillwidth">
                {this.state.allitems.map(function (row, key) {
                    return (<div key={key} className="col-12 col-lg-12 col-md-12">
                        <label className="custom-check edit-dialog-check">
                            <input className="custom-checkbox" type="checkbox" onChange={() => this.handleCheckChange(row)} checked={row.isChecked}
                                data-tag={row.id} />
                            <span className="custom-check-label">{row.name}</span>
                        </label>
                    </div>
                    );
                }, this)
                }
            </div>
        );

        var mbody = (
            <div className="fillwidth">
                <ul>
                    <li>
                        <p> Custom </p>
                        {checklist}
                    </li>
                </ul>
            </div>
        );


        return (
            this.props.showWatchlistDlg ? <div className={"modal disabledbackground fade" + (this.props.showWatchlistDlg ? "in dialog-show" : " dialog-hide")} id={this.props.id} role="dialog" >
                <div className={"symbol-watchlist modal-dialog small delete"}>
                    <div className={"modal-header delete"}>
                    <div className="row">
                        <div className="col-8 contentpanel"> {this.props.title} </div>
                        <div className="col-4 contentpanel">
                            <img src='assets/svg/close.svg' className="closebtn" alt="close" data-toggle="tooltip" title="Close" onClick={() => this.handleNegativeButton()} />
                        </div>
                    </div>
                    </div>
                    <div className={"modal-body delete"}>
                        {mbody}
                    </div>
                    <div className="modal-footer">
                    <div className="row fillwidth">
                        <div className="col-5 contentpanel text-left">
                            <span style={{ color: "#E5283F", fontSize: "12px" }}>{this.state.error}</span>
                        </div>
                        <div className="col-4">
                            <button type="button" className="footerbtn" onClick={() => this.handleNegativeButton()} >CANCEL</button>
                        </div>
                        <div className="col-3">
                            <button type="button" className="footerbtn" onClick={() => this.handlePossitiveButton()} >OK</button>
                        </div>
                    </div>
                    </div>
                    {this.state.inProgress && blockProgressBar()}
                </div>
            </div> : null
        );
    }
}

const mapStateToProps = (state) => {
    return {
        watchListGroups: state.watchlist.watchListGroups,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        commonDialog: (payload) => commonDialog(dispatch, payload),
        toggleDialog: (payload) => toggleDialog(payload, dispatch),
        setWatchlistPreSelected: (selected) => setWatchlistPreSelected(selected, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SymbolWatchlistDialog));

