import { StoreKeys } from '../common/constants';

const intialState = {
    status: false,
    accountDetails: {},
    tologout: false,
    checkSession: true,
    showRisk: false
}

const loginStatus = (state = intialState, action) => {
    if (action.type === StoreKeys.LOGIN_STATUS) {
        let { loginStatus } = action.payload;
        return Object.assign({}, state, { status: loginStatus, accountDetails: {} });
    } else if (action.type === StoreKeys.ACCOUNT_DETAILS) {
        let accountDetails = { ...action.payload };
        return Object.assign({}, state, { accountDetails: accountDetails });
    } else if (action.type === StoreKeys.TO_LOGOUT) {
        return Object.assign({}, state, { tologout: action.payload });
    } else if (action.type === StoreKeys.CHECK_SESSION) {
        return Object.assign({}, state, { checkSession: action.payload });
    } else if (action.type === StoreKeys.SHOW_RISK_DISCLOSURE){
        let { showRisk } = action.payload;
        return Object.assign({}, state, { showRisk: showRisk});
    } else {
        return state;
    }
};

export default loginStatus;