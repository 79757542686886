import React from 'react'
import  '../css/summary.css'
class SummaryRow extends React.Component{
constructor(props){
    super(props)
    this.state = {
        portfolio_data: this.props.portfolio_data
     };
}
componentWillReceiveProps(nextProps) {
    this.setState({ portfolio_data: nextProps.portfolio_data }); 
}
    renderTableDataSummary() {
        if(Object.keys(this.state.portfolio_data).length!==0)
       return this.state.portfolio_data.summary.map((summ, index) => {
          const { clientId, bookedPnl, unRealPnl,mtm,anov } = summ //destructuring
          return (
             <tr key={index} >
                <td><strong>{"Total MTM"}</strong></td>
                <td>{clientId}</td>
                <td>{bookedPnl}</td>
                <td>{unRealPnl}</td>
                <td>{mtm}</td>
                <td>{anov}</td>
             </tr>
          )
       })
    }
  
    render(){

        return(
            <tbody>
                {this.renderTableDataSummary()}
            </tbody>
        )
    }
}
export default SummaryRow;