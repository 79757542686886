import React from 'react';
import Notifications from '../../../components/notifications/Notifications'
import '../../../css/theme-dark/margin.css'

class NotificationsPage extends React.Component {

    render() {
        return (
            <div className="fillheight">
                <Notifications />
            </div>
        );
    }

}

export default NotificationsPage;