import getWatchListGroups from './watchlsitmodel'
import { localStorageKeys, StoreKeys } from '../../common/constants';
import { getItemByKey } from '../../common/utils'

export const watchListGroupAction = (dispatch, callback) =>{
    dispatch({type:StoreKeys.WATCHLIST_GROUPS, payload:JSON.parse(getItemByKey(localStorageKeys.WATCHLIST_GROUPS))});
    if(callback)
        callback();
}

export const showWatchlistError = function(dispatch, errordata) {    
    dispatch({ type: StoreKeys.SHOW_DIALOG, payload: { showDialog: true, header: errordata.header, message: errordata.message } });   
}

//common add Symbol Watchlist
export const addSymbolWatchlist = function (dispatch, payload) {
    dispatch({
        type: StoreKeys.ADD_SYMBOL_WATCHLIST, payload: payload
    });
}



