import React from "react";
import { connect } from "react-redux";
import Dropdown from "../../controls/Dropdown"
import { commonDropDownType } from "../../common/constants"
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { isSpanCalculatorPermitted } from '../../common/permissions'

class CalculatorIcon extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            // calculators: ['Option Calculator', 'Cost of Carry Calculator'] //'Margin Calculator', 'Span Calculator', 
            calculators: isSpanCalculatorPermitted() ? [{ name: "Cost of Carry Calculator" }, { name: "Option Calculator" }, { name: "Span Calculator" }] : [{ name: "Cost of Carry Calculator" }, { name: "Option Calculator" }]//{ name: "Margin Calculator" }, { name: "Span Calculator" },
        };
    }

    componentDidMount() {
    }

    onDropDownItemClick(name) {
        if (name === "Margin Calculator") {
            this.props.history.push("/home/margincalculator");
        } else if (name === "Span Calculator") {
            this.props.history.push("/home/spancalculator");
        } else if (name === 'Cost of Carry Calculator') {
            this.props.history.push("/home/costofcarrycalculator");
        }
        else if (name === "Option Calculator") {
            this.props.history.push("/home/optioncalculator");
        }
        // else if (name === "Options Calculator") {
        //         window.open("http://www.indiabullsventures.com/calculator/option-calculator/", "_blank")
        //     }
    }

    render() {
        const styles={style:"calc-drdown-parent pull_right"}
        return (
            <div className="pageheader-component">
                <Dropdown dropdownType={commonDropDownType.ICON} iconName={"assets/svg/calculator.svg"}
                    list={this.state.calculators}
                    style={styles.style}
                    alignDefault={true} id={"calc-drdown"}
                    onSelectionChanged={this.onDropDownItemClick.bind(this)} 
                    position={'header-popper'}/>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {

    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalculatorIcon)); 