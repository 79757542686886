import React from "react";
import { connect } from 'react-redux'
import { SubMenuDirections, BaseUrl } from "../common/constants"
import NavbarMenuItem from './NavbarItem'
import { setExpandWidget } from '../actions/dashboard'

class NavSubMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onDropDownClick(e, data) {
        this.props.selectedTab(this.props.header);
        e.preventDefault();
        e.stopPropagation();

        if (data.disabled) return;


        if (data.module) {
            this.props.setExpandWidget(data.module);
        }
        if (data.path.indexOf("#") === -1) {
            this.props.history.push(data.path)
        }

        //for open help click in info screen
        if (data.id === "info_help") {
            
            // window.open("http://www.indiabullsventures.com/shubh/Shubh_Internet_Trading_Help.pdf", "_blank")
            window.open("https://www.dhanistocks.com/shubh/Shubh_Internet_Trading_Help.pdf", "_blank")
        }
        else if(data.id=="prdct_track"){
            window.open(BaseUrl + "/Portfolio/PortfolioTracker", "_blank");
        }
        else if (data.id === "info_dp") {
            window.open("https://www.dhanistocks.com/customer-care/important-guidelines/", "_blank");
        }
        else if (data.id === "info_cdsl") {
            window.open("https://evoting.cdslindia.com/Evoting/EvotingLogin","_blank");
        }
        else if (data.id === "insta_options") {
            window.open("https://dhanistocks.instaoptions.in/", "_blank");
        }
        // else if (data.id === "bko_pre") {
        //     //window.open(BaseUrl + "/Reports/ReportWebService", "_blank");   
        //     // window.open("backoffice.html", "_blank");         
        // }

        
    }

    getHeader() {
        if (this.props.menudirection === SubMenuDirections.DOWN) {
            return (
                <a className="nav-main-link fillheight">
                    {this.props.header}
                </a>
            )
        }
        else if (this.props.menudirection === SubMenuDirections.RIGHT) {
            return (
                <a className="nav-main-link fillheight" >
                    <i className="fa fa-angle-down arrow-icon"></i>
                    {this.props.header}
                </a>
            )
        }
    }

    componentDidMount() {
        
    }

    render() {
        var headerContent = this.getHeader();
        return (
            <div className={"dropdown nopadding col-12 col-sm-12 col-lg-12 col-md-12 " + this.props.style}>
                <button className="btn fillheight dropdown-toggle drop-down " type="button" id={this.props.id ? this.props.id : "drop-down"} data-toggle="dropdown">
                    {headerContent}
                </button>
                <ul className={"dropdown-menu " + this.props.alignment} aria-labelledby={this.props.id ? this.props.id : "drop-down"} id="nav-main-dropdown-menu" style={{ minWidth: '100px' }} >
                    {this.props.list.map(function (row, key) {
                        /* sub menu drop down check */
                        return (row.submenu ?
                            <NavbarMenuItem row={row} key={key} history={this.props.history} />
                            :
                            [
                                <li key={row.name} className="dropdown-subMenu"><a onClick={(e) => this.onDropDownClick(e, row)}>{row.name}</a></li>,
                                key < this.props.list.length - 1 && <li key={row.name + 1} role="separator" className="common-dropdown-divider divider" ></li>
                            ])

                    }, this)}
                </ul>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        activeWidget: state.dashboard.activeWidget,
        accountDetails: state.loginStatus.accountDetails
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setExpandWidget: (activeWidget) => setExpandWidget(activeWidget, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavSubMenu);


