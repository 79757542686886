import React from "react";
import { connect } from "react-redux";
import { getConfigByKey, calcChange, calcChangePercentage, comaSeparator } from "../../common/utils"
import SearchInput from "../../controls/SearchInput"
import { getIndexQuote } from '../../actions/header/headerrequests'
import { isPositive } from '../../common/utils'
import CustomDialog from '../../controls/CustomDialog'

export class IndecesDropDown extends React.Component {

    constructor(props) {
        super(props);
    }

    clearInput() {
        this.searchInput.onClearClick();
    }

    render() {
        return (
            <ul role="menu" ref={(dropChild) => { this.dropChild = dropChild }} className="indeces-dropdown dropdown-menu col-12 col-lg-12 col-md-12 header-popper"
                onClick={(e) => { e.stopPropagation(); this.props.refParent.classList.add("show"); this.dropChild.classList.add("show") }} >
                <SearchInput ref={(searchInput) => { this.searchInput = searchInput }} placeholder={"Type Sensex, Nifty 50..."} callbackHandler={this.props.onChangeText} id={"indeces-search-input"} />
                <CustomDialog showDialog={this.props.showDialog} message={this.props.message}
                    isDismiss={true} id="indices-modal-parent" customStyle="indices-modal"
                    cancelDialog={this.props.onCancelDialog}
                    defaultBtnText="OK" />
                <li role="separator" className="col-12 common-dropdown-divider dropdown-divider"></li>
                <div className="indeces-dropdown-content col-12 nopadding">
                    {this.props.quoteList.map((data, key) => {
                        return (
                            <li key={key} >
                                <a className="custom-check" onClick={(e) => { this.props.handleClick(e, data); this.dropChild.classList.add("show") }}>
                                    <input className="custom-checkbox" type="checkbox" checked={data.check} />
                                    <span className="custom-check-label" style={{ fontFamily: data.check ? 'Roboto-Medium' : 'Roboto-Light' }}>{data.index}</span>
                                </a>
                            </li>
                        );
                    })}
                </div>
            </ul>
        );
    }
}

class IndicesQuote extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            quoteList: [{
                index: '',
                ltp: '',
                chng: '',
                chngPer: '',
                displayable_change: '',
                check: false
            }],
            curQuote: [{
                index: '',
            //    indexTR: '',
                ltp: '',
                chng: '',
                chngPer: '',
                displayable_change: ''
            }],
            selectCount: 0,
            selectIndex: 0,
            indicesList: [],
            showDialog: false,
            message: '',
            searchText: '',
            trStreamFlag:'N'
        };
        this.handleClick = this.handleClick.bind(this);
        this.onDropDownClick = this.onDropDownClick.bind(this);
        this.onStreamCallback = this.onStreamCallback.bind(this);
        this.onStreamCallbackTR = this.onStreamCallbackTR.bind(this);   

    }

    componentDidMount() {
        setTimeout(()=>{
        try {
            this.state.trStreamFlag=localStorage.getItem("TR_STREAM_FLAG"); 

            var indicesList = this.props.getConfigByKey("indices");
            if (indicesList && indicesList.length > 0) {
                indicesList.sort((data1, data2) => {
                    if (data1.index.toLowerCase() === this.props.index.toLowerCase()) {
                        data1.check = true;
                    } else {
                        data1.check = false;
                    }
                    return data1.check === data2.check ? 0 : data1.check ? -1 : 1;
                })

                this.setState({
                    indicesList: indicesList,
                    quoteList: indicesList,
                    curQuote: [indicesList[0]],
                    selectCount: 1,
                    selectIndex: 0
                }, () => {
                    this.placeRequest([indicesList[0]]);
                    this.interval = setInterval(this.tick.bind(this), 5000);
                });
            }
        } catch (ex) {

        }
    },1000);

    }

    onSocketRegister(data) {
               
        if (localStorage.getItem("TR_STREAM_FLAG") === "N") {            
        data.map(value => {
            window.socketEvents.register(value.streamSym + value.exc, { screenName: this.props.tag, sym: value.streamSym, exc: value.exc }, this.onStreamCallback)
        })
        setTimeout(function () {
            window.socketEvents.subscribe();
        }, 1000)
        } else {                      
            data.map(value => {               
                window.socketEventsTR.register(value.index, { screenName: this.props.tag, sym: value.index, exc: value.exc_seg }, this.onStreamCallbackTR, "3")
            })
            setTimeout(function () {              
                window.socketEventsTR.subscribe("3");
            }, 1000)
        }

    }

    getLtpClass(prev, current, prevColor) {

        var diff = (parseFloat(current) - parseFloat(prev));
        if (diff > 0)
            return 'positive-change';
        else if (diff < 0)
            return 'negative-change';
        else
            return prevColor;
    }

    onStreamCallback(streamData) {
        var updateCurrentList = Object.assign([], this.state.curQuote);
        updateCurrentList = updateCurrentList.map(data => {
            if (data.streamSym === streamData.data.sym && data.exc === streamData.data.exc) {
                data.ltpClass = streamData.data.ltp ? this.getLtpClass(data.ltp, streamData.data.ltp, data.ltpClass) : data.ltpClass;
                data.ltp = streamData.data.ltp ? streamData.data.ltp : data.ltp;
                data.chng = streamData.data.closePrice && streamData.data.ltp ? calcChange(streamData.data) : data.chng;
                data.chngPer = streamData.data.closePrice && streamData.data.ltp ? calcChangePercentage(streamData.data) : data.chngPer;
                data.displayable_change = data.chng + " (" + data.chngPer + "%)";
            }
            return data;
        })
        this.setState({ curQuote: updateCurrentList })
    }

    onStreamCallbackTR(streamData) {        
        var updateCurrentList = Object.assign([], this.state.curQuote);
        updateCurrentList = updateCurrentList.map(data => {            
            if (data.index === streamData.data.index) {                
                data.ltp = streamData.data.indexVal ? streamData.data.indexVal : data.ltp;                
                data.ltpClass = streamData.data.indexVal ? this.getLtpClass(data.ltp, streamData.data.indexVal, data.ltpClass) : data.ltpClass;
              //  data.ltp = streamData.data.ltp ? streamData.data.ltp : data.ltp;                
                data.chng = streamData.data.change?Number(streamData.data.change).toFixed(2) : data.chng;
                data.chngPer = streamData.data.perchange ?Number(streamData.data.perchange).toFixed(2): data.chngPer;
                data.displayable_change = (data.chng&&data.chngPer)?(data.chng + " (" + data.chngPer + "%)"):data.displayable_change;
            }
            return data;
        }) 
        this.setState({ curQuote: updateCurrentList })  
    }

    tick() {
       
        if(this.state.trStreamFlag!==localStorage.getItem("TR_STREAM_FLAG")){
            console.log("TrStreamFlag changed")
            this.state.trStreamFlag=localStorage.getItem("TR_STREAM_FLAG");
            window.socketEvents.unregister(this.props.tag);
            window.socketEventsTR.unregister(this.props.tag,"3");            
            this.onSocketRegister(this.state.curQuote)
        }
        
        if (this.state.selectCount > 1) {
            if (this.state.selectIndex === this.state.selectCount - 1) {
                this.setState({ selectIndex: 0 });
            } else {
                this.setState({ selectIndex: this.state.selectIndex + 1 });
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        window.socketEvents.unregister(this.props.tag);
       // window.socketEventsTR.unregister(this.props.tag,"3");
    }

    onCancelDialog() {
        this.setState({ showDialog: false, message: '' });
    }
    handleClick(e, updateData) {
        e.stopPropagation();
        var updatedQuoteList = [...this.state.indicesList];
        var updateCurrentList = [...this.state.curQuote];
        var count = this.state.selectCount;
        if (count === 1 && updateData.check) {
            this.setState({ showDialog: true, message: "Min one indices should be selected" });
        } else if (count === this.props.maxSelect && !updateData.check) {
            this.setState({ showDialog: true, message: "Only three indices can be selected" });
        }
        else {

            updatedQuoteList = updatedQuoteList.filter((data) => {
                if (data.index.toLowerCase() === updateData.index.toLowerCase()) {
                    data.check = data.check ? false : true;
                    if (data.check) {
                        count++;
                        updateCurrentList.push(updateData);
                    } else {
                        count--;
                        var removeIndex = updateCurrentList.map(function (item) { return item.index.toLowerCase(); }).indexOf(updateData.index.toLowerCase());
                        updateCurrentList.splice(removeIndex, 1);
                    }
                }
                if (this.state.searchText.length > 0) {
                    return data.index.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0;
                } else
                    return true;
            }, this)
            this.setState({ quoteList: updatedQuoteList, selectCount: count, selectIndex: 0, curQuote: updateCurrentList }, () => {
                this.placeRequest(updateCurrentList);
            });
        }
        this.dropParent.classList.add("show");
    }

    onChangeText(searchText, e) {
        this.setState({ searchText: searchText });
        var filterData = this.state.indicesList.filter(data => {
            return data.index.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
        })
        this.setState({ quoteList: filterData });
    }

    onDropDownClick(e) {
        var updatedQuoteList = this.state.indicesList;
        updatedQuoteList.sort((data1, data2) => {
            return data1.check === data2.check ? 0 : data1.check ? -1 : 1;
        })
        this.setState({ quoteList: updatedQuoteList, searchText: '' });
        this.dropDown.clearInput();
    }

    placeRequest(data) {
        window.socketEvents.unregister(this.props.tag);
        window.socketEventsTR.unregister(this.props.tag,"3");
        var data = this.getRequestData(data);

        getIndexQuote(data, this.parseResponse.bind(this), this.parseError.bind(this));

    }

    getRequestData(data) {
        var indices = data.map((indexObj) => {
            return { 'index': indexObj.index, 'exc': indexObj.exc };
        });

        var requestdata = {
            request: {
                data: { indices: indices }
            }
        };
        return requestdata;
    }

    parseResponse(responseData) {
        var updateData = Object.assign([], this.state.curQuote);
        updateData = updateData.map(data => {
            responseData.response.data.indices.map((res) => {
                console.log(data, res);
                if (res.index.toLowerCase() === data.index.toLowerCase()) {
                    data.displayable_change = res.chng + " (" + res.chngPer + "%)";
                    data.ltp = res.ltp;
                    data.streamSym = res.streamSym;
                    data.index=res.index;
                    data.exc_seg=res.exc_seg;            
                }
            });
            return data;
        })
        this.setState({ curQuote: updateData }, () => {
            this.onSocketRegister(this.state.curQuote)
        })
    }

    parseError(err) {
        console.log(err);
    }

    render() {
        let quote = this.state.curQuote[this.state.selectIndex];
        return (
            <div ref={(dropParent) => { this.dropParent = dropParent }} className="pageheader-component indeces-parent dropdown" tabIndex={0} onClick={(e) => this.onDropDownClick(e)}
                data-toggle="tooltip" title="Select upto 3 indices for quick view"  >
                <div className="row contentpanel indeces-row" data-toggle="dropdown">
                    <div className="col-10 col-lg-10 col-md-10 nopadding">
                        {quote.index.toUpperCase()}
                    </div>
                    <div className="col-2 col-lg-2 col-md-2 text-right nopadding">
                        <i className="indeces-caret fa fa-angle-down"></i>
                    </div>
                </div>
                <div className="row contentpanel indeces-lmt" data-toggle="dropdown">
                    <div className={"col-5 col-lg-5 col-md-5 nopadding " + quote.ltpClass} >
                        {comaSeparator(quote.ltp)}
                    </div>
                    <div className={"col-7 col-lg-7 col-md-7 text-right nopadding" + (isPositive(quote.chng) ? " positive-change" : " negative-change")}>

                        {quote.displayable_change}

                    </div>
                </div>

                <IndecesDropDown quoteList={this.state.quoteList}
                    handleClick={this.handleClick} onChangeText={this.onChangeText.bind(this)}
                    refParent={this.dropParent}
                    ref={(dropDown) => { this.dropDown = dropDown }}
                    message={this.state.message}
                    showDialog={this.state.showDialog}
                    onCancelDialog={this.onCancelDialog.bind(this)} />
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {

    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getConfigByKey: (key) => getConfigByKey(key, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicesQuote); 