import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import TabBar from "../../controls/TabBar";
import { connect } from "react-redux";

class TabbarBaseWidget extends Component {

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {

        };

        this.setActiveTab = this.setActiveTab.bind(this);
    }

    onCollapseClick() {
        
    }

    setActiveTab(tab) {
        this.refs.tab.setActiveTab(tab);
    }

    render() {
        return (
            <div className="fillheight tabbar-content-holder" id={this.props.id}>
                <div className="row contentpanel widget-title">
                    <div className="col-6  watchlist-title-holder contentpanel">
                        {this.props.title}
                    </div>
                    {this.props.onCollapseClick && <div className="col-6 contentpanel">
                        <div className="tabbar-expand text-right">
                            <img src="assets/svg/collapse.svg" className="expander-icon" onerror="this.src='assets/images/logo.png'" onClick={this.props.onCollapseClick} alt="collapse"/>
                        </div>
                        {this.props.additionalWidgetHeader && this.props.additionalWidgetHeader}                       
                    </div>}
                </div>
                <div className="row hdivider" > </div>
                <div className="tabbar-content-body contentpanel" >
                    <div className="row contentpanel">
                        <div className={"col-"+(this.props.additionalTabHeader ?"7":"12")+ " ideas-tab-bar"}>
                            <TabBar activeTab={this.props.activeTab} ref="tab" tabs={this.props.tabs} highlight="above" expanded={true} callbackHandler={this.props.onTabClicked} />
                        </div>
                        <div className={"tab-content-parent expanded "+(this.props.additionalTabHeader?'col-5':'')}>
                            {this.props.additionalTabHeader && this.props.additionalTabHeader}
                        </div>
                    </div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {withRef:true})(TabbarBaseWidget));