import React, { Component } from 'react';
import TradeHistory from './TradeHistory';
import { getTradeHistoryData  } from '../../actions/portfolio/portfoliorequest'
import SymbolWatchlistDialog from '../../controls/symbolsearch/SymbolWatchlistDialog'

import {getCookie} from '../../common/utils'
export class PortfolioRowitems extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            data:[],
            totalBuyQty:'',
            totalSellQty:'',
            bookedPL:'',
            notionalPL:'',
            netBuySell:'',
            ltp:'',
            showWatchlistDlg: false,
            symbol:''
        }
        this.onAddWatchlist = this.onAddWatchlist.bind(this);
    }
    toggleModal = () => {
        this.setState({
          isOpen: !this.state.isOpen
        });
        
        this.requestTradehistory();

      }

      
      //add watchlist symbol
    onAddWatchlist(data) {
        console.log("onAddWatchlist*********************");
        console.log(data);
        var newdata={
            id:data.symbol,
            exc:data.exchange,
            instr:'',
            optType:'',
            strike:'',
            expiry:'',
            xSym:data.securityId,
            trExc:''

        }

        console.log("this.props.selectedsegmentTrade===>"+this.props.selectedsegmentTrade);

        switch(this.props.selectedsegmentTrade)
        {
            case "Script" : 
            case "Sector" :  newdata.assetClass="equity";
            newdata.instr="STK";
            newdata.trExc=data.exchange;
            break;
            case "Fno" :  (data.instrumentType).includes("FUT")?newdata.assetClass="future":newdata.assetClass="option";
            newdata.instr=data.instrumentType;
            newdata.expiry=data.expiryDateFormatted; 
            newdata.optType= data.optionType;
            newdata.strike= data.strikePrice;
            newdata.trExc=data.exchange;
            break;
            case "Currency" :   (data.instrumentType).includes("FUT")?newdata.assetClass="future":newdata.assetClass="option";
            newdata.instr=data.instrumentType;
            newdata.expiry=data.expiryDateFormatted;
            newdata.optType= data.optionType;
            newdata.strike= data.strikePrice;
            newdata.trExc="CDS";
            break;
    
        
        }      
        console.log(newdata)  ;
        this.setState({ showWatchlistDlg: true, selectedSymbol: newdata });
  

    }
    callbackAddWacthlist() {
        this.setState({ showWatchlistDlg: false });
    }

    requestTradehistory() {
        var requestData = {
            request: {
                data: {
                    segment:this.props.selectedsegmentTrade,
                    uniqueId:this.props.row.securitycode,
                    tradedPrice:this.props.row.ltp
                }
            }
        }
        getTradeHistoryData(requestData, this.parseTradehistoryResponse.bind(this), this.parseTradehistoryError.bind(this));
}  
parseTradehistoryResponse(responseData){
    this.setState({
        data:responseData.response.data.data.dataSet,
        totalBuyQty:responseData.response.data.data.totalBuyQty,
        totalSellQty:responseData.response.data.data.totalSellQty,
        bookedPL:responseData.response.data.data.bookedPL,
        notionalPL:responseData.response.data.data.notionalPL,
        netBuySell:responseData.response.data.data.netBuySell,
    })
    // console.log(this.state.data);
}

parseTradehistoryError( error) {
        this.setState({
            inProgress: false,
            error: error.message,
        })
}

    render() {
        var errorHolder = (
            <div className="error-holder" style={{ marginTop: "5px" }}>
                <span> {this.state.error} </span>
                
            </div>
        );
        return (
                <div className="row table-data portfolio-row">
                    
                    {this.props.flag=='0'? (<div className="col-2 valign symbol-link"
                         onClick={() => this.props.onSymbolClick(this.props.row)}>
                        {(this.props.row.contractDescWeb!="" && this.props.row.contractDescWeb!=undefined)?this.props.row.contractDescWeb:this.props.row.symbol}
                    </div>):
                    <div className="col-2 valign">
                        {(this.props.row.contractDescWeb!="" && this.props.row.contractDescWeb!=undefined)?this.props.row.contractDescWeb:this.props.row.symbol}
                    </div>
                    }
                    <div className="col  text-right valign">
                        {this.props.row.qty}
                    </div>
                    <div className="col text-right valign">
                        {this.props.row.avgBuyPrice}
                    </div>
                    <div className="col text-right valign">
                        {this.props.row.purchaseValue}
                    </div>
                    <div className="col  text-right valign" >
                        {this.props.row.ltp}
                    </div>
                    <div className="col text-right valign" >
                        {this.props.row.currentValue}
                    </div>
                    <div className="col text-right valign" style={{color: Math.sign(this.props.row.todayGainLoss) === -1 ? "#E5283F" : "#1EBF8A"}}>
                     {this.props.row.todayGainLoss}
                    </div>
                    <div className="col text-right valign" style={{color: Math.sign(this.props.row.todayGainLossPerc) === -1 ? "#E5283F" : "#1EBF8A"}}>
                     {this.props.row.todayGainLossPerc}
                    </div>
                    <div className="col text-right valign" style={{color: Math.sign(this.props.row.plValue) === -1 ? "#E5283F" : "#1EBF8A"}}>
                        {this.props.row.plValue}
                    </div>
                    <div className="col text-right valign" style={{color: Math.sign(this.props.row.plPerc) === -1 ? "#E5283F" : "#1EBF8A"}}>
                       {this.props.row.plPerc} 
                    </div>
                    <div className="col text-right valign"  style={{color: Math.sign(this.props.row.realised) === -1 ? "#E5283F" : "#1EBF8A"}}>
                       {this.props.row.realised} 
                    </div>
                    <div className="col text-right valign">
                    <div className=" DropdownPortfolio">
                        <div className="dropdown col-lg-12 col-md-12 nopadding gainIndicesDrop">
                            <button className="btn dropdown-toggle drop-down " type="button" id="drop-down" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="">
                                    <div>
                                        <i className="fa fa-angle-down arrow-icon"></i>
                                    </div> 
                                </span>
                            </button>
                            <ul className="dropdown-menu popper-inline" aria-labelledby="drop-down">
                            <li>
                                </li>
                                {this.props.flag=='0'?(<li onClick={() => this.props.onSymbolClick(this.props.row)}>
                                    <a>SCRIP INFORMATION</a>
                                </li>):null}
                                {this.props.flag=='0'?(<li role="separator" className="common-dropdown-divider divider"></li>):null}
                                <li onClick={this.toggleModal}>
                                    <a>TRADE HISTORY</a>
                                </li>
                                {this.props.flag=='0'?(<li role="separator" className="common-dropdown-divider divider"></li>):null}
                                {this.props.flag=='0'?(<li onClick={() => this.onAddWatchlist(this.props.row)}>
                                    <a>ADD TO WATCHLIST</a>
                                </li>):null}
                            </ul>
                        </div>
                    </div>
                    </div>
                    <TradeHistory show={this.state.isOpen}
                        onClose={this.toggleModal}
                        traderow={this.state.data}
                        totalBuyQty={this.state.totalBuyQty}
                        totalSellQty={this.state.totalSellQty}
                        bookedPL={this.props.row.realised}
                        notionalPL={this.props.row.plValue}
                        netBuySell={this.state.netBuySell}
                        ltp={this.props.row.ltp}
                        symbol={(this.props.row.contractDescWeb!="" && this.props.row.contractDescWeb!=undefined)?this.props.row.contractDescWeb:this.props.row.symbol}
                        >
                    </TradeHistory>

                    
                    {
                    this.state.showWatchlistDlg && <SymbolWatchlistDialog
                        showWatchlistDlg={this.state.showWatchlistDlg}
                        symAddWatchlist={this.state.selectedSymbol}
                        callbackHandler={this.callbackAddWacthlist.bind(this)}
                        title="Add Scrips" />
                }
                </div>
        )
    }
}

export default PortfolioRowitems;