import React, { Component } from 'react';
import { progressBar } from '../../controls/progress'
import {getNewPortfolioData} from '../../actions/portfolio/portfoliorequest'
import {PortfolioHeader} from './PortfolioHeader'
import GroupBySymbol from './GroupBySymbol';
import GroupBySector from './GroupBySector';
import GroupBySymbolNew from './GroupBySymbolNew';
import GroupBySectorNew from './GroupBySectorNew';
import ToggleSwitch from '../../controls/ToggleSwitch';
import {holdingsDropDownOptions, commonDropDownType} from "../../common/constants"
import Dropdown from '../../controls/Dropdown'
import {getConfigByKey, getCookie} from '../../common/utils'

class PortfolioTrackerDion extends Component {
    tag = "PortfolioTrackerDion";
    constructor(props) {
        super(props);
        this.tag = this.props.componentType;
        this.state = {
            inProgress: false,
            selectedsegment: "Script",
            selectedfilter: holdingsDropDownOptions[0].value,
            data: [],
            sortColumnId:'',
            sortType:'',
            sortDataType:'',
            totalValue: '0.00',
            todayPL:'0.00',
            totalInvestment: '0.00',
            totalPL:'0.00',
            overallGainLoss:'0.00',
            totalGainLoss:'0.00',
            totalRealisedPL:'0.00',
            selectToggle:"1",
            selectToggle3:"1",
            id:getCookie("REDIRECT")=="PortfolioTrackerSSO" ? '1' : '0'

        }
        this.onStreamCallback=this.onStreamCallback.bind(this);
        this.onStreamCallbackTR=this.onStreamCallbackTR.bind(this);
    }

    onFilterSelected(selected) {
        var existing = holdingsDropDownOptions.filter(i => i.name === selected);
        if (existing.length > 0)
        {
            this.setState({ selectedfilter: existing[0].value }, () => this.requestPortfolio());
            // this.state.selectedfilter=existing[0].value;
            // console.log("this.state.selectedfilter==>"+this.state.selectedfilter);
            // this.requestPortfolio();
        }
    }

    sortSymbols(eventId, sortTemp, sortDataType) {
        this.setState({
            sortColumnId : eventId,
            sortType : sortTemp,
            sortDataType : sortDataType
        });
    }

    componentDidMount() {
        this.requestPortfolio();
    }

    componentWillUnmount() {
        window.socketEvents.unregister(this.tag);
        window.socketEventsTR.unregister(this.tag);
    }

    requestPortfolio() {
        var requestData = {
            request: {
                data: {

                }
            }
        }

        this.setState({
            inProgress: true,
            error: '',
            data: [],
            totalValue: '0.00',
            todayPL:'0.00',
            totalInvestment: '0.00',
            totalPL:'0.00',
            overallGainLoss:'0.00',
            totalGainLoss:'0.00',
            totalRealisedPL:'0.00'
        });
        requestData.request.data.filters=this.state.selectedfilter;
        switch(this.state.selectedsegment)
        {

            case "Script" :  requestData.request.data.segment = "Equity"
                break;
            case "Sector" :  requestData.request.data.segment = "EquitySector"
                break;
            case "Fno" :  requestData.request.data.segment = "Derivatives"
                break;
            case "Currency" :  requestData.request.data.segment = "Currency"
                break;
        }
        getNewPortfolioData(requestData, this.parsePortfolioResponse.bind(this, this.state.selectedsegment), this.parsePortfolioError.bind(this, this.state.selectedsegment));

    }


    parsePortfolioResponse(selectedsegment, responseData) {
        this.setState({
            totalValue:responseData.response.data.data.data.totalValue,
            todayPL:responseData.response.data.data.data.todayPL,
            totalInvestment:responseData.response.data.data.data.totalInvestment,
            totalPL:responseData.response.data.data.data.totalPL,
            totalRealisedPL:responseData.response.data.data.data.totalRealisedPL,
            overallGainLoss:responseData.response.data.data.data.overallGainLoss,
            totalGainLoss:responseData.response.data.data.data.totalGainLoss
        })


        if ("Sector" === selectedsegment) {
            this.setState({
                inProgress: false,
                data: responseData.response.data.data.data.sectorData
            })
        }
        else  {
            this.setState({
                inProgress: false,
                data: responseData.response.data.data.data.plDetailsList
            })
        }
        this.onSocketRegister(responseData.response.data.data.data.symbols);
    }



    onSocketRegister(data) {
        if (localStorage.getItem("TR_STREAM_FLAG") === "N") {
            data.map(value => {
                window.socketEvents.register(value.sym + value.exc, { screenName: this.tag, sym: value.sym, exc: value.exc }, this.onStreamCallback);
                return '';
            })
            setTimeout(function () {
                window.socketEvents.subscribe();
            }, 1000)

        } else {
            data.map(value => {
                if (value.xSym && value.xSym && value.exc_seg) {
                    return window.socketEventsTR.register(value.xSym, { screenName: this.tag, sym: value.xSym, exc: value.exc_seg }, this.onStreamCallbackTR)
                }
            })
            setTimeout(function () {
                window.socketEventsTR.subscribe();
            }, 1000)
        }

    }

    onStreamCallback(streamData) {
        var currentMarketValue=0;
        var totalGainLoss=0;
        var totalPrevClose=0;
        var totalplValue=0;
        var overallGainLossPerc=0;
        var totalGainLossPerc=0;
        var prvsclseprc = 0;
        var currentMarketValuesectorMap = {};
        var totalPLsectorMap={};
        (this.state.data).map(val=>{
            if(this.state.selectedsegment=="Sector")
            {
                if(currentMarketValuesectorMap[val.sectorName]==null || currentMarketValuesectorMap[val.sectorName]==undefined)
                {
                    currentMarketValuesectorMap[val.sectorName] = 0;
                }

                if(totalPLsectorMap[val.sectorName]==null || totalPLsectorMap[val.sectorName]==undefined)
                {
                    totalPLsectorMap[val.sectorName] = 0;
                }
                val.sectorList.map(val1=>{

                    if(val1.symbol==streamData.data.sym && streamData.data.ltp!=val1.ltp)
                    {
                        val1.ltp=Number(streamData.data.ltp).toFixed(2);
                        val1.currentValue=Number(val1.ltp*val1.qty).toFixed(2);
                        val1.todayGainLoss= Number(val1.qty*(val1.ltp-val1.prevClose)).toFixed(2);

                        prvsclseprc=val1.prevClose*val1.qty;
                        if(prvsclseprc==0){
                            val1.todayGainLossPerc= Number(0).toFixed(2);
                        }
                        else{
                            val1.todayGainLossPerc= Number(((val1.ltp-val1.prevClose)*100)/Math.abs(prvsclseprc)).toFixed(2);
                        }

                        if(val1.avgBuyPrice==0){
                            val1.plValue=Number(0).toFixed(2);
                            val1.plPerc=Number(0).toFixed(2);
                        }
                        else{
                            val1.plValue=Number((val1.ltp-val1.avgBuyPrice)*val1.qty).toFixed(2);
                            val1.plPerc=Number(((val1.ltp-val1.avgBuyPrice)*100)/val1.avgBuyPrice).toFixed(2);
                        }
                    }
                    if(val1.sector==val.sectorName)
                    {
                        currentMarketValuesectorMap[val.sectorName]=(Number(currentMarketValuesectorMap[val.sectorName])+Number(val1.currentValue));
                        totalPLsectorMap[val.sectorName]=(Number(totalPLsectorMap[val.sectorName])+Number(val1.plValue));
                    }

                    currentMarketValue=(Number(currentMarketValue) + Number(val1.currentValue)).toFixed(2);

                    totalPrevClose=Number(totalPrevClose)+(Number(val1.prevClose)*Number(val1.qty));
                    totalPrevClose= Number(totalPrevClose).toFixed(2);

                    totalGainLoss=Number(totalGainLoss)+Number(val1.todayGainLoss);
                    totalGainLoss= Number(totalGainLoss).toFixed(2);

                    totalplValue=Number(totalplValue)+Number(val1.plValue);
                    totalplValue= Number(totalplValue).toFixed(2);

                })
                val.totalValue=Number(currentMarketValuesectorMap[val.sectorName]).toFixed(2);
                val.totalPL=Number(totalPLsectorMap[val.sectorName]).toFixed(2);

            }
            else
            {
                var fix=2;
                if(this.state.selectedsegment=="Currency")
                {
                    fix=4;
                }
                if(((val.contractDesc!="" && val.contractDesc!=undefined && streamData.data.sym===val.contractDesc) || streamData.data.sym===val.symbol) && streamData.data.ltp!=val.ltp)
                {
                    val.ltp=Number(streamData.data.ltp).toFixed(fix);

                    val.currentValue=Number(val.ltp*val.qty).toFixed(fix);
                    if(this.state.selectedsegment=="Currency" || this.state.selectedsegment=="Fno")
                    {
                        val.currentValue= Math.abs(val.currentValue).toFixed(fix);
                    }
                    val.todayGainLoss= Number(val.qty*(val.ltp-val.prevClose)).toFixed(2);
                    prvsclseprc=val.prevClose*val.qty;
                    if(prvsclseprc==0){
                        val.todayGainLossPerc= Number(0).toFixed(2);
                    }
                    else{
                        val.todayGainLossPerc= Number(((val.todayGainLoss)*100)/Math.abs(prvsclseprc)).toFixed(2);
                    }

                    if(val.avgBuyPrice==0){
                        val.plValue=Number(0).toFixed(2);
                        val.plPerc=Number(0).toFixed(2);
                    }
                    else{
                        val.plValue=Number((val.ltp-val.avgBuyPrice)*val.qty).toFixed(2);
                        val.plPerc=Number(((val.plValue)*100)/Math.abs(val.avgBuyPrice*val.qty)).toFixed(2);
                    }
                }

                totalPrevClose=Number(totalPrevClose)+(Number(val.prevClose)*Number(val.qty));
                totalPrevClose= Math.abs(Number(totalPrevClose).toFixed(2));

                totalGainLoss=Number(totalGainLoss)+Number(val.todayGainLoss);
                totalGainLoss= Number(totalGainLoss).toFixed(2);

                totalplValue=Number(totalplValue)+Number(val.plValue);
                totalplValue= Number(totalplValue).toFixed(2);

                currentMarketValue=Number(currentMarketValue) + Number(val.currentValue);

            }
        })
        currentMarketValue= Number(currentMarketValue).toFixed(2);
        if(this.state.totalInvestment!=0){
            overallGainLossPerc= Number(((Number(totalplValue))*100)/Number(this.state.totalInvestment)).toFixed(2);
        }
        else{
            overallGainLossPerc= Number(0).toFixed(2);
        }
        if(totalPrevClose!=0){
            totalGainLossPerc=Number((Number(totalGainLoss)*100)/Number(totalPrevClose)).toFixed(2);
        }
        else{
            totalGainLossPerc=Number(0).toFixed(2);
        }
        this.setState({
            data: this.state.data,
            totalValue:currentMarketValue,
            totalPL:totalplValue,
            todayPL:totalGainLoss,
            overallGainLoss:overallGainLossPerc,
            totalGainLoss:totalGainLossPerc
        });
    }


    onStreamCallbackTR(streamData) {

        var currentMarketValue=0;
        var totalGainLoss=0;
        var totalPrevClose=0;
        var totalplValue=0;
        var overallGainLossPerc=0;
        var totalGainLossPerc=0;
        var prvsclseprc = 0;
        var currentMarketValuesectorMap = {};
        var totalPLsectorMap={};
        (this.state.data).map(val=>{
            if(this.state.selectedsegment=="Sector")
            {
                if(currentMarketValuesectorMap[val.sectorName]==null || currentMarketValuesectorMap[val.sectorName]==undefined)
                {
                    currentMarketValuesectorMap[val.sectorName] = 0;
                }

                if(totalPLsectorMap[val.sectorName]==null || totalPLsectorMap[val.sectorName]==undefined)
                {
                    totalPLsectorMap[val.sectorName] = 0;
                }
                val.sectorList.map(val1=>{

                    if(val1.securityId==streamData.data.xSym &&streamData.data.ltp && streamData.data.ltp!=val1.ltp)
                    {

                        val1.ltp=Number(streamData.data.ltp).toFixed(2);
                        val1.currentValue=Number(val1.ltp*val1.qty).toFixed(2);
                        val1.todayGainLoss= Number(val1.qty*(val1.ltp-val1.prevClose)).toFixed(2);

                        prvsclseprc=val1.prevClose*val1.qty;
                        if(prvsclseprc==0){
                            val1.todayGainLossPerc= Number(0).toFixed(2);
                        }
                        else{
                            val1.todayGainLossPerc= Number(((val1.ltp-val1.prevClose)*100)/Math.abs(prvsclseprc)).toFixed(2);
                        }

                        if(val1.avgBuyPrice==0){
                            val1.plValue=Number(0).toFixed(2);
                            val1.plPerc=Number(0).toFixed(2);
                        }
                        else{
                            val1.plValue=Number((val1.ltp-val1.avgBuyPrice)*val1.qty).toFixed(2);
                            val1.plPerc=Number(((val1.ltp-val1.avgBuyPrice)*100)/val1.avgBuyPrice).toFixed(2);
                        }
                    }
                    if(val1.sector==val.sectorName)
                    {
                        currentMarketValuesectorMap[val.sectorName]=(Number(currentMarketValuesectorMap[val.sectorName])+Number(val1.currentValue));
                        totalPLsectorMap[val.sectorName]=(Number(totalPLsectorMap[val.sectorName])+Number(val1.plValue));
                    }

                    currentMarketValue=(Number(currentMarketValue) + Number(val1.currentValue)).toFixed(2);

                    totalPrevClose=Number(totalPrevClose)+(Number(val1.prevClose)*Number(val1.qty));
                    totalPrevClose= Number(totalPrevClose).toFixed(2);

                    totalGainLoss=Number(totalGainLoss)+Number(val1.todayGainLoss);
                    totalGainLoss= Number(totalGainLoss).toFixed(2);

                    totalplValue=Number(totalplValue)+Number(val1.plValue);
                    totalplValue= Number(totalplValue).toFixed(2);

                })
                val.totalValue=Number(currentMarketValuesectorMap[val.sectorName]).toFixed(2);
                val.totalPL=Number(totalPLsectorMap[val.sectorName]).toFixed(2);

            } else {
                var fix=2;
                if(this.state.selectedsegment=="Currency")
                {
                    fix=4;
                }

                if(streamData.data.ltp&&(streamData.data.xSym===val.securityId)&& streamData.data.ltp!=val.ltp )
                {

                    val.ltp=Number(streamData.data.ltp).toFixed(fix);

                    val.currentValue=Number(val.ltp*val.qty).toFixed(fix);
                    if(this.state.selectedsegment=="Currency" || this.state.selectedsegment=="Fno")
                    {
                        val.currentValue= Math.abs(val.currentValue).toFixed(fix);
                    }

                    val.todayGainLoss= Number(val.qty*(val.ltp-val.prevClose)).toFixed(2);
                    prvsclseprc=val.prevClose*val.qty;
                    if(prvsclseprc==0){
                        val.todayGainLossPerc= Number(0).toFixed(2);
                    }
                    else{
                        val.todayGainLossPerc= Number(((val.todayGainLoss)*100)/Math.abs(prvsclseprc)).toFixed(2);
                    }

                    if(val.avgBuyPrice==0){
                        val.plValue=Number(0).toFixed(2);
                        val.plPerc=Number(0).toFixed(2);
                    }
                    else{
                        val.plValue=Number((val.ltp-val.avgBuyPrice)*val.qty).toFixed(2);
                        val.plPerc=Number(((val.plValue)*100)/Math.abs(val.avgBuyPrice*val.qty)).toFixed(2);
                    }
                }

                totalPrevClose=Number(totalPrevClose)+(Number(val.prevClose)*Number(val.qty));
                totalPrevClose= Math.abs(Number(totalPrevClose).toFixed(2));

                totalGainLoss=Number(totalGainLoss)+Number(val.todayGainLoss);
                totalGainLoss= Number(totalGainLoss).toFixed(2);

                totalplValue=Number(totalplValue)+Number(val.plValue);
                totalplValue= Number(totalplValue).toFixed(2);

                currentMarketValue=Number(currentMarketValue) + Number(val.currentValue);

            }
        })
        currentMarketValue= Number(currentMarketValue).toFixed(2);
        if(this.state.totalInvestment!=0){
            overallGainLossPerc= Number(((Number(totalplValue))*100)/Number(this.state.totalInvestment)).toFixed(2);
        }
        else{
            overallGainLossPerc= Number(0).toFixed(2);
        }
        if(totalPrevClose!=0){
            totalGainLossPerc=Number((Number(totalGainLoss)*100)/Number(totalPrevClose)).toFixed(2);
        }
        else{
            totalGainLossPerc=Number(0).toFixed(2);
        }
        this.setState({
            data: this.state.data,
            totalValue:currentMarketValue,
            totalPL:totalplValue,
            todayPL:totalGainLoss,
            overallGainLoss:overallGainLossPerc,
            totalGainLoss:totalGainLossPerc
        });
    }




    parsePortfolioError(selectedsegment, error) {
        if (selectedsegment === this.state.selectedsegment) {
            this.setState({
                inProgress: false,
                error: error.message,
                data:[],
                totalValue: '0.00',
                todayPL:'0.00',
                totalInvestment: '0.00',
                totalPL:'0.00',
                overallGainLoss:'0.00',
                totalGainLoss:'0.00',
                totalRealisedPL:'0.00'
            })
        }
    }

    onToggleSelected(selected) {

        if (selected == "1")
        {
            //  this.setState({
            //     selectedsegment:"Script",
            //     selectToggle:"1"
            //  }, ()=> {this.requestPortfolio()});
            this.state.selectedsegment="Script";
            this.setState({selectToggle:"1"}, this.requestPortfolio());
        }
        else if (selected == "2")
        {
            // this.setState({
            // selectedsegment:"Sector",
            // selectToggle:"2"
            //  }, ()=> {this.requestPortfolio()});
            this.state.selectedsegment="Sector";
            this.setState({selectToggle:"2"}, this.requestPortfolio());
        }

    }

    onToggle3Selected(selected) {

        if (selected === "1")
        {
            this.state.selectedsegment="Script";
            this.setState({
                // selectedsegment:"Script",
                selectToggle:"1",
                selectToggle3:"1"
            }, this.requestPortfolio());
        }
        else if (selected === "2")
        {
            this.state.selectedsegment="Fno";
            this.setState({
                // selectedsegment:"Fno",
                selectToggle3:"2"
            }, this.requestPortfolio());
        }
        else if (selected === "3")
        {
            this.state.selectedsegment="Currency";
            this.setState({
                // selectedsegment:"Currency",
                selectToggle3:"3"
            }, this.requestPortfolio());
        }

    }




    render() {
        var errorHolder = (
            <div className="error-holder" style={{ marginTop: "5px" }}>
                <span> {this.state.error} </span>

            </div>
        );

        let selectedId = this.state.id;

        return (

            <div className="fillheight ">
                <div className="row">
                    <div className="col-lg-6 widget-title portfit-heading">
                        Profit & Loss Summary
                    </div>
                    <div className="col-lg-6 right-button">

                    </div>
                </div>
                <div className="row">
                    <div className="col  portfolio-tracker">
                        <div className="header-text1">
                            <div className="portfolio-header-title">Total Value</div>
                            <div className="portfolio-header-value"> {(this.state.totalInvestment!="")?this.state.totalInvestment:"0.00"} </div>
                        </div>

                    </div>
                    <div className="col  portfolio-tracker">
                        <div className="header-text1">
                            <div className="portfolio-header-title">Current Value</div>
                            <div className="portfolio-header-value"> {(this.state.totalValue!="")?this.state.totalValue:"0.00"}</div>
                        </div>
                    </div>
                    <div className="col portfolio-tracker">
                        <div className="header-text1">
                            <div className="portfolio-header-title">Overall Gain/Loss(%)</div>
                            <div className="portfolio-header-value" style={{color: Math.sign(this.state.totalPL) === -1 ? "#E5283F" : "#1EBF8A"}}>{(this.state.totalPL!="")?this.state.totalPL:"0.00"} {(this.state.overallGainLoss!="")?"("+this.state.overallGainLoss+"%)":"(0.00%)"} </div>
                        </div>
                    </div>
                    <div className="col portfolio-tracker">
                        <div className="header-text1">
                            <div className="portfolio-header-title">Today Gain/Loss(%)</div>
                            <div className="portfolio-header-value" style={{color: Math.sign(this.state.todayPL) === -1 ? "#E5283F" : "#1EBF8A"}}> {(this.state.todayPL!="")?this.state.todayPL:"0.00"} {(this.state.totalGainLoss!="")?"("+this.state.totalGainLoss+"%)":"(0.00%)"}
                            </div>
                        </div>
                    </div>
                    <div className="col portfolio-tracker">
                        <div className="header-text1 no-border">
                            <div className="portfolio-header-title">Realised Gain/Loss</div>
                            <div className="portfolio-header-value" style={{color: Math.sign(this.state.totalRealisedPL) === -1 ? "#E5283F" : "#1EBF8A"}}> {(this.state.totalRealisedPL!="")?this.state.totalRealisedPL:"0.00"}
                            </div>
                        </div>
                    </div>
                </div>
                <div  className={'shiftDown tabbar-content-holder' + (selectedId === '1' ? ' bottomchange' : '')} id="1">
                    <div className="row header-row">
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col contentpanel">
                                    <div className="ideas-sort-drop-down">
                                        {(this.state.selectedsegment =="Fno" || this.state.selectedsegment =="Currency")?
                                        <Dropdown list={holdingsDropDownOptions}
                                                  dropdownType={commonDropDownType.DEFAULT}
                                                  onSelectionChanged={this.onFilterSelected.bind(this)} /> :<div className="">Holdings</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="row">
                                <div className="col-6">
                                    {(this.state.selectedsegment=="Script" || this.state.selectedsegment=="Sector")?<div className="widget-title-component float-right order-toggle">
                                        <ToggleSwitch button1="SCRIP" button2="SECTOR"
                                                      selected={this.state.selectToggle}
                                                      onChange={this.onToggleSelected.bind(this)}/>
                                    </div>:''}
                                </div>

                                <div className="col-6">
                                    <div className="widget-title-component float-right order-toggle">
                                        <ToggleSwitch button1="HOLDINGS" button2="F&O" button3="CURRENCY"
                                                      selected={this.state.selectToggle3}
                                                      onChange={this.onToggle3Selected.bind(this)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row hdivider margin-bottom"> </div>
                    <div className="fillheight">
                        {this.state.error ? errorHolder :
                            this.state.inProgress ? progressBar() : [
                                this.state.selectedsegment === "Sector" ?
                                    <div className="dynamicheight"><PortfolioHeader headingName="SECTOR"  callback={this.sortSymbols.bind(this)} />
                                        <GroupBySectorNew data={this.state.data}
                                                       selectedsegment={this.state.selectedsegment}
                                                       sortColumnId={this.state.sortColumnId} sortType={this.state.sortType} sortDataType={this.state.sortDataType}
                                                       flag={this.state.id} />
                                    </div>:
                                    <div className="dynamicheight"><PortfolioHeader headingName="SCRIP"  callback={this.sortSymbols.bind(this)}  />
                                        <GroupBySymbolNew  data={this.state.data} sortColumnId={this.state.sortColumnId}
                                                        sortType={this.state.sortType} sortDataType={this.state.sortDataType}
                                                        Purchasevalue={this.state.totalInvestment}
                                                        CurrentMktValue={this.state.totalValue}
                                                        selectedsegment={this.state.selectedsegment}
                                                        todayPL={this.state.todayPL}
                                                        NotionalPfLs={this.state.totalPL}
                                                        totalRealisedPL={this.state.totalRealisedPL}
                                                        flag={this.state.id}/></div>

                            ]

                        }



                    </div>

                </div>

            </div>

        )
    }

}

export default PortfolioTrackerDion;




