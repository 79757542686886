import { localStorageKeys, enableEncryption, encryptionKey, instrumentType, ModuleKeys } from './constants'
import { commonErrorHandler } from '../actions/common'

var CryptoJS = require('crypto-js');

var wordArray = CryptoJS.enc.Utf8.parse(encryptionKey);
var skey = CryptoJS.enc.Base64.stringify(wordArray);

var ekey = CryptoJS.enc.Base64.parse(skey);
var eiv = CryptoJS.enc.Base64.parse(skey);


export const storeConfig = function (configData) {
    setItemByKey(localStorageKeys.CONFIG_KEY, configData);
    // window.localStorage.setItem(localStorageKeys.CONFIG_KEY, configData)
}

export const getConfigByKey = function (key, dispatch) {
    var config = getItemByKey(localStorageKeys.CONFIG_KEY);
    // var config = window.localStorage.getItem(localStorageKeys.CONFIG_KEY);
    if (config) {
        config = JSON.parse(config);
        return config[key];
    } else {
        if (dispatch)
            commonErrorHandler("Error Occured. Please login again.");
        // throw needed for the blocks to move to catch
        throw Error("Config data not found.");
    }
}

export const getTheme = function () {
    var theme = window.sessionStorage.getItem(localStorageKeys.ACTIVE_THEME);
    if (theme)
        return theme;
    else
        return 'dark';
}

export const getFilterMapIndex = function (mapData, key, filterValue) {
    var filterData = mapData.filter(data => {
        return data[key].toLowerCase().indexOf(filterValue) === 0;
    });
    return filterData;
}

export const setItemByKey = function (key, value) {
    if (enableEncryption) {
        var edata = CryptoJS.AES.encrypt(value, ekey, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: eiv });
        var b64 = edata.ciphertext.toString(CryptoJS.enc.Base64);
        window.sessionStorage.setItem(key, b64);
    }
    else {
        window.sessionStorage.setItem(key, value);
    }
}

export const getItemByKey = function (key) {
    if (enableEncryption) {
        var val = window.sessionStorage.getItem(key);
        if (!val) return val;
        var bytes = CryptoJS.AES.decrypt(val, ekey, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: eiv });
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    else {
        return window.sessionStorage.getItem(key);
    }
}

export const clearSessionStorage = function () {
    //  window.localStorage.clear();

    // To avoid deleting Config.
    window.sessionStorage.removeItem(localStorageKeys.ACCOUNT_DETAILS);
    window.sessionStorage.removeItem(localStorageKeys.ACCOUNT_ID);
    window.sessionStorage.removeItem(localStorageKeys.ACTIVE_THEME);
    window.sessionStorage.removeItem(localStorageKeys.LOGIN_STATUS);
    window.sessionStorage.removeItem(localStorageKeys.SCRIP_INFO);
    window.sessionStorage.removeItem(localStorageKeys.WATCHLIST_GROUPS);
    window.sessionStorage.removeItem(localStorageKeys.USER_EDIS_DETAILS);
    window.sessionStorage.removeItem(localStorageKeys.LIMITAGAINSTHOLDINGFLAG);
    window.sessionStorage.removeItem(localStorageKeys.SymbolOBJ);  
    window.sessionStorage.removeItem(localStorageKeys.EDIS_RESPONSE);
    window.sessionStorage.removeItem(localStorageKeys.CLIENT_HOLDINGS);
    window.sessionStorage.removeItem(localStorageKeys.PLEDGE_NSDL_REQ_REF);
    window.sessionStorage.removeItem(localStorageKeys.USERSESSION_ID);
    window.sessionStorage.removeItem(localStorageKeys.TR_STREAM_FLAG);
    window.sessionStorage.removeItem(localStorageKeys.EDIS_REVOC_RESPONSE);
    window.sessionStorage.removeItem(localStorageKeys.PIB_APP_KEY);
    clearChartPreference();
}

export const isPositive = function (value) {
    if (undefined !== value)
        return (!value.startsWith('-'));
}

export const numericSort = function (data, field, sortType) {
    if (sortType === "ASC") {
        return data.sort(function (first, next) {
            return first[field] - next[field]
        });
    } else {
        return data.sort(function (first, next) {
            return next[field] - first[field]
        });
    }
}


export const stringSort = function (data, field, sortType) {
    if (sortType === "ASC") {
        return data.sort(function (first, next) {
            if (first[field] < next[field]) {
                return -1;
            } else if (first[field] > next[field]) {
                return 1;
            }
            return 0;
        });
    } else {
        return data.sort(function (first, next) {
            if (first[field] < next[field]) {
                return 1;
            } else if (first[field] > next[field]) {
                return -1;
            }
            return 0;
        });
    }
}

export const dateSort = function (data, field, sortType) {
    if (sortType === "DSC") {
        return data.sort(function (first, next) {
            var firtDate = first.split("-");
            var nextDate = next.split("-");
            return new Date(nextDate[2] + "," + nextDate[1] + "," + nextDate[0]).getTime() - new Date(firtDate[2] + "," + firtDate[1] + "," + firtDate[0]).getTime()
        });
    } else {
        return data.sort(function (first, next) {
            var firtDate = first.split("-");
            var nextDate = next.split("-");
            return new Date(firtDate[2] + "," + firtDate[1] + "," + firtDate[0]).getTime() - new Date(nextDate[2] + "," + nextDate[1] + "," + nextDate[0]).getTime()
        });
    }
}

export const calcChange = function (data, instr) {
    if (parseFloat(data.closePrice) === 0 && parseFloat(data.ltp) === 0) {
        return '-'
    } else {
        if (instr === instrumentType.CURRENCY_FUTURE || instr === instrumentType.CURRENCY_OPTIONS || instr === instrumentType.CURRENCY)
            return (data.ltp - data.closePrice).toFixed(4).toString();
        else
            return (data.ltp - data.closePrice).toFixed(2).toString();
    }
}


export const calcChangeTR = function (change, instr) {
	if (instr === instrumentType.CURRENCY_FUTURE || instr === instrumentType.CURRENCY_OPTIONS || instr === instrumentType.CURRENCY)
		
	      return Number(change).toFixed(4);		
	 else		
	      return Number(change).toFixed(2);		
}	    


export const calcChangePercentage = function (data) {
    return parseFloat(data.closePrice) === 0 ? "-" : (((data.ltp - data.closePrice) / data.closePrice) * 100).toFixed(2).toString();
}

export const comaSeparator = function (value, data) {
    if (data && isNaN(Number(data))) {
        return isNaN(Number(value)) ? value :
            Number(value).toLocaleString("en-IN",
                { minimumFractionDigits: data === "FUTCUR" || data === "OPTCUR" ? 4 : 2, maximumFractionDigits: 10 });
    } else {

        value = value + '';
        data = value && value.indexOf(".") !== -1 ? value.split(".")[1].length : data;
        return isNaN(Number(value)) ? value :
            Number(value).toLocaleString("en-IN", { minimumFractionDigits: isNaN(Number(data)) ? 0 : data, maximumFractionDigits: 16 });
    }
}

export const getLtpClass = function (current, prev, prevclass) {
    var diff = (current - prev);
    var toclass = prevclass;

    if (diff > 0)
        toclass = 'positive-change-bg';
    else if (diff < 0)
        toclass = 'negative-change-bg';
    else
        toclass = 'no-change-bg';;
    return toclass;
}

//deleting chart preference when logut
export const clearChartPreference = function () {
    window.localStorage.removeItem(ModuleKeys.CHART_1);
    window.localStorage.removeItem(ModuleKeys.CHART_2);
    window.localStorage.removeItem(ModuleKeys.CHART_3);
    window.localStorage.removeItem(ModuleKeys.CHART_4);
};

//masking data
export const maskUserData = function (data) {
    if (data && data !== '') {
        let result = "";
        for (let i = 0; i < data.length; i++) {
            if (i < 5) {
                result += "*";
            } else {
                result += data.charAt(i);
            }
        }
        return result;
    }
}

//cookie get data
export const getCookie = function (name) {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
}


export const setCookie= function(cname, cvalue, minutes) {
    console.log(cname);
    var d = new Date();
    d.setTime(d.getTime() + (minutes*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires+";httpOnly;secure";
}

export const tConv24 = function(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };

  

  var  number = '' ;

  export const createMask = function(string){
    if(string){
    number = string.substring(0,5)
    console.log("create mask number")
    return string.replace(/(\d{2})(\d{3})(\d{2})/,"*****$3");
    }
    return null;
}

export const destroyMask = function(string){
    console.log("distroy mask number")
     var num = parseInt(string).toString();
    var unmasknumber = string.replace(/\D/g,'').substring(0,10);
    return number + unmasknumber;
}
