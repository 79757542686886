import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setChartsExpandWidget } from '../../actions/dashboard'
import { ModuleKeys } from '../../common/constants'
import { progressBar } from '../../controls/progress'

class ChartsBaseWidget extends Component {

    // static propTypes = {
    //     history: PropTypes.object.isRequired
    // }

    // constructor(props) {
    //     super(props);
    // }

    onExpandToggle(type) {
        if (this.props.activeWidget === ModuleKeys.ALL) {
            this.props.setChartsExpandWidget(type, { prevWidget: undefined });
        } else {
            this.props.setChartsExpandWidget(ModuleKeys.ALL, { prevWidget: undefined });
        }
    }

    render() {

        // var progressindicator = (
        //     <div className="widget-progress-holder">
        //         <svg class="circular-progress" viewBox="25 25 50 50">
        //             <circle class="circular-progress_path" cx="50" cy="50" r="20"
        //                 fill="none" stroke-width="2" stroke-miterlimit="10" />
        //         </svg>
        //     </div>
        // );

        var errorHolder = (
            <div className="error-holder">
                <span> {this.props.error} </span>
            </div>
        );

        return (
            <div className="fillheight">
                {/* <div className="row contentpanel widget-title chart-header">
                    <div className="col-xs-10 contentpanel" style={{ paddingLeft: '10px' }}>
                        CHARTS
                    </div>

                    <div className="col-xs-2 contentpanel chart-expander text-right" onClick={() => this.onExpandToggle(this.props.componentType)}>
                        {this.props.noexpand ? "" : <img src={this.props.activeWidget !== ModuleKeys.ALL ? "assets/svg/collapse.svg" : "assets/svg/expand.svg"} className="expander-icon" onerror="this.src='assets/images/logo.png'" />}
                    </div>
                </div> */}
                <div className="contentpanel chart-content-holder">
                    {
                        (this.props.error) ? errorHolder :
                            <div className="fillheight fillwidth">
                                {this.props.inProgress ? progressBar() : this.props.children}
                            </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeWidget: state.charts.activeWidget,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setChartsExpandWidget: (activeWidget, statedata) => setChartsExpandWidget(activeWidget, statedata, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartsBaseWidget); 