import { getChartBaseData } from '../../actions/chartiq/chartiqRequests'
var dateFormat = require('dateformat');

export default class ChartService {

	quotefeedSimulator = {}; // the quotefeed object

	constructor(symbol) {
		this.quotefeedSimulator.symbol = symbol;

		// called by chart to fetch initial data
		this.quotefeedSimulator.fetchInitialData = function (symbol, suggestedStartDate, suggestedEndDate, params, cb) {
			console.log('chartiq initial requested');
			console.log(suggestedStartDate);
			console.log(suggestedEndDate);
			var requestData = {
				request: {
					data: {
						sym: this.quotefeedSimulator.symbol,
						period: params.period + '',
						fromDate: dateFormat(new Date(suggestedStartDate), "yyyy-mm-dd HH:MM:ss"),
						toDate: dateFormat(new Date(suggestedEndDate), "yyyy-mm-dd HH:MM:ss"),
						interval: params.interval + '',
						extended: params.stx.extendedHours ? "1" : "0"
					}
				}
			};
			getChartBaseData(requestData, this.quotefeedSimulator.reponseBaseDataSuccess.bind(this, cb, params), this.quotefeedSimulator.reponseBaseDataError.bind(this, cb));
		}.bind(this);

		this.quotefeedSimulator.reponseBaseDataSuccess = function (cb, params, response) {
			console.log('dddrequest succeeded with JSON response', response);
			console.log(params);
			if (response.response.infoID === "0" && response.response.data.chart &&  response.response.data.chart.length > 0) {
				console.log('proper response')
				var newQuotes = this.quotefeedSimulator.formatChartData(response);
				cb({ quotes: newQuotes, moreAvailable: (params.interval !== 'minute' ), attribution: { source: "simulator", exchange: "RANDOM" } });
			}
			else {
				cb({ quotes: [], moreAvailable: false, attribution: { source: "simulator", exchange: "RANDOM" } });
			}
		}

		this.quotefeedSimulator.reponseBaseDataError = function (cb, error) {
			console.log('dddrequest failed', error);
			cb({ quotes: [], moreAvailable: false, attribution: { source: "simulator", exchange: "RANDOM" } });
		}

		// called by chart to fetch update data
		this.quotefeedSimulator.fetchUpdateData = function (symbol, startDate, params, cb) {
			console.log('chartiq update requested');
			// var requestData = {
			// 	request: {
			// 		data: {
			// 			sym: symobj,
			// 			period: params.period + '',
			// 			fromDate: dateFormat(new Date(startDate), "yyyy-mm-dd HH:MM:ss"),
			// 			toDate: dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss"),
			// 			interval: params.interval + '',
			// 			extended: params.stx.extendedHours ? "1" : "0"
			// 		}
			// 	}
			// };
			// getChartBaseData(requestData, this.quotefeedSimulator.reponseBaseDataSuccess.bind(this, cb), this.quotefeedSimulator.reponseBaseDataError.bind(this, cb));
		};

		// called by chart to fetch pagination data
		this.quotefeedSimulator.fetchPaginationData = function (symbol, suggestedStartDate, endDate, params, cb) {
			console.log('chartiq page requested');
			var requestData = {
				request: {
					data: {
						sym: this.quotefeedSimulator.symbol,
						period: params.period + '',
						fromDate: dateFormat(new Date(suggestedStartDate), "yyyy-mm-dd HH:MM:ss"),
						toDate: dateFormat(new Date(endDate), "yyyy-mm-dd HH:MM:ss"),
						interval: params.interval + '',
						extended: params.stx.extendedHours ? "1" : "0"
					}
				}
			};
			getChartBaseData(requestData, this.quotefeedSimulator.reponseBaseDataSuccess.bind(this, cb, params), this.quotefeedSimulator.reponseBaseDataError.bind(this, cb));
		}.bind(this);

		// utility function to format data for chart input; given simulator was designed to work with library, very little formatting is needed
		this.quotefeedSimulator.formatChartData = function (response) {
			var feeddata = response.response.data.chart;
			var newQuotes = [];
			for (var i = 0; i < feeddata.length; i++) {
				newQuotes[i] = {};
				var dateTemp = (feeddata[i].updDate).split(" ");
				newQuotes[i].DT = new Date(dateTemp[0] + "T" + dateTemp[1] + "Z"); // DT is a string in ISO format, make it a Date instance
				newQuotes[i].Open = parseFloat(feeddata[i].open);
				newQuotes[i].High = parseFloat(feeddata[i].high);
				newQuotes[i].Low = parseFloat(feeddata[i].low);
				newQuotes[i].Close = parseFloat(feeddata[i].price);
				newQuotes[i].Volume = parseInt(feeddata[i].volume,10);
			}
			return newQuotes;
		};

		this.quotefeedSimulator.setSymbol = function (symbol) {
			this.symbol = symbol;
		}
	}

	makeFeed() {
		return this.quotefeedSimulator;
	}
}
