import React, { Component } from "react";
import { connect } from "react-redux";
import BaseWidget from "../base/BaseWidget";
import { ModuleKeys } from "../../common/constants";
import { getWorldMarket } from '../../actions/dashboard/dashboardrequests'
import RowWidget from './RowWidget';


class WorldMarketSummarySmall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      error: '',
      worldMarketData: []
    };
  }

  componentDidMount() {
    var data = {
      request: {
        data: {
        }
      }
    };
    this.setState({ inProgress: true, error: '' });
    getWorldMarket(data, this.parseResponse.bind(this), this.parseError.bind(this));
  }

  parseResponse(responseData) {
    this.setState({
      worldMarketData: responseData.response.data.indices,
      inProgress: false,
      error : ''
    });
  }

  parseError(error) {
    console.log(error);
    this.setState({
      inProgress: false,
      error: error.message
    });
  }

  render() {
    var isLarge = this.props.activeWidget === ModuleKeys.WORLD_MARKET;
    var widget = (
      this.state.worldMarketData.length > 0 &&
      <div className={"panel-group " + (isLarge ? "scrolly" : "worldmarket-accordion")} id="accordion" aria-multiselectable="true">
        {this.state.worldMarketData.map((data, index) => {
          return <RowWidget data={data} isLarge={isLarge} index={index} />

        })
        }
      </div>
    );

    return (
      <BaseWidget
        title={this.props.title}
        componentType={this.props.componentType}
        inProgress={this.state.inProgress}
        error={this.state.error}
        isExpanded={this.props.activeWidget === ModuleKeys.WORLD_MARKET}
      >
        {widget}
      </BaseWidget>
    );
  }
}
const mapStateToProps = state => {
  return {
    activeWidget: state.dashboard.activeWidget
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(
  WorldMarketSummarySmall
);
