import React from 'react';
import { connect } from 'react-redux';
import { getNetPostions } from '../../actions/netpositions/netpostionrequest'
import PositionDataHolder from './PositionDataHolder'
import { stringSort } from '../../common/utils'
import { onOrderSuccess } from '../../actions/orderentry/orderentryaction'
import { progressBar } from '../../controls/progress'
import { commonDropDownType } from "../../common/constants"
import Dropdown from '../../controls/Dropdown'

class CurrencyTabContent extends React.Component {
    tag = "NetpositionCurrency"
    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            producttypes: [{ name: "ALL", key: "ALL" }, { name: "CASH", key: "CASH" }, { name: "INTRADAY", key: "INTRADAY" }, { name: "MARGIN", key: "MARGIN" },
            { name: "COVER ORDER", key: "CO" }, { name: "BRACKET ORDER", key: "BO" }, { name: "SHORT SELL", key: "SHORTSELL" }],
            selectedproducttype: "all",
            fnooptions: [{ name: "ALL FnO", key: "all" }, { name: "FUTURES", key: "future" }, { name: "OPTIONS", key: "option" }],
            selectedfnooptions: "all",
            filterData: [],
            showTranfer: true,
            isBuySell: true,
            selectedday: ''
        }
        this.onStreamCallback = this.onStreamCallback.bind(this);
        this.onStreamCallbackTR = this.onStreamCallbackTR.bind(this);
        this.onOrderSuccess = this.onOrderSuccess.bind(this);
    }

    componentDidMount() {
        this.setState({ selectedday: (this.props.selectedday) }, () => {
            this.requestCurrencyNP();
            this.props.onOrderSuccess(this.onOrderSuccess);
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.selectedday !== this.props.selectedday) {
            this.setState({ selectedday: (newProps.selectedday) }, () => {
                this.requestCurrencyNP();
            });
        }
    }

    onOrderSuccess() {
        this.requestCurrencyNP();
    }

    // === Get fno details ======
    requestCurrencyNP() {
        var requestData = {
            request: {
                data: {
                    filters: [{
                        key: "prdType",
                        value: this.state.selectedproducttype
                    },
                    {
                        key: "assetClass",
                        value: this.state.selectedfnooptions,
                    },
                    {
                        key: "positionDay",
                        value: this.props.selectedday,
                    }
                    ],
                    tab: "currency"
                }
            }
        };
        this.setState({ inProgress: true, error: "" });
        getNetPostions(requestData, this.parseRequestCurrencyNPResponse.bind(this), this.parseCurrencyNPError.bind(this));
    }

    parseRequestCurrencyNPResponse(responseData) {
        this.setState({
            inProgress: false,
            filterData: responseData.response.data.positions,
        }, function () {
            this.onSocketRegister(this.state.filterData);
        });
    }

    parseCurrencyNPError(error) {
        this.setState({ inProgress: false, error: error.message });
    }

    onOptionTypeSelected(selected) {
        var selectedOption = this.state.fnooptions.filter(data => data.name === selected)[0].key;
        this.setState({ selectedfnooptions: selectedOption }, function () { this.requestCurrencyNP() })
    }

    onProductTypeSelected(selected) {
        var selectedproducttype = this.state.producttypes.filter(data => data.name === selected)[0].key;
        this.setState({ selectedproducttype: selectedproducttype }, function () { this.requestCurrencyNP() })
    }

    sortSymbol() {
        var sortData = stringSort(this.state.filterData, "displaySym", this.state.sortType === "ASC" ? "DSC" : "ASC")
        this.setState({ filterData: sortData, sortType: this.state.sortType === "ASC" ? "DSC" : "ASC" })
    }

    //================  Stream Functions  =================//

    onSocketRegister(data) {

        if (localStorage.getItem("TR_STREAM_FLAG") === "N") {
        data.map(value => {
            return window.socketEvents.register(value.streamSym + value.sym.exc, { screenName: this.tag, sym: value.streamSym, exc: value.sym.exc }, this.onStreamCallback)
        })
        setTimeout(function () {
            window.socketEvents.subscribe();
        }, 1000)

        } else {           
            data.map(value => {
                if (value.sym.xSym && value.sym.exc_seg) {
                    return window.socketEventsTR.register(value.sym.xSym, { screenName: this.tag, sym: value.sym.xSym, exc: value.sym.exc_seg }, this.onStreamCallbackTR)
                }
            })
            setTimeout(function () {
                window.socketEventsTR.subscribe();
            }, 1000)
       }
    }

    onStreamCallback(streamData) {
        var updateCurrentList = this.state.filterData;
        updateCurrentList = updateCurrentList.map(data => {
            if (data.streamSym === streamData.data.sym && data.sym.exc === streamData.data.exc) {
                data.ltp = streamData.data.ltp ? streamData.data.ltp : data.ltp;
            }
            return data;
        })
        this.setState({ filterData: updateCurrentList })
    }

    onStreamCallbackTR(streamData) {
        var updateCurrentList = this.state.filterData;
        updateCurrentList = updateCurrentList.map(data => {
            if (data.sym.xSym === streamData.data.xSym ) {
                data.ltp = streamData.data.ltp ? streamData.data.ltp : data.ltp;
            }
            return data;
        })
        this.setState({ filterData: updateCurrentList })
    }

    componentWillUnmount() {
        window.socketEvents.unregister(this.tag);
        window.socketEventsTR.unregister(this.tag);
    }

    transferCallback() {
        this.requestCurrencyNP();
    }

    getSelectedFNO = () => {
        let selectedName = this.state.fnooptions.filter(data => this.state.selectedfnooptions.toLowerCase() === data.key.toLowerCase())[0];
        return selectedName && selectedName.name;
    }

    getSelectedProduct = () => {
        let selectedName = this.state.producttypes.filter(data => this.state.selectedproducttype.toLowerCase() === data.key.toLowerCase())[0];
        return selectedName && selectedName.name;
    }

    render() {

        var errorHolder = (
            <div className="error-holder">
                {this.state.error}
            </div>
        );

        var additionalProperties = (
            <div className="row contentpanel ">
                <span className="col-6 widget-title-component">
                    <Dropdown className="equity-pt-drop-down"
                        dropdownType={commonDropDownType.DEFAULT}
                        alignDefault={true}
                        list={this.state.fnooptions}
                        onSelectionChanged={this.onOptionTypeSelected.bind(this)}
                        selected={this.getSelectedFNO()}
                    />
                </span>
                <span className="col-6 nopadding equity-pt-drop-down">
                    <Dropdown className="equity-pt-drop-down"
                        dropdownType={commonDropDownType.DEFAULT}
                        alignDefault={true}
                        list={this.state.producttypes}
                        onSelectionChanged={this.onProductTypeSelected.bind(this)}
                        selected={this.getSelectedProduct()}
                    />
                </span>
            </div>
        );

        return (
            <div id="currency" className="np-tab-content tab-pane in active">

                <div className="fillheight">

                    <div style={{ height: "calc(100% - 75px)" }}>
                        {this.state.error ? errorHolder :
                            this.state.inProgress ? progressBar() : (
                                <PositionDataHolder
                                    positionData={this.state.filterData}
                                    showTranfer={this.state.showTranfer}
                                    segment={"currency"}
                                    qtylabel={"LOT"}
                                    sortCallback={this.sortSymbol.bind(this)}
                                    transferCallback={this.transferCallback.bind(this)}
                                    isBuySell={this.state.isBuySell}
                                    additionalProperties={additionalProperties} 
                                    selectedday={this.state.selectedday}/>
                            )}
                    </div>
                </div>
            </div >
        )
    }

}


const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onOrderSuccess: (callback) => onOrderSuccess(dispatch, callback)
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(CurrencyTabContent);