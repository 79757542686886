import React from 'react';
import { connect } from 'react-redux';
import BidEntry from './BidEntry'
import ConfirmBid from './ConfirmBid'
import ReceivedBid from './ReceivedBid'
import { progressBar } from '../../controls/progress'

class ProductEntryWindow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            currentpage: 'bidinput',
            currentbiddetails: {},
            previousbidinputs: []
        }
        // this.setOrderDetails = this.setOrderDetails.bind(this);
        // this.onCancelClicked = this.onCancelClicked.bind(this);
        // this.getOrderInputComponent = this.getOrderInputComponent.bind(this);
    }
    onConfirmClick(){
        console.log("confirm clicked")
        this.setState({currentpage:'bidconfirmation'});
    }
    onSubmitClick(){
        console.log("submit clicked")
        this.setState({currentpage:'bidresult'});
    }
    componentDidMount() {
    }
    render() {
        // var progressindicator = (
        //     <div className="orderinput-progress-holder" >
        //         <svg class="circular-progress" viewBox="25 25 50 50">
        //             <circle class="circular-progress_path" cx="50" cy="50" r="20"
        //                 fill="none" stroke-width="2" stroke-miterlimit="10" />
        //         </svg>
        //     </div>
        // );

        return (
            this.props.showBidDialog ?
                <div className={"modal disabledbackground orderentry-modal fade" + (this.props.showBidDialog ? "in dialog-show" : "dialog-hide")} id={this.props.id} role="dialog" >

                    {this.state.currentpage === 'bidinput' ?
                        <BidEntry onConfirmClick={this.onConfirmClick.bind(this)} />
                        : ''}

                    {this.state.currentpage === 'bidconfirmation' ?
                        <ConfirmBid ref="bidconfirmation" onSubmitClick={this.onSubmitClick.bind(this)}
                        // onNegativeButtonClick={this.onCancelClicked}
                        // onOrderModify={this.onOrderModify.bind(this)}
                        // onConfirmOrder={this.onOrderConfirmation.bind(this)}
                        />
                        : ''}

                    {this.state.currentpage === 'bidresult' ?
                        <ReceivedBid ref="bidresult"
                        // onNegativeButtonClick={this.onCancelClicked}
                        // onRetryClicked={this.onOrderResultRetry.bind(this)}
                        />
                        : ''}

                    {this.state.inProgress ? progressBar() : ''}

                </div> :
                null
        );
    }

}
const mapStateToProps = (state) => {
    return {
        showBidDialog: state.productentry.showBidDialog,
        bidDetails: state.productentry.bidDetails,
        // onOrderSuccessCallback: state.orderentry.onOrderSuccessCallback,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // toggleOrderDialog: (value) => toggleOrderDialog(dispatch, value),
        // showErrorDialog: (errordata) => showErrorDialog(dispatch, errordata)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductEntryWindow);