import React from 'react'
import { connect } from 'react-redux';
import  '../css/summary.css'

class DetailsRowClientWise extends React.Component{
constructor(props){
    super(props)
    this.state = {
        isVisible:false,
        UniqueClient : [],
        filtered_data : {},
        content : [],
        portfolio_data : this.props.portfolio_data

    }
  
}

componentDidMount(){
    this.gettingUniqueID(this.state);
}

componentWillReceiveProps(nextProps) {

        this.setState({
         portfolio_data: nextProps.portfolio_data,
        }, ()=>{
            //console.log("new data");
            //console.log(this.state.portfolio_data);
        }); 
        
        this.gettingUniqueID(nextProps);
}

/*shouldComponentUpdate(nextProps, nextState){
    console.log("component should update");
    console.log(nextState);
    if(!(JSON.stringify(this.state.portfolio_data) === JSON.stringify(nextState.portfolio_data))){
        console.log('coming..');
        return true;
    }
    //return (!(JSON.stringify(this.state.portfolio_data) === JSON.stringify(nextState.portfolio_data))) 
    return false;
}*/

gettingUniqueID(nextState){

    let unq = [];
    let lastVal = "";
    
    this.state.UniqueClient = new Array();
    nextState.portfolio_data.details.map((summ) => {
        if(lastVal!==summ.userId){
            unq.push(summ.userId);
            //console.log("summ.userId");
            //console.log(summ.userId); 
        }    
        lastVal = summ.userId;
        })
        this.setState({
            UniqueClient : [...this.state.UniqueClient, ...unq]
        }, ()=>{
            //console.log("this.state.UniqueClient");
            //console.log(this.state.UniqueClient);
        })
}


showHide(detail,index,display1,display2,displayValue){
 document.getElementsByClassName('data-main-'+detail)[0].style.display=displayValue;
 document.getElementById('show'+index).style.display=display1
 document.getElementById('hide'+index).style.display=display2; 
}


showInitialRecords(){
    console.log("called");
    const xyz =   this.state.UniqueClient.map((detail,index) => (
    
                <React.Fragment key={index}>
                    <tbody>
                        <tr key={index}  >
                    
                        <td>
                            <div><strong>{detail}</strong></div>
                        </td>
                        <td>
                            <div className= "btn btn-info dropdown-toggle fa fa-plus" aria-label="Left Align" style = {{display :'none'}} id={'show'+index} onClick={()=>this.showHide(detail,index,'none','inline','')}>
                                </div>
                            <div className= "btn btn-info dropdown-toggle fa fa-minus" aria-label="Left Align" style = {{display :'inline'}} id={'hide'+index} onClick={()=>this.showHide(detail,index,'inline','none', 'none')}>
                                </div>
                        </td>
                        
                        <td colSpan='13'>&nbsp;</td>
                        </tr>
                </tbody>
                <tbody style ={{display:''}}  className = {'data-main-'+detail}> 
                        {this.showDetailsRecords(detail,index)}
                
                        {this.renderTotalForDetails(detail,index)}
                    </tbody>
               </React.Fragment>
       )
       );
       return xyz;
}

renderTotalForDetails(detail,index){
    let anovSum = 0.00;
    let bookedPlSum = 0.00;
    let notionalPl = 0.00;
    let productPl = 0;
    let totalDetails = {};
    if(Object.keys(this.state.filtered_data).length!==0){
      this.state.filtered_data.map((details_,i) =>{
      bookedPlSum += parseFloat(details_.bookedPnl);
      anovSum += parseFloat(details_.Anov);
      notionalPl += parseFloat(details_.unRealPnl);
      })
      return (
       <tr style={{backgroundColor:''}} >
         <td><strong>TOTAL</strong></td>
         <td>&nbsp;</td>
         <td>&nbsp;</td>
         <td>&nbsp;</td>
         <td>&nbsp;</td>
         <td>&nbsp;</td>
         <td>&nbsp;</td>
         <td>&nbsp;</td>
         <td>&nbsp;</td>
         <td>&nbsp;</td>
         <td>&nbsp;</td>
         <td><strong>{parseFloat(anovSum).toFixed(2)}</strong></td>
         <td><strong>{parseFloat(bookedPlSum).toFixed(2)}</strong></td>
         <td><strong>{parseFloat(notionalPl).toFixed(2)}</strong></td>
         <td><strong>{(parseFloat(bookedPlSum) + parseFloat(notionalPl)).toFixed(2)}</strong></td>
       </tr>
       
    );
    }
   }
showDetailsRecords(detail,index){
  
    var _details={};
    _details = this.state.portfolio_data.details;

    if(detail.length > 0){
    this.state.filtered_data = _details.filter(l => {
            return l.userId.toString().match( detail );
        });
    } 
    //this.state.isVisible=!this.state.isVisible
    //this.setState({
        //filtered_data : this.state.filtered_data,
       // isVisible : !this.state.isVisible
    //})


    if(Object.keys(this.state.filtered_data).length!==0)

      return this.state.filtered_data.map((fdetail, i) => {
          console.log("value is" + i);
         const { contraDesc, userId, mktPrice, prevPos,prevAvgPrice,buyQty,buyAvgPrice,sellQty,sellAvgPrice,netQty,netAvgPrice,Anov,bookedPnl,unRealPnl,prdType } = fdetail //destructuring
         return (
            <tr key={i}  id ={"data-"+i}   >
               <td>{userId}</td>
               <td>{contraDesc}</td>
               <td>{mktPrice}</td>
               <td>{prevPos}</td>
               <td>{prevAvgPrice}</td>
               <td>{buyQty}</td>
               <td>{buyAvgPrice}</td>
               <td>{sellQty}</td>
               <td>{sellAvgPrice}</td>
               <td>{netQty}</td>
               <td>{netAvgPrice}</td>
               <td>{Anov}</td>
               <td>{bookedPnl}</td>
               <td>{unRealPnl}</td>
               <td>{prdType}</td>
            </tr>
         )
      })
}
render(){
    
return(<React.Fragment>{this.showInitialRecords()}</React.Fragment>  )
   
}

}

const mapStateToProps = (state) => {
    return {
  
    }
  }
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
       // showErrorDialog: (errordata) => showErrorDialog(dispatch, errordata),
        //commonDialog: (payload) => commonDialog(dispatch, payload),
        //toggleDialog: (payload) => toggleDialog(payload, dispatch)
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(DetailsRowClientWise); 