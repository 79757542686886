import React from "react"
import ToggleSwitch from '../../controls/ToggleSwitch'
import ButtonTab from '../../controls/ButtonTab'
import InputPlusMinus from '../../controls/InputPlusMinus'
import Dropdown from '../../controls/Dropdown'
import { commonDropDownType } from '../../common/constants'

class BidEntryDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: ["IS", "RS"],
            selectedtab: 'RS',
            marginData: [{ name: 'NSE-CM', key: "NSE-CM" }, { name: 'NSE-FNO', key: "NSE-FNO" }, { name: 'NSE-CDS', key: "NSE-CDS" }, { name: 'BSE', key: "BSE" }],

        }
        this.onClosePopUp = this.onClosePopUp.bind(this);
        this.handleEQCheckChange = this.handleEQCheckChange.bind(this);
        this.handleCMDCheckChange = this.handleCMDCheckChange.bind(this);
        this.onClickSubmit = this.onClickSubmit.bind(this);
    }

    onClosePopUp() {
        if (this.props.closeECNPopUp)
            this.props.closeECNPopUp();
    }

    handleEQCheckChange() {
        this.setState({ isEquityChecked: !this.state.isEquityChecked })
    }

    handleCMDCheckChange() {
        this.setState({ isCommodityChecked: !this.state.isCommodityChecked })
    }

    onClickSubmit() {
        this.props.onConfirmClick()
    }
    onTabSelected(selected) {
        this.setState({ selectedtab: selected });
    }
    render() {
        return (
            <div className="modal-dialog orderentry" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <div className="modal-header">
                        OFS BID ENTRY
                        </div>
                    <div className="modal-header ">
                        <div className="col-xs-3">
                            <div className="prod-headings">Quantity</div>
                            <div className="prod-value">MOIL</div>
                        </div>
                        <div className="col-xs-2">
                            <div className="prod-headings">Floor Price</div>
                            <div className="prod-value" >40.05</div>
                        </div>
                        <div className="col-xs-2">
                            <div className="prod-headings">Underlying Price</div>
                            <div className="prod-value">38.96</div>
                        </div>
                        <div className="col-xs-2">
                            <div className="prod-headings">Discount %</div>
                            <div className="prod-value">0</div>
                        </div>
                        <div className=" widget-title-component marginleft order-toggle" style={{ float: 'right', marginTop: 10 }}>
                            <ToggleSwitch button1="NSE" button2="BSE" />
                        </div>
                    </div>
                    <div className="row order-row">
                        <div className="col-xs-2" style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <ButtonTab ref="btScope"
                                tabs={this.state.tabs}
                                callbackHandler={this.onTabSelected.bind(this)}
                                activeTab={this.state.selectedtab}
                            // isDisabled={this.state.isproducttypedisabled}
                            />
                        </div>
                    </div>
                    <div style={{}} className='row order-row'>
                        <div className='col-xs-3'>
                            <div className='prod-headings' style={{ marginBottom: 10 }}>Quantity</div>
                            <InputPlusMinus ref="ipQuantity" isnumber={true}
                            // incrementvalue={this.state.lotsize}
                            />
                        </div>
                        <div className='col-xs-3'>
                            <div className='prod-headings' style={{ marginBottom: 10 }}>Price</div>
                            {this.state.selectedtab === 'IS' ? <InputPlusMinus ref="ipQuantity" isnumber={true}
                            // incrementvalue={this.state.lotsize}
                            /> : <div className="alertDropdown" style={{ height: 40, }}><input name="value" type="tel" style={{ background: 'transparent', border: 0, }} />
                                </div>
                            }
                        </div>
                        <div className='col-xs-3'>
                            <div className='prod-headings' style={{ marginBottom: 10 }}>Order Price</div>
                            <InputPlusMinus ref="ipQuantity" isnumber={true}
                            // incrementvalue={this.state.lotsize}
                            />
                        </div>
                    </div>
                    <div className='row order-row'>
                        <div className="col-xs-3">
                            <span>Margin Style</span>
                            <div className="alertDropdown "><Dropdown
                                ref={excRef => this.excRef = excRef} dropdownType={commonDropDownType.DEFAULT} alignDefault={true} list={this.state.marginData}
                            // onSelectionChanged={this.onExchangeSelected.bind(this)}
                            />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer text-right">
                    <div >
                        <div>
                            <button type="button" className="btn-footer order-cancel" onClick={this.props.onClose} >CANCEL</button>

                            <button type="button" className="btn-footer order-place"
                                onClick={this.onClickSubmit}
                            //  disabled={(this.state.symbol === undefined || this.state.error)} 
                            >CONFIRM ORDER</button>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default BidEntryDialog;
