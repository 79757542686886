import { StoreKeys } from '../common/constants';

const intialState = {
    showDialog: false,
    showCancelOrderDialog: false,
    orderDetails:{},
    cancelOrderCallback: undefined,
    onOrderSuccessCallback: undefined,
    showMarketDepth: false,
}

const orderentry = (state = intialState, action) => {
    if (action.type === StoreKeys.SHOW_ORDER_DIALOG) {
        return Object.assign({}, state, { showDialog: action.payload });
    } else if (action.type === StoreKeys.SHOW_ORDER_DIALOG_DATA) {
        return Object.assign({}, state, { showDialog: true, orderDetails:action.payload });
    } else if (action.type === StoreKeys.SHOW_CANCEL_ORDER_DIALOG_CALLBACK) {
        return Object.assign({}, state, { cancelOrderCallback:action.payload });
    } else if(action.type === StoreKeys.ON_ORDER_SUCCESS) {
        return Object.assign({}, state, { onOrderSuccessCallback:action.payload });
    } else if(action.type === StoreKeys.SHOW_MARKET_DEPTH) {
        return Object.assign({}, state, { showMarketDepth:action.payload });
    }
    
    else if (action.type === StoreKeys.SHOW_CANCEL_ORDER_DIALOG) {
        return Object.assign({}, state, { showCancelOrderDialog: action.payload });
    } else if (action.type === StoreKeys.SHOW_CANCEL_ORDER_DIALOG_DATA) {
        return Object.assign({}, state, { showCancelOrderDialog: true, orderDetails:action.payload });
    } 
    
    else {
        return state;
    }
};

export default orderentry;