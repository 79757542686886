import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import ReportBaseWidget from '../base/ReportBaseWidget';
import TabBar from "../../controls/TabBar";
import ReportPopup from '../reports/ReportPopup'
import ToggleSwitch from '../../controls/ToggleSwitch'
import { showBidDialog, } from '../../actions/productentry/productentryactions'


const ActiveTabContent = props => {
    return (
        <div style={{ height: "calc(100% - 20px)" }} >
            <div className="row contentpanel fillwidth products-header">
                <div className="col-1 col-lg-1 col-md-1 products-white-content">
                    <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="sort"></img>
                    SYMBOL
                </div>
                <div className="col-1 col-lg-1 col-md-1">
                    COMPANY NAME
                </div>
                <div className="col-1 col-lg-1 col-md-1 text-right">
                    CATEGORY
                </div>
                <div className="col-1 col-lg-1 col-md-1 text-right">
                    CATEGORY DESCRIPTION
                </div>
                <div className="col-1 col-lg-1 col-md-1 text-right">
                    FLOOR PRICE
                </div>
                <div className="col-1 col-lg-1 col-md-1 text-right">
                    OUT OF PRICE
                </div>
                <div className="col-1 col-lg-1 col-md-1">
                    TICK SIZE
                </div>
                <div className="col-1 col-lg-1 col-md-1">
                    MIN BID QTY
                </div>
                <div className="col-1 col-lg-1 col-md-1">
                    DISCOUNT %
                </div>
                <div className="col-1 col-lg-1 col-md-1">
                    OPEN DATE
                </div>
                <div className="col-1 col-lg-1 col-md-1">
                    CLOSE DATE col-lg-1 col-md-1
                </div>
            </div>
            <div style={{ height: 'calc(100% - 60px', overflowY: 'auto' }} >
                {props.data.map(function (row, key) {
                    return [
                        <div className="row contentpanel" >
                            <div key={key} className="row contentpanel products-row">
                                <div className="col-1 col-lg-1 col-md-1">
                                    GOKUL REFOILS AND SOLVENT LIMITED
                            </div>
                                <div className="col-1 col-lg-1 col-md-1">
                                    BOOK BUILDING
                            </div>
                                <div className="col-1 col-lg-1 col-md-1 text-right">
                                    175-195
                            </div>
                                <div className="col-1 col-lg-1 col-md-1 text-right">
                                    35
                            </div>
                                <div className="col-1 col-lg-1 col-md-1 text-right">
                                    35
                            </div>
                                <div className="col-1 col-lg-1 col-md-1 text-right">
                                    10,00,000
                            </div>
                                <div className="col-1 col-lg-1 col-md-1">
                                    16-JAN-2018
                            </div>
                                <div className="col-1 col-lg-1 col-md-1">
                                    16-FEB-2018
                            </div>
                                <div className="col-1 col-lg-1 col-md-1">
                                    DATE
                            </div>
                                <div className="col-1 col-lg-1 col-md-1">
                                    OPEN DATE
                            </div>
                                <div className="col-1 col-lg-1 col-md-1">
                                    CLOSE DATE
                            </div>
                                <div className="col-1 col-lg-1 col-md-1">
                                    <button type="button" className="topgainerslosers-btn buy" data-tag="tag" onClick={props.onClickBuy}>BUY</button>
                                </div>
                            </div>
                            <div className="hdivider contentpanel" />
                        </div>
                    ];
                }, this)}
            </div>
        </div>
    );
};

const ForthcomingTabContent = props => {
    return (
        <div style={{ height: "calc(100% - 20px)" }}  >
            <div className="row contentpanel fillwidth products-header">
                <div className="col-2 products-white-content">
                    <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="sort"></img>
                    SYMBOL
                </div>
                <div className="col-1">
                    COMPANY NAME
                </div>
                <div className="col-1 text-right">
                    CATEGORY
                </div>
                <div className="col-1 text-right">
                    CATEGORY DESCRIPTION
                </div>
                <div className="col-1 text-right">
                    FLOOR PRICE
                </div>
                <div className="col-1 text-right">
                    OUT OF PRICE
                </div>
                <div className="col-1">
                    TICK SIZE
                </div>
                <div className="col-1">
                    MIN BID QTY
                </div>
                <div className="col-1">
                    DISCOUNT %
                </div>
                <div className="col-1">
                    OPEN DATE
                </div>
                <div className="col-1">
                    CLOSE DATE
                </div>
            </div>
            <div style={{ height: 'calc(100% - 60px', overflowY: 'auto' }} >
                {props.data.map(function (row, key) {
                    return [
                        <div className="row contentpanel" >
                            <div key={key} className="row contentpanel products-row">
                                <div className="col-2">
                                    GOKUL REFOILS AND SOLVENT LIMITED
                            </div>
                                <div className="col-1">
                                    BOOK BUILDING
                            </div>
                                <div className="col-1 text-right">
                                    175-195
                            </div>
                                <div className="col-1 text-right">
                                    35
                            </div>
                                <div className="col-1 text-right">
                                    35
                            </div>
                                <div className="col-1 text-right">
                                    10,00,000
                            </div>
                                <div className="col-1">
                                    16-JAN-2018
                            </div>
                                <div className="col-1">
                                    16-FEB-2018
                            </div>
                                <div className="col-1">
                                    DATE
                            </div>
                                <div className="col-1">
                                    OPEN DATE
                            </div>
                                <div className="col-1">
                                    CLOSE DATE
                            </div>
                            </div>
                            <div className="hdivider contentpanel" />
                        </div>
                    ];
                }, this)}
            </div>
        </div>
    );
};

// const progressindicator = (
//     <div className="widget-progress-holder">
//         <svg class="circular-progress" viewBox="25 25 50 50">
//             <circle class="circular-progress_path" cx="50" cy="50" r="20"
//                 fill="none" stroke-width="2" stroke-miterlimit="10" />
//         </svg>
//     </div>
// );

class OFS extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabs: ["Active OFS", "Forthcoming OFS"],
            selectedsegment: "Active OFS",
            data: [1, 2, 3, 4],
            sampleData: { key: 'key', value: { key2: 'key2' } },
            selectedExchange: "NSE",
        }
        this.onClickBuyButton = this.onClickBuyButton.bind(this);
        this.onExchangeSelected = this.onExchangeSelected.bind(this);
    }

    onTabClicked(tabName) {
        switch (tabName) {
            case "Active OFS":
                this.setState({ selectedsegment: "Active OFS" });
                break;
            case "Forthcoming OFS":
                this.setState({ selectedsegment: "Forthcoming OFS" });
                break;
            default:break;
        }
    }

    onClickBuyButton() {
        this.props.showBidDialog(this.state.sampleData);
    }

    onExchangeSelected(selected) {
        if (selected === '1') {
            this.setState({ selectedExchange: "NSE" });
        }
        else {
            this.setState({ selectedExchange: "BSE" });
        }
    }

    render() {
        var additionalWidgetHeader = (
            <div>
                <div className="watchlistoptions">
                    <ReportPopup popupMessage="Popup text" />
                </div>
            </div>
        );

        var additionalTabHeader = (
            <div className="watchlistoptions widget-title-component marginleft order-toggle">
                <ToggleSwitch ref="exchangeSwitch" button1="NSE" button2="BSE" onChange={this.onExchangeSelected} />
            </div>
        );

        return (
            <ReportBaseWidget title="OFS DASHBOARD" additionalWidgetHeader={additionalWidgetHeader}>
                <div className="fillheight" >
                    <div className="row contentpanel">
                        <div className="col-8 ideas-tab-bar">
                            <TabBar tabs={this.state.tabs} highlight="above" expanded={true} callbackHandler={this.onTabClicked.bind(this)} />
                        </div>
                        <div className="tab-content-parent expanded">
                            {additionalTabHeader}
                        </div>
                    </div>
                    <div className="ofs-tab-content" >
                        {this.state.selectedsegment === "Active OFS" ?
                            <ActiveTabContent data={this.state.data} onClickBuy={this.onClickBuyButton} /> : ''
                        }

                        {this.state.selectedsegment === "Forthcoming OFS" ?
                            <ForthcomingTabContent data={this.state.data} /> : ''
                        }
                    </div>
                </div>
            </ReportBaseWidget>
        )
    }

}
const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showBidDialog: (data) => showBidDialog(dispatch, data),
        // showCancelOrderDialog: (data) => showCancelOrderDialog(dispatch, data),
        // showCancelOrderDialogCallback: (callback) => showCancelOrderDialogCallback(dispatch, callback),
        // setScripInfo: (data) => setScripInfo(dispatch, data),
        // onOrderSuccess: (callback) => onOrderSuccess(dispatch, callback)
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OFS));