import React, { Component } from 'react';
import { connect } from 'react-redux';
import ToggleSwitch from '../../controls/ToggleSwitch'
import SymbolSearch from '../home/SymbolSearch'
import OrderNumberInput from '../../controls/OrderNumberInput'
import RadioButtons from '../../controls/RadioButtons'
import {
    TradeActions, orderTypes, validityTypes, advanceOrderTypes,
    productTypes_Equity, productTypes_FUT, productTypes_OPT, symbolSearchType,
    orderEntryTypes, instrumentType, assetClasses, productType,
    marketAndHoldingsTab, basketOrderType, localStorageKeys
} from '../../common/constants';
import { areValidOrderInputs, priceValidation } from '../../validators/orderinputValidations'
import { getQuote, getHoldingsPositions, getHoldingsSummaryBySymbol, getTriggerPriceRange, marginRequired, DpIDSrequest, forgetTpinCdsl } from '../../actions/quote/quoterequests'
import { calcChange, calcChangePercentage, comaSeparator, getCookie, getItemByKey} from '../../common/utils'
import {
    getMarginMode, getMTA, getFnOProductTypes,
    isMCXPermitted, isNCDEXPermitted,
    isNCDEX_FUT_Permitted, isNCDEX_OPT_Permitted, getShortsell
} from '../../common/permissions'
import { blockProgressBar } from '../../controls/progress';
import MarketDepth from '../../controls/MarketDepth';
import HoldingsPositions from './HoldingsPositions';
import { showMTFDefnition } from '../../actions/common';
import { showMTFAlert } from '../../actions/common';
import InputPlusMinus from '../../controls/InputPlusMinus'
import { AddBasketSymbolrequest, ModifyBasketrequest } from '../../actions/products/productrequest'
import Dialog from "../../controls/Dialog";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import {checkenableEncrypt, reloadWhenSymbolChange} from '../../../src/actions/base/api';
import { getTrStreamFlag } from '../../actions/common'

class OrderInput extends Component {

    static propTypes = {
        history: PropTypes.object.isRequired
    }


    tag = "OrderEntry";
    EDIS = "NSDL";
    EDIS_RESPONSE = '';
    constructor(props) {
        super(props);
        this.state = {
            orderentrytype: orderEntryTypes.NEW,
            symbolsearchvisible: true,
            selectedaction: TradeActions.BUY,
            company: "",
            symbol: "",
            exchange_button1: 'NSE',
            exchange_button2: 'BSE',
            tabs: productTypes_Equity,
            visible_validity_types: validityTypes,
            advanceOrderTypes: advanceOrderTypes,
            basketOrderType: basketOrderType,
            selectedMarketTab: marketAndHoldingsTab.MARKET_DEPTH,
            priceInput: '',
            disclosedInput: '0',
            quantity: '10',
            selectedExchangeIndex: "1",
            selectedtab: '',
            selectedptype: '',
            selectedpIndex: 0,
            disabledPType: [],
            selectedAdvancedType: advanceOrderTypes[0],
            selectedAdvancedIndex: 0,
            selectedvaliditytype: '',
            selectedValidityIndex: 0,
            selectedordertype: '',
            selectedOrderTypeIndex: 0,
            symobj: undefined,
            exchange: '',
            instr: '',
            last: '',
            change: '',
            changeper: '',
            vtddate: '',
            advanced_order_tabs: advanceOrderTypes,
            advanced_order_type_open: false,
            selected_traling_sl_type: 'ABS',
            daily_price_range: '',
            lotsize: 1,
            ticksize: 1,
            selectedindp: true,
            lotqualifier: '',
            pricequalifier: '',
            bestbuy: '',
            bestsell: '',
            disableButtonlist: [],
            showdiscquantity: true,
            ispricedisabled: false,
            isdiscqtydisabled: false,
            isproducttypedisabled: false,
            isactiondisabled: false,
            isexchangedisabled: false,
            isexchangehidden: false,
            isproductsubtypedisabled: false,


            quantity_label: 'Quantity',
            discquantity_label: "Disclosed Qty",

            correspondingorder: undefined,
            symDetails: {},
            marketdepth: [],
            totalbuyquantity: 0,
            totalsellquantity: 0,

            inProgress: false,
            error: '',
            holdings_error: '',
            holdingsdata: {},
            dpquantity: '-',
            slDisable: false,
            slPrice: '0',
            isAmo: false,
            disableAmo: false,
            minPrice: 0,
            maxPrice: 0,
            maxQuantity: undefined,
            bestbuyPrice: 0,
            bestsellPrice: 0,
            tabPressCount: 0,
            //ohlc
            openPrice: '',
            highPrice: '',
            lowPrice: '',
            closePrice: '',
            lastUpdatedTime: '',
            ltd: '',
            //cover order
            triggerCvrPriceRange: '',
            maxCvrTrgrPrice: '',
            minCvrTrgrPrice: '',
            cvrTriggerPrice: '',
            slCoverDisable: false,
            //bracket
            brcTriggerPrice: '0',
            isBrcTriggerDisabled: false,
            targetPrice: '0',
            isTargetPriceDisabled: false,
            trailingPrice: '0',
            isTrailingPriceDisabled: false,
            isquantitydisabled: false,
            shortSellPermisson: false,
            status: 'Y',
            requiredMargin: "0.00",

            disabledAdcTypesState: [],
            expriDateSprd: '',
            PriDfernc: '50',
            ShowBaketList: false,
            showDialog: false,
            message: '',
            mainDialog: true,
            disQty: '',
            CdslFlag: "abcd",
            "EDIS_RESPONSE": '',
            UserEdisDetails: {},
            NonPoaquto: {},
            NonPoaconfirmQTY: '0',
            NonPoaQTY: '0',
            cashPositions: 0,
            InDpshowDialog: false,
            ForgetTpiStatus:'',
            ForgetMessageTpin:'',
            survId:'',
            survMsg:''
        };
        this.SubmitEdis = this.SubmitEdis.bind(this);
        this.EDIS = this.getEDISData();
        this.EDIS_RESPONSE = this.showEDISResponse();
        this.onPlaceOrder = this.onPlaceOrder.bind(this);
        this.AddBasketRequest = this.AddBasketRequest.bind(this);
        this.onAdvanceOrderTypeSelected = this.onAdvanceOrderTypeSelected.bind(this);
        this.marketHoldingsToggle = this.marketHoldingsToggle.bind(this);
        this.onExchangeSelected = this.onExchangeSelected.bind(this);
        this.setOrderDetails = this.setOrderDetails.bind(this);
        this.setCorrespondingOrder = this.setCorrespondingOrder.bind(this);
        this.priceOnChange = this.priceOnChange.bind(this);
        this.quantityOnChange = this.quantityOnChange.bind(this);
        this.disclosedOnChange = this.disclosedOnChange.bind(this);
        this.slpriceOnChange = this.slpriceOnChange.bind(this);
        this.activateMTF = this.activateMTF.bind(this);
        this.isCoverLegModify = false;
        this.quantityOnChangeSread = this.quantityOnChangeSread.bind(this);
    }

    //Life cycle events
    componentDidMount() {
        this.searchRef.getWrappedInstance().searchInput.setOrderEntry();
        let orderDetails = this.props.currentorderdetails;
        let previousinputs = this.props.previousinputs;
        if (orderDetails && orderDetails.symobj && previousinputs && previousinputs.symobj) {
            this.setOrderDetails(orderDetails, previousinputs);
        } else if (orderDetails && orderDetails.symobj) {
            this.setOrderDetails(orderDetails);
        } else if (orderDetails && orderDetails.action) {
            this.setState({ selectedaction: orderDetails.action })
        }
    }

     //---------------------

    componentWillMount() {
        var requestData = {
            request: {
                data: {}
            }
        }
        getTrStreamFlag(requestData, this.parseTrStreamFlagResponse.bind(this), this.parseTrStreamFlagError.bind(this));
    }
    parseTrStreamFlagResponse(responseData){
        console.log("StreamerFlag-->"+responseData.response.data.trStreamStatus)
        localStorage.setItem("TR_STREAM_FLAG", responseData.response.data.trStreamStatus);
    }
    parseTrStreamFlagError(){
        console.log("StreamerFlag error data ===")
    }
    //----------------



    componentWillReceiveProps(nextProps) {
        let neworderDetails = nextProps.currentorderdetails;
        if (neworderDetails && neworderDetails.symobj) {
            this.setOrderDetails(neworderDetails);
        }
    }

    //SYMBOL SELECTION
    onSymbolSelected(data) {
        var orderdetails = {
            orderentrytype: this.state.orderentrytype,
            action: this.state.selectedaction,
            symobj: data,
            displaySym: data.id,
            company: '',
            last: "0.00",
            ltpclass: '',
            change: "0.00",
            changeper: "0.00"
        };
        this.props.setParentOrderDetails(orderdetails);
    }


    //================  Get Quote  =================//

    placeGetQuoteRequest(symobj, previousinputs) {

        console.log("quto flag");
        console.log(this.state.CdslFlag);
        this.setState({ inProgress: true, error: '' });
        var requestData = {
            request: {
                data: {
                    sym: symobj
                }
            }
        };
        window.socketEvents.unregister(this.tag);
        window.socketEventsTR.unregister(this.tag);
        getQuote(requestData, this.parseGetQuoteResponse.bind(this, previousinputs), this.parseGetQuoteError.bind(this));
    }

    parseGetQuoteResponse(previousinputs, responseData) {
        var quote = responseData.response.data.quote;
        var asks = responseData.response.data.asks;
        var bids = responseData.response.data.bids;
        // values set on flag bases
        this.setState({
            inProgress: false,
            error: '',
            symobj: quote.sym,
            expriDateSprd: quote.sym.calStretch,
            symbol: quote.displaySym ? quote.displaySym : quote.sym.id,

            company: quote.companyName,
            last: quote.ltp ? quote.ltp : '0.00',
            change: quote.chng ? quote.chng : '0.00',
            changeper: quote.chngPer ? quote.chngPer : '0.00',
            daily_price_range: quote.dpr,
            openPrice: quote.openPrice ? quote.openPrice : '0.00',
            highPrice: quote.highPrice ? quote.highPrice : '0.00',
            lowPrice: quote.lowPrice ? quote.lowPrice : '0.00',
            closePrice: quote.closePrice ? quote.closePrice : '0.00',
            lotsize: parseInt(quote.lot),
            ticksize: parseFloat(quote.tickSize),
            lotqualifier: quote.lotQualifier,

            pricequalifier: quote.priceQualifier,
            bestbuy: quote.bidQty + '  @   ₹ ' + quote.bidPrice,
            bestsell: quote.askQty + '  @   ₹ ' + quote.askPrice,
            bestbuyPrice: parseFloat(quote.bidPrice) !== 0 ? quote.bidPrice : quote.ltp,
            bestsellPrice: parseFloat(quote.askPrice) !== 0 ? quote.askPrice : quote.ltp,
            symDetails: { streamSym: quote.streamSym, sym: quote.sym },
            marketasks: asks ? asks : [],
            marketbids: bids ? bids : [],
            minPrice: parseFloat(quote.dpr.split(' ')[0]),
            maxPrice: parseFloat(quote.dpr.split(' ')[2]),
            totalbuyquantity: quote.totBuyQty,
            totalsellquantity: quote.totSellQty,
            lastUpdatedTime: quote.lUpdatedTime,
            survId: quote.survId ? quote.survId : '',
            survMsg: quote.survMsg ? quote.survMsg : ''
        }, () => {

            if (this.state.orderentrytype === orderEntryTypes.NEW || this.state.orderentrytype === orderEntryTypes.SQUAREOFF) {
                if (this.state.selectedaction !== TradeActions.BUY) {
                    let newPrice = quote.ltp;
                    this.setState({ priceInput: newPrice });
                }
                else {
                    let newPrice = quote.ltp;
                    this.setState({ priceInput: newPrice });
                }
            }
            // for non poa data 
            if (this.state.CdslFlag == 'CDSL' || this.state.CdslFlag == 'NSDL') {
                this.props.currentorderdetails.symobj.xSym = quote.sym.xSym;
                if (this.EDIS_RESPONSE == undefined) {
                    let newQuantity = this.state.NonPoaQTY;
                    this.setState({ quantity: newQuantity })
                }
                else {
                    window.setTimeout(() => {
                        let newQuantity = parseInt(this.state.NonPoaconfirmQTY) > 0 ? this.state.NonPoaconfirmQTY : this.state.NonPoaQTY;
                        this.setState({ quantity: newQuantity })
                    }, 500);
                    
                }
            }
            else {
                if (this.state.orderentrytype === orderEntryTypes.NEW) {
                    let newQuantity = previousinputs ? previousinputs.quantity : this.state.lotsize;
                    this.setState({ quantity: newQuantity })
                }
            }


            if (previousinputs) {
                this.setInputs(previousinputs);
            }

            // if (this.state.CdslFlag == 'CDSL' || this.state.CdslFlag == 'NSDL') {

            // }

            // else {
                this.onSocketRegister(this.state.symDetails);
                this.props.currentorderdetails.symobj = quote.sym;
                this.placeHoldingsPositionsRequest(quote.sym);
            // }
        });

        {
            (this.state.symobj &&
                ((this.state.symobj.instr === instrumentType.INDEX_FUTURE_SPREAD) || (this.state.symobj.instr === instrumentType.STOCK_FUTURE_SPREAD))) ?

                this.setState({
                    lotsize: parseInt(quote.lot),
                }, () => {
                    // if (this.state.lotsize) {
                    //     this.refs.Quantity.setValue(quote.lot);
                    // }

                    // if (this.state.PriDfernc) {
                    //     this.refs.pridfr.setValue(0);
                    // }
                })

                : ''
        }

    }

    parseGetQuoteError(error) {
        this.setState({ inProgress: false, error: error.message, last: '0.00', change: '0.00', changeper: '0.00' });
    }

    //SET ORDER DETAILS
    setOrderDetails(orderdetails, previousinputs) {

        this.setState({ orderentrytype: orderdetails.orderentrytype });
        if (orderdetails.action === TradeActions.BUY) {
            this.setState({ selectedaction: TradeActions.BUY });
        }
        else if (orderdetails.action === TradeActions.SELL) {
            this.setState({ selectedaction: TradeActions.SELL });
        }

        if (orderdetails.symobj) {
            if (orderdetails.symobj.instr === 'FUTCOM' || orderdetails.symobj.instr === 'OPTCOM' || orderdetails.symobj.instr === 'OPTFUT') {
                // Set MCX NCDEX instead of NSE BSE
                this.setState({
                    exchange_button1: isMCXPermitted() ? 'MCX' : undefined, exchange_button2: isNCDEXPermitted() ? 'NCDEX' : undefined,
                    isexchangehidden: false, isexchangedisabled: true
                }, () => {
                    if (orderdetails.symobj.exc === 'MCX') {
                        this.setState({ selectedExchangeIndex: "1" });
                    } else if (orderdetails.symobj.exc === 'NCDEX') {
                        this.setState({ selectedExchangeIndex: "2" });
                    }
                });
                this.setState({ isexchangehidden: false });
            }
            else if (orderdetails.symobj.instr === 'FUTCUR' || orderdetails.symobj.instr === 'OPTCUR') {
                // Hide switch
                this.setState({ isexchangehidden: true });
            }
            else if (orderdetails.symobj.instr == "FUTSTK" || orderdetails.symobj.instr === "OPTSTK"
                || orderdetails.symobj.instr == "FUTIDX" || orderdetails.symobj.instr === "FUTSTKSPR" || orderdetails.symobj.instr === "FUTIDXSPR" || orderdetails.symobj.instr === "OPTIDX") {
                if (orderdetails.symobj.exc === 'NFO') {
                   this.setState({ selectedExchangeIndex: "1" });
                   this.setState({ exchange_button1: 'NFO', exchange_button2: undefined}); }
               else if (orderdetails.symobj.exc === 'BFO') {
                   this.setState({ selectedExchangeIndex: "2" });
                   this.setState({ exchange_button1: undefined, exchange_button2: 'BFO'}); }
            }
            else {
                this.setState({ exchange_button1: 'NSE', exchange_button2: 'BSE', isexchangehidden: false, isexchangedisabled: (orderdetails.orderentrytype !== orderEntryTypes.NEW) }, () => {
                    if (orderdetails.symobj.exc === 'NSE') {
                        this.setState({ selectedExchangeIndex: "1" });
                    }
                    else if (orderdetails.symobj.exc === 'BSE') {
                        this.setState({ selectedExchangeIndex: "2" });
                    }
                });
            }
        }
        this.getShortsellPermisson(orderdetails.symobj ? orderdetails.symobj.exc : '');
        this.setState({
            symobj: orderdetails.symobj,
            symbol: orderdetails.displaySym,
            exchange: orderdetails.symobj ? orderdetails.symobj.exc : '',
            company: orderdetails.company,
            instr: orderdetails.symobj ? orderdetails.symobj.instr : '',
            last: orderdetails.last,
            change: orderdetails.change,
            changeper: orderdetails.changeper
        });

        if (orderdetails.symobj && orderdetails.symobj.id)
            this.placeGetQuoteRequest(orderdetails.symobj, previousinputs);
        this.applyDisplaySettings(orderdetails, () => {
            if (orderdetails.orderentrytype === orderEntryTypes.MODIFY) {
                this.setState({
                    disableAmo: true,
                    isAmo: orderdetails.correspondingorder.isAmo,
                    selectedindp: orderdetails.correspondingorder.isinDp
                });
                this.setCorrespondingOrder(orderdetails.correspondingorder);
                this.getTriggerPriceRange();
            } else if (orderdetails.orderentrytype === orderEntryTypes.SQUAREOFF) {
                this.setCorrespondingOrder(orderdetails.correspondingorder);
                this.setState({
                    maxQuantity: orderdetails.correspondingorder.quantity,
                    disableAmo: true
                })
            } else if (previousinputs) {
                this.setState({ isAmo: previousinputs.isAmo, selectedindp: previousinputs.inDp });
                this.setInputs(previousinputs);
            }
        });

    }

    //DISPLAY SETTINGS FOR ORDER INPUT
    applyDisplaySettings(orderdetails, callback) {

        //decide which fields to be hidden
        if (!orderdetails.symobj)
            return;
        let productTypes = [];
        let instr = orderdetails.symobj.instr;

        productTypes = productTypes_Equity;

        if (instr === instrumentType.STOCK_FUTURE ||
            instr === instrumentType.INDEX_FUTURE ||
            instr === instrumentType.INDEX_FUTURE_SPREAD ||
            instr === instrumentType.STOCK_FUTURE_SPREAD
        ) {
            productTypes = getFnOProductTypes();
        }
        else if (instr === instrumentType.CURRENCY_FUTURE ||
            instr === instrumentType.COMMODITY_FUTURE ||
            instr === instrumentType.STOCK_OPTIONS
            || instr === instrumentType.INDEX_OPTIONS
        ) {
            productTypes = productTypes_FUT;
        }
        else if (instr === instrumentType.CURRENCY_OPTIONS ||
            instr === instrumentType.COMMODITY_OPTIONS
            // || instr === instrumentType.INDEX_OPTIONS 
            // instr === instrumentType.STOCK_OPTIONS

        ) {
            productTypes = productTypes_OPT;
        }


        let tabs = productTypes;
        let selectedptype = productTypes[0].value;

        // PERMISSION: if marginmode is M, 
        // Margin should be selected.
        // if marginmode is A, Normal should be selected
        var marginmode = getMarginMode();
        var defselpsubtype = marginmode === 'M' ? 1 : 0;
        var sdefselsubtype = marginmode === 'M' ? '2' : '1';

        // PERMISSION: if mta for the exchange is Y, proceed with Margin.
        // else select Normal
        var mta = getMTA(orderdetails.symobj.exc);
        if (!mta) {
            defselpsubtype = 1;
            sdefselsubtype = '2';
        }

        if (orderdetails.symobj.instr === "STK") {
            //equity
            selectedptype = productTypes[1].value
            this.setState({
                tabs,//: pt_equity,
                selectedtab: assetClasses.equity, // : pt_equity[0],
                selectedptype,
                selectedpIndex: 1,
                showdiscquantity: true,
                visible_validity_types: validityTypes,
                quantity_label: "Quantity",
                discquantity_label: "Disclosed Qty",
                selectedordertype: orderTypes[0],
                selectedOrderTypeIndex: 0,
                selectedvaliditytype: validityTypes[0],
                selectedValidityIndex: 0,
                selectedAdvancedIndex: 0,
                selectedAdvancedType: advanceOrderTypes[0],
                isproductsubtypedisabled: !mta,
                disclosedInput: '0',
                slPrice: '0',
                quantity: '0'
            }, () => {
                callback();
            });
        }
        else if (orderdetails.symobj.instr == "FUTSTK" || orderdetails.symobj.instr === "OPTSTK"
            || orderdetails.symobj.instr == "FUTIDX" || orderdetails.symobj.instr === "OPTIDX"
            || orderdetails.symobj.instr === 'FUTSTKSPR' || orderdetails.symobj.instr === 'FUTIDXSPR') {
            //futures and options
            this.setState({
                tabs,//
                selectedtab: assetClasses.fno, //: pt_futopt[0],
                selectedptype,
                selectedpIndex: 0,
                showdiscquantity: false,
                visible_validity_types: validityTypes.slice(0, 2),
                quantity_label: "Quantity",
                discquantity_label: "Disclosed Qty",
                selectedordertype: orderTypes[0],
                selectedOrderTypeIndex: 0,
                selectedvaliditytype: validityTypes[0],
                selectedValidityIndex: 0,
                selectedAdvancedIndex: 0,
                selectedAdvancedType: advanceOrderTypes[0],
                disclosedInput: '0',
                slPrice: '0',
                quantity: '0'
            }, () => {
                callback();
            });
        }
        else if (orderdetails.symobj.instr === "FUTCUR" || orderdetails.symobj.instr === "OPTCUR") {
            // currency
            this.setState({
                tabs, // : pt_futopt,
                selectedtab: assetClasses.currency, //: pt_futopt[0],
                selectedptype,
                selectedpIndex: 0,
                showdiscquantity: true,
                visible_validity_types: validityTypes.slice(0, 2),
                quantity_label: "Lot",
                discquantity_label: "Disclosed Lot",
                selectedordertype: orderTypes[0],
                selectedOrderTypeIndex: 0,
                selectedvaliditytype: validityTypes[0],
                selectedValidityIndex: 0,
                selectedAdvancedIndex: 0,
                selectedAdvancedType: advanceOrderTypes[0],
                disclosedInput: '0',
                slPrice: '0',
                quantity: '0'
            }, () => {
                callback();
            });
        }
        else if (orderdetails.symobj.instr === "FUTCOM" || orderdetails.symobj.instr === "OPTCOM" || orderdetails.symobj.instr === "OPTFUT") {
            // commodity
            this.setState({
                tabs, // : pt_futopt,
                selectedtab: assetClasses.currency, //: pt_futopt[0],
                selectedptype,
                selectedpIndex: 0,
                showdiscquantity: true,
                visible_validity_types: validityTypes.slice(0, 2),
                quantity_label: "Lot",
                discquantity_label: "Disclosed Lot",
                selectedordertype: orderTypes[0],
                selectedOrderTypeIndex: 0,
                selectedvaliditytype: validityTypes[0],
                selectedAdvancedIndex: 0,
                selectedAdvancedType: advanceOrderTypes[0],
                disclosedInput: '0',
                slPrice: '0',
                quantity: '0'
            }, () => {
                callback();
            });
        }

        if (orderdetails.orderentrytype === orderEntryTypes.MODIFY || orderdetails.orderentrytype === orderEntryTypes.SQUAREOFF) {
            var corder = orderdetails.correspondingorder;
            var cptype = corder.producttype && corder.producttype.toLowerCase();
            var ptype = '';
            if (instr === 'STK') {
                // intraday
                if (productType[cptype.toUpperCase()] === productType.CO) {
                    ptype = productTypes_Equity[0].value;
                } else if (productType[cptype.toUpperCase()] === productType.BO) {
                    ptype = productTypes_Equity[0].value;
                } else if (cptype === productTypes_Equity[1].value || productType[cptype.toUpperCase()] === productType.SHORTSELL) {
                    ptype = productTypes_Equity[1].value;
                } else if (cptype === productTypes_Equity[2].value) {
                    ptype = productTypes_Equity[2].value;
                } else {
                    ptype = productTypes_Equity[0].value;
                }
            }
            else if ((instr.indexOf('FUT') !== -1 && instr !== 'OPTFUT') || (instr == 'OPTSTK' || instr == 'OPTIDX')) {
                ptype = productTypes_FUT[0].value; // carry forward
                if (cptype != productTypes_FUT[0].value) {
                    // intraday
                    ptype = productTypes_FUT[1].value
                }
            }
            else if (instr.indexOf('OPT') != -1) {
                ptype = productTypes_OPT[0].value;
            }

            var slDisable = (instr === instrumentType.COMMODITY || instr === instrumentType.COMMODITY_FUTURE
                || instr === instrumentType.COMMODITY_OPTIONS) && orderdetails.orderentrytype === orderEntryTypes.MODIFY
                && (orderdetails.correspondingorder && orderdetails.correspondingorder.ordertype === orderTypes[0].value)
                && (isNaN(orderdetails.correspondingorder.triggerprice) || orderdetails.correspondingorder.triggerprice === "" || parseFloat(orderdetails.correspondingorder.triggerprice) === 0);
            this.setState({
                selectedptype: ptype, isproducttypedisabled: true, isactiondisabled: true,
                symbolsearchvisible: false, isproductsubtypedisabled: true, slDisable: slDisable,
            }, () => {


                if (instr === 'STK') {
                    let disabledProductType = [];
                    if (cptype === productTypes_Equity[0].value || productType[cptype.toUpperCase()] === productType.CO
                        || productType[cptype.toUpperCase()] === productType.BO || productType[cptype.toUpperCase()] === productType.SHORTSELL) {
                        // intraday
                        disabledProductType.push(1);
                        disabledProductType.push(2);
                        this.setState({ selectedpIndex: 0, disabledPType: disabledProductType, disabledAdcTypes: true }, () => {
                            if (productType[cptype.toUpperCase()] === productType.CO) {
                                this.setState({ selectedAdvancedIndex: 1, selectedAdvancedType: advanceOrderTypes[1] });
                            } else if (productType[cptype.toUpperCase()] === productType.BO) {
                                this.setState({ selectedAdvancedIndex: 2, selectedAdvancedType: advanceOrderTypes[2] });
                            } else {
                                this.setState({ selectedAdvancedIndex: 0, selectedAdvancedType: advanceOrderTypes[0] });
                            }
                        });
                    }
                    else if (cptype === productTypes_Equity[1].value) {
                        // delivery
                        disabledProductType.push(0);
                        disabledProductType.push(2);
                        this.setState({ selectedpIndex: 1, disabledPType: disabledProductType, disabledAdcTypes: true });
                    } else {
                        disabledProductType.push(0);
                        disabledProductType.push(1);
                        this.setState({ selectedpIndex: 2, disabledPType: disabledProductType, disabledAdcTypes: true }, () => {
                            this.getMarginRequired();
                        });
                    }
                }
                else if ((instr.indexOf('FUT') !== -1 && instr !== 'OPTFUT') || (instr == 'OPTSTK' || instr == 'OPTIDX')) {
                    let disabledProductType = [];
                    if (cptype === productTypes_FUT[0].value) {
                        // carry forward

                        disabledProductType.push(1);
                        this.setState({ selectedpIndex: 0, disabledPType: disabledProductType, disabledAdcTypes: true });
                    }
                    else {
                        // intraday
                        disabledProductType.push(0);
                        this.setState({ selectedpIndex: 1, disabledPType: disabledProductType, disabledAdcTypes: true });
                        if (productType[cptype.toUpperCase()] === productType.CO) {
                            this.setState({ selectedAdvancedIndex: 1, selectedAdvancedType: advanceOrderTypes[1] });
                        } else if (productType[cptype.toUpperCase()] === productType.BO) {
                            this.setState({ selectedAdvancedIndex: 2, selectedAdvancedType: advanceOrderTypes[2] });
                        } else {
                            this.setState({ selectedAdvancedIndex: 0, selectedAdvancedType: advanceOrderTypes[0] });
                        }
                    }
                }
                else if (instr.indexOf('OPT') != -1) {
                    this.setState({ selectedpIndex: 0 });
                }

                if (corder.ordertype) {
                    if (corder.ordertype === orderTypes[0].value) {
                        this.setState({ selectedordertype: orderTypes[0], selectedOrderTypeIndex: 0 });
                    }
                    else {
                        this.setState({ selectedordertype: orderTypes[1], selectedOrderTypeIndex: 1, priceInput: '0' });
                    }
                }

                if (corder.validitytype) {
                    if (corder.validitytype === validityTypes[0].value) {
                        this.setState({ selectedValidityIndex: 0, selectedvaliditytype: validityTypes[0] });
                    }
                    else if (corder.validitytype === validityTypes[1].value) {
                        this.setState({ selectedValidityIndex: 1, selectedvaliditytype: validityTypes[1] });
                    }
                }
                if (productType[cptype.toUpperCase()] === productType.CO || (productType[cptype.toUpperCase()] === productType.BO)) {
                    //set disabled for cover order and bracket order
                    this.setState({ isdiscqtydisabled: true, slDisable: true })
                }
                if (productType[cptype.toUpperCase()] === productType.CO) {
                    this.setState({ isquantitydisabled: true });
                }
            });

        }
    }

    setCorrespondingOrder(corder) {
        if (!corder) return;
        this.setState({ correspondingorder: corder });
        if (corder.price && corder.price !== '') {
            this.setState({ priceInput: corder.price });
        }

        if (corder.quantity && corder.quantity !== '') {
            this.setState({ quantity: corder.quantity });
        }

        if (corder.discquantity && corder.discquantity !== '') {
            this.setState({ disclosedInput: corder.discquantity });
        }

        if (corder.triggerprice && corder.triggerprice !== '' && productType[corder.producttype] !== productType.BO &&
            productType[corder.producttype] !== productType.CO) {
            let slPrice = parseFloat(corder.triggerprice) === 0 ? '0' : corder.triggerprice;
            this.setState({ slPrice: slPrice });
        }
        if (corder.basketFlagNew == "mybasket") {
            this.setState({ selectedindp: corder.isinDp });
        }
        else {
            if (corder.producttype && corder.producttype.toUpperCase() === 'SHORTSELL') {
                this.setState({ selectedindp: false });
            } else {
                this.setState({ selectedindp: true });
            }
        }

        if (productType[corder.producttype] === productType.CO) {
            this.setState({ cvrTriggerPrice: (corder.triggerprice) }, () => {
            });
        }

        if (corder.slCoverDisable) {
            this.setState({ slCoverDisable: true });
        }

        if (corder.ispricedisabled) {
            this.isCoverLegModify = true;
            this.setState({ ispricedisabled: true });
        }

    }

    //SET INPUTS
    setInputs(previousinputs) {
        if (previousinputs.action) {
            this.setState({ selectedaction: previousinputs.action });
        }
        if (previousinputs.producttype) {
            let cptype;
            let ptype = '';
            let productIndex = 0;
            let instr = this.state.symobj.instr;
            if (previousinputs.producttype && previousinputs.producttype.value) {
                if (previousinputs.producttype === advanceOrderTypes[1] || previousinputs.producttype === advanceOrderTypes[2]) {
                    cptype = productTypes_Equity[0].value;
                    this.onAdvanceOrderTypeSelected(previousinputs.producttype.value);
                } else {
                    cptype = previousinputs.producttype && previousinputs.producttype.value;
                }
            }
            if (instr === 'STK') {
                if (cptype === productTypes_Equity[0].value) {
                    ptype = productTypes_Equity[0].value; // intraday
                    productIndex = 0;
                } else if (cptype === productTypes_Equity[1].value) {
                    ptype = productTypes_Equity[1].value; // cash
                    productIndex = 1;
                } else if (cptype === productTypes_Equity[2].value) {
                    ptype = productTypes_Equity[2].value; // margin
                    productIndex = 2;
                }
            }
            else if ((instr.indexOf('FUT') !== -1 && instr !== 'OPTFUT') || (instr == 'OPTSTK' || instr == 'OPTIDX')) {
                if (cptype === productTypes_FUT[0].value) {
                    ptype = productTypes_FUT[0].value// carry forward
                    productIndex = 0;
                } else if (cptype === productTypes_FUT[1].value) {
                    ptype = productTypes_FUT[1].value; // intraday
                    productIndex = 1;
                }
            }
            else if (instr.indexOf('OPT') != -1) {
                ptype = productTypes_OPT[0].value;
                productIndex = 0;
            }

            this.setState({ selectedptype: ptype, selectedpIndex: productIndex }, () => {
                this.getMarginRequired();
            });
        }

        if (previousinputs.quantity) {
            this.setState({ quantity: previousinputs.quantity });
        }

        if (previousinputs.price) {
            this.setState({ priceInput: previousinputs.price });
        }

        if (previousinputs.discquantity) {
            this.setState({ disclosedInput: previousinputs.discquantity });
        }

        if (!previousinputs.indp && previousinputs.action === TradeActions.SELL && previousinputs.symobj.assetClass === assetClasses.equity.toLowerCase()) {
            this.setState({ selectedindp: false });
        }
        if (previousinputs.ordertype) {
            var index = orderTypes.indexOf(previousinputs.ordertype);
            if (previousinputs.ordertype === orderTypes[0])
                this.setState({ selectedordertype: previousinputs.ordertype, selectedOrderTypeIndex: index, ispricedisabled: false });
            else if (previousinputs.ordertype === orderTypes[1]) {
                this.setState({ selectedordertype: previousinputs.ordertype, selectedOrderTypeIndex: index, ispricedisabled: true, priceInput: "0" });
            }
        }

        if (previousinputs.ordervalidity) {
            this.onValiditySelected(previousinputs.ordervalidity.value);
        }

        if (previousinputs.slprice) {
            this.setState({ slPrice: previousinputs.slprice });
        }

        if (previousinputs.coTriggerPrice) {
            this.setState({ cvrTriggerPrice: previousinputs.coTriggerPrice });
        }

        if (previousinputs.boTriggerPrice) {
            this.setState({ brcTriggerPrice: previousinputs.boTriggerPrice });
        }

        if (previousinputs.boTrailingSL) {
            this.setState({ trailingPrice: previousinputs.boTrailingSL });
        }

        if (previousinputs.boTargetPrice) {
            this.setState({ targetPrice: previousinputs.boTargetPrice });
        }

        if (previousinputs.producttype === advanceOrderTypes[1] || previousinputs.producttype === advanceOrderTypes[2]) {
            this.setState({ isdiscqtydisabled: true });
        }

        if (previousinputs.isCoverLegModify) {
            this.setState({ ispricedisabled: true });
        }
    }

    //BUY - SELL ACTIONS
    onActionSelected = (selected) => {
        if (selected === '1') {
            this.setState({ selectedaction: TradeActions.BUY }, () => {
                if (this.state.selectedAdvancedType === advanceOrderTypes[1]) {
                    this.getTriggerPriceRange();
                }
                if (parseFloat(this.state.requiredMargin) === 0) {
                    this.getMarginRequired();
                }
            });
        }
        else if (selected === '2') {
            this.setState({ selectedaction: TradeActions.SELL }, () => {
                if (this.state.selectedAdvancedType === advanceOrderTypes[1]) {
                    this.getTriggerPriceRange();
                }
            });
        }
    }

    onExchangeSelected(selected) {
        //TODO: get implementation details.
        var marginmode = getMarginMode();
        var defselpsubtype = marginmode === 'M' ? 1 : 0;
        var sdefselsubtype = marginmode === 'M' ? '2' : '1';
        if (selected === '1') {
            var mta = getMTA('NSE');
            if (!mta) {
                defselpsubtype = 1;
                sdefselsubtype = '2';
            }
            this.setState({
                exchange: 'NSE',
                isproductsubtypedisabled: !mta
            }, () => {
                var obj = this.state.symobj;
                obj.exc = this.state.exchange;
                if(obj.assetClass === 'equity'){
                obj.exc_seg = "nse_cm";
                obj.trExc = "NSE";
                var symbol = obj.trSymbol;
                obj.trSymbol = symbol.match("-EQ") !== null ? symbol : symbol+"-EQ";
                var curObjDetails = this.props.currentorderdetails.symobj;
                curObjDetails.exc = this.state.exchange;
                curObjDetails.exc_seg = "nse_cm";
                curObjDetails.trExc = "NSE";
                this.props.currentorderdetails.symobj = curObjDetails;
                }
                this.placeGetQuoteRequest(obj, undefined);
                let clickMktDpt = document.getElementsByClassName("nav-main-link")[0]; 
                if(clickMktDpt.text === "MARKET DEPTH"){
                    clickMktDpt.click();
                }
            });
            this.getShortsellPermisson('NSE');
        }
        else if (selected === '2') {
            var mta = getMTA('BSE');
            if (!mta) {
                defselpsubtype = 2;
                sdefselsubtype = '1';
            }
            this.setState({
                exchange: 'BSE',
                isproductsubtypedisabled: !mta
            }, () => {
                var obj = this.state.symobj;
                obj.exc = this.state.exchange;
                if(obj.assetClass === 'equity'){
                obj.exc_seg = "bse_cm";
                obj.trExc = "BSE";
                var symbol = obj.trSymbol;
                obj.trSymbol =  symbol !== undefined ? symbol.match("-EQ") !== null ? symbol.substring(0, symbol.length-3) : symbol: "";
                var curObjDetails = this.props.currentorderdetails.symobj;
                curObjDetails.exc = this.state.exchange;
                curObjDetails.exc_seg = "bse_cm";
                curObjDetails.trExc = "BSE";
                this.props.currentorderdetails.symobj = curObjDetails;
                }
                this.placeGetQuoteRequest(obj, undefined);
                let clickMktDpt = document.getElementsByClassName("nav-main-link")[0]; 
                if(clickMktDpt.text === "MARKET DEPTH"){
                    clickMktDpt.click();
                }
            });
            this.getShortsellPermisson('BSE')
        }

    }

    getShortsellPermisson(exchangeType) {
        var shortSellPer = getShortsell(exchangeType);
        this.setState({ shortSellPermisson: shortSellPer });
    }

    // basket request

    AddBasketRequest() {

        let orderinputs = this.frameOrderInputs();

        let error, priceError;
        if (this.state.orderentrytype === orderEntryTypes.MODIFY &&
            (this.state.selectedAdvancedIndex === 2 || this.state.selectedAdvancedIndex === 1)) {
            error = areValidOrderInputs(orderinputs);
        } else {
            error = areValidOrderInputs(orderinputs);
        }

        priceError = priceValidation(this.state.minPrice, this.state.maxPrice, orderinputs);

        if (error) {
            this.props.showErrorDialog({ ShowBaketList: false, header: "Place Order", message: error });
        } else if (priceError) {
            this.props.showErrorDialog({ ShowBaketList: false, header: "Place Order", message: priceError });
        }
        else {
            {
                this.state.orderentrytype == orderEntryTypes.MODIFY ?

                    this.BasketModifyOrder(this.props.currentorderdetails)
                    :
                    this.showbasketListData();
            }
        }
    }

    // basket modify order
    BasketModifyOrder(orderDetails) {
        this.BasketRequest(orderDetails);

    }
    // end basket modify order

    showbasketListData() {
        this.setState({
            ShowBaketList: true,
            showDialog: false,
        })

    }


    closeDialog() {
        this.setState({
            ShowBaketList: false
        })

    }

    BasketAddRequest(selectedid) {
        let orderinputs = this.frameOrderInputs();
        this.BasketRequest(orderinputs, selectedid);
    }

    BasketRequest(orderinputs, selectedid) {
        var basketdata;

        basketdata = this.basketdataRequest(orderinputs, selectedid);
        var requestData = {
            request: {
                data: basketdata
            }
        };

        {
            this.state.orderentrytype == orderEntryTypes.MODIFY ?
                ModifyBasketrequest(requestData, this.parseBasketSymbolResponse.bind(this), this.parseBasketSymbolError.bind(this))
                :
                AddBasketSymbolrequest(requestData, this.parseBasketSymbolResponse.bind(this), this.parseBasketSymbolError.bind(this));
        }
    }

    parseBasketSymbolResponse(responseData) {
        this.setState({
            message: responseData.response.data.data
        }, () => {
            this.ontoggleButton("N");
        })

    }
    // on positive click
    OnPositiveClick() {
        this.setState({
            status: 'N'
        }, () => {
            this.showbasketListData();
        })
    }
    ontoggleButton(status) {
        this.setState({
            showDialog: true,
            ShowBaketList: false,
            status: status,
            message: <p>{this.state.message}</p>
        }, () => {
        });
    }

    closePopup() {
        this.setState({
            showDialog: false,
            error: false,
            mainDialog: false
        }, () => {
            this.props.onNegativeButtonClick();
        });
        window.sessionStorage.removeItem(localStorageKeys.EDIS_RESPONSE);
        this.props.history.push("/home/basketorder");
    }

    parseBasketSymbolError(error) {
        this.setState({
            message: error.message
        }, () => {
            this.ontoggleButton();
        })

    }

    basketdataRequest(orderinputs, selectedid) {
        var data = {};
        if (this.state.orderentrytype == orderEntryTypes.MODIFY) {
            data.uniqueId = orderinputs.correspondingorder.orderid,
                data.sym = orderinputs.symobj;
            data.qty = this.state.quantity ? this.state.quantity.toString() : '';
            data.limitPrice = this.state.priceInput == null ? '0.00' : this.state.priceInput;
            data.ordType = this.state.selectedordertype.value;
            data.prdType = orderinputs.correspondingorder.producttype;
            data.ordAction = orderinputs.action;
            data.ordDuration = this.state.selectedvaliditytype.value
            data.triggerPrice = this.state.slPrice == null ? '0' : this.state.slPrice;
            data.disQty = this.state.disclosedInput == '0' ? null : this.state.disclosedInput
            data.displaySym = orderinputs.sprip,
                data.isinDp = this.state.selectedindp
        }

        else {
            data.sym = orderinputs.symobj;
            data.qty = orderinputs.quantity ? orderinputs.quantity.toString() : '';
            data.limitPrice = orderinputs.price == null ? '0.00' : orderinputs.price;
            data.priceDiff = orderinputs.price;
            data.ordType = orderinputs.ordertype.value;
            data.prdType = orderinputs.producttype.value;
            data.id = selectedid;
            data.ordAction = orderinputs.action;
            data.ordDuration = orderinputs.ordervalidity.value
            data.triggerPrice = orderinputs.slprice == null || orderinputs.slprice == '0' ? '0' : orderinputs.slprice
            data.disQty = orderinputs.discquantity == null ? '0' : orderinputs.discquantity
            data.displaySym = this.state.symbol
            data.status = this.state.status,
                data.isinDp = this.state.selectedindp
        }
        return data;

    }


// in dp close popup
    IndpclosePopup(){
        this.setState({
            InDpshowDialog: false
        }, ()=>{
            setTimeout(() => {
                document.getElementById("nonpoa").click();
            }, 100);
        })
        
        
    }

    onPlaceOrder() {

        if(parseInt(this.state.NonPoaconfirmQTY) <= 0 && this.state.selectedindp == true && this.EDIS !== undefined && this.EDIS !== null 
        && parseInt( this.state.NonPoaQTY) > 0 && this.state.selectedptype !== "intraday" && this.state.selectedptype !== "margin" && this.state.selectedaction == 'SELL') 
        {
            this.setState({
                InDpshowDialog: true
            })
        }
        else{
            let orderinputs = this.frameOrderInputs();
            console.log(orderinputs);
            let error, priceError;
            if (this.state.orderentrytype === orderEntryTypes.MODIFY &&
                (this.state.selectedAdvancedIndex === 2 || this.state.selectedAdvancedIndex === 1)) {
                error = areValidOrderInputs(orderinputs);
            } else {
                error = areValidOrderInputs(orderinputs);
            }

            priceError = priceValidation(this.state.minPrice, this.state.maxPrice, orderinputs);
            if (!error && !priceError) {
                if (this.props.onPlaceOrderClick)
                    this.props.onPlaceOrderClick(orderinputs);
            } else if (error) {
                this.props.showErrorDialog({ header: "Place Order", message: error });
            } else if (priceError) {
                this.props.showErrorDialog({ header: "Place Order", message: priceError });
            } else {
                this.props.showErrorDialog({ header: "Place Order", message: error });
            }
        }
        window.sessionStorage.removeItem(localStorageKeys.EDIS_RESPONSE);
    }

    getEDISData = () => {

        console.log(this.props.currentorderdetails);
        var fetchOrder = getItemByKey(localStorageKeys.USER_EDIS_DETAILS);
        if (fetchOrder !== undefined && fetchOrder !== "undefined") {
            var orderType = JSON.parse(fetchOrder);
            console.log("orderInput -----")
            console.log(orderType);
            if (orderType !== undefined && orderType !== null) {
                return orderType;
            }
            else {
                return orderType;
            }


        }
        // return "NSDL";
    }

    showEDISResponse() {

        var orderResponse = JSON.parse(getItemByKey(localStorageKeys.EDIS_RESPONSE));
        console.log("edis responce data");
        console.log(orderResponse)
        if ((orderResponse !== undefined && orderResponse !== null) && (orderResponse.Flag == 'F' || orderResponse.Flag == 'S')) {
            setTimeout(() => {
                document.getElementById("nonpoa").click();
            }, 1000);

            this.setState({
                CdslFlag: 'CDSL'
            }, () => {
                console.log("flag name");
                console.log(this.state.CdslFlag);
            })
        }
        return orderResponse;
    }

    frameOrderInputs = () => {
        var orderinputs = {};
        orderinputs.orderentrytype = this.state.orderentrytype;
        orderinputs.action = this.state.selectedaction;
        orderinputs.symobj = this.state.symobj;
        orderinputs.scrip = this.state.symbol;
        orderinputs.isAmo = this.state.isAmo;
        orderinputs.minPrice = this.state.minPrice;
        orderinputs.maxPrice = this.state.maxPrice;
        orderinputs.HoldingQTY = this.state.NonPoaconfirmQTY;
        orderinputs.NonPoaFlag = this.state.CdslFlag;
        orderinputs.indpselectedpin = this.state.selectedptype;
        orderinputs.ltp = this.state.last;
        orderinputs.lotqualifier=this.state.lotqualifier;
        orderinputs.survId=this.state.survId;
        orderinputs.survMsg=this.state.survMsg;
        if (this.state.symobj.instr === 'STK') {
            if (this.state.selectedptype === productTypes_Equity[0].value) {
                // intraday
                // orderinputs.producttype = productTypes_Equity[0];
                if (this.state.selectedAdvancedType === advanceOrderTypes[1]) {
                    orderinputs.producttype = advanceOrderTypes[1];
                } else if (this.state.selectedAdvancedType === advanceOrderTypes[2]) {
                    orderinputs.producttype = advanceOrderTypes[2];
                } else {
                    orderinputs.producttype = productTypes_Equity[0];
                }
            }
            // delivery
            else if (this.state.selectedptype === productTypes_Equity[1].value) {
                orderinputs.producttype = productTypes_Equity[1];
            } else if (this.state.selectedptype === productTypes_Equity[2].value) {
                //margin
                orderinputs.producttype = productTypes_Equity[2];
            }
        } else if ((this.state.symobj.instr.indexOf('FUT') !== -1 && this.state.symobj.instr !== 'OPTFUT') || (this.state.symobj.instr == 'OPTSTK' || this.state.symobj.instr == 'OPTIDX')
        ) {
            if (this.state.selectedptype === productTypes_FUT[0].value) {
                // carry forward
                orderinputs.producttype = productTypes_FUT[0];
            }
            else {
                // intraday
                // orderinputs.producttype = productTypes_FUT[1];
                if (this.state.selectedAdvancedType === advanceOrderTypes[1]) {
                    orderinputs.producttype = advanceOrderTypes[1];
                } else if (this.state.selectedAdvancedType === advanceOrderTypes[2]) {
                    orderinputs.producttype = advanceOrderTypes[2];
                } else {
                    orderinputs.producttype = productTypes_FUT[1];
                }
            }
        } else if (this.state.symobj.instr.indexOf('OPT') !== -1) {
            orderinputs.producttype = productTypes_OPT[0];
        }

        orderinputs.quantity_label = this.state.quantity_label;
        orderinputs.quantity = this.state.quantity;

        if (this.state.selectedaction === TradeActions.SELL && this.state.symobj.instr === 'STK' &&
            this.state.selectedptype !== productTypes_Equity[0].value) {
            if (this.state.selectedindp) {
                orderinputs.indp = true;
                orderinputs.maxquantity = this.state.maxQuantity; //WARNING: get actual value from quote request
            }
            else {
                orderinputs.indp = false;
            }
        }

        if (this.state.orderentrytype === orderEntryTypes.SQUAREOFF) {
            if (this.state.selectedaction === TradeActions.SELL && this.state.selectedindp) {
                orderinputs.indp = true;
            }
            orderinputs.isSquareoff = true;
        } else {
            orderinputs.isSquareoff = false;
        }

        if (this.state.selectedordertype === orderTypes[0]) // only for Limit orders 
        {
            orderinputs.price = this.state.priceInput;
        }
        orderinputs.ticksize = this.state.ticksize;
        orderinputs.ordertype = this.state.selectedordertype;
        orderinputs.ordervalidity = this.state.selectedvaliditytype;

        if (this.state.selectedvaliditytype === validityTypes[2]) {
            orderinputs.vtddate = this.state.vtddate;
        }

        if (this.state.showdiscquantity) {
            var discquantity = this.state.disclosedInput;
            if (parseInt(discquantity) !== 0) {
                orderinputs.discquantity_label = this.state.discquantity_label;
                orderinputs.discquantity = discquantity;
            }
        }
        orderinputs.requiredlotsize = this.state.lotsize;

        //triggger price for cover order
        if (this.state.slPrice && this.state.selectedAdvancedType === advanceOrderTypes[0]) {
            orderinputs.slprice = this.state.slPrice;
        }

        //cover ltp trigger price
        if (this.state.selectedAdvancedType === advanceOrderTypes[1]) {
            orderinputs.coTriggerPrice = this.state.cvrTriggerPrice;
            orderinputs.minCvrTriggerPrice = this.state.minCvrTrgrPrice;
            orderinputs.maxCvrTriggerPrice = this.state.maxCvrTrgrPrice;
            if (this.isCoverLegModify) {
                orderinputs.isCoverLegModify = true;
            }
        }
        //bracket order inputs
        if (this.state.selectedAdvancedType === advanceOrderTypes[2]) {
            orderinputs.boTriggerPrice = this.state.brcTriggerPrice;
            orderinputs.boTargetPrice = this.state.targetPrice;
            orderinputs.boTrailingSL = this.state.trailingPrice;
        }
        return orderinputs;
    }

    isPlaceButtonDisabled() {
        window.sessionStorage.removeItem(localStorageKeys.EDIS_RESPONSE);
    }

    marketHoldingsToggle(selectedTab) {
        this.setState({ selectedMarketTab: selectedTab });
        if(selectedTab === marketAndHoldingsTab.HOLDINGS_POSITIONS && (Object.keys(this.state.holdingsdata).length === 0)){
        this.setState({ inProgress: true, holdings_error: '', holdingsdata: {} });

        var requestData = {
            request: {
                data: {
                    "sym": this.props.currentorderdetails.symobj
                }
            }
        };
        getHoldingsPositions(requestData, this.parseHoldingsSummaryResponse.bind(this), this.parseHoldingsPositionsError.bind(this));
        }
    }

    parseHoldingsSummaryResponse(responseData){
        this.setState({
            inProgress: false,
            holdings_error: '',
            holdingsdata: responseData.response.data
        });
    }

    

    //================  Stream Functions  =================//
    onSocketRegister = (data) => {
             
        if (localStorage.getItem("TR_STREAM_FLAG") === "N") {
            console.log("IVL Stream register-->"+this.tag)
        window.socketEvents.register(data.streamSym + data.sym.exc, { screenName: this.tag, sym: data.streamSym, exc: data.sym.exc }, this.onStreamCallback)
        setTimeout(function () {
            window.socketEvents.subscribe();
        }, 1000)
        } else { 
            console.log("TR Stream register-->OrderInput")           
            window.socketEventsTR.register(data.sym.xSym, { screenName: this.tag, sym: data.sym.xSym, exc: data.sym.exc_seg }, this.onStreamCallbackTR)
            setTimeout(function () {
                window.socketEventsTR.subscribe();
            }, 1000)
        }    
    }


    onStreamCallbackTR = (streamData) => {
        //console.log("OrderEntry-->this.state.quoteobj-->"+JSON.stringify(this.state.symDetails))
       
        if(this.state.symDetails.sym.xSym===streamData.data.xSym){         
            this.setState({
                last: streamData.data.ltp?streamData.data.ltp:this.state.last,
                ltpclass: streamData.data.ltp?this.getLtpClass(streamData.data.ltp, this.state.last, this.state.ltpclass):this.state.ltpclass,
                change: streamData.data.change?calcChange(streamData.data):this.state.change,
                changeper: streamData.data.perchange?calcChangePercentage(streamData.data):this.state.changeper,
                bestbuy: (streamData.data.bidQty&&streamData.data.bidPrice)?(streamData.data.bidQty + '  @   ₹ ' + streamData.data.bidPrice):this.state.bestbuy,
                bestsell:(streamData.data.askQty&&streamData.data.askPrice) ?(streamData.data.askQty + '  @   ₹ ' + streamData.data.askPrice):this.state.bestsell,
                daily_price_range: (streamData.data.lDPR && streamData.data.hDPR) ? streamData.data.lDPR + " - " + streamData.data.hDPR : this.state.daily_price_range,
                minPrice: streamData.data.lDPR? streamData.data.lDPR : this.state.minPrice,
                maxPrice: streamData.data.hDPR? streamData.data.hDPR : this.state.maxPrice,
                // totalbuyquantity: streamData.data.totBuyQty,
                // totalsellquantity: streamData.data.totSellQty
                openPrice: streamData.data.openPrice? streamData.data.openPrice : this.state.openPrice,
                highPrice: streamData.data.highPrice? streamData.data.highPrice : this.state.highPrice,
                lowPrice: streamData.data.lowPrice? streamData.data.lowPrice : this.state.lowPrice,
                closePrice: streamData.data.closePrice? streamData.data.closePrice : this.state.closePrice,
                lastUpdatedTime:streamData.data.lUpdatedTime?streamData.data.lUpdatedTime:this.state.lastUpdatedTime
            })
        }
    }


    //STREAM CALLBACK
    onStreamCallback = (streamData) => {
        if (this.state.symDetails.streamSym === streamData.data.sym && this.state.symDetails.sym.exc === streamData.data.exc) {
            this.setState({
                last: streamData.data.ltp,
                ltpclass: this.getLtpClass(streamData.data.ltp, this.state.last, this.state.ltpclass),
                change: calcChange(streamData.data),
                changeper: calcChangePercentage(streamData.data),
                bestbuy: streamData.data.bidQty + '  @   ₹ ' + streamData.data.bidPrice,
                bestsell: streamData.data.askQty + '  @   ₹ ' + streamData.data.askPrice,
                daily_price_range: (streamData.data.lDPR != null && streamData.data.hDPR != null) ? streamData.data.lDPR + " - " + streamData.data.hDPR : this.state.daily_price_range,
                minPrice: streamData.data.lDPR != null ? streamData.data.lDPR : this.state.minPrice,
                maxPrice: streamData.data.hDPR != null ? streamData.data.hDPR : this.state.maxPrice,
                // totalbuyquantity: streamData.data.totBuyQty,
                // totalsellquantity: streamData.data.totSellQty
                openPrice: streamData.data.openPrice !== null ? streamData.data.openPrice : this.state.openPrice,
                highPrice: streamData.data.highPrice !== null ? streamData.data.highPrice : this.state.highPrice,
                lowPrice: streamData.data.lowPrice !== null ? streamData.data.lowPrice : this.state.lowPrice,
                closePrice: streamData.data.closePrice !== null ? streamData.data.closePrice : this.state.closePrice,
            })
        }
    }

    getLtpClass(current, prev, prevclass) {
        var diff = (current - prev);
        if (diff === 0)
            // return 'ltp-plain';
            return prevclass;

        var toclass = prevclass;

        if (diff > 0)
            toclass = 'ltp-anim-pos';
        else if (diff < 0)
            toclass = 'ltp-anim-neg';

        return toclass;
    }

    //================  Get Holdings/Positions  =================//

    placeHoldingsPositionsRequest(symobj) {
        this.setState({ inProgress: true, holdings_error: '', holdingsdata: {} });

        var requestData = {
            request: {
                data: {
                    "sym": symobj
                }
            }
        };

        getHoldingsSummaryBySymbol(requestData, this.parseHoldingsPositionsResponse.bind(this), this.parseHoldingsPositionsError.bind(this));
    }

    parseHoldingsPositionsResponse(responseData) {
        console.log("holdings data");
        console.log(responseData);

        var dpq = 0;
        try {
            dpq = responseData.response.data.holdings.cash.dp;
        }
        catch (ex) {
            dpq = '-'
        }
        this.setState({
            inProgress: false,
            holdings_error: '',
            dpquantity: dpq,
            // holdingsdata: responseData.response.data,
            NonPoaconfirmQTY: responseData.response.data.holdings.cash.confNonPoaQty,
            NonPoaQTY: responseData.response.data.holdings.cash.nonPoaQty,
            cashPositions: responseData.response.data.positions.cash.qty
        });
    }

    parseHoldingsPositionsError(error) {
        this.setState({ inProgress: false, holdings_error: error.message });
    }

    showMTFInfo = () => {
        let payload = {
            showMTFDefnition: true
        }
        this.props.showMTFDefnition(payload);
    }

    //================  On Change Events  =================//
    priceOnChange(price) {
        this.setState({ priceInput: price }, () => {
            this.getMarginRequired();
        });
    }

    quantityOnChange(quantity) {
        this.setState({ quantity: quantity }, () => {
            this.getMarginRequired();
        });
    }
    quantityOnChangeSread(quantity) {
        this.setState({ quantity: quantity }, () => {
        });
    }

    disclosedOnChange(discPrice) {
        this.setState({ disclosedInput: discPrice });
    }

    slpriceOnChange(slprice) {
        if (parseFloat(slprice) > 0) {
            this.setState({ slPrice: slprice }, () => {
                this.onValiditySelected(validityTypes[0].value);
            });
        } else {
            this.setState({ slPrice: slprice });
        }
    }

    slpriceCoverOnchange = (price) => {
        this.setState({ cvrTriggerPrice: price })
    }

    slpriceBracketOnchange = (price) => {
        this.setState({ brcTriggerPrice: price });
    }

    targetPriceOnchange = (price) => {
        this.setState({ targetPrice: price });
    }

    trailingSlOnchange = (price) => {
        this.setState({ trailingPrice: price });
    }

    handleAmoCheckbox() {
        this.setState({ isAmo: !(this.state.isAmo) })
    }

    onProductTypeSelected(selected) {
        console.log("se;ected=====");
        console.log(selected);

        let cptype = selected;
        let ptype = '';
        let productIndex = 0;
        let instr = this.state.symobj.instr;
        if (instr === 'STK') {
            if (cptype === productTypes_Equity[0].value) {
                ptype = productTypes_Equity[0].value; // intraday
                productIndex = 0;
            } else if (cptype === productTypes_Equity[1].value) {
                ptype = productTypes_Equity[1].value; // cash
                productIndex = 1;
                this.setState({isAmo:false})
                this.onAdvanceOrderTypeSelected(advanceOrderTypes[0].value);
            } else if (cptype === productTypes_Equity[2].value) {
                if (getMTA('NSE')) {
                    ptype = productTypes_Equity[2].value; // margin
                    productIndex = 2;
                    this.onAdvanceOrderTypeSelected(advanceOrderTypes[0].value);
                } else {
                    this.setState({ selectedpIndex: (this.state.selectedpIndex) });
                    this.activateMTF();
                    return;
                }
            }
        }
        else if ((instr.indexOf('FUT') !== -1 && instr !== 'OPTFUT') || (instr == 'OPTSTK' || instr == 'OPTIDX')) {
            if (cptype === productTypes_FUT[0].value) {
                ptype = productTypes_FUT[0].value// carry forward
                productIndex = 0;
                this.onAdvanceOrderTypeSelected(advanceOrderTypes[0].value);
                //this.setState({ selectedAdvancedIndex: 0, selectedAdvancedType: advanceOrderTypes[0] });
            } else if (cptype === productTypes_FUT[1].value) {
                ptype = productTypes_FUT[1].value; // intraday
                productIndex = 1;
                if ((instr == 'OPTSTK' || instr == 'OPTIDX')) {
                    this.onAdvanceOrderTypeSelected(advanceOrderTypes[0].value);
                    this.setState({
                        disabledAdcTypesState: this.getAdvanceTypeDisabled()
                    }, () => {
                    });
                }

            }
        }
        else if (instr.indexOf('OPT') != -1) {
            this.onAdvanceOrderTypeSelected(advanceOrderTypes[0].value);
            ptype = productTypes_OPT[0].value;
            productIndex = 0;
        }

        this.setState({ selectedptype: ptype, selectedpIndex: productIndex }, () => {
            if (this.state.selectedptype === productTypes_Equity[2].value) {
                this.getMarginRequired();
            }
        });
    }

    onOrderTypeSelected = (selected) => {
        var item = orderTypes.filter(i => i.value === selected)[0];
        if (item === orderTypes[0]) // limit
            this.setState({ selectedOrderTypeIndex: 0, selectedordertype: item, ispricedisabled: false }, () => {
                if (this.state.orderentrytype === orderEntryTypes.NEW || this.state.orderentrytype === orderEntryTypes.SQUAREOFF) {
                    this.setState({ priceInput: this.state.last }, () => {
                        this.getMarginRequired();
                    });
                }
                else {
                    if (this.state.correspondingorder)
                        this.setState({ priceInput: this.state.correspondingorder.price }, () => {
                            this.getMarginRequired();
                        });
                }
            });
        else if (item === orderTypes[1]) // market
            this.setState({ selectedOrderTypeIndex: 1, selectedordertype: item, ispricedisabled: true }, () => {
                this.setState({ priceInput: '0' }, () => {
                    this.getMarginRequired();
                });
            });
    }

    onValiditySelected(selected) {
        var item = validityTypes.filter(i => i.value === selected)[0];

        if (item === validityTypes[1]) { // ioc
            this.setState({ isdiscqtydisabled: true, selectedValidityIndex: 1 }, () => {
                this.setState({ disclosedInput: '0' });
            });
        }
        else if (item === validityTypes[0]) { // day
            this.setState({ isdiscqtydisabled: false, selectedValidityIndex: 0 });
        }

        this.setState({ selectedvaliditytype: item });
    }

    onDPChanged(selected) {
        console.log("selected=======");
        console.log(selected);
        if (selected === '1') {
            this.setState({
                selectedindp: true,
                CdslFlag: 'abcd'
            }, () => {
                this.InputSetNonPoa()
            });
        }
        else if (selected === '2') {
            this.setState({
                selectedindp: false,
                CdslFlag: 'notindp'
            }, () => {
                this.InputSetNonPoa()
            });
        }
        else if (selected === '3') {
            this.setState({
                selectedindp: true,
                CdslFlag: this.EDIS.demats[0].dpType
            }, () => {
                this.InputSetNonPoa()
            })
        }
    }

    InputSetNonPoa(flag) {
        this.placeGetQuoteRequest(this.props.currentorderdetails.symobj, undefined);
    }

    // dp ides======
    SubmitEdis() {
        console.log("confNonPoaQty");
        if (this.EDIS.demats[0].dpApiStatus == 'N') {
            console.log("heloo  failer case")
            var msg = "";
            if(this.EDIS.demats[0].dpType == 'CDSL'){
            msg = "CDSL-EDIS Service currently not avaliable";
            }else if(this.EDIS.demats[0].dpType == 'NSDL'){
                msg = "NSDL-EDIS Service currently not avaliable";
            }
            this.props.showErrorDialog({ header: "Api Status", message: (msg) });
        }

        else {
            var MyEdisDetails = this.props.currentorderdetails
            this.EDISData(MyEdisDetails)
            console.log("UserEdisDetails")
            console.log(this.state.UserEdisDetails)
            window.sessionStorage.setItem(localStorageKeys.SymbolOBJ, checkenableEncrypt(MyEdisDetails));
        }
    }

    EDISData(MyEdisDetails) {
        
        var requestData = {
            request: {
                dpId: this.EDIS.demats[0].dpID,
                clientId: this.EDIS.demats[0].dpAccount,
                symbolId: MyEdisDetails.symobj.xSym,
                exchangeId: MyEdisDetails.symobj.exc,
                companyName: '',
                source: 'W',
                appKey: getCookie('JSESSIONID'),
                appName: '',
                reqType: 'DEFAULT_REQ',
                clientId1: this.EDIS.clientId,
                quantity: this.state.NonPoaQTY
            }
        };
        DpIDSrequest(requestData);
        console.log("my edis data==" + JSON.stringify(requestData));
        console.log(MyEdisDetails)
    }
    // forget tpin cdsl===
    forgetTpinCdsl(){
        var accountDet = JSON.parse(getItemByKey(localStorageKeys.ACCOUNT_ID));
        var requestData = {
            request: {
                data: {
                    clientId: accountDet ? accountDet.accID : '',
                    source:'web'
                }
            }
        };
        forgetTpinCdsl(requestData, this.parseForgettpinResponse.bind(this), this.parseForgettpinError.bind(this));
    }

    parseForgettpinResponse(responseData){
        console.log("forget tpin responseData");
        console.log(responseData);
        this.setState({
            ForgetTpiStatus:responseData.response.data.STATUS,
            ForgetMessageTpin:responseData.response.data.MESSAGE
        })
        var msg = "";
        if(this.state.ForgetTpiStatus == 'S'){
            msg = "TPIN generate successfully, Kindly check your mail and SMS"
        }
        else{
            msg = this.state.ForgetMessageTpin
        }
        this.props.showErrorDialog({ header: "CDSL Message", message: (msg) });
    }
    parseForgettpinError(error){
        this.setState({
            error: "Somthing went wrong"
        })
        this.props.showErrorDialog({ header: "Message", message: (this.state.error) });
    }

    onAdvanceOrderTypeSelected = (selected) => {
        var item = advanceOrderTypes.filter(i => i.value === selected)[0];

        if (item === advanceOrderTypes[0]) { // regular
            this.setState({
                selectedAdvancedIndex: 0, slDisable: false,
                disableAmo: false, isdiscqtydisabled: false,

            }, () => {

            });
        } else if (item === advanceOrderTypes[1]) { // cover order
            this.setState({
                selectedAdvancedIndex: 1, slDisable: true, disableAmo: true, isdiscqtydisabled: true,
                isAmo: false, selectedValidityIndex: 0, selectedvaliditytype: validityTypes[0], slPrice: "0",
                disclosedInput: '0'
            }, () => {
                this.getTriggerPriceRange();
            });
        } else if (item === advanceOrderTypes[2]) { // bracket order
            this.setState({
                selectedAdvancedIndex: 2,
                slDisable: true,
                disableAmo: true,
                isdiscqtydisabled: true,
                isAmo: false,
                selectedValidityIndex: 0,
                selectedvaliditytype: validityTypes[0],
                disclosedInput: '0',
                selectedordertype: orderTypes[0],
                selectedOrderTypeIndex: 0,
                ispricedisabled: false,
                slPrice: "0",
                cvrTriggerPrice: '0',
                brcTriggerPrice: '0',
                targetPrice: '0',
                trailingPrice: '0'
            }, () => {
                if (parseFloat(this.state.priceInput) === 0) {
                    this.setState({ priceInput: (this.state.last) });
                }
            });
        }
        this.setState({ selectedAdvancedType: item }, () => {
            if (this.inputsCont && this.inputsCont.scrollTo
                && (item === advanceOrderTypes[1]
                    || item === advanceOrderTypes[2])) {
                this.inputsCont.scrollTo(0, 300)
            }
        });
    }

    activateMTF = () => {
        let payload = {
            showMTFAlert: true
        }
        this.props.showMTFAlert(payload);
    }

    checkVisibleValidiyTypes(validityButtons) {
        if (parseFloat(this.state.slPrice) > 0) {
            return validityButtons.slice(0, 1);
        }
        return validityButtons;
    }

    getTriggerPriceRange = () => {
        let ordAction;

        this.setState({
            // cvrTriggerPrice: '0',
            // brcTriggerPrice: '0',
            // targetPrice: '0',
            // trailingPrice: '0'
        })
        if (this.isCoverLegModify) {
            ordAction = this.state.selectedaction === TradeActions.BUY ? TradeActions.SELL : TradeActions.BUY;
        } else {
            ordAction = this.state.selectedaction;
        }
        let requestData = {
            request: {
                data: {
                    sym: this.state.symobj,
                    ordAction: ordAction
                }
            }
        };
        getTriggerPriceRange(requestData, this.triggerPriceRangeResponse, this.triggerPriceRangeError);
    }

    triggerPriceRangeResponse = (responseData) => {
        let response = responseData.response.data
        if (response && response.trgPriceRange) {
            let priceRange = response.trgPriceRange;
            this.setState({ triggerCvrPriceRange: priceRange, minCvrTrgrPrice: (priceRange.split('-')[0]).trim(), maxCvrTrgrPrice: (priceRange.split('-')[1]).trim() });
        }
    }

    triggerPriceRangeError = (error) => {
        this.setState({ triggerCvrPriceRange: (this.state.daily_price_range), minCvrTrgrPrice: (this.state.minPrice), maxCvrTrgrPrice: (this.state.maxPrice) });
    }

    getAdvanceTypeDisabled = () => {
        if (this.state.orderentrytype === orderEntryTypes.MODIFY || this.state.orderentrytype === orderEntryTypes.SQUAREOFF) {
            return [0, 1, 2];
        }
        if (this.state.selectedptype === productTypes_Equity[0].value) {
            if (this.state.symobj.instr == 'OPTSTK' || this.state.symobj.instr == 'OPTIDX') {
                return [];
            }
            else {
                return [];
            }
        } else {
            return [1, 2];
        }
    }

    getValidityDisabledList = () => {
        if (this.state.selectedAdvancedType === advanceOrderTypes[2] || this.state.selectedAdvancedType === advanceOrderTypes[1]) {
            return [1];
        }
    }

    getOrderTypeDisabledList = () => {
        if (this.state.orderentrytype === orderEntryTypes.MODIFY) {
            if (this.state.selectedAdvancedType === advanceOrderTypes[1] && !this.isCoverLegModify) {
                return [];
            } else if (this.state.selectedAdvancedType === advanceOrderTypes[2] || this.isCoverLegModify) {
                return [1, 2];
            } else {
                return [];
            }

        }
        if (this.state.selectedAdvancedType === advanceOrderTypes[2]) {
            return [1];
        }
    }

    // get margin required
    getMarginRequired = () => {

        if (this.state.selectedaction === TradeActions.BUY && this.state.selectedptype === productTypes_Equity[2].value) {

            let requestData = {
                request: {
                    data: {
                        sym: this.state.symobj,
                        ordType: this.state.selectedordertype.value,
                        qty: this.state.quantity.toString(),
                        ordAction: TradeActions.BUY.toLowerCase(),
                        limitPrice: this.state.priceInput,
                        triggerPrice: this.state.slPrice,
                        prdType: productType.cash.toLowerCase()
                    }
                }
            }
            this.setState({ inProgress: true }, () => {
                marginRequired(requestData, this.getMarginRequiredResponse, this.getMarginRequiredError);
            });
        }
    }

    getMarginRequiredResponse = (responseData) => {

        if (responseData.response && responseData.response.data) {
            this.setState({ inProgress: false }, () => {
                this.setState({ requiredMargin: responseData.response.data.requiredMargin });
            });
        }
    }
    getMarginRequiredError = (error) => {

        this.setState({ inProgress: false });
    }



    render() {
        // let disabledAdcTypes = this.getAdvanceTypeDisabled();
        this.state.disabledAdcTypesState = this.getAdvanceTypeDisabled();
        let disabledValidity = this.getValidityDisabledList();
        let disabledOrderTypes = this.getOrderTypeDisabledList();
        let orderDetails = this.props.currentorderdetails;
        
        console.log("shruti----");
        reloadWhenSymbolChange();
        return (
            <div>
                {/* basket start */}
                {orderDetails.basketFlagNew == "mybasket" ?
                    <div>
                        <div className="modal-dialog orderentry" style={(this.state.ShowBaketList == true) || (this.state.showDialog == true) || (this.state.mainDialog == false) ? { display: 'none' } : { display: 'block' }}>
                            <div className="row order-header-row nopadding">
                                <div className="col-3" style={{ paddingRight: "0px" }}>
                                    {
                                        this.state.orderentrytype === orderEntryTypes.MODIFY ?
                                            <div className="header-text">MODIFY ORDER</div> :
                                            <div className="header-text">ADD TO BASKET - <span>{
                                                this.state.selectedaction === TradeActions.BUY ? "BUY ORDER" : "SELL ORDER"
                                            }</span></div>
                                    }
                                </div>
                                <div className="col-6 nopadding">
                                    {this.state.symbolsearchvisible ?
                                        <SymbolSearch
                                            ref={searchRef => { this.searchRef = searchRef }}
                                            searchType={symbolSearchType.orderentry} isForSelection={true}
                                            callbackHandler={this.onSymbolSelected.bind(this)}
                                            spotdisable={true}
                                        /> : ''
                                    }
                                </div>
                                <div className="col-3 text-right order-toggle-btns">
                                    <ToggleSwitch button1="BUY" button2="SELL" button_type="order" selected={this.state.selectedaction === TradeActions.BUY ? "1" : "2"}
                                        onChange={this.onActionSelected.bind(this)} isDisabled={this.state.isactiondisabled} />
                                </div>
                            </div>
                            <div className={"row " + (this.state.selectedaction === TradeActions.BUY ? "orderheader-divider-buy" : "orderheader-divider-sell")}>
                            </div>
                            <div className={"row order-content " + (!this.state.symbol ? "disable" : "")}>
                                <div className="col-7 divider-right" style={{ paddingRight: "3px" }}>
                                    <div className="row order-row row-margin">
                                        <div className="col-7 nopadding">
                                            <div style={{ float: 'left' }}>
                                                <div>
                                                    <span className="order-header" style={{ color: '#FFFFFF' }}> {this.state.symbol} </span>
                                                </div>
                                                <div style={{ color: '#AAAAAA' }}>
                                                    {this.state.company}
                                                </div>
                                                <div style={{ color: '#CDCDCD', fontSize: "10px" }}>
                                                    {this.state.lastUpdatedTime}
                                                </div>
                                                {this.props.currentorderdetails.ltd?<div style={{ color: '#CDCDCD', fontSize: "10px" }}>
                                                    Last Trading Day : {this.props.currentorderdetails.ltd}
                                                </div>:''}
                                                {/* {(this.state.symobj &&
                                        ((this.state.symobj.instr === instrumentType.INDEX_FUTURE_SPREAD) || (this.state.symobj.instr === instrumentType.STOCK_FUTURE_SPREAD))) ?
                                        <div><span style={{ color: '#CDCDCD', fontSize: "10px", paddingRight: '10px' }}>Expiry Date</span> {this.state.expriDateSprd}</div> :
                                        ''} */}
                                            </div>
                                            <div className="order-toggle order-exchange-holder " style={{ float: 'left' }}>
                                                {this.state.isexchangehidden ? "" :
                                                    <ToggleSwitch
                                                        button1={this.state.exchange_button1}
                                                        button2={this.state.exchange_button2}
                                                        onChange={this.onExchangeSelected.bind(this)}
                                                        selected={this.state.selectedExchangeIndex}
                                                        isDisabled={this.state.orderentrytype === orderEntryTypes.SQUAREOFF ? false : this.state.isexchangedisabled} />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-5 nopadding changeper">
                                            <div className="text-center" style={{ float: "right" }}>
                                                <span className={"order-header " + this.state.ltpclass} style={{ paddingLeft: '5px' }} > {comaSeparator(this.state.last)} </span>
                                                <div className={this.state.change && this.state.change.indexOf('-') ? "positive-change" : "negative-change"} style={{ paddingLeft: '5px' }} >
                                                    {comaSeparator(this.state.change)} ({comaSeparator(this.state.changeper)}%)
                                    </div>
                                            </div>
                                            {
                                                this.state.selectedaction === "SELL" && this.state.symobj && this.state.symobj.instr === "STK" && (this.state.selectedptype === productTypes_Equity[1].value ||
                                                    this.state.selectedptype === productType.SHORTSELL) ?
                                                    <div className="order-toggle sell-toggle">

                                                        {

                                                            this.state.shortSellPermisson && this.state.selectedptype !== 'intraday' ?
                                                                <div className="col-12 contentpanel">
                                                                    <ToggleSwitch button1="IN DP" button2="NOT IN DP"
                                                                        // isDisabled={this.state.orderentrytype === orderEntryTypes.SQUAREOFF || this.state.orderentrytype === orderEntryTypes.MODIFY ? true : false}
                                                                        onChange={this.onDPChanged.bind(this)} additionalbtnstyle={{ fontSize: '10px' }}
                                                                        selected={this.state.selectedindp ? "1" : "2"} />
                                                                </div> : <div></div>
                                                        }
                                                        {
                                                            this.state.selectedindp && this.state.selectedptype !== 'intraday' ?
                                                                <div className="col-12 contentpanel" style={{ fontSize: '10px', marginTop: '3px' }} >
                                                                    Quantity: {this.state.dpquantity}
                                                                </div>
                                                                : ""
                                                        }
                                                    </div>
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                    <div className="order-properties-divider"></div>
                                    <div id="order-inputs-scroll" className="order-inputs-scroll" ref={inputsCont => { this.inputsCont = inputsCont }}>
                                        <div className="row order-row row-margin product-type">
                                            <div className="col-12 nopadding order-properties-name">PRODUCT TYPE</div>
                                            <div className={"order-radio " + (this.state.tabs.length === 2 ? "col-7" : "col-11")} style={{ float: 'left', marginTop: '5px' }}>
                                                <RadioButtons
                                                    buttons={this.state.tabs}
                                                    callbackHandler={this.onProductTypeSelected.bind(this)}
                                                    className="flex-space-between"
                                                    selected={this.state.selectedpIndex}
                                                    disabledlist={this.state.disabledPType} />
                                            </div>
                                            {
                                                this.state.selectedtab === assetClasses.equity ?
                                                    <div className="col-1 nopadding mtf-info-icon">
                                                        <span onClick={(e) => { this.showMTFInfo() }}  >
                                                            <img src="assets/svg/info_mtf.svg" alt="basket" />
                                                        </span>
                                                    </div> : ''
                                            }
                                        </div>
                                        {
                                            this.state.selectedtab === assetClasses.fno || this.state.selectedtab === assetClasses.currency ? '' : <div className="row amo_checkbox">
                                                {/* <div className="col-6 nopadding">
                                        <label className="custom-check">
                                            <input className="custom-checkbox" type="checkbox" onChange={this.handleAmoCheckbox.bind(this)} checked={this.state.isAmo}
                                                data-tag="rdd-accpted" disabled={this.state.disableAmo} />
                                            <span className={"amo-label " + (this.state.disableAmo ? "disabled" : "")}>After Market Order</span>
                                        </label>
                                    </div> */}
                                                <div className="col-6" style={{ paddingLeft: "10px" }}>
                                                    {
                                                        this.state.selectedptype === productTypes_Equity[2].value && this.state.selectedaction === TradeActions.BUY ?
                                                            <div className="row fillwidth">
                                                                <span className="col-6 nopadding order-properties-name">Required Margin</span>
                                                                <span className="col-6 ideas-radio-label">{this.state.requiredMargin}</span>
                                                            </div> : ''
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {/*order type*/}
                                        <div className="row order-row row-margin">
                                            <div className="col-6 order-radio nopadding order-type">
                                                <div className="order-properties-name" style={{ paddingBottom: "10px" }}>Order Type</div>
                                                <div className="col-10 nopadding">
                                                    <RadioButtons buttons={orderTypes}
                                                        selected={this.state.selectedOrderTypeIndex}
                                                        callbackHandler={this.onOrderTypeSelected}
                                                        className="flex-space-between"
                                                        disabledlist={disabledOrderTypes} />
                                                </div>
                                            </div>
                                            <div className="col-6 order-radio nopadding validity-type" >
                                                <div className="order-properties-name" style={{ paddingBottom: "10px", paddingLeft: "5px" }}>Validity</div>
                                                <div className="col-10 nopadding">
                                                    <RadioButtons buttons={this.checkVisibleValidiyTypes(this.state.visible_validity_types)}
                                                        selected={this.state.selectedValidityIndex}
                                                        callbackHandler={this.onValiditySelected.bind(this)}
                                                        disabledlist={disabledValidity}
                                                        className="flex-space-between" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row order-row row-margin">
                                            <div className="col-3 nopadding">
                                                <div className="order-properties-name quantity-label"><span>{this.state.quantity_label}</span>
                                                    {(this.state.symobj &&
                                                        (this.state.symobj.instr === instrumentType.COMMODITY_FUTURE || this.state.symobj.instr === instrumentType.COMMODITY_OPTIONS
                                                            || this.state.symobj.instr === instrumentType.CURRENCY_FUTURE || this.state.symobj.instr === instrumentType.CURRENCY_OPTIONS))
                                                        && this.state.lotqualifier &&
                                                        <span style={{ marginLeft: '10px' }} >({this.state.lotqualifier})</span>
                                                    }
                                                </div>

                                            </div>
                                            <div className="col-3 nopadding">
                                                <OrderNumberInput
                                                    onChange={this.quantityOnChange}
                                                    defaultValue={this.state.quantity}
                                                    isnumber={true}
                                                    tabIndex="1"
                                                    isDisabled={this.state.isquantitydisabled}
                                                    incrementvalue={this.state.lotsize}
                                                    minValue={this.state.lotsize}
                                                    maxValue={this.state.maxQuantity}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <div className="order-properties-name quantity-label"><span>Price</span>
                                                    {(this.state.symobj && (this.state.symobj.instr === instrumentType.COMMODITY_FUTURE || this.state.symobj.instr === instrumentType.COMMODITY_OPTIONS)
                                                        && this.state.pricequalifier) &&
                                                        <span style={{ marginLeft: '10px' }} >({this.state.pricequalifier})</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-3 nopadding">
                                                <OrderNumberInput
                                                    onChange={this.priceOnChange}
                                                    defaultValue={this.state.priceInput}
                                                    tabIndex="2"
                                                    isDisabled={this.state.ispricedisabled}
                                                    isdecimal={true}
                                                    decimalplacescount={this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("CUR") === -1 ? 2 : 4}
                                                    incrementvalue={this.state.ticksize} />
                                            </div>
                                        </div>
                                        <div className="daily-price-range" style={{ paddingTop: "3px" }}>
                                            (Daily Price Range {this.state.daily_price_range})
                    </div>
                                        <div className="row order-row row-margin">
                                            {this.state.showdiscquantity ?
                                                <div className="col-3 nopadding">
                                                    <div className="" style={{ color: "#AAAAAA" }}><span>{this.state.discquantity_label}</span></div>
                                                </div> : ''}
                                            {this.state.showdiscquantity ?
                                                <div className="col-3 nopadding">
                                                    <OrderNumberInput
                                                        onChange={this.disclosedOnChange}
                                                        defaultValue={this.state.disclosedInput}
                                                        isnumber={true}
                                                        tabIndex="3"
                                                        isDisabled={this.state.isdiscqtydisabled}
                                                        incrementvalue={this.state.lotsize} />
                                                </div> : ''
                                            }
                                            <div className={"col-3 " + (this.state.showdiscquantity ? '' : 'nopadding')}>
                                                <div style={{ color: "#AAAAAA" }}><span>Trigger Price SL (Optional)</span></div>
                                            </div>
                                            <div className="col-3 nopadding">
                                                <OrderNumberInput
                                                    onChange={this.slpriceOnChange}
                                                    defaultValue={this.state.slPrice}
                                                    isdecimal={true}
                                                    decimalplacescount={this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("CUR") === -1 ? 2 : 4}
                                                    tabIndex="4"
                                                    isDisabled={this.state.slDisable}
                                                    incrementvalue={this.state.ticksize} />
                                            </div>
                                        </div>
                                        {
                                            this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("COM") === -1 && this.state.symobj.instr.indexOf("OPTFUT") === -1 ?
                                                <div>
                                                    <div className="row order-row row-margin">
                                                        <span className="order-properties-name" style={{ paddingBottom: "10px" }}>Advance Order Type</span>
                                                        <div className="col-11 order-radio" style={{ float: "left", marginTop: "5px" }}>
                                                            <RadioButtons
                                                                selected={this.state.selectedAdvancedIndex}
                                                                buttons={this.state.basketOrderType}
                                                                callbackHandler={this.onAdvanceOrderTypeSelected.bind(this)}
                                                                className="flex-space-between"
                                                                // disabledlist={disabledAdcTypes}
                                                                disabledlist={this.state.disabledAdcTypesState}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.selectedAdvancedType.value === advanceOrderTypes[0].value ||
                                                            this.state.selectedAdvancedIndex === 0 ? '' :
                                                            <div>
                                                                {this.state.selectedAdvancedType.value === advanceOrderTypes[1].value
                                                                    ?
                                                                    <div className="row order-row row-margin">
                                                                        <div className="col-3 nopadding">
                                                                            <div className="quantity-label"><span style={{ color: "#AAAAAA" }}>Trigger Price SL</span></div>
                                                                        </div>
                                                                        <div className="col-3 nopadding">
                                                                            <OrderNumberInput
                                                                                onChange={this.slpriceCoverOnchange}
                                                                                defaultValue={this.state.cvrTriggerPrice}
                                                                                isdecimal={true}
                                                                                decimalplacescount={this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("CUR") === -1 ? 2 : 4}
                                                                                tabIndex="5"
                                                                                isDisabled={this.state.slCoverDisable}
                                                                                incrementvalue={this.state.ticksize} />
                                                                        </div>
                                                                        <div className="col-6"></div>
                                                                        <div className="col-6 trigger-price-range">
                                                                            (Price Range {this.state.triggerCvrPriceRange})
                                            </div>
                                                                    </div> :
                                                                    this.state.orderentrytype !== orderEntryTypes.MODIFY ?
                                                                        // bracket order
                                                                        <div className="row order-row row-margin">
                                                                            <div className="col-3 nopadding">
                                                                                <div className=""><span style={{ color: "#AAAAAA" }}>Trigger Price SL <br></br>(Abs Value)</span></div>
                                                                            </div>
                                                                            <div className="col-3 nopadding">
                                                                                <OrderNumberInput
                                                                                    onChange={this.slpriceBracketOnchange}
                                                                                    defaultValue={this.state.brcTriggerPrice}
                                                                                    isdecimal={true}
                                                                                    decimalplacescount={this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("CUR") === -1 ? 2 : 4}
                                                                                    tabIndex="5"
                                                                                    isDisabled={this.state.isBrcTriggerDisabled}
                                                                                    incrementvalue={this.state.ticksize} />
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="row">
                                                                                    <div className="col-6">
                                                                                        <div className=""><span style={{ color: "#AAAAAA" }}>Target Price <br></br> (Abs Value)</span></div>
                                                                                    </div>
                                                                                    <div className="col-6 nopadding">
                                                                                        <OrderNumberInput
                                                                                            onChange={this.targetPriceOnchange}
                                                                                            defaultValue={this.state.targetPrice}
                                                                                            isdecimal={true}
                                                                                            decimalplacescount={this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("CUR") === -1 ? 2 : 4}
                                                                                            tabIndex="6"
                                                                                            isDisabled={this.state.isTargetPriceDisabled}
                                                                                            incrementvalue={this.state.ticksize} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 nopadding">
                                                                                <div className="row">
                                                                                    <div className="col-6 nopadding">
                                                                                        <div className=""><span style={{ color: "#AAAAAA" }}>Trailing SL  <br></br>Abs (Optional)</span></div>
                                                                                    </div>
                                                                                    <div className="col-6 nopadding">
                                                                                        <OrderNumberInput
                                                                                            onChange={this.trailingSlOnchange}
                                                                                            defaultValue={this.state.trailingPrice}
                                                                                            isdecimal={true}
                                                                                            decimalplacescount={this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("CUR") === -1 ? 2 : 4}
                                                                                            tabIndex="7"
                                                                                            isDisabled={this.state.isTrailingPriceDisabled}
                                                                                            incrementvalue={this.state.ticksize} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> : null
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                                : ''
                                        }

                                    </div>
                                </div>
                                <div className="col-5 nopadding">
                                    <div className="flex-space-evenly ohlc-row">
                                        <div>Open <span>{comaSeparator(this.state.openPrice)}</span></div>
                                        <div>High <span>{comaSeparator(this.state.highPrice)}</span></div>
                                        <div>Low <span>{comaSeparator(this.state.lowPrice)}</span></div>
                                        <div>Close <span>{comaSeparator(this.state.closePrice)}</span></div>
                                    </div>
                                    <div className="market-holdings">
                                        <div className="headers">
                                            <ul className="navbar-nav secondaryMenu flex-space-evenly">
                                                <li className={"nav-main-li " + (this.state.selectedMarketTab === marketAndHoldingsTab.MARKET_DEPTH ? "active" : "")}
                                                    onClick={(e) => { this.marketHoldingsToggle(marketAndHoldingsTab.MARKET_DEPTH) }}>
                                                    <a className={"nav-main-link " + (this.state.selectedMarketTab === marketAndHoldingsTab.MARKET_DEPTH ? "active" : "")}>MARKET DEPTH</a></li>
                                                {this.state.symobj && this.state.symobj.assetClass == 'spread' ? '' :
                                                    <li className={"nav-main-li " + (this.state.selectedMarketTab === marketAndHoldingsTab.HOLDINGS_POSITIONS ? "active" : "")}
                                                        onClick={(e) => { this.marketHoldingsToggle(marketAndHoldingsTab.HOLDINGS_POSITIONS) }}>
                                                        <a className={"nav-main-link " + (this.state.selectedMarketTab === marketAndHoldingsTab.HOLDINGS_POSITIONS ? "active" : "")}>{this.state.symobj && this.state.symobj.assetClass != 'equity' ? 'POSITIONS' : 'HOLDING/POSTIONS'}</a></li>}
                                            </ul>
                                        </div>
                                        <div className="marketdepth-container">
                                            {
                                                this.state.selectedMarketTab === marketAndHoldingsTab.MARKET_DEPTH ? <MarketDepth
                                                    symDetails={this.state.symDetails}
                                                    bids={this.state.marketbids}
                                                    asks={this.state.marketasks}
                                                    totalbuyquantity={this.state.totalbuyquantity}
                                                    totalsellquantity={this.state.totalsellquantity} /> :
                                                    <HoldingsPositions
                                                        error={this.state.holdings_error}
                                                        data={this.state.holdingsdata}
                                                        symobj={this.state.symobj}
                                                        isDisabled={(this.state.error || (this.state.symobj
                                                            && (this.state.symobj.instr === instrumentType.COMMODITY
                                                                || this.state.symobj.instr === instrumentType.COMMODITY_FUTURE
                                                                || this.state.symobj.instr === instrumentType.COMMODITY_OPTIONS))) ? true : false} />
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="row">
                            <div className="col-12">
                                <div className="row order-properties-divider">
                                </div>
                            </div>
                        </div> */}
                                    <div className="col-12 flex-space-evenly best-buy-sell">
                                        <div>
                                            <span className="ohlc-row">
                                                BEST BUY:
                                    </span>
                                            <span style={{ marginLeft: '5px' }}>
                                                {this.state.error ? '-' : this.state.bestbuy}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="ohlc-row">
                                                BEST SELL:
                                    </span>
                                            <span style={{ marginLeft: '5px' }}>
                                                {this.state.error ? '-' : this.state.bestsell}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.inProgress ? blockProgressBar() : ''}
                            <div className="order-row text-right order-footer">
                                <button type="button" className="btn-footer order-cancel" onClick={this.props.onNegativeButtonClick} >CANCEL</button>
                                <button type="button" className={"btn-footer order-place " + (this.state.selectedaction === TradeActions.BUY ? "active-buy" : "active-sell")} onClick={this.AddBasketRequest}
                                    tabIndex="8"
                                    disabled={this.isPlaceButtonDisabled()}>ADD TO BASKET</button>

                            </div>

                        </div>

                        {this.state.ShowBaketList ?
                            <div className="modal fade dialog-show in trade-overlay" id="myModal" role="dialog">
                                <div className={"modal-dialog appdialogmodal tradehistory-data"}>
                                    <div className="modal-content">
                                        <div className="modal-header history-close-button">
                                            <button type="button" className="close" onClick={this.closeDialog.bind(this)}><img src="assets/svg/close.svg" alt="close" /></button>
                                            <h4 className="modal-title text-center">ADD TO BASKET</h4>
                                        </div>
                                        <div className="modal-body">
                                            <div style={{ height: '200px' }} className="tab-pane fade in active fillheight">
                                                <div className="fillheight portfolio-tracker">
                                                    <div className="row" style={{ padding: '20px 50px' }}>
                                                        {orderDetails.basketListDataNew.length == '0' ?
                                                            <div className="col-12">
                                                                <p>There is no basket avaliable. Please create basket to add Script</p>
                                                            </div>
                                                            :
                                                            <div className="col-12">
                                                                <p>Select a basket to add scrip</p>
                                                            </div>
                                                        }
                                                        {orderDetails.basketListDataNew.map((data, Index) =>
                                                            <div className="col-3" style={{ cursor: 'pointer', background: '#42454a', padding: '10px 20px', color: '#fff', marginBottom: 10, marginRight: 10 }}>
                                                                <div onClick={() => this.BasketAddRequest(data.id)}>{data.name}</div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div> : ''}

                        {this.state.showDialog ?
                            <div>
                                {this.state.status == 'N' || this.state.orderentrytype == orderEntryTypes.MODIFY ?
                                    <Dialog header="Message"
                                        message={this.state.message}
                                        negativeButtonName="Ok"
                                        showDialog={this.state.showDialog}
                                        negativeClick={this.closePopup.bind(this)} />
                                    :
                                    <Dialog header="Message"
                                        message={this.state.message}
                                        showDialog={this.state.showDialog}
                                        positiveButtonName="Yes"
                                        negativeButtonName="No"
                                        positiveClick={this.OnPositiveClick.bind(this)}
                                        negativeClick={this.closePopup.bind(this)} />
                                }
                            </div>
                            : ''}

                    </div>
                    // basket end
                    :
                    <div className="modal-dialog orderentry">
                        <div className="row order-header-row nopadding">
                            <div className="col-3" style={{ paddingRight: "0px" }}>
                                {
                                    this.state.orderentrytype === orderEntryTypes.MODIFY ?
                                        <div className="header-text">MODIFY ORDER</div> :
                                        <div className="header-text">ORDER ENTRY - <span>{
                                            this.state.selectedaction === TradeActions.BUY ? "BUY ORDER" : "SELL ORDER"
                                        }</span></div>
                                }
                            </div>
                            <div className="col-6 nopadding">
                                {this.state.symbolsearchvisible ?
                                    <SymbolSearch
                                        ref={searchRef => { this.searchRef = searchRef }}
                                        searchType={symbolSearchType.orderentry} isForSelection={true}
                                        callbackHandler={this.onSymbolSelected.bind(this)}
                                        spotdisable={true}
                                    /> : ''
                                }
                            </div>
                            <div className="col-3 text-right order-toggle-btns">
                                <ToggleSwitch button1="BUY" button2="SELL" button_type="order" selected={this.state.selectedaction === TradeActions.BUY ? "1" : "2"}
                                    onChange={this.onActionSelected.bind(this)} isDisabled={this.state.isactiondisabled} />
                            </div>
                        </div>
                        <div className={"row " + (this.state.selectedaction === TradeActions.BUY ? "orderheader-divider-buy" : "orderheader-divider-sell")}>
                        </div>
                        <div className={"row order-content " + (!this.state.symbol ? "disable" : "")}>
                            <div className="col-7 divider-right" style={{ paddingRight: "3px" }}>
                                <div className="row order-row row-margin">
                                    <div className="col-7 nopadding">
                                        <div style={{ float: 'left' }}>
                                            <div>
                                                <span className="order-header" style={{ color: '#FFFFFF' }}> {this.state.symbol} </span>
                                            </div>
                                            <div style={{ color: '#AAAAAA' }}>
                                                {this.state.company}
                                            </div>
                                            <div style={{ color: '#CDCDCD', fontSize: "10px" }}>
                                                Last updated Time :  {this.state.lastUpdatedTime}
                                            </div>
                                            {this.props.currentorderdetails.ltd?<div style={{ color: '#CDCDCD', fontSize: "10px" }}>
                                                Last Trading Day : {this.props.currentorderdetails.ltd}
                                            </div>:''}
                                            {(this.state.symobj &&
                                                ((this.state.symobj.instr === instrumentType.INDEX_FUTURE_SPREAD) || (this.state.symobj.instr === instrumentType.STOCK_FUTURE_SPREAD))) ?
                                                <div><span style={{ color: '#CDCDCD', fontSize: "10px", paddingRight: '10px' }}>Expiry Date</span> {this.state.expriDateSprd}</div> :
                                                ''}
                                        </div>
                                        <div className="order-toggle order-exchange-holder " style={{ float: 'left' }}>
                                            {this.state.isexchangehidden ? "" :
                                                <ToggleSwitch
                                                    button1={this.state.exchange_button1}
                                                    button2={this.state.exchange_button2}
                                                    onChange={this.onExchangeSelected.bind(this)}
                                                    selected={this.state.selectedExchangeIndex}
                                                    isDisabled={this.state.orderentrytype === orderEntryTypes.SQUAREOFF ? false : this.state.isexchangedisabled} />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-5 nopadding changeper" style={{ paddingRight: "5px" }}>
                                        <div className="text-center" style={{ float: "right" }}>
                                            <span className={"order-header " + this.state.ltpclass} style={{ paddingLeft: '5px' }} > {comaSeparator(this.state.last)} </span>
                                            <div className={this.state.change && this.state.change.indexOf('-') ? "positive-change" : "negative-change"} style={{ paddingLeft: '5px' }} >
                                                {comaSeparator(this.state.change)} ({comaSeparator(this.state.changeper)}%)
                                    </div>
                                        </div>
                                        {
                                            this.state.selectedaction === "SELL" && this.state.symobj && this.state.symobj.instr === "STK" && (this.state.selectedptype === productTypes_Equity[1].value ||
                                                this.state.selectedptype === productType.SHORTSELL) ?
                                                <div className="order-toggle sell-toggle" style={{ textAlign: 'right' }}>
                                                    {
                                                        this.state.shortSellPermisson && this.state.selectedptype !== 'intraday' ?
                                                            <div className="col-12 contentpanel">
                                                                {this.EDIS !== undefined && this.EDIS !== null ?
                                                                    (this.EDIS.nonPoaFlag == true ?
                                                                        <ToggleSwitch button1="IN DP" button2="NOT IN DP" button3="IN DP w/o POA"
                                                                            isDisabled={this.state.orderentrytype === orderEntryTypes.SQUAREOFF || this.state.orderentrytype === orderEntryTypes.MODIFY ? true : false}
                                                                            onChange={this.onDPChanged.bind(this)} additionalbtnstyle={{ fontSize: '10px' }}
                                                                            selected={this.state.selectedindp && this.state.CdslFlag == "abcd" ? "1"
                                                                                :
                                                                                (this.state.CdslFlag !== 'abcd' && this.state.CdslFlag !== 'notindp' ? '3' : '2')} />
                                                                        :
                                                                        <ToggleSwitch button1="IN DP" button2="NOT IN DP"
                                                                            isDisabled={this.state.orderentrytype === orderEntryTypes.SQUAREOFF || this.state.orderentrytype === orderEntryTypes.MODIFY ? true : false}
                                                                            onChange={this.onDPChanged.bind(this)} additionalbtnstyle={{ fontSize: '10px' }}
                                                                            selected={this.state.selectedindp ? "1" : "2"} />

                                                                    )
                                                                    :
                                                                    <ToggleSwitch button1="IN DP" button2="NOT IN DP"
                                                                        isDisabled={this.state.orderentrytype === orderEntryTypes.SQUAREOFF || this.state.orderentrytype === orderEntryTypes.MODIFY ? true : false}
                                                                        onChange={this.onDPChanged.bind(this)} additionalbtnstyle={{ fontSize: '10px' }}
                                                                        selected={this.state.selectedindp ? "1" : "2"} />
                                                                }
                                                            </div> : <div></div>
                                                    }
                                                    {
                                                        this.state.selectedindp && this.state.selectedptype !== 'intraday' && this.state.CdslFlag == 'abcd' ?
                                                            <div className="col-12 contentpanel" style={{ fontSize: '10px', marginTop: '3px', textAlign: 'left' }} >
                                                                Quantity: {this.state.dpquantity}
                                                            </div>
                                                            : ""
                                                    }


                                                    {
                                                        this.state.CdslFlag !== 'abcd' && this.state.CdslFlag !== 'notindp' ?
                                                            <div className="col-12 contentpanel" style={{ fontSize: '10px', marginTop: '3px' }} >
                                                                DP A/C No: {this.EDIS.demats[0].dpAccount} / QTY:
                                                                    {this.state.NonPoaQTY}
                                                            </div>
                                                            : ""
                                                    }

                                                    {(this.EDIS_RESPONSE !== undefined && this.EDIS_RESPONSE !== null && this.EDIS_RESPONSE !== "") ?
                                                        <div className="col-12 contentpanel" id="edisMessage" style={{ fontSize: '12px', marginTop: '3px', color: '#d4403f' }} >
                                                            {this.EDIS_RESPONSE.Message}
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                                : ""
                                        }
                                    </div>
                                </div>
                                <div className="order-properties-divider"></div>
                                <div id="order-inputs-scroll" className="order-inputs-scroll" ref={inputsCont => { this.inputsCont = inputsCont }}>
                                    <div className="row order-row row-margin product-type">
                                        <div className="col-12 nopadding order-properties-name">Order For</div>
                                        <div className={"order-radio " + (this.state.tabs.length === 2 ? "col-7" : "col-11")} style={{ float: 'left', marginTop: '5px' }}>
                                            <RadioButtons
                                                buttons={this.state.tabs}
                                                callbackHandler={this.onProductTypeSelected.bind(this)}
                                                className="flex-space-between"
                                                selected={this.state.selectedpIndex}
                                                disabledlist={this.state.disabledPType} />
                                        </div>
                                        {
                                            this.state.selectedtab === assetClasses.equity ?
                                                <div className="col-1 nopadding mtf-info-icon">
                                                    <span onClick={(e) => { this.showMTFInfo() }}  >
                                                        <img src="assets/svg/info_mtf.svg" alt="basket" />
                                                    </span>
                                                </div> : ''
                                        }
                                    </div>
                                    {
                                        this.state.selectedtab === assetClasses.fno || this.state.selectedtab === assetClasses.currency ? '' : <div className="row amo_checkbox">
                                            <div className="col-6 nopadding">
                                                {(this.state.CdslFlag !== 'abcd' && this.state.CdslFlag !== 'notindp')||(this.state.selectedaction === "SELL"&&this.state.CdslFlag === 'abcd'&& this.state.selectedptype==productTypes_Equity[1].value&&this.EDIS&&this.state.cashPositions>0) ? '' : <label className="custom-check">
                                                    <input className="custom-checkbox" type="checkbox" onChange={this.handleAmoCheckbox.bind(this)} checked={this.state.isAmo}
                                                        data-tag="rdd-accpted" disabled={this.state.disableAmo} />
                                                    <span className={"amo-label " + (this.state.disableAmo ? "disabled" : "")}>After Market Order</span>
                                                </label>
                                                }
                                            </div>
                                            <div className="col-6" style={{ paddingLeft: "10px" }}>
                                                {
                                                    this.state.selectedptype === productTypes_Equity[2].value && this.state.selectedaction === TradeActions.BUY ?
                                                        <div className="row fillwidth">
                                                            <span className="col-6 nopadding order-properties-name">Required Margin</span>
                                                            <span className="col-6 ideas-radio-label">{this.state.requiredMargin}</span>
                                                        </div> : ''
                                                }
                                            </div>
                                        </div>
                                    }
                                    {/*order type*/}
                                    {(this.state.symobj &&
                                        ((this.state.symobj.instr === instrumentType.INDEX_FUTURE_SPREAD) || (this.state.symobj.instr === instrumentType.STOCK_FUTURE_SPREAD))) ?

                                        <div className="row">
                                            <div className={"headeingPositive col-12 spread-buySell" + (this.state.selectedaction === TradeActions.BUY ? " sell" : " buy")}>
                                                {this.state.selectedaction === TradeActions.BUY ? "SELL" : "BUY"}
                                            </div>
                                            <div className="col-3 spread-colum">
                                                <p className="order-properties-name quantity-label">Expiry Date</p>
                                                <p><span>{this.state.symobj && this.state.symobj.expiry}</span></p>
                                            </div>
                                            <div className="col-3 spread-colum">
                                                <p className="order-properties-name quantity-label">Quantity</p>
                                                {/* <InputPlusMinus ref="Quantity" isnumber={true} 
                                        defaultValue={this.state.quantity} 
                                        incrementvalue={this.state.lotsize}  
                                        minValue={this.state.lotsize}
                                        tabIndex="1"
                                        maxValue={this.state.maxQuantity}
                                        onChange={this.quantityOnChange}
                                        isDisabled={this.state.isquantitydisabled}
                                        /> */}
                                                <OrderNumberInput
                                                    onChange={this.quantityOnChange}
                                                    defaultValue={this.state.quantity}
                                                    isnumber={true}
                                                    tabIndex="1"
                                                    isDisabled={this.state.isquantitydisabled}
                                                    incrementvalue={this.state.lotsize}
                                                    minValue={this.state.lotsize}
                                                    maxValue={this.state.maxQuantity}
                                                />
                                            </div>
                                            <div className="col-4 spread-colum">
                                                <p className="order-properties-name quantity-label">Price Difference</p>
                                                {/* <InputPlusMinus ref="pridfr"  isnumber={true} incrementvalue={this.state.PriDfernc}  minValue={-9999999} /> */}
                                                <OrderNumberInput
                                                    onChange={this.priceOnChange}
                                                    defaultValue={this.state.priceInput}
                                                    tabIndex="2"
                                                    isDisabled={this.state.ispricedisabled}
                                                    isdecimal={true}
                                                    decimalplacescount={this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("CUR") === -1 ? 2 : 4}
                                                    incrementvalue={this.state.ticksize}
                                                    allowNegativeSign={true}
                                                    minValue={-9999999.9999}
                                                />
                                            </div>
                                            <div className="col-2 spread-colum">
                                                <p className="order-properties-name quantity-label">Validity</p>
                                                <p><span>Day</span></p>
                                            </div>
                                            {/* sell colume */}
                                            <div className={"headeingPositive col-12 spread-buySell" + (this.state.selectedaction === TradeActions.BUY ? " buy" : " sell")}>
                                                {this.state.selectedaction === TradeActions.SELL ? "SELL" : "BUY"}

                                            </div>
                                            <div className="col-4 spread-colum">
                                                <p className="order-properties-name quantity-label">Expiry Date</p>
                                                <p><span>{this.state.symobj && this.state.symobj.expiry2}</span></p>
                                            </div>
                                            <div className="col-4 spread-colum">
                                                <p className="order-properties-name quantity-label">Quantity</p>
                                                <p className="sellQnty"><span>{this.state.quantity}</span></p>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className="row order-row row-margin">
                                                <div className="col-6 order-radio nopadding order-type">
                                                    <div className="order-properties-name" style={{ paddingBottom: "10px" }}>Order Type</div>
                                                    <div className="col-10 nopadding">
                                                        <RadioButtons buttons={orderTypes}
                                                            selected={this.state.selectedOrderTypeIndex}
                                                            callbackHandler={this.onOrderTypeSelected}
                                                            className="flex-space-between"
                                                            disabledlist={disabledOrderTypes} />
                                                    </div>
                                                </div>
                                                <div className="col-6 order-radio nopadding validity-type" >
                                                    <div className="order-properties-name" style={{ paddingBottom: "10px", paddingLeft: "5px" }}>Validity</div>
                                                    <div className="col-10 nopadding">
                                                        <RadioButtons buttons={this.checkVisibleValidiyTypes(this.state.visible_validity_types)}
                                                            selected={this.state.selectedValidityIndex}
                                                            callbackHandler={this.onValiditySelected.bind(this)}
                                                            disabledlist={disabledValidity}
                                                            className="flex-space-between" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row order-row row-margin">
                                                <div className="col-3 nopadding">
                                                    <div className="order-properties-name quantity-label"><span>{this.state.quantity_label}</span>
                                                        {(this.state.symobj &&
                                                            (this.state.symobj.instr === instrumentType.COMMODITY_FUTURE || this.state.symobj.instr === instrumentType.COMMODITY_OPTIONS
                                                                || this.state.symobj.instr === instrumentType.CURRENCY_FUTURE || this.state.symobj.instr === instrumentType.CURRENCY_OPTIONS))
                                                            && this.state.lotqualifier &&
                                                            <span style={{ marginLeft: '10px' }} >({this.state.lotqualifier})</span>
                                                        }
                                                    </div>

                                                </div>
                                                <div className="col-3 nopadding">
                                                    {(this.state.CdslFlag == 'CDSL' || this.state.CdslFlag == 'NSDL') && 
                                                    (this.state.selectedptype == "cash") && this.state.selectedaction == 'SELL' ?

                                                        <OrderNumberInput
                                                            onChange={this.quantityOnChange}
                                                            defaultValue={this.state.quantity}
                                                            isnumber={true}
                                                            tabIndex="1"
                                                            isDisabled={parseInt(this.state.NonPoaconfirmQTY) > 0 ? false : true}
                                                            incrementvalue={this.state.lotsize}
                                                            minValue={this.state.lotsize}
                                                            maxValue={this.state.maxQuantity}
                                                        />
                                                        :
                                                        <OrderNumberInput
                                                            onChange={this.quantityOnChange}
                                                            defaultValue={this.state.quantity}
                                                            isnumber={true}
                                                            tabIndex="1"
                                                            isDisabled={this.state.isquantitydisabled}
                                                            incrementvalue={this.state.lotsize}
                                                            minValue={this.state.lotsize}
                                                            maxValue={this.state.maxQuantity}
                                                        />
                                                    }

                                                </div>
                                                <div className="col-3">
                                                    <div className="order-properties-name quantity-label"><span>Price</span>
                                                        {(this.state.symobj && (this.state.symobj.instr === instrumentType.COMMODITY_FUTURE || this.state.symobj.instr === instrumentType.COMMODITY_OPTIONS)
                                                            && this.state.pricequalifier) &&
                                                            <span style={{ marginLeft: '10px' }} >({this.state.pricequalifier})</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-3 nopadding">
                                                    <OrderNumberInput
                                                        onChange={this.priceOnChange}
                                                        defaultValue={this.state.priceInput}
                                                        tabIndex="2"
                                                        isDisabled={this.state.ispricedisabled}
                                                        isdecimal={true}
                                                        decimalplacescount={this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("CUR") === -1 ? 2 : 4}
                                                        incrementvalue={this.state.ticksize} />
                                                </div>
                                            </div>
                                            <div className="daily-price-range" style={{ paddingTop: "3px" }}>
                                                (Daily Price Range {this.state.daily_price_range})
                            </div>
                                            <div className="row order-row row-margin">
                                                {this.state.showdiscquantity ?
                                                    <div className="col-3 nopadding">
                                                        <div className="" style={{ color: "#AAAAAA" }}><span>{this.state.discquantity_label}</span></div>
                                                    </div> : ''}
                                                {this.state.showdiscquantity ?
                                                    <div className="col-3 nopadding">
                                                        <OrderNumberInput
                                                            onChange={this.disclosedOnChange}
                                                            defaultValue={this.state.disclosedInput}
                                                            isnumber={true}
                                                            tabIndex="3"
                                                            isDisabled={this.state.isdiscqtydisabled}
                                                            incrementvalue={this.state.lotsize} />
                                                    </div> : ''
                                                }
                                                <div className={"col-3 " + (this.state.showdiscquantity ? '' : 'nopadding')}>
                                                    <div style={{ color: "#AAAAAA" }}><span>Trigger Price SL (Optional)</span></div>
                                                </div>
                                                <div className="col-3 nopadding">
                                                    <OrderNumberInput
                                                        onChange={this.slpriceOnChange}
                                                        defaultValue={this.state.slPrice}
                                                        isdecimal={true}
                                                        decimalplacescount={this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("CUR") === -1 ? 2 : 4}
                                                        tabIndex="4"
                                                        isDisabled={this.state.slDisable}
                                                        incrementvalue={this.state.ticksize} />
                                                </div>
                                            </div>
                                            {
                                                this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("COM") === -1 && this.state.symobj.instr.indexOf("OPTFUT") === -1 && this.state.symobj.exc != "BFO" ?
                                                    <div>
                                                        <div className="row order-row row-margin">
                                                            <span className="order-properties-name" style={{ paddingBottom: "10px" }}>Advance Order Type</span>
                                                            <div className="col-11 order-radio" style={{ float: "left", marginTop: "5px" }}>
                                                                <RadioButtons
                                                                    selected={this.state.selectedAdvancedIndex}
                                                                    buttons={this.state.advanceOrderTypes}
                                                                    callbackHandler={this.onAdvanceOrderTypeSelected.bind(this)}
                                                                    className="flex-space-between"
                                                                    // disabledlist={disabledAdcTypes}
                                                                    disabledlist={this.state.disabledAdcTypesState}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.selectedAdvancedType.value === advanceOrderTypes[0].value ||
                                                                this.state.selectedAdvancedIndex === 0 ? '' :
                                                                <div>
                                                                    {this.state.selectedAdvancedType.value === advanceOrderTypes[1].value
                                                                        ?
                                                                        <div className="row order-row row-margin">
                                                                            <div className="col-3 nopadding">
                                                                                <div className="quantity-label"><span style={{ color: "#AAAAAA" }}>Trigger Price SL</span></div>
                                                                            </div>
                                                                            <div className="col-3 nopadding">
                                                                                <OrderNumberInput
                                                                                    onChange={this.slpriceCoverOnchange}
                                                                                    defaultValue={this.state.cvrTriggerPrice}
                                                                                    isdecimal={true}
                                                                                    decimalplacescount={this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("CUR") === -1 ? 2 : 4}
                                                                                    tabIndex="5"
                                                                                    isDisabled={this.state.slCoverDisable}
                                                                                    incrementvalue={this.state.ticksize} />
                                                                            </div>
                                                                            <div className="col-6"></div>
                                                                            <div className="col-6 trigger-price-range">
                                                                                (Price Range {this.state.triggerCvrPriceRange})
                                                        </div>
                                                                        </div> :
                                                                        this.state.orderentrytype !== orderEntryTypes.MODIFY ?
                                                                            // bracket order
                                                                            <div className="row order-row row-margin">
                                                                                <div className="col-3 nopadding">
                                                                                    <div className=""><span style={{ color: "#AAAAAA" }}>Trigger Price SL <br></br>(Abs Value)</span></div>
                                                                                </div>
                                                                                <div className="col-3 nopadding">
                                                                                    <OrderNumberInput
                                                                                        onChange={this.slpriceBracketOnchange}
                                                                                        defaultValue={this.state.brcTriggerPrice}
                                                                                        isdecimal={true}
                                                                                        decimalplacescount={this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("CUR") === -1 ? 2 : 4}
                                                                                        tabIndex="5"
                                                                                        isDisabled={this.state.isBrcTriggerDisabled}
                                                                                        incrementvalue={this.state.ticksize} />
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="row">
                                                                                        <div className="col-6">
                                                                                            <div className=""><span style={{ color: "#AAAAAA" }}>Target Price <br></br> (Abs Value)</span></div>
                                                                                        </div>
                                                                                        <div className="col-6 nopadding">
                                                                                            <OrderNumberInput
                                                                                                onChange={this.targetPriceOnchange}
                                                                                                defaultValue={this.state.targetPrice}
                                                                                                isdecimal={true}
                                                                                                decimalplacescount={this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("CUR") === -1 ? 2 : 4}
                                                                                                tabIndex="6"
                                                                                                isDisabled={this.state.isTargetPriceDisabled}
                                                                                                incrementvalue={this.state.ticksize} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6 nopadding">
                                                                                    <div className="row">
                                                                                        <div className="col-6 nopadding">
                                                                                            <div className=""><span style={{ color: "#AAAAAA" }}>Trailing SL  <br></br>Abs (Optional)</span></div>
                                                                                        </div>
                                                                                        <div className="col-6 nopadding">
                                                                                            <OrderNumberInput
                                                                                                onChange={this.trailingSlOnchange}
                                                                                                defaultValue={this.state.trailingPrice}
                                                                                                isdecimal={true}
                                                                                                decimalplacescount={this.state.symobj && this.state.symobj.instr && this.state.symobj.instr.indexOf("CUR") === -1 ? 2 : 4}
                                                                                                tabIndex="7"
                                                                                                isDisabled={this.state.isTrailingPriceDisabled}
                                                                                                incrementvalue={this.state.ticksize} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                        }
                                                    </div>
                                                    : ''
                                            }
                                        </div>
                                    }


                                </div>
                            </div>
                            <div className="col-5 nopadding">
                                <div className="flex-space-evenly ohlc-row">
                                    <div>Open <span>{comaSeparator(this.state.openPrice)}</span></div>
                                    <div>High <span>{comaSeparator(this.state.highPrice)}</span></div>
                                    <div>Low <span>{comaSeparator(this.state.lowPrice)}</span></div>
                                    <div>Close <span>{comaSeparator(this.state.closePrice)}</span></div>
                                </div>
                                <div className="market-holdings">
                                    <div className="headers">
                                        <ul className="navbar-nav secondaryMenu flex-space-evenly">
                                            <li className={"nav-main-li " + (this.state.selectedMarketTab === marketAndHoldingsTab.MARKET_DEPTH ? "active" : "")}
                                                onClick={(e) => { this.marketHoldingsToggle(marketAndHoldingsTab.MARKET_DEPTH) }}>
                                                <a className={"nav-main-link " + (this.state.selectedMarketTab === marketAndHoldingsTab.MARKET_DEPTH ? "active" : "")}>MARKET DEPTH</a></li>
                                            {this.state.symobj && this.state.symobj.assetClass == 'spread' ? '' :
                                                <li className={"nav-main-li " + (this.state.selectedMarketTab === marketAndHoldingsTab.HOLDINGS_POSITIONS ? "active" : "")}
                                                    onClick={(e) => { this.marketHoldingsToggle(marketAndHoldingsTab.HOLDINGS_POSITIONS) }}>
                                                    <a className={"nav-main-link " + (this.state.selectedMarketTab === marketAndHoldingsTab.HOLDINGS_POSITIONS ? "active" : "")}>{this.state.symobj && this.state.symobj.assetClass != 'equity' ? 'POSITIONS' : 'HOLDING/POSTIONS'}</a></li>}
                                        </ul>
                                    </div>
                                    <div className="marketdepth-container">
                                        {
                                            this.state.selectedMarketTab === marketAndHoldingsTab.MARKET_DEPTH ? <MarketDepth
                                                symDetails={this.state.symDetails}
                                                bids={this.state.marketbids}
                                                asks={this.state.marketasks}
                                                totalbuyquantity={this.state.totalbuyquantity}
                                                totalsellquantity={this.state.totalsellquantity} /> :
                                                <HoldingsPositions
                                                    error={this.state.holdings_error}
                                                    data={this.state.holdingsdata}
                                                    symobj={this.state.symobj}
                                                    isDisabled={(this.state.error || (this.state.symobj
                                                        && (this.state.symobj.instr === instrumentType.COMMODITY
                                                            || this.state.symobj.instr === instrumentType.COMMODITY_FUTURE
                                                            || this.state.symobj.instr === instrumentType.COMMODITY_OPTIONS))) ? true : false} />
                                        }
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-12">
                                        <div className="row order-properties-divider">
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-12 flex-space-evenly best-buy-sell">
                                    <div>
                                        <span className="ohlc-row">
                                            BEST BUY:
                                    </span>
                                        <span style={{ marginLeft: '5px' }}>
                                            {this.state.error ? '-' : this.state.bestbuy}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="ohlc-row">
                                            BEST SELL:
                                    </span>
                                        <span style={{ marginLeft: '5px' }}>
                                            {this.state.error ? '-' : this.state.bestsell}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.inProgress ? blockProgressBar() : ''}
                        <div className="order-row text-right order-footer">
                                {(this.state.CdslFlag == "CDSL") && (parseInt(this.state.NonPoaconfirmQTY) <= 0) ? 
                                    <div style={{float: 'left'}}>
                                        <a href="https://edis.cdslindia.com/home/generatepin" target="_blank" style={{color: '#fff', marginRight :'25px', lineHeight: '35px'}}> Generate new CDSL TPIN </a>
                                        <a href="https://power.indiabulls.com/edis/faq.html" target="_blank" style={{color: '#fff', marginRight :'25px', lineHeight: '35px'}}> EDIS FAQ </a>
                                        <p onClick={this.forgetTpinCdsl.bind(this)} style={{color: '#fff', marginRight :'25px', lineHeight: '35px', float:'left', cursor:'pointer'}}> Forget TPIN </p>
                                    </div>
                                    :
                                    ''
                                }
                            
                            <button type="button" className="btn-footer order-cancel" onClick={this.props.onNegativeButtonClick} >CANCEL</button>
                            {this.EDIS !== undefined && this.EDIS !== null && this.state.selectedptype == "cash"  && this.state.selectedaction == 'SELL' ?

                                ((this.state.CdslFlag == "CDSL" || this.state.CdslFlag == "NSDL") && (this.EDIS_RESPONSE == undefined || this.EDIS_RESPONSE == "" || this.EDIS_RESPONSE == null) && (parseInt(this.state.NonPoaconfirmQTY) <= 0) ?

                                    <button type="button" className={"btn-footer order-place " + (this.state.selectedaction === TradeActions.BUY ? "active-buy" : "active-sell")} onClick={this.SubmitEdis}
                                        tabIndex="8"
                                        disabled={this.isPlaceButtonDisabled()}>SUBMIT E - DIS</button>
                                    :
                                    ((this.EDIS_RESPONSE !== undefined && this.EDIS_RESPONSE !== "" && this.EDIS_RESPONSE !== null) && (this.EDIS_RESPONSE.Flag == 'F' ||this.EDIS_RESPONSE.Flag == 'C' || parseInt(this.state.NonPoaconfirmQTY) <= 0) && (this.state.CdslFlag == 'CDSL' || this.state.CdslFlag == 'NSDL') ?
                                        <button type="button" className={"btn-footer order-place " + (this.state.selectedaction === TradeActions.BUY ? "active-buy" : "active-sell")} onClick={this.SubmitEdis}
                                            tabIndex="8"
                                            disabled={this.isPlaceButtonDisabled()}>SUBMIT E - DIS</button>
                                        :
                                        <button type="button" className={"btn-footer order-place " + (this.state.selectedaction === TradeActions.BUY ? "active-buy" : "active-sell")} onClick={this.onPlaceOrder}
                                            tabIndex="8"
                                            disabled={this.isPlaceButtonDisabled()}>CONFIRM ORDER</button>
                                    )

                                )
                                :
                                <button type="button" className={"btn-footer order-place " + (this.state.selectedaction === TradeActions.BUY ? "active-buy" : "active-sell")} onClick={this.onPlaceOrder}
                                    tabIndex="8"
                                    disabled={this.isPlaceButtonDisabled()}>CONFIRM ORDER</button>
                            }

                        </div>

                        {this.state.InDpshowDialog ?
                            
                            <Dialog header="Message"
                                message= "Kindly provide eDIS approval for Non POA holiding Qty before placing sell order request"
                                showDialog={this.state.InDpshowDialog}
                                negativeButtonName="OK"
                                negativeClick={this.IndpclosePopup.bind(this)} />
                            : ''}
                    </div>
                }
            </div>
        );
    }
};


const mapStateToProps = (state) => {
    return {

    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showMTFDefnition: (value) => showMTFDefnition(dispatch, value),
        showMTFAlert: (value) => showMTFAlert(dispatch, value)
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderInput));
