import React, { Component } from 'react';

class RangeSelector extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.$(".range.quick-link.1d").addClass("active");
  }
  componentWillReceiveProps(nextprops) {
    if (this.props.setSpan !== nextprops.setSpan) {
      let element;
      if (nextprops.setSpan && nextprops.setSpan.base) {
        if (nextprops.setSpan.base === "today") {
          element = document.getElementById("1d " + this.props.id);
        } else if (nextprops.setSpan.base === "month" && nextprops.setSpan.multiplier === 3) {
          element = document.getElementById("3m " + this.props.id);
        } else if (nextprops.setSpan.base === "year" && nextprops.setSpan.multiplier === 5) {
          element = document.getElementById("5y " + this.props.id);
        } else if (nextprops.setSpan.base === "year") {
          element = document.getElementById("1y " + this.props.id);
        }
        if (element) {
          window.$(this.props.id ? "." + this.props.id : ".chartcontainer" + ".range.quick-link").removeClass("active");
          element.classList.add("active");
        }
      }
    }
  }
  setSpan(e, multiplier, base, interval, period, timeUnit) {
    this.props.setSpanWithLoader(multiplier, base, interval, period, timeUnit)
    window.$(this.props.id ? "." + this.props.id : ".chartcontainer" + ".range.quick-link").removeClass("active");
    window.$(e.target).addClass("active");
  }

  render() {
    var addclass = this.props.id ? this.props.id : "chartcontainer";

    return (
      <div>

        {/* <div className="quick-link hide-sm" key='R8' onClick={()=>setSpan(1,'all',1,1,'month')}>All</div> */}
        {/* <div className="quick-link hide-sm" key='R5' onClick={()=>setSpan(1,'YTD')}>YTD</div> */}
        {/* <div className="quick-link hide-sm" key='R4' onClick={()=>setSpan(6,'month')}>6M</div> */}
        {
          // this.props.symobj.instr === "FUTCUR" || this.props.symobj.instr === "FUTCOM" || this.props.symobj.instr === "OPTCUR" ||
          // this.props.symobj.instr === "OPTFUT" || this.props.symobj.instr === "UNDCUR" || this.props.symobj.instr === "COM" ||
          // this.props.symobj.instr === "FUTCURSPR" ?
          this.props.symobj.instr === "UNDCUR" || this.props.symobj.instr === "COM" ||
          this.props.symobj.instr === "FUTCURSPR" ?
          <div>
            <div id={"5y " + (this.props.id)} className={addclass + " range quick-link hide-sm 5y"} key='R7' style={{ pointerEvents: "none" }} >5Y</div>
            <div id={"1y " + (this.props.id)} className={addclass + " range quick-link 1y"} key='R6' style={{ pointerEvents: "none" }} >1Y</div>
            <div id={"3m " + (this.props.id)} className={addclass + " range quick-link hide-sm 3m"} key='R3' style={{ pointerEvents: "none" }} >3M</div>
          </div>
          :
          <div>
            <div id={"5y " + (this.props.id)} className={addclass + " range quick-link hide-sm 5y"} key='R7' onClick={(e) => this.setSpan(e, 5, 'year', 1, 1, 'week')}>5Y</div>
            <div id={"1y " + (this.props.id)} className={addclass + " range quick-link 1y"} key='R6' onClick={(e) => this.setSpan(e, 1, 'year')}>1Y</div>
            <div id={"3m " + (this.props.id)} className={addclass + " range quick-link hide-sm 3m"} key='R3' onClick={(e) => this.setSpan(e, 3, 'month')}>3M</div>
          </div>
        }
        {/* <div className="quick-link" key='R2' onClick={()=>setSpan(1,'month',30,8,'minute')}>1M</div> */}
        {/* <div className="quick-link" key='R1' onClick={()=>setSpan(5,'day',30,2,'minute')}>5D</div> */}
        <div id={"1d " + (this.props.id)} className={addclass + " range quick-link 1d"} key='R0' onClick={(e) => this.setSpan(e, 1, 'today')}>1D</div>

      </div>
    )
  }
}

export default RangeSelector
