import React, { Component } from 'react';
import TabbarBaseWidget from '../base/TabbarBaseWidget'
import PledgeData from './PledgeData'
import UnPledgeData from './UnPledgeData'
import { progressBar } from '../../controls/progress'
import Dropdown from '../../controls/Dropdown'
import { commonDropDownType,localStorageKeys} from "../../common/constants"
import { getItemByKey, maskUserData} from '../../common/utils'
import {getPledgeUnpledge} from '../../actions/PoaPledge/PledgeUpledgeRequest'


class PoaPledge extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabs: ["PLEDGE", "UNPLEDGE"],
            data: [],
            DematAccList:[],
            selectedsegment: 'PLEDGE',
            selectedAcount:'',
            DematDetails:[],
            PledgerdpName:'',
            PledgerMobile:'',
            PledgerName:'',
            SelectedDematAcc:'',
            Pledgeename: '',
            PledgeeDematAc:'',
            apiStatus:'',
            error: '',
            inProgress: false,
            sortColumnId:'',
            sortType:'',
            sortDataType:'',
            
        }
    }


    onTabClicked(tabName) {
        switch (tabName) {
            case "PLEDGE":
                this.setState({ selectedsegment: "PLEDGE" },() => this.requestPledgeUnpledge());
                break;
            case "UNPLEDGE":
                this.setState({ selectedsegment: "UNPLEDGE" },() => this.requestPledgeUnpledge());
                break;
            default:
                this.setState({ selectedsegment: "PLEDGE" },() => this.requestPledgeUnpledge());
                break;
        }
    }
    // shorting-----
    sortSymbols(eventId, sortTemp, sortDataType) {
        this.setState({
            sortColumnId : eventId,
            sortType : sortTemp,
            sortDataType : sortDataType
        });
      }
    

    componentDidMount() {
        this.requestPledgeUnpledge();
        var accountDet = JSON.parse(getItemByKey(localStorageKeys.ACCOUNT_ID));
        this.setState({
            Mobileclientid: accountDet ? accountDet.accID : ''
        })
    }


    requestPledgeUnpledge() {
        var accountDet = JSON.parse(getItemByKey(localStorageKeys.ACCOUNT_ID));
        var requestData = {
            request: {
                data: {
                    tab: this.state.selectedsegment,
                    source: 'W',
                    clientId: accountDet ? accountDet.accID : ''
                }
            }
        };
        this.setState({ 
            inProgress: true, 
            error: '',
            data: [],
            DematAccList:[],
            selectedAcount:'',
            DematDetails:[],
            PledgerdpName:'',
            PledgerMobile:'',
            PledgerName:'',
            SelectedDematAcc:'',
            Pledgeename: '',
            PledgeeDematAc:'',
            apiStatus:'',
            apiKey:''
        });
            getPledgeUnpledge(requestData, this.parsePledgeUnpledgeResponse.bind(this, this.state.selectedsegment), this.parsePledgeUnpledgeError.bind(this, this.state.selectedsegment));
        }
        parsePledgeUnpledgeResponse(selectedsegment, responseData) {
                var DematAccList = responseData.response.data.dematData.map(data => {
                    data.name = data.pledgerDemat;
                    data.data = data.data.map(paydata => {
                        paydata.name = paydata.gatewaySource
                        return paydata;
                    })
                    return data;
                });
                var selectedAcount = {}
                if (DematAccList && DematAccList.length > 0) {
                    selectedAcount = DematAccList[0];
                    this.setState({
                        inProgress: false,
                        data: responseData.response.data,
                        DematAccList: DematAccList,
                        DematDetails: DematAccList && DematAccList.length > 0 ? DematAccList[0].data : [],
                        selectedAcount: selectedAcount,
                        PledgerName: DematAccList && DematAccList.length > 0 ? DematAccList[0].pledgerName: '',
                        PledgerMobile:DematAccList && DematAccList.length > 0 ? DematAccList[0].mobile:'',
                        PledgerdpName: DematAccList && DematAccList.length > 0 ? DematAccList[0].dpName: '',
                        SelectedDematAcc: DematAccList && DematAccList.length > 0 ? DematAccList[0].name: '',
                        Pledgeename: DematAccList && DematAccList.length > 0 ? DematAccList[0].pledgeeName: '',
                        PledgeeDematAc: DematAccList && DematAccList.length > 0 ? DematAccList[0].pledgeeDematId: '',
                        apiStatus: DematAccList && DematAccList.length > 0 ? DematAccList[0].apiStatus: '',
                        apiKey: DematAccList && DematAccList.length > 0 ? ( DematAccList[0].apiKey !== undefined ? DematAccList[0].apiKey :''):''
                        // selectedPayIn: selectedPayIn
                    }, () => {
                        this.dematselectacc.setSelected(this.state.selectedAcount ? this.state.selectedAcount.pledgerDemat : ' ')
                    });
                } else {
                    this.setState({
                        inProgress: false
                    });
                }
        }
    
        parsePledgeUnpledgeError(selectedsegment, error) {
            if (selectedsegment === this.state.selectedsegment) {
                this.setState({
                    inProgress: false,
                    error: error.message
                });
            }
        }


        // on dropdown change
        onAcckSelected(selected, index) {
            this.setState({
                PledgerName: this.state.DematAccList[index].pledgerName,
                PledgerMobile: this.state.DematAccList[index].mobile,
                PledgerdpName: this.state.DematAccList[index].dpName,
                DematDetails: this.state.DematAccList[index].data,
                SelectedDematAcc:this.state.DematAccList[index].name,
                PledgeeDematAc: this.state.DematAccList[index].pledgeeDematId,
                Pledgeename: this.state.DematAccList[index].pledgeeName,
                apiStatus: this.state.DematAccList[index].apiStatus,
                apiKey: this.state.DematAccList[index].apiKey !== undefined ? this.state.DematAccList[index].apiKey :''
                        
                
            }, () => {
            });
            var symblArr = document.getElementsByClassName("pledgeCheckbox");
            for (var i = 0; i < symblArr.length; i++) {
                document.getElementById(symblArr[i].id).checked = true;
            }
            document.getElementById('submitRequest').disabled = false;
            document.getElementById('unselectAll').disabled = false;
        }


    render() {

        var errorHolder = (
            <div className="error-holder fillwidth">
                <span> {this.state.error} </span>
            </div>
        );
        return (
            <TabbarBaseWidget  tabs={this.state.tabs} onTabClicked={this.onTabClicked.bind(this)}>
            <div className="row contentpanel dp-tab-content">
            {this.state.error ? errorHolder :
                    <div className="fillheight fillwidth">
                            <div className="row" style={{marginBottom: '20px',textAlign:'left'}}>
                            <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-12 pledger_headning">
                                            PLEDGEOR DETAILS
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-6">
                                                    Pledgeor demats account
                                                </div> 
                                                <div className="col-6">
                                                    <Dropdown
                                                        ref={dematselectacc => this.dematselectacc = dematselectacc}
                                                        id="selectDematId"
                                                        dropdownType={commonDropDownType.DEFAULT}
                                                        list={this.state.DematAccList}
                                                        onSelectionChanged={this.onAcckSelected.bind(this)}
                                                    />
                                                    {/* <Dropdown list={PledgeOptions} />  */}
                                                </div>   
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    Name/Mobile Number
                                                </div> 
                                                <div className="col-6">                                                   
                                                    {this.state.PledgerName} / {maskUserData(this.state.PledgerMobile)}
                                                </div>   
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    DP Name
                                                </div> 
                                                <div className="col-6">
                                                    <input type="hidden" id="apiStatusPledgeOnline" value={this.state.apiStatus} />
                                                    <span id="dpNameValue">{this.state.PledgerdpName}</span>    
                                                </div>

                                            </div>
                                            </div>
                                    </div>
                                </div> 
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-12 pledger_headning">
                                            PLEDGEE'S DETAILS
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-6">
                                                    Pledge demats account
                                                </div> 
                                                <div className="col-6">
                                                    {this.state.PledgeeDematAc}
                                                </div>   
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    Pledge name
                                                </div> 
                                                <div className="col-6">
                                                    {this.state.Pledgeename}
                                                </div>   
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                  
                            </div> 
                            {this.state.inProgress ? progressBar() :
                                this.state.data.error ? this.state.errorHolder :
                                [
                                    this.state.selectedsegment === "PLEDGE" ?
                                      <PledgeData data={this.state.DematDetails} dematAcc={this.state.SelectedDematAcc} selecttab={this.state.selectedsegment}
                                      callback={this.sortSymbols.bind(this)}  requestPledgeUnpledge = {this.requestPledgeUnpledge.bind(this)} apiKey= {this.state.apiKey}
                                      sortColumnId={this.state.sortColumnId} sortType={this.state.sortType} sortDataType={this.state.sortDataType}
                                      />
                                :   <UnPledgeData data={this.state.DematDetails} dematAcc={this.state.SelectedDematAcc} selecttab={this.state.selectedsegment} 
                                    callback={this.sortSymbols.bind(this)}  requestPledgeUnpledge = {this.requestPledgeUnpledge.bind(this)}
                                        sortColumnId={this.state.sortColumnId} sortType={this.state.sortType} sortDataType={this.state.sortDataType}
                                      />
                        
                                ]
                            }
                    </div>
                    
            }
            </div>
            

        </TabbarBaseWidget>
        )
    }
}

export default PoaPledge;