import React, { Component } from 'react';
import '../../css/common.css'

class UnsupportedBrowser extends Component {

    render() {
        if(this.props.browser === 'mobile' ||  this.props.browser.mobile) {
            return (
                <div className="unsupported_div panel">
                   Download Dhani Stocks app from Google Playstore or iOS Appstore
                </div>
            );
        }
        else {
            return (
                <div className="unsupported_div panel">
                   We do not support  {this.props.browser.name}  {this.props.browser.version}. Please upgrade your browser.
                </div>
            );
        }
       
      }
}

export default UnsupportedBrowser;
