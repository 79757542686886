import { getWatchlistGroups, addSymbolToWatchlist } from './watchlistrequests'
import { setItemByKey } from '../../common/utils'
import { localStorageKeys } from '../../common/constants'

// === Get watchlists ======
export const getWatchlists = function (doneCallback) {
    var data = {
        request: {
            data: {
            }
        }
    };

    getWatchlistGroups(data,
        (responseData) => {
            var predefData = responseData.response.data.predef;
            // var predefData = [{"settings":{},"isEditable":false,"name":"NIFTY 50","channel":"OTS","count":"57","id":"681","type":"1","favid":"0"},{"settings":{},"isEditable":false,"name":"SENSEX","channel":"OTS","count":"30","id":"682","type":"1","favid":"0"},{"settings":{},"isEditable":false,"name":"BANK NIFTY","channel":"OTS","count":"12","id":"683","type":"1","favid":"0"}];
            var groupData = predefData;
            // groupData.push(predefData.find(i => i.name.toUpperCase() === "NIFTYBANK(NSE)"));
            // groupData.push(predefData.find(i => i.name.toUpperCase() === "BANK NIFTY"));
            // groupData.push(predefData.find(i => i.name.toUpperCase() === "SENSEX"));


            if (responseData.response.data.groups) {
                groupData = groupData.concat(responseData.response.data.groups);
            }
            if ( responseData.response.data.mpib) {
                for (var i = 0; i < responseData.response.data.mpib.length; i++) {
                    responseData.response.data.mpib[i]["isMobile"] = true;
                }
                groupData = groupData.concat(responseData.response.data.mpib);
            }
            setItemByKey(localStorageKeys.WATCHLIST_GROUPS, JSON.stringify(groupData));
            if (doneCallback)
                doneCallback(groupData);
                 console.log(groupData)
        },
        (error) => {
            // error callback
            //WARNING : have to discuss what to do in this case.
            doneCallback(error);
        });
}

// === Add symbol to watchlist ======
export const addWatchlistSymbols = function (symbol, watchlistid, doneCallback) {
    var data = {
        request: {
            data: {
                watchId: watchlistid,
                sym: symbol
            }
        }
    };

    addSymbolToWatchlist(data,
        (responseData) => {
            // response callback
            doneCallback();
        },
        (error) => {
            // error callback
            doneCallback(error);
        });


}
