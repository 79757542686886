import React from "react";
import { connect } from "react-redux";
import { ModuleKeys, orderEntryTypes, TradeActions } from '../../../common/constants'
import { showOrderDialog, toggleOrderDialog, toggleCancelOrderDialog } from '../../../actions/orderentry/orderentryaction'
import { toggleDialog } from '../../../actions/common'
import { toggleChartDialog } from '../../../actions/chartiq/chartaction'
import { setExpandWidget, setChartsExpandWidget, setWatchlistExpandWidget, setFooterWidget } from '../../../actions/dashboard'
import { setExpandWidget as setmsExpandWidget } from '../../../actions/marketstats'
import Navbar3 from './Navbar3'

class KeyShortcuts extends React.Component {

    constructor(props) {
        super(props);

        this.onKeyPress = this.onKeyPress.bind(this);
    }


    componentDidMount() {
        window.document.onkeydown = this.onKeyPress;
    }

    componentWillUnmount() {
        window.document.onkeydown = null;
    }

    onKeyPress(e) {

        if (!this.props.loginStatus)
            return;

        var keyCode = e.keyCode || e.which;

        // Function Keys
        if (keyCode === 112 || e.keyCode === 'F1') {
            e.preventDefault();
            if (!this.isAnyPopupOpen())
                this.props.showOrderDialog({ orderentrytype: orderEntryTypes.NEW, action: TradeActions.BUY });

            //This is to prevent default microsoft help window open 
            document.onhelp = function () {
                return false;
            }

            window.onhelp = function () {
                return false
            }

        } else if (keyCode === 113 || e.keyCode === 'F2') {
            e.preventDefault();
            if (!this.isAnyPopupOpen())
                this.props.showOrderDialog({ orderentrytype: orderEntryTypes.NEW, action: TradeActions.SELL });
        } else if (keyCode === 114 || e.keyCode === 'F3') {
            e.preventDefault();
            if (!this.isAnyPopupOpen() && this.props.location && this.props.location.pathname !== "/home/myorders")
                this.props.history.push("/home/myorders");
        } else if (keyCode === 115 || e.keyCode === 'F4') {
            e.preventDefault();
            if (!this.isAnyPopupOpen() && this.props.location && this.props.location.pathname !== "/home/watchlist")
                this.props.history.push("/home/watchlist");
        } else if (keyCode === 117 || e.keyCode === 'F6') {
            e.preventDefault();
            // in order window, market depth            
            if (this.props.showingOrderDialog) {
                if (this.props.showMarketDepth) {
                    this.props.showMarketDepth();
                }
            }
        } else if (keyCode === 119 || e.keyCode === 'F8') {
            e.preventDefault();
            if (!this.isAnyPopupOpen() && this.props.location && this.props.location.pathname !== "/home/mytrades")
                this.props.history.push("/home/mytrades");
        } else if (keyCode === 120 || e.keyCode === 'F9') {
            e.preventDefault();
            if (!this.isAnyPopupOpen() && this.props.location && this.props.location.pathname !== "/home/margin")
                this.props.history.push("/home/margin");
        } else if (keyCode === 121 || e.keyCode === 'F10') {
            e.preventDefault();
            if (!this.isAnyPopupOpen() && this.props.location && this.props.location.pathname !== "/home/netpositions")
                this.props.history.push("/home/netpositions");
        }

        //  Order Dialog + -
        else if (keyCode === 107 || e.keyCode === '+') {
            if (!this.isAnyPopupOpen())
                this.props.showOrderDialog({ orderentrytype: orderEntryTypes.NEW, action: TradeActions.BUY });
        }
        else if (keyCode === 109 || e.keyCode === '-') {
            if (!this.isAnyPopupOpen())
                this.props.showOrderDialog({ orderentrytype: orderEntryTypes.NEW, action: TradeActions.SELL });
        }

        // Escape
        else if (keyCode === 27 || e.keyCode === 'Escape') {

            // SHOULD BE IN ORDER
            // first error
            if (this.props.showingErrorDialog)
                this.props.toggleDialog(false);

            else if (this.props.showingOrderDialog)
                this.props.toggleOrderDialog(false);

            else if (this.props.showingCancelOrderDialog)
                this.props.toggleCancelOrderDialog(false);

            else if (this.props.showingChartDialog)
                this.props.toggleChartDialog(false);

            if (this.props.dashboardActive !== ModuleKeys.ALL) {
                this.props.setExpandWidget(ModuleKeys.ALL);
            }
            if (this.props.watchlistActive !== ModuleKeys.ALL) {
                this.props.setWatchlistExpandWidget(ModuleKeys.ALL, { prevWidget: undefined });
            }
            // if (this.props.chartActive !== ModuleKeys.ALL) {
            //     this.props.setChartsExpandWidget(ModuleKeys.ALL);
            // }
            // if (this.props.marketActive !== ModuleKeys.ALL) {
            //     this.props.setMarketExpandWidget(ModuleKeys.ALL);
            // }
            if (this.props.footerActive !== ModuleKeys.ALL) {
                this.props.setFooterWidget(ModuleKeys.ALL);
                this.props.history.push("home/dashboard")
                return <Navbar3 match={this.props.match} history={this.props.history} />
            }
            if (this.props.location && this.props.location.pathname !== "/home/dashboard") {
                this.props.history.push("/home/dashboard");
                return <Navbar3 match={this.props.match} history={this.props.history} />
            }
        }


    }

    isAnyPopupOpen() {

        // closing calander, if open
        window.$('#popover-date').hide();

        var othermodals = window.$('.disabledbackground');
        return othermodals.length > 0;
    }


    render() {
        return (
            this.props.children
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loginStatus: state.loginStatus.status,

        showingOrderDialog: state.orderentry.showDialog,
        showingCancelOrderDialog: state.orderentry.showCancelOrderDialog,

        showingChartDialog: state.charts.showChartDialog,

        showingBidDialog: state.productentry.showBidDialog,
        showingCancelBidDialog: state.productentry.showCancelBidDialog,



        showingErrorDialog: state.common.showDialog,

        // cbs
        showMarketDepth: state.orderentry.showMarketDepth,

        dashboardActive: state.dashboard.activeWidget,

        watchlistActive: state.watchlist.activeWidget,

        chartActive: state.charts.activeWidget,

        marketActive: state.marketstats.msactiveWidget,

        footerActive: state.dashboard.footerActive
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showOrderDialog: (data) => showOrderDialog(dispatch, data),
        toggleOrderDialog: (value) => toggleOrderDialog(dispatch, value),
        toggleCancelOrderDialog: (value) => toggleCancelOrderDialog(dispatch, value),

        toggleChartDialog: (value) => toggleChartDialog(dispatch, value),

        toggleDialog: (value) => toggleDialog(value, dispatch),
        setExpandWidget: (activeWidget) => setExpandWidget(activeWidget, dispatch),
        setWatchlistExpandWidget: (activeWidget, statedata) => setWatchlistExpandWidget(activeWidget, statedata, dispatch),
        setChartsExpandWidget: (activeWidget, statedata) => setChartsExpandWidget(activeWidget, statedata, dispatch),
        setMarketExpandWidget: (activeWidget) => setmsExpandWidget(activeWidget, dispatch),
        setFooterWidget: (activeWidget) => setFooterWidget(activeWidget, dispatch),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(KeyShortcuts);