import { StoreKeys } from '../../common/constants';

export const toggleChartDialog = function (dispatch, data) {
    dispatch({ type: StoreKeys.SHOW_CHART_DIALOG, payload: data });
}

export const setChartSymbol = function (dispatch, data) {
    Promise.all([
        dispatch({ type: StoreKeys.CHART_SYMBOL, payload: data }),
        dispatch({ type: StoreKeys.SHOW_CHART_DIALOG, payload: true })
    ])
}

export const setSelectChart = function(dispatch, data){
    dispatch({ type: StoreKeys.CHART_SELECT_LIST, payload: data })
}