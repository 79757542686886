import { UrlExtensions, BaseUrl } from '../../common/constants';
import { httpPost } from '../base/api'

// === Place Order ====
export const placeOrder = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.ADD_ORDER;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Modify Order ====
export const modifyOrder = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.MODIFY_ORDER;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Cancel Order ====
export const cancelOrder = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.CANCEL_ORDER;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Place Spread Order ====
export const placeSpreadOrder = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.ADD_SPREAD_ORDER;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
};

//exit cover order
export const exitCoverOrder = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.EXIT_COVER_ORDER;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

//exit bracket order
export const exitBracketOrder = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.EXIT_BRACKET_ORDER;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const getCharges = function (requestData, responseCallback,errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_CHARGES;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
} 
