import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'


class SurvPopup extends Component {

    constructor(props){
        super(props);
    }
    render(){
        return(
            <div className="modal-dialog survpop">
                <div className="surv-title">
                    Security is under Surveillance Measure, would you like to continue?
                </div>
                <div className="surv-msg">
                    <div> {ReactHtmlParser(this.props.orderInputs.survMsg)}</div>
                </div>

                <div className="surv-btngrp"  >
                    <button onClick={this.props.onOrderModify} >NO</button>
                    <button className="surv-btn-blue" onClick={this.props.onConfirmOrder} >YES</button>
                </div>
            </div>
        );

    }



};


export default SurvPopup;
