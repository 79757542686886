import React, { Component } from 'react';
import {PortfolioRowitems} from './PortfolioRowitems'
import { numericSort, stringSort} from '../../common/utils'
import { setScripInfo } from '../../actions/scripdetails/scripdetailsaction'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';

export class GroupBySymbol extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data:this.props.data,
            sortColumnId:this.props.sortColumnId,
            sortType:this.props.sortType,
            sortDataType:this.props.sortDataType,
            Purchasevalue:this.props.Purchasevalue        
        }
    }
    onSymbolClick(data) {
        
        var newdata={
            id:data.symbol,
            exc:data.exchange
        }


        switch(this.props.selectedsegment)
        {
            case "Script" : 
            case "Sector" :  newdata.assetClass="equity";
            newdata.instr="STK";
            break;
            case "Fno" :  (data.instrumentType).includes("FUT")?newdata.assetClass="future":newdata.assetClass="option";
            newdata.instr=data.instrumentType;
            newdata.expiry=data.expiryDateFormatted; 
            newdata.optType= data.optionType;
            newdata.strike= data.strikePrice;
            break;
            case "Currency" :   (data.instrumentType).includes("FUT")?newdata.assetClass="future":newdata.assetClass="option";
            newdata.instr=data.instrumentType;
            newdata.expiry=data.expiryDateFormatted;
            newdata.optType= data.optionType;
            newdata.strike= data.strikePrice;
            break;
    
        
        }        
        this.props.setScripInfo(newdata);
        this.props.history.push("/home/scripdetails");
    }
    

    componentWillReceiveProps(nextProps)
    {
        this.setState({data:nextProps.data});
        var temp=nextProps.data, 
        sortType=nextProps.sortType;

        switch (nextProps.sortDataType) 
        {
            case "STRING":
                temp = stringSort(temp, nextProps.sortColumnId, sortType);
                break;
            case "NUMBER":
                temp = numericSort(temp, nextProps.sortColumnId, sortType);
                break;
            
            default: break;
        
        }
        this.setState({data:temp});

    }

    render() {
    return (
        (
            <div className="fillheight">    
            <div className="dynamicheightscroll"> 
        
        <div className="tab-content expanded tabnomargin">
                    
        {this.state.data.map(function (data, index){
            return (
                <PortfolioRowitems
                    key={data.symbol}
                    row={data}
                    selectedsegmentTrade={this.props.selectedsegment}
                    onSymbolClick={this.onSymbolClick.bind(this)} 
                    flag={this.props.flag}                   
                />
            )
        }, this)}
        <div className="row" style={{padding: '10px'}}>
            <div className="col-12"></div>
        </div>
                    
            
    </div>
    <div className="row data-total portfolio-row">
        <div className="col-2 valign">
            TOTAL
        </div>
        <div className="col text-right valign">
            
        </div>
        <div className="col text-right valign">
            
        </div>
        <div className="col text-right valign">
            {this.state.Purchasevalue}
        </div>
        <div className="col text-right valign">
            
        </div>
        <div className="col text-right valign">
            {this.props.CurrentMktValue}
        </div>
        <div className="col text-right valign" style={{color: Math.sign(this.props.todayPL) === -1 ? "#E5283F" : "#1EBF8A"}}>
            {this.props.todayPL}
        </div>
        <div className="col text-right valign">
            
        </div>
        <div className="col text-right valign" style={{color: Math.sign(this.props.NotionalPfLs) === -1 ? "#E5283F" : "#1EBF8A"}}>
            {this.props.NotionalPfLs}
        </div>
        <div className="col text-right valign" >
            
        </div>
        <div className="col text-right valign" style={{color: Math.sign(this.props.totalRealisedPL) === -1 ? "#E5283F" : "#1EBF8A"}}>
            {this.props.totalRealisedPL}
        </div>
        <div className="col text-right valign" >
            
        </div>
    </div>
    <div className="row bottom-note">
    * Buy Rate includes Actual Rate + (Brokerage + GST/Service Tax + Transaction Charges)<br></br>
    * Sell Rate includes Actual Rate - (Brokerage + GST/Service Tax + Transaction Charges)
    </div>
    </div></div>)
    )
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {   
    console.log("hello shwtea"); 
    return {       
        setScripInfo: (data) => setScripInfo(dispatch, data)
    };
};
export default  withRouter(connect(null, mapDispatchToProps)(GroupBySymbol));
// export default GroupBySymbol;