import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showMTFDefnition } from '../../actions/common'
class MtfDefnition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDefnition: false
        }
    }
    componentDidMount() {
        this.setState({ showDefnition: this.props.showDefnition });
    }
    componentWillReceiveProps(newProps) {
        if (this.props.showDefnition !== newProps.showDefnition)
            this.setState({ showDefnition: newProps.showDefnition });
    }
    closeDialog() {
        if (this.props.showMTFDefnition) {
            let payload = {
                showMTFDefnition: false
            }
            this.props.showMTFDefnition(payload);
        }
    }

    render() {
        return (
            this.state.showDefnition ?
                <div className={"modal fade " + (this.state.showDefnition ? "dialog-show in" : "dialog-hide")} id="myModal" role="dialog">
                    <div className="modal-dialog mtf-modal">
                        <div className="mtf-header">
                            <span>WHAT IS MARGIN TRADING?</span>
                            <button className="mtf-close" onClick={this.closeDialog.bind(this)}>&times;</button>
                        </div>
                        <div className="mtf-body" style={{ textAlign: "justify" }}>
                            <span className="mtf-textcontent">
                                Margin Trading is SEBI approved mechanism where a client can trade with borrowed funds.
                    To create a position under Margin Trading Facility (MTF), the client is required to provide
                    initial margin in the form of fund and/or securities to the stock broker and stock broker provides
                    the shortfall of the amount, on interest, to meet the pay-in obligation. To carry position in MTF,
                    client is required to maintain Maintenance margin.
                    <br /><br />
                                E.g. If a client buys shares with INR 1,00,000/-, under MTF,on which initial margin
                    prescribed by the exchange is 25%, then the client needs to provide INR 25,000/-
                    upfront in the form of funds and/or securities (after hair cut) and INR 75,000 will be
                    funded by Dhani Stocks Limited. DSL will charge interest on the funded amount.
                    </span>
                        </div>
                        <div className="mtf-footer">
                            <div className="buttons">
                                <button type="button" className="mtf-buttons" onClick={this.closeDialog.bind(this)}>OK</button>
                            </div >
                        </div>
                    </div>
                </div> : null
        );
    }
};
const mapStateToProps = (state) => {
    return {
        showDefnition: state.mtfDefnition.showMTFDefnition
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showMTFDefnition: (value) => showMTFDefnition(dispatch, value)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MtfDefnition);