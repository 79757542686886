import { UrlExtensions,  BaseUrl } from '../../common/constants';
import { httpPost } from '../base/api'

//========================profit loss data

export const getPortfolioData = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.PORTFOLIO;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('request succeeded with JSON response', response); 
        responseCallback(response);
        // return response;
    }).catch(function (error) {
        console.log('request failed', error);
        errorCallback(error);
    });
}
export const getNewPortfolioData = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.NEW_PORTFOLIO;
    httpPost(target, requestData)
        .then(function (response) {
            console.log('request succeeded with JSON response', response);
            responseCallback(response);
            // return response;
        }).catch(function (error) {
        console.log('request failed', error);
        errorCallback(error);
    });
}
//tradehistory

export const getTradeHistoryData = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.TRADEHISTORY;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('request succeeded with JSON response', response); 
        responseCallback(response);
        // return response;
    }).catch(function (error) {
        console.log('request failed', error);
        errorCallback(error);
    });
}
