/* Pre Login */

export const isValidUserName = function (name) {
    if(!name || name.trim() === '') {
        return "Please enter valid Client ID";
    }
    //TODO: add other validations


    //finally
    return '';
}

export const isValidLoginPassword = function (name) {
    if(!name || name.trim() === '') {
        return "Please enter valid Password";
    }
    // if(name.length < 6) {
    //     return "Password must be at least 6 characters"
    // }
    // if(name.length > 12) {
    //     return "Password must be a maximum of 12 characters"
    // }
    //TODO: add other validations


    //finally
    return '';
}

export const isValidLoginTPIN = function (name) {
    if(!name || name.trim() === '') {
        return "Please enter valid PAN/TPIN/BIRTH YEAR/INCORPORATION YEAR";
    }
    if(name.length !== 4 && name.length !== 10) {
        return "Length of PAN / TPIN should be either 10 or 4 characters"
    }
    if(name.length === 4 && /\D/.test(name)) {
        return "TPIN should contain only digits"
    }
    //TODO: add other validations


    //finally
    return '';
}

/* Post Login */

export const isValidClientId = function (clientid) {
    if(!clientid || clientid.trim() === '') {
        return "Please enter valid Client ID";
    }
    //TODO: add other validations


    //finally
    return '';
}

export const isValidPassword = function (name) {
    if(!name || name.trim() === '') {
        return "Please enter valid Password";
    }
    // if(name.length < 6) {
    //     return "Password must be at least 6 characters"
    // }
    // if(name.length > 12) {
    //     return "Password must be a maximum of 12 characters"
    // }
    //TODO: add other validations


    //finally
    return '';
}

export const isValidTPIN = function (name) {
    if(!name || name.trim() === '') {
        return "Please enter valid TPIN";
    }
    if(name.length !== 4) {
        return "Length of TPIN should be 4 characters"
    }
    if(name.length === 4 && /\D/.test(name)) {
        return "TPIN should contain only digits"
    }
    //TODO: add other validations


    //finally
    return '';
}

export const isValidPAN= function (name) {
    if(!name || name.trim() === '') {
        return "Please enter valid PAN";
    }
    if(name.length !== 10) {
        return "Length of PAN should be 10 characters"
    }
    //TODO: add other validations


    //finally
    return '';
}

export const doPasswordsMatch = function (newpassword, confirmpassword) {
    if(newpassword !== confirmpassword) {
        return "New Password and Confirm Password do not match";
    } 
}

export const doTPINsMatch = function (newtpin, confirmtpin) {
    if(newtpin !== confirmtpin) {
        return "New TPIN and Confirm TPIN do not match";
    } 
}
export const isValidMobileOrEmail = function (name) {
    if(!name || name.trim() === '') {
        return "Please enter valid Mobile / Email ID";
    }
    return '';
}