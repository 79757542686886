import React, { Component } from 'react';
import {PortfolioRowitems} from './PortfolioRowitems';

import { numericSort, stringSort} from '../../common/utils';
import {PortfolioHeader} from './PortfolioHeader'

import { setScripInfo } from '../../actions/scripdetails/scripdetailsaction'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';


export class GroupBySectorNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data:this.props.data,
            sortColumnId:this.props.sortColumnId,
            sortType:this.props.sortType,
            sortDataType:this.props.sortDataType,
        }
    }
    componentDidMount() {
        console.log(this.state.data)
    }


    onSymbolClick(data) {

        var newdata={
            id:data.symbol,
            exc:data.exchange,
            assetClass:"equity",
            instr: "STK",
        }
        this.props.setScripInfo(newdata);
        this.props.history.push("/home/scripdetails");
    }

    componentWillReceiveProps(nextProps)
    {
        this.setState({data:nextProps.data});
        var sectorData=nextProps.data, sortType=nextProps.sortType;
        {sectorData.map((data, index)=>{
            var temp=data.sectorList;
            switch (nextProps.sortDataType)
            {
                case "STRING":
                    temp = stringSort(temp, nextProps.sortColumnId, sortType);
                    break;
                case "NUMBER":
                    temp = numericSort(temp, nextProps.sortColumnId, sortType);
                    break;

                default: break;

            }
            sectorData[index].sectorList=temp;
        }
        )};
        this.setState({data:sectorData});

    }

    render() {

    return (<div className="fillheight">
                <div className="dynamicheightscroll" style={ location.pathname === '/PortfolioTrackerSSODion' ? { height: 'calc(100% - 190px)' } : {}}>
                {this.state.data.map((data, index) =>
                <div>
                    <div className="tab-content expanded tabnomargin ">
                    <div className="col-12 sector-banking">
                        <p className="heading">
                            <span>{data.sectorName}</span>
                        </p>
                    </div>
                    <div>
                        {data.sectorList.map((a, i) =>
                            (
                            <PortfolioRowitems
                                key={a.symbol}
                                row={a}
                                onSymbolClick={this.onSymbolClick.bind(this)}
                                selectedsegmentTrade={this.props.selectedsegment}
                                flag={this.props.flag}
                            />
                            )
                        )}
                    </div>

                    </div>
                    <div className="row data-total-sector portfolioDetails-row">
                        <div className="col-2 valign">
                            TOTAL
                        </div>
                        <div className="col text-right valign">

                        </div>
                        <div className="col text-right valign">

                        </div>
                        <div className="col text-right valign">
                            {data.totalInvestment}
                        </div>
                        <div className="col text-right valign">

                        </div>
                        <div className="col text-right valign">
                            {data.totalValue}
                        </div>
                        <div className="col text-right valign" style={{color: Math.sign(data.todayPL) === -1 ? "#E5283F" : "#1EBF8A"}}>
                            {data.todayPL}
                        </div>
                        <div className="col text-right valign">

                        </div>
                        <div className="col text-right valign" style={{color: Math.sign(data.totalPL) === -1 ? "#E5283F" : "#1EBF8A"}}>
                            {data.totalPL}
                        </div>
                        <div className="col text-right valign">

                        </div>
                        <div className="col text-right valign" style={{color: Math.sign(data.totalRealisedPL) === -1 ? "#E5283F" : "#1EBF8A"}}>
                            {data.totalRealisedPL}
                        </div>
                        <div className="col text-right valign">

                        </div>
                    </div>
                </div>
                )}
                <div className="row bottom-note-new">
                {(this.state.data?.find((item) => !!item.sectorList.find((sector)=> sector.avgBuyPrice === '0.00'))) && <><span className="warning-pnl" style={{color: "#E5283F"}}>* Apologies, the buy price for some stocks is unavailable, resulting in Avg Price displayed as 0 and impacting Gain/Loss calculation.</span><br></br></>}
                * Buy Rate includes Actual Rate + (Brokerage + GST/Service Tax + Transaction Charges)<br></br>
                * Sell Rate includes Actual Rate - (Brokerage + GST/Service Tax + Transaction Charges)
                </div>
                </div>

            </div>
    )

 }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setScripInfo: (data) => setScripInfo(dispatch, data)
    };
};
export default  withRouter(connect(null, mapDispatchToProps)(GroupBySectorNew));
// export default GroupBySector;}