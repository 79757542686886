import React from "react"

class ButtonTab extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    onButtonClick(tab) {
        if(this.props.callbackHandler){
            this.props.callbackHandler(tab);
        }
    }

    render() {
        return (
            <div className={"btn-parent btn-group "+(this.props.customStyle && this.props.customStyle) } role="group" aria-label="Button Bar">
                {this.props.tabs.map(function (tab, key) {
                    return <button type="button" className={"btn-btnbar btn btn-default "+(this.props.activeTab === tab ? "active" : '')} key={key}
                        onClick={() => this.onButtonClick(tab)}
                        disabled={this.props.isDisabled} >{tab}</button>
                }, this)}
            </div>
        );
    }
}

export default ButtonTab;