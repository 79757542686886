import React from 'react';

class MenuSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            searchText: ""
        }
        this.toggleMenu = this.toggleMenu.bind(this);
        this.searchTextBoxchange = this.searchTextBoxchange.bind(this);
        this.clearAllStudies = this.clearAllStudies.bind(this);
        this.removeStudy = this.removeStudy.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.openStudyModal = this.openStudyModal.bind(this);
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    handleClick(event) {
        if (!this.dropdown.contains(event.target)) {
            this.setState({ menuOpen: false, searchText: "" });
        }
    }

    searchTextBoxchange(event) {
        this.setState({ searchText: event.target.value });
    }

    toggleMenu(open) {
        this.setState({ menuOpen: open, searchText: "" });
    }


    addedStudies() {
        let addedStudyNames = [];
        if (this.props.ciq) {
            let addedStudies = this.props.ciq.layout.studies;
            for (let study in addedStudies) {
                addedStudyNames.push(study);
            }
        }
        return addedStudyNames;
    }

    removeStudy(studyName) {
        if (this.props.removeStudy && this.props.ciq) {
            this.props.removeStudy(this.props.ciq, studyName);
        }
        this.setState({ menuOpen: false });
    }

    clearAllStudies() {
        if (this.props.clearAllStudies) {
            this.props.clearAllStudies();
        }
        this.setState({ menuOpen: false })
    }

    openStudyModal(study) {
        //let layoutStudies = this.props.ciq.layout.studies;
        // let sd = layoutStudies[study];
        if (this.props.openStudyModal) {

        }
    }

    filterStudyOptions() {
        let options;
        if (this.state.searchText === "") {
            options = this.props.options.map((option, i) => {
                let onSelect = this.props.needsCiq ? this.props.handleOptionSelect.bind(this, this.props.ciq, option) : this.props.handleOptionSelect.bind(this, option),
                    optionLabel = this.props.name ? option[this.props.name] : option
                return (
                    <menu-option key={'menuSelectOption' + this.props.keyName + i} className='option' onClick={onSelect}>{optionLabel}</menu-option>
                );
            });
        } else {
            let filteredOptions = this.props.options.filter((data) => {
                let name = data.name.toLowerCase();
                return name.indexOf(this.state.searchText.toLowerCase()) >= 0;
            });
            options = filteredOptions.map((option, i) => {
                let onSelect = this.props.needsCiq ? this.props.handleOptionSelect.bind(this, this.props.ciq, option) : this.props.handleOptionSelect.bind(this, option),
                    optionLabel = this.props.name ? option[this.props.name] : option
                return (
                    <menu-option key={'menuSelectOption' + this.props.keyName + i} className='option' onClick={onSelect}>{optionLabel}</menu-option>
                );
            });
        }
        return options;
    }

    render() {
        let options = this.filterStudyOptions();
        let addedStudies = this.addedStudies();
        let menuDisplay = {
            display: this.state.menuOpen ? 'block' : 'none'
        };

        return (
            // <menu-select id={this.props.menuId} onMouseOver={this.toggleMenu.bind(this, true)} onMouseOut={this.toggleMenu.bind(this, false)} onClick={this.toggleMenu.bind(this, false)}>
            //onMouseLeave={this.toggleMenu.bind(this, false)}
            <menu-select id={this.props.menuId} ref={(dropdown) => { this.dropdown = dropdown }} >
                <div onClick={this.toggleMenu.bind(this, !this.state.menuOpen)}>
                    {this.props.showTitle && <span className='title'>{this.props.title}</span>}
                    <button className={this.props.menuIcon}> </button>
                </div>
                <menu-select-options className='ps-container' style={menuDisplay} >
                    {
                        this.props.isStudy ?
                            <div className="added-studies">
                                <div className="header">
                                    CURRENT STUDIES
                                </div>
                                <div className="studies-list">
                                    {
                                        addedStudies.map((study) => {
                                            return (
                                                <div className="study-names">
                                                {/* /<span onClick={() => { this.openStudyModal(study) }}> */}
                                                {/* todo open study edit through added study list dropdown*/}
                                                    <span>
                                                        {study}
                                                    </span>
                                                    <span>
                                                        <button type="button" className="close" onClick={() => { this.removeStudy(study) }}><img src="assets/svg/close.svg" alt="close" /></button>
                                                    </span>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                <div className="footer">
                                    <button className={"studies-clear " + (addedStudies.length === 0 ? 'disabled-clear' : '')} onClick={this.clearAllStudies} disabled={addedStudies.length === 0 ? true : false}> CLEAR ALL</button>
                                </div>
                            </div> : <div></div>
                    }
                    {
                        this.props.isStudy ?
                            <input type="text" ref={(input) => { input && input.focus() }} onChange={this.searchTextBoxchange}
                                spellcheck="off" value={this.state.searchText}
                                autocomplete="off" autocorrect="off" autocapitalize="off"
                                placeholder="search..."
                                style={{ height: "30px", width: "100%" }} />
                            : <div></div>
                    }
                    <div onClick={this.toggleMenu.bind(this, false)} style={{ overflowY: 'auto' }}>
                        {options}
                    </div>
                </menu-select-options>
            </menu-select>
        );
    }
}

export default MenuSelect