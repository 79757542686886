import React from 'react';
import { Popover, OverlayTrigger } from "react-bootstrap"
var dateFormat = require('dateformat');

class DateInitailize extends React.Component {

    constructor(props) {
        super(props);
        this.curTime = this.props.now ? this.props.now : dateFormat(new Date(), "dd/mm/yyyy");
    }

    componentDidMount() {
        var refs = this;
        if (refs.props.selectCallback) {
            refs.props.selectCallback(this.curTime);
        }
        var id = refs.props.id ? ("#" + refs.props.id) : "#datepicker"
        window.$(id).datepicker({
            format: 'dd/mm/yyyy',
            toggleActive: false,
            todayHighlight: false,
            oldNewDisabled: true,
            ignoreReadonly: true,
            startDate: '-7y',
            templates: {
                leftArrow: '<i class="fa fa-chevron-left"></i>',
                rightArrow: '<i class="fa fa-chevron-right"></i>'
            }
        }).on('changeDate', function (e) {
            if (refs.props.selectCallback) {
                refs.props.selectCallback(window.$(id).datepicker('getFormattedDate'));
            }
        })
    }

    render() {
        return (
            <div className="custom-datepicker" id={this.props.id ? this.props.id : "datepicker"} data-date={this.curTime}></div>
        )
    }
}

class DatePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = { date: dateFormat(new Date(), "dd/mm/yyyy") }
    }

    selectCallback(date) {
        this.setState({ date: date });
    }

    setSelected(date) {
        // TODO: Set the selected date to the UI
    }

    render() {

        const popoverDatepicker = (
            <Popover className="popup-green" id="popover-date">
                <DateInitailize selectCallback={this.selectCallback.bind(this)} now={this.props.now} id={this.props.id} />
                <div className="date-footer">
                    <button type="button" class="date-btn cancel" onClick={() => { this.refs.popover.hide() }}>CANCEL</button>
                    <button type="button" class="date-btn" onClick={() => {
                        this.refs.popover.hide();
                        this.props.selectedDateCallback && this.props.selectedDateCallback(this.state.date)
                    }}>OK</button>
                </div>
            </Popover>
        );

        return (
            <OverlayTrigger trigger={['click']} ref="popover" placement={this.props.placement ? this.props.placement : "bottom"} container={this.props.container} containerPadding={25} overlay={popoverDatepicker} rootClose>
                <div className="date-holder-parent">
                    {this.props.showDate && <p className={this.props.holderstyle ? this.props.holderstyle : "date-holder"}>{this.props.now ? this.props.now : this.state.date}</p>}
                    {this.props.hideicon ? '' :
                        <span id="datepicker-icon">
                            <img src='assets/svg/calendar.svg' alt="calender" />
                        </span>
                    }
                </div>
            </OverlayTrigger>
        )
    }
}

export default DatePicker;


//dont delete

/*var x = window.$('#datepicker .datepicker-days tr td.old');
if (x.length > 0) {
    x.css('visibility', 'hidden');
    if (x.length === 7) {
        x.parent().hide();
    }
}

var x = window.$('#datepicker .datepicker-days tr td.new');
if (x.length > 0) {
    x.hide();
}*/