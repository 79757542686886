import React,{Component} from "react";
import { BaseUrl } from "../common/constants"

class NavbarMenuItem extends Component {

    // constructor(props) {
    //     super(props);

    // }

    onDropDownClick(e, path, moduleName,id) {
        console.log("id==========");
        console.log(id);
        console.log("moduleName============");
        console.log(moduleName);
        console.log("path==================");
        console.log(path);

        e.preventDefault();

        // if(id === 'bko_pre'){
        //     console.log("in if condition===");
        //     window.open(BaseUrl + "/Reports/ReportWebService", "_blank");  
        // }
        if (moduleName) {
            this.props.setExpandWidget(moduleName);
        } 
        if(path.indexOf("#") === -1 ){
            console.log("in histroy repoter========");
            this.props.history.push(path);
        }
    }

    render() {
        return ([
            <li key={this.props.row.name} className="nav-submenu dropdown-submenu nav-item dropdown" style={{minWidth:"100px"}}><a className="dropdown-toggle nav-list" data-toggle="dropdown" >{this.props.row.name}</a>
                <ul className="dropdown-menu">
                    {this.props.row.submenu.map(function (data, key) {
                        if (data.submenu) {
                            return [
                                <NavbarMenuItem row={data} key={key} history={this.props.history}/>
                            ]
                        }
                        else {
                            return [<li key={data.name} className="dropdown-subMenu">
                                <a onClick={(e) => this.onDropDownClick(e, data.path, data.module,data.id)}>{data.name}</a>
                            </li>,
                            <li key={data.name + 1} role="separator" className="common-dropdown-divider divider"></li>
                            ]
                        }
                    }, this)
                    }
                </ul>
            </li>,
            <li key={this.props.row.name + 1} role="separator" className="common-dropdown-divider divider" ></li>
        ])
    }
}

export default NavbarMenuItem;
