import { UrlExtensions, StoreKeys, BaseUrl, localStorageKeys } from '../common/constants';
import { storeConfig, clearSessionStorage } from '../common/utils'
import { submitLogout, setToLogout } from './login/submitLogin'
import store from '../store'

import { httpPost } from './base/api'


// ====  theme ====

export const setTheme = function (theme, dispatch) {
    dispatch({
        type: StoreKeys.ACTIVE_THEME,
        payload: theme
    });
}



// ================

export const getConfig = function (dispatch, callback) {
    var target = BaseUrl + UrlExtensions.GET_CONFIG;
    var request = { request: { data: {} } }
    httpPost(target, request)
        .then(function (data) {
            storeConfig(JSON.stringify(data.response.data));
            dispatch({ type: StoreKeys.CONFIG_STATUS, payload: { status: true } });
            if (callback)
                callback(false);
        }).catch(function (error) {
            // dispatch({ type: StoreKeys.SHOW_DIALOG, payload: { showDialog: true, header: "Network" } });
            if (callback)
                callback(true);
        });
}


// ================ check Valid session ================

export const checkSessionValid = function (responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.VALIDATE_SESSION;
    var request = { request: { data: {} } }
    httpPost(target, request, true)
        .then(function (response) {
            console.log('dddrequest succeeded with JSON response', response);
            responseCallback(response);
        }).catch(function (error) {
            console.log('dddrequest failed', error);
            clearSessionStorage();
            store.dispatch({ type: StoreKeys.LOGIN_STATUS, payload: { loginStatus: false } })
            store.dispatch({ type: StoreKeys.SHOW_DIALOG, payload: { showDialog: false } })
            store.dispatch({ type: StoreKeys.SHOW_ORDER_DIALOG, payload: false })
            store.dispatch({ type: StoreKeys.SHOW_CHART_DIALOG, payload: false })
            errorCallback(error);
        });
}

export const toggleDialog = function (showDialog, dispatch) {
    dispatch({ type: StoreKeys.SHOW_DIALOG, payload: { showDialog: showDialog } });
}

//error handler for app level check
export const commonErrorHandler = function (error) {
    store.dispatch({
        type: StoreKeys.SHOW_DIALOG, payload: {
            showDialog: true, closeBtn: false, message: error, header: "Indiabulls",
            buttons: [{
                name: "ok", action: function () {
                    clearSessionStorage();
                    store.dispatch({ type: StoreKeys.LOGIN_STATUS, payload: { loginStatus: false } })
                    store.dispatch({ type: StoreKeys.SHOW_DIALOG, payload: { showDialog: false } })
                    store.dispatch({ type: StoreKeys.SHOW_ORDER_DIALOG, payload: false })
                    store.dispatch({ type: StoreKeys.SHOW_CHART_DIALOG, payload: false })
                }
            }]
        }
    });
}

export const commonErrorHandler2 = function (error) {
    Promise.all([
        store.dispatch({ type: StoreKeys.TO_LOGOUT, payload: true }),
        clearSessionStorage(),
        store.dispatch({ type: StoreKeys.LOGIN_STATUS, payload: { loginStatus: false } }),
        store.dispatch({ type: StoreKeys.SHOW_ORDER_DIALOG, payload: false }),
        store.dispatch({ type: StoreKeys.SHOW_CHART_DIALOG, payload: false })
    ]).then(
        store.dispatch({
            type: StoreKeys.SHOW_DIALOG, payload: {
                showDialog: true, closeBtn: false, message: error, header: "Indiabulls",
                buttons: [{
                    name: "ok", action: function () {
                        store.dispatch({ type: StoreKeys.SHOW_DIALOG, payload: { showDialog: false } })
                    }
                }]
            }
        }
        )
        )

    // store.dispatch({
    //     type: StoreKeys.SHOW_DIALOG, payload: {
    //         showDialog: true, closeBtn: false, message: error, header: "Indiabulls",
    //         buttons: [{
    //             name: "ok", action: function () {
    //                 clearSessionStorage();
    //                 store.dispatch({ type: StoreKeys.LOGIN_STATUS, payload: { loginStatus: false } })
    //                 store.dispatch({ type: StoreKeys.SHOW_DIALOG, payload: { showDialog: false } })
    //                 store.dispatch({ type: StoreKeys.SHOW_ORDER_DIALOG, payload: false })
    //                 store.dispatch({ type: StoreKeys.SHOW_CHART_DIALOG, payload: false })
    //             }
    //         }]
    //     }
    // });
}

export const onSessionTimeout = function (dispatch) {
    Promise.all([
        dispatch({ type: StoreKeys.TO_LOGOUT, payload: true }),
        clearSessionStorage(),
        dispatch({ type: StoreKeys.LOGIN_STATUS, payload: { loginStatus: false } }),
        dispatch({ type: StoreKeys.SHOW_ORDER_DIALOG, payload: false }),
        dispatch({ type: StoreKeys.SHOW_CHART_DIALOG, payload: false })
    ]).then(
        dispatch({
            type: StoreKeys.SHOW_DIALOG, payload: {
                showDialog: true, closeBtn: false, message: "Session has expired due to inactivity. Please login again.", header: "Session Timeout",
                buttons: [{
                    name: "ok", action: function () {
                        store.dispatch({ type: StoreKeys.SHOW_DIALOG, payload: { showDialog: false } })
                    }
                }]
            }
        }
        )
        )
}

//common dialog
export const commonDialog = function (dispatch, payload) {
    store.dispatch({
        type: StoreKeys.SHOW_DIALOG, payload: payload
    });
}

//mtf dialog
export const showMTFDefnition = function(dispatch, payload){
    store.dispatch({
        type: StoreKeys.SHOW_MTF_DEFNITION, payload: payload
    });
}

export const showMTFAlert = function(dispatch, payload){
    store.dispatch({
        type: StoreKeys.SHOW_MTF_ALERT, payload: payload
    });
}

export const showRiskDisclosure = function(dispatch, payload){
    store.dispatch({
        type: StoreKeys.SHOW_RISK_DISCLOSURE, payload: payload
    });
}


//==========================TR stream  functions=========================


export const getTrStreamFlag = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.TR_STREAM_FLAG;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('getTrStreamFlag request succeeded with JSON response', response); 
        responseCallback(response);
        // return response;
    }).catch(function (error) {
        console.log('getTrStreamFlag request failed', error);
        errorCallback(error);
    });
}

export const streamLogging = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.STREAM_LOGGING; 
    console.log("streamLogging-->target-->"+target);   
    httpPost(target, requestData)
    .then(function (response) {        
        console.log('StreamLogging request succeeded with JSON response', response); 
        responseCallback(response);
        // return response;
    }).catch(function (error) {
        console.log('StreamLogging request failed', error);
        errorCallback(error);
    });
}
