import { UrlExtensions, BaseUrl } from '../../common/constants';
import { httpPost } from '../base/api'


// === Get IPO ====
export const getIPORequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_IPO;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const getIPODataRequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_IPO_DATA;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const validateDematAcc = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.VALIDATE_DEMAT_ACC;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}


export const getDPDetails = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.DP_DETAILS;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}
export const addIPO = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.ADD_IPO;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const getIPOOrderBookRequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.ORDER_BOOK;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const getDeleteBasketequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.DELETE_BASKET;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}
// create baskte


export const getCreateBasketequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.CREATE_BASKET;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// get basket list

export const getBasketListrequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_BASKETLIST;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}


//  add basket symbol

export const AddBasketSymbolrequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.ADD_BASKET_SYMBOL;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// rename basket

export const RenameBasketrequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.RENAME_BASKTE;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// get basket symbol

export const GetBasketSymbolBasketrequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_BASKET_SYMBOL;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// basket execution

export const BasketExecutionrequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.BASKET_EXCUTION;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

//  execution log

export const BasketExecutionLogrequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.BASKET_EXECUTION_LOG;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// delete basket symbol

export const DeleteSymbolrequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.DELETE_BASKET_SYMBOL;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}


// modify basket order 

export const ModifyBasketrequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.MODIFY_BASKET_ORDER;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}









