import React from 'react';
import { connect } from 'react-redux';
import SetOTPPage from '../../components/prelogin/SetOTP';
import { isValidClientId } from '../../validators/loginValidations'
import { dormantClientID, dormantRegenerateOTP, dormantVerifyOTP } from '../../actions/login/preloginrequests';
import { showErrorDialog } from '../../actions/orderentry/orderentryaction'
import { progressBar } from '../../controls/progress'
import { setCookie } from '../../common/utils'

const ClientIDPage = props => {
    return (
        <div>
            <div style={{ marginTop: 50, color: '#000' }}>
                <div style={{ textAlign: 'left' }}>Reactivate Dormant</div>
                <div style={{ textAlign: 'left', fontSize: 12, color: '#ccc' }}>Enter Your Registered Client Id</div>
            </div>
            <div className="otp-mobile" style={{ marginTop: 30 }}>
                <div className='icon_style mobile-input'>
                    {/* <span className="fa fa-user-o login_icon "></span> */}
                    <img className="login_icon" src="assets/images/clientid2.png" alt="login" />
                    <input className="form-control mobile_login_formcontrol" value={props.value} type="text" name="clientid" placeholder="Client ID"
                        onChange={e => props.onInputChange("clientid", e)} onKeyPress={e => props.onKeyPress("clientid", e)}
                        ref={props.clientIdRef}
                        maxlength="12"
                    >
                    </input>
                </div>
                <button type="button" className="form-control login_btn mobile-btn" onClick={props.callbackHandler}>CONTINUE</button>
            </div>
        </div>
    );
}

const Success = props => {
    return (
        <div>
            <div style={{ marginTop: 50 }}>
                <div style={{ textAlign: 'center', fontSize: "16px", color: '#1EBF8A' }}>SUCCESS </div>
                <div style={{ textAlign: 'center', marginTop: 20, fontSize: "14px", color: '#000' }}>{props.msg} </div>
            </div>

            {/* <div style={{ marginTop: 30 }}> */}
            {/* <i className="fa fa-hourglass-2" style={{ color: "#1EBF8A", height: 100 }} aria-hidden="true" /> */}
            {/* <button type="button" className="form-control login_btn " style={{ background: '#1EBF8A', color: "#fff" }} onClick={props.callbackHandler}>OKAY</button> */}
            {/* </div> */}
        </div>
    );
}

class ReactivateDormantMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPage: 'ClientId',
            status: false,
            inProgress: false,
            error: '',
            userid: "",
            successMsg: '',
            resendMsg: ' '
        }
    }

    componentDidMount() {
        if (this.clientId)
            this.clientId.focus();
            setCookie("isMobileDormant", "", 0);
        
    }

    onButtonClick() {
        switch (this.state.selectedPage) {
            case "Setotp":
                this.setState({ selectedPage: "SetnewPassword" });
                break;
            case "SetnewPassword":
                this.setState({ selectedPage: "Success" });
                break;
            // case "Success":
            //     this.setState({ status: true });
            //     break;
            default: break;
        }
    }

    onBackButtonClicked() {
        switch (this.state.selectedPage) {
            // case "ClientId":
            //     this.props.history.goBack();
            //     break;
            case "Setotp":
                this.setState({ selectedPage: "ClientId" });
                break;
            case "Success":
                this.setState({ selectedPage: "Setotp" });
                break;
            default: break;
        }
    }

    onInputChange(id, e) {
        switch (id) {
            case "clientid":
                let value = e.target.value;
                if (value.length <= 7) {
                    this.setState({ userid: value.toUpperCase() });
                }
                break;
            default: break;
        }
    }

    onKeyPress(name, e) {
        if (e.key === 'Enter') {
            this.placeDormantClientRequest();
        }
    }

    placeDormantClientRequest() {
        var error;
        error = isValidClientId(this.state.userid);
        if (error !== '') {
            this.props.showErrorDialog({ header: "Error", message: error });
            return;
        }

        this.setState({ inProgress: true, error: '' });
        var requestData = {
            request: {
                data: {
                    userId: this.state.userid,
                }
            }
        };
        dormantClientID(requestData, this.parseDormantClientResponse.bind(this), this.parseDormantClientError.bind(this));
    }

    parseDormantClientResponse(responseData) {
        this.setState({ inProgress: false, selectedPage: "Setotp" })
    }

    parseDormantClientError(error) {
        this.setState({ inProgress: false, error: error.message }, () => {
            this.props.showErrorDialog({ header: "Error", message: error.message });
        });
    }

    placeRegenerateOTPRequest() {
        this.setState({ inProgress: true, error: '', resendMsg: ' ' });
        var requestData = {
            request: {
                data: {
                    userId: this.state.userid
                }
            }
        };
        dormantRegenerateOTP(requestData, this.parseRegenerateOTPResponse.bind(this), this.parseRegenerateOTPError.bind(this));
    }

    parseRegenerateOTPResponse(responseData) {
        this.setState({ inProgress: false, selectedPage: "Setotp", resendMsg: responseData.response.data.msg });
    }

    parseRegenerateOTPError(error) {
        this.setState({ inProgress: false, error: error.message }, () => {
            this.props.showErrorDialog({ header: "Error", message: error.message });
        });
    }

    placeVerifyOTPRequest() {
        if (this.setotpRef.getWrappedInstance().otpValidation()) {
            this.setState({ inProgress: true, error: '' });
            var requestData = {
                request: {
                    data: {
                        userId: this.state.userid,
                        otp: this.setotpRef.getWrappedInstance().getOTP()
                    }
                }
            };
            dormantVerifyOTP(requestData, this.parseVerifyOTPResponse.bind(this), this.parseVerifyOTPError.bind(this));
        }
    }

    parseVerifyOTPResponse(responseData) {
        this.setState({ inProgress: false, selectedPage: "Success", successMsg: responseData.response.data.msg });
    }

    parseVerifyOTPError(error) {
        this.setState({ inProgress: false, error: error.message }, () => {
            this.props.showErrorDialog({ header: "Error", message: error.message });
        });
    }

    clearMsg() {
        this.setState({ resendMsg: ' ' })
    }
    render() {
        // var progressindicator = (
        //     <div className="progress_block_background">

        //         <svg class="circular-progress" viewBox="25 25 50 50">
        //             <circle class="circular-progress_path" cx="50" cy="50" r="20"
        //                 fill="none" stroke-width="2" stroke-miterlimit="10" />
        //         </svg>

        //     </div>
        // );
        return (
            <div className='mobile-main-container'>

                <img className="logo-mobile" src="assets/svg/india_bulls_logo.svg" alt="Indiabulls" />

                {/* {this.state.selectedPage === "Setotp" ?

                    <button type='button' className='pl-back-btn' onClick={this.onBackButtonClicked.bind(this)}>
                        <img src="assets/svg/back.svg" style={{ height: "15px", marginRight: "5px", paddingTop: "7px" }} />
                        BACK
                    </button>
                    : ''
                } */}

                <div className="fillheight">
                    {this.state.selectedPage === "ClientId" ?
                        <ClientIDPage
                            callbackHandler={this.placeDormantClientRequest.bind(this)}
                            onKeyPress={this.onKeyPress.bind(this)}
                            onInputChange={this.onInputChange.bind(this)}
                            clientIdRef={(id) => { this.clientId = id; }}
                            value={this.state.userid}
                        />
                        : ''
                    }

                    {this.state.selectedPage === "Setotp" ?
                        <SetOTPPage
                            ref={(id) => { this.setotpRef = id }}
                            dormantHandler={this.placeVerifyOTPRequest.bind(this)}
                            userid={this.state.userid}
                            onResendOTPClicked={this.placeRegenerateOTPRequest.bind(this)}
                            resendMsg={this.state.resendMsg}
                            clearMsg={this.clearMsg.bind(this)}
                        />
                        : ''
                    }

                    {this.state.selectedPage === "Success" ?
                        <Success callbackHandler={this.onButtonClick.bind(this)} msg={this.state.successMsg} />
                        : ''
                    }
                    {/* {this.state.status ? this.props.history.push("/login") : ''} */}
                </div>
                {this.state.inProgress ? progressBar() : ''}
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showErrorDialog: (errordata) => showErrorDialog(dispatch, errordata)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReactivateDormantMobile);
