import { UrlExtensions, BaseUrl,localStorageKeys } from '../../common/constants';
import { httpPost,encryptUrl,serialiseForm,generateRandomString } from '../base/api'
import {getItemByKey} from '../../../src/common/utils';


// === Get User Profile ====
export const getUserProfile = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_USER_PROFILE;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Get Commodity User Profile ====
export const getCommodityUserProfile = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_COMMODITY_USER_PROFILE;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const getCashDpHolding = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_CASH_DPHOLDING;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const getMarginDpHolding = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_MARGIN_DPHOLDING;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}
export const getNomineeDetails = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_NOMINEE_DETAILS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const setMTFActivation = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.MTF_ACTIVATION;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// profile email id changes

export const getEmailIdProfile = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.EMAILID_PROFILE;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}




// getEDISAllRequests

export const getEDISAllRequests = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.EDIS_REQ;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

    // get all placed orders which are outstanding/request placed...

export const getPlacedOrders = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.MY_ORDERS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

//get all ISIN details omn the basis of order token


export const getOrderDataDetails = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.ORDER_DETAILS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}
    
export const edisCDSLRevoke = function(requestData, responseCallback, errorCallback){


console.log("requestData")
console.log(requestData)

var url = BaseUrl + UrlExtensions.EDIS_CDSL_REVOKE;
console.log("URL");
console.log(url);
var form = document.createElement("form");
form.setAttribute("method", "POST");
form.setAttribute("action", url, "_blank", "scrollbars=no,menubar=no,height=600,width=800,resizable=yes,toolbar=no,status=no");
form.setAttribute("id","edisCDSLRevokeForm");
var hiddenField = document.createElement("input");
var hiddenField1 = document.createElement("input");
var hiddenField2 = document.createElement("input");
var hiddenField3 = document.createElement("input");
var hiddenField4 = document.createElement("input");
var hiddenField5 = document.createElement("input");
hiddenField.setAttribute('type',"text");
hiddenField1.setAttribute('type',"text");
hiddenField2.setAttribute('type',"text");
hiddenField3.setAttribute('type',"text");
hiddenField4.setAttribute('type',"text");
hiddenField5.setAttribute('type',"text");
hiddenField.setAttribute("name", "BOID")	
hiddenField1.setAttribute("name", "appKey")
hiddenField2.setAttribute("name", "RevocReqDtls")
hiddenField3.setAttribute("name", "clientId")
hiddenField4.setAttribute("name", "source")
hiddenField5.setAttribute("name", "RevocReqLog")
hiddenField.setAttribute("value", requestData.request.BOID)	
hiddenField1.setAttribute("value", JSON.parse( getItemByKey(localStorageKeys.USER_EDIS_DETAILS)).nonPoaAppKey)
hiddenField2.setAttribute("value", requestData.request.RevocReqDtls)	
hiddenField3.setAttribute("value", requestData.request.clientId)
hiddenField4.setAttribute("value", requestData.request.source)
hiddenField5.setAttribute("value", requestData.request.RevocReqLog)
form.appendChild(hiddenField);
form.appendChild(hiddenField1);
form.appendChild(hiddenField2);
form.appendChild(hiddenField3);
form.appendChild(hiddenField4);
form.appendChild(hiddenField5);
document.body.appendChild(form);
// form.submit();
var targetEdisRevokeForm = serialiseForm("edisCDSLRevokeForm").trim();
var key = generateRandomString(32);
console.log(url+"?source=W&"+encryptUrl(targetEdisRevokeForm, key));
window.location.href = url+"?source=W&"+encryptUrl(targetEdisRevokeForm, key)+"@@"+key;
}
