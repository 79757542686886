import React from 'react';
import InputPlusMinus from '../../controls/InputPlusMinus'
import { transferPosition } from '../../actions/netpositions/netpostionrequest'
import { productType } from "../../common/constants"
import Dropdown from '../../controls/Dropdown';
import { commonDropDownType ,localStorageKeys} from "../../common/constants"
import ToggleSwitch from '../../controls/ToggleSwitch'
import { getQuote, getHoldingsPositions} from '../../actions/quote/quoterequests';
import { comaSeparator, calcChangePercentage, calcChange ,getItemByKey} from '../../common/utils';
const TransferSuccess = (props) => {
    return (
        <div className="text-center">
            <div>
                {props.success ?
                    < img src="assets/svg/order_transfer_success.svg" className="img-responsive transfer-img center-block" alt="success" />
                    :
                    <img src="assets/svg/order_failed.svg" className="img-responsive center-block" alt="orderfailed" />
                }
            </div>
            <div >{props.msg}</div>
        </div>
    )
}

class TransferModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            success: false,
            header: '',
            inProgress: false,
            error: '',
            selectedType: this.props.transferType[0].name,
            changeper: '',
            change: '',
            lastUpdatedTime: '',
            ltp: '',
            lotSize: '',
            holdingstransferdata:{},
            confirmqtyNonPoa:'0',
            Nonconfmqty:'0'
        };
        this.selectConvertionType = this.selectConvertionType.bind(this);
        this.tag = "transfer"
    }

    componentDidMount() {
        this.getQuote();
    }


// holding api call
TransferHoldingsPositionsRequest(symobj) {
    console.log("vvhvhgvhvhvg");
    this.setState({ inProgress: true, holdings_error: '', holdingstransferdata: {} });

    var requestData = {
        request: {
            data: {
                "sym": symobj
            }
        }
    };

    getHoldingsPositions(requestData, this.parseHoldingsPositionsResponse.bind(this), this.parseHoldingsPositionsError.bind(this));
}

parseHoldingsPositionsResponse(responseData) {
    console.log("holdings data");
    console.log(responseData);


    var dpq = 0;
    try {
        dpq = responseData.response.data.holdings.cash.dp;
    }
    catch (ex) {
        dpq = '-'
    }
    this.setState({ 
        inProgress: false, 
        holdings_error: '', 
        dpquantity: dpq, 
        holdingstransferdata: responseData.response.data,
        confirmqtyNonPoa: responseData.response.data.holdings.cash.confNonPoaQty,
        Nonconfmqty: responseData.response.data.holdings.cash.nonPoaQty
    });
}

parseHoldingsPositionsError(error) {
    this.setState({ inProgress: false, holdings_error: error.message });
}

// holding end

    getQuote = () => {
        this.setState({ inProgress: true, error: '' });
        if (this.props.transferSymbol && this.props.transferSymbol.sym) {
            var requestData = {
                request: {
                    data: {
                        sym: this.props.transferSymbol.sym
                    }
                }
            };
            getQuote(requestData, this.parseGetQuoteResponse.bind(this), this.parseGetQuoteError.bind(this));
        }
    }

    parseGetQuoteResponse = (responseData) => {
        if (responseData.response && responseData.response.data) {
            let quoteData = responseData.response.data.quote;
            this.setState({
                inProgress: false, error: '',
                lastUpdatedTime: quoteData.lUpdatedTime,
                change: quoteData.chng,
                changeper: quoteData.chngPer,
                ltp: quoteData.ltp,
                lotSize: quoteData.lot
            }, () => {
                this.onSocketRegister(quoteData);
                this.TransferHoldingsPositionsRequest(quoteData.sym);
            })
        }
    }

    parseGetQuoteError = (error) => {
        this.setState({ inProgress: false })
    }

    closeDialog = () => {
        this.props.closeDialog();
    }

    successCallback = () => {
        this.props.closeDialog(true);
    }

    selectConvertionType(type) {
        this.setState({ selectedType: type })
    }

    onRequestTransfer() {
        console.log("hello intraday");
        if ((this.inputRef.getValue() >= this.props.transferSymbol.lot) || (this.props.transferSymbol.sym.exc == "MCX" && this.inputRef.getValue() >=1))  {
            var requestData = {
                request: {
                    data: {
                        sym: this.props.transferSymbol.sym,
                        qty: this.inputRef.getValue(),
                        fromPOS: this.props.transferSymbol.prdType,
                        toPOS: this.state.selectedType,
                        ordAction: this.props.transferSymbol.netQty.indexOf("-") === -1 ? "buy" : "sell"
                    }
                }
            };
            this.setState({ inProgress: true, error: "" });
            transferPosition(requestData, this.parseTransferResponse.bind(this), this.parseTransferError.bind(this));
        } else if (this.inputRef.getValue() <= 0) {
            this.props.showErrorDialog("Quantity should be greater than 0");
        } else if (this.inputRef.getValue() > parseInt(this.props.transferSymbol.netQty)) {
            this.props.showErrorDialog("Quantity should be lesser than " + this.props.transferSymbol.netQty);
        }
        else if(this.inputRef.getValue()%this.props.transferSymbol.lot!=0) {
            this.props.showErrorDialog("Quantity should be multiple of " + this.props.transferSymbol.lot);
        }
    }

    onTransferClick = () => {
        var fetchOrder = getItemByKey(localStorageKeys.USER_EDIS_DETAILS);
        // if(fetchOrder !== undefined && fetchOrder !== "undefined"){
            var orderType = JSON.parse(fetchOrder);
            console.log("orderInput------------")
            console.log(orderType);
            if(orderType !== undefined && orderType !== null && orderType !== ''){
                if((this.props.transferSymbol.prdType == "intraday" || this.props.transferSymbol.prdType == "SHORTSELL")  
                && orderType.nonPoaFlag == true && this.state.confirmqtyNonPoa <= '0' && this.state.Nonconfmqty > '0'){
                    console.log("hello inraday qty");
                    this.props.showErrorDialog("You can not transfer from " + this.props.transferSymbol.prdType + " to Cash due to Non-POA holding stock");
                }
                else{
                    this.onRequestTransfer();
                }
            }
            else{
                    this.onRequestTransfer();
            }
           
        // }
        

        
    }

    parseTransferResponse(responseData) {
        var data = responseData.response.data.result
        this.setState({
            inProgress: false,
            header: data.status=="true" ? "SUCCESS" : "FAILED",
            msg: data.message,
            success: true,
        });
    }

    parseTransferError(error) {
        this.setState({
            inProgress: false,
            msg: error.message,
            success: true,
            header: "FAILED",
        });
    }


    onSocketRegister = (data) => {
        window.socketEvents.register(data.streamSym + data.sym.exc, { screenName: this.tag, sym: data.streamSym, exc: data.sym.exc }, this.onStreamCallback)
        setTimeout(function () {
            window.socketEvents.subscribe();
        }, 1000)
    }

    onStreamCallback = (streamData) => {
        if (this.props.transferSymbol.streamSym === streamData.data.sym && this.props.transferSymbol.sym.exc === streamData.data.exc) {
            this.setState({
                ltp: streamData.data.ltp,
                change: calcChange(streamData.data),
                changeper: calcChangePercentage(streamData.data),
            })
        }
    }

    render() {
        const transferSymbol = this.props.transferSymbol;
        var value = this.props.transferSymbol.netQty ? Math.abs(this.props.transferSymbol.netQty) : "";
        return (
            <div className={"modal disabledbackground fade dialog-show in"} id="myModal" role="dialog">
                {
                    !this.state.success ?
                        <div className="modal-dialog np-modal">
                            <div className="mtf-header">
                                <span>TRANSFER POSITION</span>
                                <button className="mtf-close" onClick={(e) => { this.closeDialog() }}>&times;</button>
                            </div>

                            <div className="text-center" style={{ paddingBottom: "70px" }}>
                                <div className="row np-transfer symbol-data">
                                    <div className="col-6 text-left nopadding">
                                        <div className="row">
                                            <span className="col-6 nopadding" style={{ fontSize: "18px" }}>{transferSymbol.displaySym}</span>
                                            <span className="col-6" style={{ paddingTop: "5px" }}>
                                            {transferSymbol.sym.exc === "MCX" ?
                                            <ToggleSwitch button1="MCX"  isDisabled={true}
                                                    selected={transferSymbol.sym.exc === "MCX" ? "1" : "1"} />:


                                                <ToggleSwitch button1="NSE" button2="BSE" isDisabled={true}
                                                    selected={transferSymbol.sym.exc === "NSE" ? "1" : "2"} />
                                            }
                                            </span>
                                        </div>
                                        <div>
                                            <span> {productType[transferSymbol.prdType]}</span>
                                        </div>
                                        <div>
                                            <span> {this.state.lastUpdatedTime}</span>
                                        </div>
                                    </div>
                                    <div className="col-6 nopadding text-right">
                                        <div>
                                            <span className={"order-header "} style={{ paddingLeft: '5px' }} > {comaSeparator(this.state.ltp)} </span>
                                            <div className={this.state.change && this.state.change.indexOf('-') ? "positive-change" : "negative-change"} style={{ paddingLeft: '5px' }} >
                                                {comaSeparator(this.state.change)} ({comaSeparator(this.state.changeper)}%)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row positions-details">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-2 nopadding position-np text-right">
                                                NET. QTY
                                        </div>
                                            <div className="col-10 nopadding">
                                                <div className="row">
                                                    <div className="col-3 position-np text-right">
                                                        NET PROFIT/LOSS
                                                </div>
                                                    <div className="col-3 position-np text-right">
                                                        AVG. PRICE
                                                </div>
                                                    <div className="col-3 position-np text-right3">
                                                        REALISED PROFIT/LOSS
                                                </div>
                                                    <div className="col-3 position-np text-right">
                                                        UNREALISED PROFIT/LOSS
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-2 nopadding text-right">
                                                {transferSymbol.netQty}
                                            </div>
                                            <div className="col-10 nopadding">
                                                <div className="row">
                                                    <div className="col-3 text-right">
                                                        {transferSymbol.netPnL}
                                                    </div>
                                                    <div className="col-3 text-right">
                                                        {transferSymbol.avgPrice}
                                                    </div>
                                                    <div className="col-3 text-right">
                                                        {transferSymbol.bookedPnl}
                                                    </div>
                                                    <div className="col-3 text-right">
                                                        {transferSymbol.unRealPnl}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 nopadding">
                                        <div className="col-12 ">
                                            <div className="row">
                                                <div className="col-3 position-np text-right">
                                                    {
                                                        this.props.selectedday === "all" ?
                                                            "CURRENT NET QTY" : "BUY QTY"
                                                    }
                                                </div>
                                                <div className="col-3 position-np text-right">
                                                    {
                                                        this.props.selectedday === "all" ?
                                                            "CURRENT AVG. PRICE" : "BUY AVG. PRICE"
                                                    }
                                                </div>
                                                <div className="col-3 position-np text-right">
                                                    {
                                                        this.props.selectedday === "all" ?
                                                            "PREVIOUS QTY" : "SELL QTY"
                                                    }
                                                </div>
                                                <div className="col-3 position-np text-right">
                                                    {
                                                        this.props.selectedday === "all" ?
                                                            "PREVIOUS AVG. PRICE" : "SELL AVG. PRICE"
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 nopadding">
                                        <div className="col-12 ">
                                            <div className="row">
                                                <div className="col-3 text-right">
                                                    {
                                                        this.props.selectedday === "all" ?
                                                            transferSymbol.currPos : transferSymbol.buyQty
                                                    }
                                                </div>
                                                <div className="col-3 text-right">
                                                    {
                                                        this.props.selectedday === "all" ?
                                                            comaSeparator(transferSymbol.currAvgPrice) :
                                                            comaSeparator(transferSymbol.buyAvgPrice)
                                                    }
                                                </div>
                                                <div className="col-3 text-right">
                                                    {
                                                        this.props.selectedday === "all" ?
                                                            transferSymbol.prevPos : transferSymbol.sellQty
                                                    }
                                                </div>
                                                <div className="col-3 text-right">
                                                    {
                                                        this.props.selectedday === "all" ?
                                                            comaSeparator(transferSymbol.prevAvgPrice) :
                                                            comaSeparator(transferSymbol.sellAvgPrice)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row transfer-part">
                                    <div className="col-5">
                                        <div className="text-left position-np" style={{ paddingBottom: "5px" }}>QUANTITY</div>
                                        <div>
                                            <InputPlusMinus isnumber={true}
                                                minValue={1}
                                                maxValue={value}
                                                defaultValue={value}
                                                incrementvalue={this.state.lotSize}
                                                ref={inputRef => this.inputRef = inputRef} />
                                        </div>
                                    </div>
                                    <div className="col-3"></div>
                                    <div className="col-4 text-right">
                                        <div className="text-right position-np" style={{ paddingBottom: "5px" }}>TRANSFER TO</div>
                                        <div>
                                            {
                                                this.props.transferType.length > 1 ? <span className="transfer-type">
                                                    <Dropdown dropdownType={commonDropDownType.DEFAULT} onSelectionChanged={this.selectConvertionType} list={this.props.transferType} /></span> :
                                                    <span className="transfer-type">{this.props.transferType[0].name}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div >
                            <div className="mtf-footer">
                                <div className="buttons">
                                    <button type="button" className="mtf-buttons" onClick={this.onTransferClick.bind(this)}>TRANSFER</button>
                                </div>
                            </div>
                            {this.state.inProgress && <div className="progress_block_background">
                                <img className="subh-loader" src="assets/images/loader.gif" alt="loader" />
                            </div>
                            }
                        </div >
                        : <div className="modal-dialog np-success">
                            <div className="mtf-header">
                                <span>{this.state.header}</span>
                                <button className="mtf-close" onClick={(e) => { this.closeDialog() }}>&times;</button>
                            </div>
                            <TransferSuccess
                                msg={this.state.msg}
                                success={this.state.success}
                                onTransferClose={this.closeDialog.bind(this)}
                            />
                            <div className="mtf-footer">
                                <div className="buttons">
                                    <button type="button" className="mtf-buttons" onClick={this.successCallback.bind(this)}>OK</button>
                                </div>
                            </div>
                        </div>
                }
            </div >
        );
    }
};
export default TransferModal;