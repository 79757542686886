import React from 'react'
import  '../css/summary.css'
class SummaryHeader  extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            _SUMMARY_HEADER : ['Details','Client ID','Booked P/L','Notional P/L','Total MTM (Booked P/L+ Notional P/L)','ANOV (For Short Position)']
        }
    }
    renderSummaryHeader(){
        return this.state._SUMMARY_HEADER.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
         })
     }
    render(){
        return(
            <thead>
                <tr>{this.renderSummaryHeader()}</tr>
            </thead>
        )
    }
}

export default SummaryHeader;