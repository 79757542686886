import React from 'react'
import SymbolSearch from '../../../../home/SymbolSearch'
import { symbolSearchType, instrumentType } from '../../../../../common/constants'

class ChartSearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            placeholder: 'Enter Symbol',
            showSearch: false
        }
        this.bindCorrectContext()
    }

    bindCorrectContext() {
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleKeyPress(event) {
        let key = event.key;
        if (key === 'Enter') {
            this.onOptionClick()
            this.setState({ text: "" })
        }
    }

    toggleSearch(visible) {
        this.setState({ showSearch: visible }, () => {
            if (this.state.showSearch) {
                this.symbolSearch.getWrappedInstance().dropParent.classList.add("show");
                this.symbolSearch.getWrappedInstance().dropChild.classList.add("show");
                this.symbolSearch.getWrappedInstance().searchInput.setFocus();
            }
        })
    }

    onSymbolSelected(data) {
        this.props.setSymbol(data.id, data);
        this.setState({ showSearch: false });
    }

    render() {
        return (
            <div className="row contentpanel chart-search">
                {this.props.showSearch &&
                    [!this.state.showSearch && <div className="col-6" style={{height:"25px"}}>
                        <div className={"col-12 contentpanel"} style={{ display: 'flex', alignItems: 'center' }} onClick={() => this.toggleSearch(true)}>
                            <img className="toggle-search" src="assets/svg/search.svg" alt="search"/>
                            <span>
                                <span className="search-symbol">{this.props.symbol}</span>
                                <span className="search-exc">{this.props.symobj.exc} </span>
                            </span>
                        </div>
                    </div>,
                    this.state.showSearch && [
                        <div className="col-1" style={{ paddingLeft: "20px", width: "45px" }} >
                            <img className="toggle-close" src="assets/svg/close.svg" onClick={() => this.toggleSearch(false)} alt="close"/>
                        </div>,
                        <div className={"col-" + (this.props.isSmall ? "11" : "6") + ""} style={{ paddingLeft: "0px" }}>
                            <div className={"col-12 contentpanel"}>
                                <SymbolSearch ref={symbolSearch => { this.symbolSearch = symbolSearch }} searchType={symbolSearchType.chart} isForSelection={true}
                                    callbackHandler={this.onSymbolSelected.bind(this)} />
                            </div>
                        </div>
                    ]
                        ,
                    (!this.props.isSmall && this.props.symobj.instr !== instrumentType.CURRENCY && this.props.symobj.instr !== instrumentType.COMMODITY) && <div className={"col-6 contentanel text-right"}>
                        <button type="button" className="search-btn buy-btn" data-toggle="tooltip" title="Buy" onClick={() => this.props.gotoBuy()}>
                            BUY
                    </button>
                        <button type="button" className="search-btn sell-btn" data-toggle="tooltip" title="Sell" onClick={() => this.props.gotoSell()}>
                            SELL
                    </button>
                    </div>
                    ]

                }
                {<div className={"col-12 contentpanel"} id={this.props.id ? this.props.id + "-hu" : "chartContainer-hu"}>
                    <div style={{ paddingLeft: "23px" }} >
                        <span className="hu-label">O:</span><span id="huopen" className="hu-field"></span>
                        <span className="hu-label">H:</span><span id="huhigh" className="hu-field"></span>
                        <span className="hu-label">L:</span><span id="hulow" className="hu-field"></span>
                        <span className="hu-label">C:</span><span id="huclose" className="hu-field"></span>
                        <span className="hu-label">V:</span><span id="huvol" className="hu-field"></span>
                    </div>
                </div>}
            </div>
        );
    }
}

export default ChartSearchBar;
