import {
    TradeActions, orderTypes, validityTypes, advanceOrderTypes, orderEntryTypes
} from '../common/constants'

export const areValidOrderInputs = function (orderinputs) {

    if (!orderinputs || !orderinputs.symobj)
        return 'Unable to proceed';

    if (orderinputs.symobj.instr === "STK") {
        //equity
        return validateEquityInputs(orderinputs);
    }
    else if (orderinputs.symobj.instr === "FUTSTK" || orderinputs.symobj.instr === "OPTSTK"
        || orderinputs.symobj.instr === "FUTIDX" || orderinputs.symobj.instr === "OPTIDX") {
        let error = validateEquityInputs(orderinputs); // common validations
        // no specific yet.
        return error;
    }
    else if (orderinputs.symobj.instr === "FUTCUR" || orderinputs.symobj.instr === "OPTCUR") {
        let error = validateEquityInputs(orderinputs); // common validations
        // no specific yet.
        return error;
    }
    else if (orderinputs.symobj.instr === "FUTCOM" || orderinputs.symobj.instr === "OPTCOM" || orderinputs.symobj.instr === "OPTFUT") {
        let error = validateEquityInputs(orderinputs); // common validations
        // no specific yet.
        return error;
    }

    else if (orderinputs.symobj.instr.indexOf('SPR') !== -1) {
        let error = validateEquityInputs(orderinputs);
        return error;
    }

    //TODO:  validate all inputs
    return 'Inputs not validated';
}

// Validations for equity
var validateEquityInputs = function (orderinputs) {
    console.log("orderinputs validation");
    console.log(orderinputs);
    var qty;



    // non poa qty check=============
    if((orderinputs.NonPoaFlag == 'CDSL' || orderinputs.NonPoaFlag == 'NSDL') && (orderinputs.indpselectedpin == 'cash') && (orderinputs.action == 'SELL')){
        if(parseInt(orderinputs.HoldingQTY) < parseInt(orderinputs.quantity))

        return 'Order quantity can not be greater then E-dis quantity' ;
    }


    // ================================================== 
    // quantity
    // Validation: 
    // 1. Quantity cannot be empty or zero always
    // 2. Quantity should be multiple of lotsize
    // 3. If SELL order, and in DP selected, quantity should be less than max quantity. 
    if (!orderinputs.quantity || orderinputs.quantity === '')
        return 'Please enter valid Quantity';

    qty = parseInt(orderinputs.quantity, 10);

    if (qty <= 0)
        return 'Please enter valid Quantity';

    var lotsize = orderinputs.requiredlotsize;
    /***NTO-1051 removed as per bug
    if (qty < lotsize) {
        return 'Quantity should be greater than or equal to ' + lotsize;
    }*/

    if (lotsize !== 0) {
        if (qty % lotsize !== 0)
            return 'Quantity should be multiple of ' + lotsize;
    }

    //TODO: do after api available.
    // if(orderinputs.action === TradeActions.SELL && orderinputs.indp) {
    //     var max = parseInt(orderinputs.maxquantity);
    //     if(qty > max)
    //         return 'Quantity should be less than ' + max;
    // }

    var decimalplaces = 2;
    if (orderinputs.symobj.instr === "FUTCUR" || orderinputs.symobj.instr === "OPTCUR") {
        decimalplaces = 4;
    }

    // ================================================== 
    // price
    // Validation: 
    // 1. Price should only be for 'Limit' Order
    // 2. Price cannot be empty or zero 
    // 3. Price should be multiple of ticksize
    if (orderinputs.ordertype === orderTypes[0]) { 
        if (orderinputs.symobj.instr.indexOf('SPR') != -1 && orderinputs.price===0){          
            orderinputs.price='0.00';
        }      
        if (!orderinputs.price || orderinputs.price === '')
       //if (orderinputs.price === '')
            return 'Please enter valid Price';
        if (orderinputs.symobj.instr.indexOf('SPR') == -1){
            if (parseFloat(orderinputs.price) <= 0)
                return 'Please enter valid Price';
        }
        if (orderinputs.ticksize && orderinputs.ordertype === orderTypes[0]) {

            let multiplier = Math.pow(10, decimalplaces);
            let prc = parseFloat(orderinputs.price).toFixed(decimalplaces);
            let tsz = parseFloat(orderinputs.ticksize).toFixed(decimalplaces);
            let pr = Math.round(prc * multiplier);
            let ts = Math.round(tsz * multiplier);

            let rem = (Math.round(pr % ts)) / multiplier;
            if (rem !== 0) {
                return 'Price should be multiple of Tick Size which is ₹ ' + orderinputs.ticksize + '';
            }
        }
    }

    // ================================================== 
    // disclosed quantity
    // Validation: 
    // 1. Disc. quantity should be minimum (25% for Commodity or 10% for others) of quantity 
    // 2. Disc. quantity should be less than quantity
    // 3. Disc. Quantity should be multiple of lotsize
    if (orderinputs.discquantity) {
        let dq = parseInt(orderinputs.discquantity, 10);

        let minqty = 10;
        if (orderinputs.symobj.instr === "FUTCOM" || orderinputs.symobj.instr === "OPTCOM" || orderinputs.symobj.instr === "OPTFUT") {
            minqty = 25;
        }

        if (dq < qty * minqty / 100)
            return orderinputs.discquantity_label + " should not be less than " + minqty + "% of the Order " + orderinputs.quantity_label;

        if (dq > qty)
            return orderinputs.discquantity_label + " should be less than Order " + orderinputs.quantity_label;

        if (lotsize !== 0) {
            if (dq % lotsize !== 0)
                return orderinputs.discquantity_label + ' should be multiple of Order ' + lotsize;
        }

    }


    // ================================================== 
    // stoploss
    // Validation: 
    // 1. For BUY action, stoploss should be greater than price.
    // 2. For SELL action, stoploss should be less than price.
    if (orderinputs.producttype !== advanceOrderTypes[1] && orderinputs.producttype !== advanceOrderTypes[2] && parseFloat(orderinputs.slprice) > 0) {

        let same = (parseFloat(orderinputs.slprice) === parseFloat(orderinputs.price))

        if (same)
            return 'Stoploss should not be equal to limit price';

        let slGreater = (parseFloat(orderinputs.slprice) > parseFloat(orderinputs.price))

        if (orderinputs.action === TradeActions.BUY && orderinputs.ordertype.key !== orderTypes[1].key) {
            if (slGreater)
                return '\'STOP LOSS PRICE\' cannot be greater than \'PRICE\' for Stop Loss Buy order';
        } else if (orderinputs.action === TradeActions.SELL && orderinputs.ordertype.key !== orderTypes[1].key) {
            if (!slGreater)
                return '\'STOP LOSS PRICE\' cannot be less than \'PRICE\' for Stop Loss Sell order ';
        }


        if (orderinputs.ticksize) {
            let multiplier = Math.pow(10, decimalplaces);
            let prc = parseFloat(orderinputs.slprice).toFixed(decimalplaces);
            let tsz = parseFloat(orderinputs.ticksize).toFixed(decimalplaces);
            let pr = Math.round(prc * multiplier);
            let ts = Math.round(tsz * multiplier);
            let rem = (Math.round(pr % ts)) / multiplier;
            if (rem !== 0) {
                return '\'STOP LOSS PRICE\' should be multiple of ' + orderinputs.ticksize;
            }
        }

    }

    // ================================================== 
    // order validity
    // Validation: 
    // 1. if 'VTD' is selected, date cannot be emtpy
    // 2. date should always be greater than or equal to today
    if (orderinputs.ordervalidity === validityTypes[2]) {
        if (!orderinputs.vtddate || orderinputs.vtddate === '')
            return 'Please select VTD date';
        var today = new Date(), selectedDate, temp;
        temp = orderinputs.vtddate.split("/");
        selectedDate = new Date(temp[2], temp[1] - 1, temp[0]);
        if (selectedDate <= today)
            return 'Please select valid VTD date';
    }


    // ================================================== 
    // Validation: 
    // 1. For Cover Order, Stop loss is mandatory
    if (orderinputs.producttype === advanceOrderTypes[1]) {
        if (!orderinputs.coTriggerPrice || orderinputs.coTriggerPrice === '')
            return 'Please enter valid StopLoss Price';
        if (parseInt(orderinputs.coTriggerPrice, 10) <= 0)
            return 'Please enter valid StopLoss Price';
        let same = (parseFloat(orderinputs.coTriggerPrice) === parseFloat(orderinputs.price))

        if (same)
            return 'Stoploss should not be equal to limit price';

        let slGreater = (parseFloat(orderinputs.coTriggerPrice) > parseFloat(orderinputs.price))
        
        if (orderinputs.action === TradeActions.BUY && orderinputs.ordertype.key !== orderTypes[1].key
            && !orderinputs.isCoverLegModify) {
            if (slGreater)
                return '\'STOP LOSS PRICE\' cannot be greater than \'PRICE\' for Stop Loss Buy order';
        } else if (orderinputs.action === TradeActions.SELL && orderinputs.ordertype.key !== orderTypes[1].key
            && !orderinputs.isCoverLegModify) {
            if (!slGreater)
                return '\'STOP LOSS PRICE\' cannot be less than \'PRICE\' for Stop Loss Sell order ';
        }
    }


    // ================================================== 
    // advance order - bracket order 
    // Validation: 
    // 1. For Bracket Order, Stop loss, Target Price, Trailing stoploss are mandatory
    if (orderinputs.producttype === advanceOrderTypes[2] && orderinputs.orderentrytype === orderEntryTypes.NEW) {
        let triggerPrice = parseFloat(orderinputs.boTriggerPrice);
        let targetPrice = parseFloat(orderinputs.boTargetPrice);
        let limitPrice = parseFloat(orderinputs.price);
        let minPrice = parseFloat(orderinputs.minPrice);
        let maxPrice = parseFloat(orderinputs.maxPrice);
        if (!orderinputs.boTriggerPrice || orderinputs.boTriggerPrice === '')
            return 'Please enter valid StopLoss Price(Abs Value)';
        if (triggerPrice <= 0)
            return 'Please enter valid StopLoss Price(Abs Value)';

        if (!orderinputs.boTargetPrice || orderinputs.boTargetPrice === '')
            return 'Please enter valid Target Price(Abs Value)';
        if (targetPrice <= 0)
            return 'Please enter valid Target Price(Abs Value)';

        //tick validation
        if (orderinputs.ticksize) {

            let multiplier = Math.pow(10, decimalplaces);
            let tsz = parseFloat(orderinputs.ticksize).toFixed(decimalplaces);
            let tp = Math.round(triggerPrice * multiplier);
            let ts = Math.round(tsz * multiplier);

            let rem = (Math.round(tp % ts)) / multiplier;
            if (rem !== 0) {
                return 'Trigger Price should be multiple of Tick Size which is ₹' + orderinputs.ticksize + '';
            }
        }

        if (orderinputs.ticksize) {

            let multiplier = Math.pow(10, decimalplaces);
            let tsz = parseFloat(orderinputs.ticksize).toFixed(decimalplaces);
            let tarPrice = Math.round(targetPrice * multiplier);
            let ts = Math.round(tsz * multiplier);
            let rem = (Math.round(tarPrice % ts)) / multiplier;
            if (rem !== 0) {
                return 'Target Price should be multiple of Tick Size which is ₹' + orderinputs.ticksize + '';
            }
        }
        //price validation
        if (orderinputs.action === TradeActions.BUY) {
            if ((limitPrice + targetPrice) > maxPrice) {
                return "Please enter Target Price(Abs Value) within the Daily Price Range"
            }
            if ((limitPrice - triggerPrice) < minPrice) {
                return "Please enter Trigger Price(Abs Value) within the Daily Price Range"
            }
        }
        if (orderinputs.action === TradeActions.SELL) {
            if ((limitPrice - targetPrice) > maxPrice) {
                return "Please enter Target Price(Abs Value) within the Daily Price Range"
            }
            if ((limitPrice + triggerPrice) < minPrice) {
                return "Please enter Trigger Price(Abs Value) within the Daily Price Range"
            }
        }

    }


    // ================================================== 
    // all perfect
    return undefined;
}
// separate price and trigger price validation

export const priceValidation = function (min, max, orderinputs) {   
    if (orderinputs.symobj.instr.indexOf('SPR') == -1){       
        if (parseFloat(orderinputs.price) <= 0) {
            return 'Please enter valid price'
        }
    }
   // commented by birender because on market high some packet missed at streamer having DPR range 
   // if (parseFloat(orderinputs.price) > parseFloat(max)) {
   //     return 'Price Should not be greater than Daily Price Range';
   //  }
   //if (parseFloat(orderinputs.price) < parseFloat(min)) {
   //     return 'Price Should not be lesser than Daily Price Range';
   //  }
   if (parseFloat(orderinputs.slprice) > parseFloat(max) && parseFloat(orderinputs.slprice) > 0) {
     return 'Trigger Price Should not be greater than Daily Price Range';
  }
   if (parseFloat(orderinputs.slprice) < parseFloat(min) && parseFloat(orderinputs.slprice) > 0) {
     return 'Trigger Price Should not be lesser than Daily Price Range';
   }
    
    
    if (orderinputs.producttype === advanceOrderTypes[1]) {
        if (orderinputs.orderentrytype === orderEntryTypes.MODIFY && !orderinputs.isCoverLegModify) {
            return undefined;
        }
        if (parseFloat(orderinputs.coTriggerPrice) < parseFloat(orderinputs.minCvrTriggerPrice) && parseFloat(orderinputs.coTriggerPrice) > 0) {
            return 'Trigger Price Should not be lesser than Price Range';
        }
        if (parseFloat(orderinputs.coTriggerPrice) > parseFloat(orderinputs.maxCvrTriggerPrice) && parseFloat(orderinputs.coTriggerPrice) > 0) {
            return 'Trigger Price Should not be greater than Price Range';
        }
    }
    return undefined;
}


