import React from 'react';
import { Popover, OverlayTrigger } from "react-bootstrap"

class ReportPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focussed: true,
        }
    }
    
    render() {

        const popoverHoverFocus = (

            <Popover className="products-popover" id="marginutil-positioned-top" >
                <div>                    
                    <div className="row contentpanel">
                        <div className="col-11">
                          {this.props.popupMessage}
                        </div>   
                        <div className="col-1">
                            <img src='assets/svg/close.svg' className="closebtn" data-toggle="tooltip" title="Close" onClick={() => { this.refs.productsPopover.hide() }} alt="close"/>
                        </div>                      
                    </div>
                </div>
            </Popover>
        );

        return (
            <OverlayTrigger ref="productsPopover" trigger={['click']} placement="bottom" container={this.props.parentRef} containerPadding={25} overlay={popoverHoverFocus} rootClose>
                <div className="watchlistoptions text-right">
                    <img src="assets/svg/info_selected.svg" className="info-icon" onerror="this.src='assets/images/logo.png'" alt="selected"/>
                </div>
            </OverlayTrigger>
        )
    }
}

export default ReportPopup;