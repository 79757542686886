
import { StoreKeys, localStorageKeys } from '../../common/constants';
import { getItemByKey, setItemByKey } from '../../common/utils'

export const setExpandWidget = (dispatch, activeWidget) =>{
    dispatch({type:StoreKeys.SCRIP_ACTIVE_WIDGET, payload:activeWidget});
}

export const setScripInfo = function(dispatch, scripinfo) {  
    console.log('changing')  
    setItemByKey(localStorageKeys.SCRIP_INFO, JSON.stringify(scripinfo));
    dispatch({ type: StoreKeys.SCRIP_INFO, payload: scripinfo });   
}

export const setMarketDepth = function(dispatch, depthDetails) {    
    dispatch({ type: StoreKeys.DEPTH_DETAILS, payload: depthDetails });   
}

export const setOptionChainExpandWidget = function (activeWidget, dispatch) {
    dispatch({
        type: StoreKeys.SCRIP_OPTION_CHAIN,
        payload: activeWidget
    });
}