
import { StoreKeys } from '../../common/constants';

export const setShowMarketDepth=(dispatch, cb) => {
    dispatch({type:StoreKeys.SHOW_MARKET_DEPTH, payload:cb})
}

export const toggleOrderDialog = (dispatch, value) =>{
    dispatch({type:StoreKeys.SHOW_ORDER_DIALOG, payload:value})
}

export const showOrderDialog = (dispatch, value) =>{
    dispatch({type:StoreKeys.SHOW_ORDER_DIALOG_DATA, payload:value})
}

export const onOrderSuccess = (dispatch, value) => {
    dispatch({type:StoreKeys.ON_ORDER_SUCCESS, payload:value})
}

export const toggleCancelOrderDialog = (dispatch, value) =>{
    dispatch({type:StoreKeys.SHOW_CANCEL_ORDER_DIALOG, payload:value})
}

export const showCancelOrderDialog = (dispatch, value) =>{
    dispatch({type:StoreKeys.SHOW_CANCEL_ORDER_DIALOG_DATA, payload:value})
}

export const showCancelOrderDialogCallback = (dispatch, value) =>{
    dispatch({type:StoreKeys.SHOW_CANCEL_ORDER_DIALOG_CALLBACK, payload:value})
}

export const showErrorDialog = function(dispatch, errordata) {    
    dispatch({ type: StoreKeys.SHOW_DIALOG, payload: { showDialog: true, header: errordata.header, message: errordata.message } });   
}