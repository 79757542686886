import { StoreKeys } from '../common/constants';

const intialState = {
    showMTFDefnition: false
};

export const mtfDefnition = (state = intialState, action) => {
    if (action.type === StoreKeys.SHOW_MTF_DEFNITION) {        
        const { showMTFDefnition } = action.payload;
        return Object.assign({}, state, {
            showMTFDefnition: showMTFDefnition ? true : false
        });
    }
    else {
        return state;
    }
};

const initialAlert = {
    showMTFAlert : false
}
export const mtfAlert = (state = intialState, action) => {
    if (action.type === StoreKeys.SHOW_MTF_ALERT) {        
        const { showMTFAlert } = action.payload;
        return Object.assign({}, state, {
            showMTFAlert: showMTFAlert ? true : false
        });
    }
    else {
        return state;
    }
};