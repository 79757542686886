import React, { Component } from 'react';
import DatePicker from '../../controls/DatePicker'
import Dialog from '../../controls/Dialog';
import IPOOrderRow from './IPOOrderRow';
import { getIPOOrderBookRequest } from '../../actions/products/productrequest'
import { progressBar } from '../../controls/progress'
import {getItemByKey} from '../../common/utils'
import {localStorageKeys} from '../../common/constants'

var dateFormat = require('dateformat');
class IPOOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inProgress:false,
            error:'',
            msgerror:'',
            showDialog:false,
            selectedFromDate: dateFormat((new Date()).setDate((new Date()).getDate() - 10), "dd/mm/yyyy"),
            selectedToDate: dateFormat(new Date(), "dd/mm/yyyy"),
            ipoData:[]
        }
    }

    

    componentWillMount() {
        this.getIPOOrderBook();
    }

    getIPOOrderBook() {
        
        this.setState({ inProgress: true, error: ''});
        let appKey = getItemByKey(localStorageKeys.PIB_APP_KEY) !== "" ? JSON.parse(getItemByKey(localStorageKeys.PIB_APP_KEY)) : "";
        var requestData = {
            request: {
                data: {
                    selectedFromDate:this.state.selectedFromDate,
                    selectedToDate:this.state.selectedToDate,
                    appKey:appKey 
                }
            }
        };
        getIPOOrderBookRequest(requestData, this.parseIPOOrderBookResponse.bind(this), this.parseIPOOrderBookError.bind(this));
    }

    parseIPOOrderBookResponse(responseData) {
        this.setState({ inProgress: false, 
                ipoData: responseData.response.data.data});
        
    }

    parseIPOOrderBookError(error) {
        this.setState({ inProgress: false, error: error.message!="Request Failed"?error.message:"Request failed, Please try again." });
        
    }

    onSelectedFromDate(selectedDate) {
        var fromdate=this.state.selectedFromDate;
        if (this.checkDateExceeds(selectedDate)) {
            this.setState({ selectedFromDate: selectedDate }, () =>
                (this.dateFilterCheck()) ?
                    this.getIPOOrderBook() : (this.setState({ msgerror: 'From Date Should Be Less Than To Date', selectedFromDate: fromdate, showDialog:true })))
        }
        else
            this.setState({ msgerror: "Exceeded Today's Date", showDialog:true })
    }
    onSelectedToDate(selectedDate) {
        var todate=this.state.selectedToDate;
        if (this.checkDateExceeds(selectedDate)) {
            this.setState({ selectedToDate: selectedDate }, () =>
                (this.dateFilterCheck()) ?
                    this.getIPOOrderBook() : (this.setState({ msgerror: 'From Date Should Be Less Than To Date', selectedToDate: todate, showDialog:true })))
        }
        else
            this.setState({ msgerror: "Exceeded Today's Date", showDialog:true })

    }

    dateFilterCheck() {
        var fromDate = this.state.selectedFromDate;
        var toDate = this.state.selectedToDate;
        var splitFrom = fromDate.split("/");
        var splitTo = toDate.split("/");
        return (Date.parse(splitFrom[2] + "-" + splitFrom[1] + '-' + splitFrom[0]) <= Date.parse(splitTo[2] + "-" + splitTo[1] + '-' + splitTo[0])) ? true : false
    }
    checkDateExceeds(inputDate) {
        var splitDate = inputDate.split("/");
        return (Date.parse(splitDate[2] + "-" + splitDate[1] + '-' + splitDate[0]) > new Date()) ? false : true;
    }

    closePopup() {
        this.setState({
            showDialog: false,
            error:'',
            msgerror:''
        }, ()=>{this.getIPOOrderBook()});        
    }

    
    render()
    {
        var errorHolder = (
            <div className="error-holder" style={{ marginTop: "20px" }}>
                {this.state.error}
            </div>
        );

        return (
            <div className="fillheight fillwidth" style={this.state.inProgress?{pointerEvents:'none', opacity:'0.7'}:{}}>
                {this.state.showDialog?
                <Dialog header="Error" message={this.state.msgerror}
                    positiveButtonName="OK" positiveClick={this.closePopup.bind(this)}/>
                :''}
                
                
                <div className="tab-content expanded fillheight">
                    <div className="row contentpanel">
                        <div className="col-5 contentpanel mytrades-date-parent">
                            <div>
                                From
                            </div>
                            <DatePicker inputVisible={false} order={true} placement="right"
                                selectedDateCallback={this.onSelectedFromDate.bind(this)}
                                now={this.state.selectedFromDate}
                                showDate={true}
                                id="datepicker1" />
                            <div style={{ padding: "0px 0px 0px 30px" }} >
                                To
                            </div>
                            <DatePicker inputVisible={false} order={true} placement="right"
                                selectedDateCallback={this.onSelectedToDate.bind(this)}
                                now={this.state.selectedToDate}
                                showDate={true}
                                id="datepicker2" />
                        </div>
                    </div>  
                    <div className="row ipo-header">
                        <div className="col-4">
                            IPO NAME
                        </div>
                        <div className="col-4 text-center">
                            QTY/LOTS
                        </div>
                        <div className="col-3 text-center">
                            BID STATUS
                        </div>
                        <div className="col-1 text-center">

                        </div>
                    </div>  
                    {this.state.error ? errorHolder :  
                        this.state.inProgress ? progressBar() :                 
                    <div className="panel-group ipo-order-height ipoScroll">
                        {this.state.ipoData.map((row, key) => {
                            return (<IPOOrderRow row={row}
                                onClickBid={(name, data)=>{this.props.onClickBid(name, data)}}
                                reloadOrders={()=>this.getIPOOrderBook()}
                                />)
                        })}                
                    </div>}
                </div>
            </div>
        );

    }
}

export default IPOOrders;