import { UrlExtensions, BaseUrl } from '../../common/constants';
import { httpPost } from '../base/api'


// === Get My Orders ====
export const getMyOrders = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.MY_ORDERS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Get My Trades ====
export const getMyTrades = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.MY_TRADES;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}


// === Get Order Log ====
export const getOrderLog = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.ORDER_LOG;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}
