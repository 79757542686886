import React, { Component } from 'react';

class ToggleSwitch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected ? this.props.selected : '1'
        };
        this.handleClick = this.handleClick.bind(this);
        this.setSelected = this.setSelected.bind(this);
    }

    componentDidMount() {
        this.setState({ selected: this.props.selected ? this.props.selected : '1' });
    }

    componentWillReceiveProps(nextProps){
        if(this.props.selected !== nextProps.selected){
            this.setSelected(nextProps.selected);
        }
    }

    setSelected(index) {
        this.setState({ selected: index });
    }

    handleClick(e) {
        this.setState({ selected: e.target.value });
        this.props.onChange ? this.props.onChange(e.target.value) : null;
    }



    render() {

        var classNames;
        if (this.props.button_type == "order") {
            if (this.state.selected === '1') {
                classNames = {
                    'normal': 'toggle-button order-button',
                    'selected': 'toggle-button order-button active-buy',
                }
            }
            if (this.state.selected === '2') {
                classNames = {
                    'normal': 'toggle-button order-button',
                    'selected': 'toggle-button order-button active-sell',
                }
            }
        } else {
            classNames = {
                'normal': 'toggle-button',
                'selected': 'toggle-button active',
            }
        }

        return (
            <div className="toggle-switch" >
                {this.props.button1 ?
                    <button className={(this.state.selected === '1') ? classNames.selected : classNames.normal} type="button" value='1'
                        onClick={(e) => this.handleClick(e)} disabled={this.props.isDisabled} style={this.props.additionalbtnstyle} >
                        {this.props.button1}
                    </button>
                    : ''}
                {this.props.button2 ?
                    <button className={(this.state.selected === '2') ? classNames.selected : classNames.normal} type="button" value='2'
                        onClick={(e) => this.handleClick(e)} disabled={this.props.isDisabled} style={this.props.additionalbtnstyle}>
                        {this.props.button2}
                    </button>
                    : ''}
                {this.props.button3 ?
                <button className={(this.state.selected === '3') ? classNames.selected : classNames.normal} type="button" id="nonpoa" value='3'
                    onClick={(e) => this.handleClick(e)} disabled={this.props.isDisabled} style={this.props.additionalbtnstyle}>
                    {this.props.button3}
                </button>
                : ''}
            </div>
        );
    }
}

export default ToggleSwitch;