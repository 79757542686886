import React from 'react';
//import ChartSymbol from './UI/ChartSymbol'
//import Comparison from './UI/Comparison'
//import Periodicity from './UI/Periodicity'
import ChartTypes from './UI/ChartTypes'
import Crosshairs from './UI/Crosshairs'
//import TimeZoneButton from './UI/TimeZoneButton'
import DrawingToolbarButton from './UI/DrawingToolbarButton'
import ShareChartModal from './Modals/ShareChartModal';
//import { ModuleKeys } from '../../../../common/constants'

//redux components
//import ThemeUIBaseView from '../containers/ThemeUIBaseView'
import StudyBaseView from '../containers/StudyBaseView'

const UI = (props) => {
	return (
		<ciq-UI-Wrapper>
			{
				props.ciq !== null
					?
					<nav className="ciq-nav chart-header">
						{/* <div className="left"> */}
						{/* search symbol implementation */}
						{/* <ChartSymbol {...props} /> */}
						{/* <Comparison {...props} /> */}
						{/* </div> */}
						<div className="left  widget-title chart-header-padding">
							<Crosshairs {...props} />
							{/* <Periodicity {...props} /> */}
							<ChartTypes {...props} menuIcon={"candle-icon chart-icon"} />
							<StudyBaseView ciq={props.ciq} {...props} menuIcon={"study-icon chart-icon"} />
							{/* <ThemeUIBaseView ciq={props.ciq} {...props} /> */}
							{/* <TimeZoneButton {...props} /> */}
							<DrawingToolbarButton {...props} />
							<ShareChartModal {...props} />
						</div>

						<div className="right chart-expander widget-title" onClick={() => props.onExpandToggle(props.componentType)}>
							{/* {props.showCrosshairs && <div id={props.id ? props.id+"-hu" : "chartContainer-hu"}>
								<div style={{paddingRight:"10px"}} >
									<span className="hu-label">P</span><span id="huprice" className="hu-field"></span>
									<span className="hu-label">O</span><span id="huopen" className="hu-field"></span>
									<span className="hu-label">C</span><span id="huclose" className="hu-field"></span>
								</div>
								<div>
									<span className="hu-label">V</span><span id="huvol" className="hu-field"></span>
									<span className="hu-label">H</span><span id="huhigh" className="hu-field"></span>
									<span className="hu-label">L</span><span id="hulow" className="hu-field"></span>
								</div>
							</div>} */}
							<img src={props.isSmall ? "assets/svg/expand.svg" : "assets/svg/collapse.svg"} className="expander-icon" onerror="this.src='assets/images/logo.png'" alt="expand"/>
						</div>
					</nav>
					:
					<div></div>
			}
		</ciq-UI-Wrapper>
	)
}

export default UI
