import React, { Component } from 'react';
import OrderLogModal from './OrderLogModal'
import { setScripInfo } from '../../actions/scripdetails/scripdetailsaction'
import { comaSeparator } from '../../common/utils';
import DescriptionPopover from "../../components/user/DescriptionPopover";
import { progressBar } from '../../controls/progress';
import CoverOrder from './CoverOrder';
import BracketOrder from './BracketOrder';
import {
    commonDropDownType, TradeActions, orderDropdownOptions, orderEntryTypes, productType,
    assetClasses, tabMap
} from "../../common/constants"

class Rowitem extends React.Component {
    constructor() {
        super();
        this.state = {
            showAdcDetails: false,
            showOrderDetails: false
        }
    }

    onShowDetailsClick = () => {
        this.setState({ showOrderDetails: !(this.state.showOrderDetails) }, () => {
            if (this.state.showOrderDetails) {
                this.setState({ showAdcDetails: false });
            }
        });
    }

    showAdvancorders = () => {
        this.setState({ showAdcDetails: !(this.state.showAdcDetails) }, () => {
            if (this.state.showAdcDetails) {
                this.setState({ showOrderDetails: false });
            }
        });
    }

    isOrderCancellable = (row) => {
        if (row.cancellable) {
            return true;
        } else if (row.leg1 && row.leg2 && productType[row.prdType] === productType.BO
            && row.leg1.length > 0 && row.leg2.length > 0) {
            let checkLeg1Cancellable = row.leg1.filter((data) => {
                if (data.cancellable === true) {
                    return true;
                }
            });
            let checkLeg2Cancellable = row.leg2.filter((data) => {
                if (data.cancellable === true) {
                    return true;
                }
            });
            return checkLeg1Cancellable.length > 0 || checkLeg2Cancellable.length > 0 ? true : false;
        } else if (row.leg1 && productType[row.prdType] === productType.CO) {
            return row.leg1.cancellable;
        } else {
            return false;
        }
    }

    render() {
        const { row, key, selectedsegment, qty_label } = this.props;
        const isCancellable = this.isOrderCancellable(row);
        return (
            <div key={key} id="plus">
                <div className="row contentpanel myorders-row fillwidth" >
                    <div className="contentpanel col-9 click" onClick={(e) => {
                        this.onShowDetailsClick()
                    }}>
                        <div className="row">
                            <div className="col-2">
                                <div className="flex-space-start">
                                    <span className={row.ordAction === 'buy' ? "positive-change-blue" : "negative-change"}>
                                        {row.ordAction === 'buy' ? 'B' : 'S'}
                                    </span>
                                    <span className="myorders-sym">
                                        <span className="symbol-link" onClick={(e) => {
                                            e.stopPropagation()
                                            this.props.onSymbolClick(row)
                                        }}>
                                            {row.displaySym}
                                        </span>
                                        <span className="myorders-exc">
                                            {row.sym.exc}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className="col-2 nopadding">
                                <div className="row">
                                    <div className="col-4 text-right nopadding" >
                                        {(productType[row.prdType] === productType.BO || productType[row.prdType] === productType.CO) &&
                                            ((productType[row.prdType] === productType.BO ? ((row.leg1 && row.leg1.length > 0 && row.leg2 && row.leg2.length > 0) ? true : false) : false) ||
                                                (productType[row.prdType] === productType.CO ? (row.leg1 && row.leg1 && row.leg1) ? true : false : false))
                                            ?
                                            <span onClick={(e) => {
                                                e.stopPropagation();
                                                this.showAdvancorders();
                                            }}>
                                                {
                                                    this.state.showAdcDetails ?
                                                        <img src="assets/svg/minus.svg" alt="plus" width="11px" height="11px" /> :
                                                        <img src="assets/svg/plus.svg" alt="plus" width="11px" height="11px" />
                                                }
                                            </span> : ''}
                                    </div>
                                    <div className="col-8 text-right nopadding">
                                        {productType[row.prdType]}
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 text-right">
                                {comaSeparator(row.limitPrice)}
                            </div>
                            <div className="col-1 text-right">
                                {comaSeparator(row.qty)}
                            </div>
                            <div className="col-1 text-right nopadding">
                                {comaSeparator(row.remQty)}
                            </div>
                            <div className="col-2 text-right" onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                {row.ordStatus}
                                {row.rejectReason && row.rejectReason !== "-" && row.rejectReason !== "" ? <DescriptionPopover description={row.rejectReason} /> : ""}
                            </div>
                            <div className="col-1 text-right nopadding">
                                {comaSeparator(row.tradedQty)}
                            </div>
                            <div className="col-1 text-right">
                                {row.ordType}
                            </div>
                        </div>
                        {/* </a> */}
                    </div>

                    <div className="col-3 contentpanel text-right">
                        <div className="row">
                            <div className="col-4">
                                <button type="button" className="form-control myorder-btn modify-btn"
                                    data-tag={row.orderId} onClick={(e) => { this.props.onModifyOrderClick(row, false, false) }}
                                    disabled={!row.modifiable} >
                                    MODIFY
                                </button>
                            </div>
                            <div className="col-4">
                                <button type="button" className={"form-control myorder-btn " + (productType[row.prdType] === productType.BO ||
                                    productType[row.prdType] === productType.CO ? "exit-btn" : "cancel-btn")}
                                    data-tag={row.orderId} onClick={(e) => { this.props.onCancelOrderClick(row, false, false) }}
                                    disabled={!isCancellable}
                                >
                                    {productType[row.prdType] === productType.BO || productType[row.prdType] === productType.CO ? "EXIT" : "CANCEL"}
                                </button>
                            </div>
                            {/* <div className="col-4"> */}
                            <OrderLogModal parentRef={"order" + key} orderid={row.orderId} symobj={row.sym}
                                selectedsegment={selectedsegment}
                                displaySym={row.displaySym} exchange={row.sym.exc}
                                disclosedQty={comaSeparator(row.disQty)}
                                triggerPrice={comaSeparator(row.triggerPrice)}
                                validity={row.ordDuration}
                                productType={row.prdType}
                            />
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                {
                    <div className="myorders-panel-body myorders-accordion expand-row" ref={(ordElement) => this.ordElement = ordElement}
                        style={{ maxHeight: (this.state.showOrderDetails ? this.ordElement.scrollHeight : "") }}>
                        {
                            productType[row.prdType] === productType.BO || productType[row.prdType] === productType.CO ?
                                <div className="my-accordion-padding">
                                    <div className="row accordian-orderheader">
                                        <div className="col-3 text-right">VALIDITY</div>
                                        <div className="col-2 text-right">ORDER REFERENCE NO.</div>
                                        <div className="col-2 text-right">EXCHANGE ORDER NO.</div>
                                        <div className="col-1 text-right">ORDER PLACED BY</div>
                                        <div className="col-1 text-right">DATE & TIME</div>
                                        <div className="col-3"></div>
                                    </div>
                                    <div className="row accordian-orderdata">
                                        <div className="col-3 text-right">{row.ordDuration}</div>
                                        <div className="col-2 text-right">{row.orderId}</div>
                                        <div className="col-2 text-right">{row.exchOrderId}</div>
                                        <div className="col-1 text-right">{row.user}</div>
                                        <div className="col-1 text-right">{row.ordDate}</div>
                                        <div className="col-3"></div>
                                    </div>
                                </div> :
                                <div className="my-accordion-padding">
                                    <div className="row">
                                        <div className="col-1 text-right" style={{ fontSize: "12px", color: "#E4E4E5" }}>{row.ordStatus}</div>
                                        {(this.props.selectedsegment === assetClasses.spread ? '' :
                                            <div className="col-1 text-right">
                                                <div className="accordian-orderheader">TRIGGER PRICE</div>
                                                <div>{row.triggerPrice}</div>
                                            </div>)}
                                        <div className="col-1 text-right">
                                            <div className="accordian-orderheader">VALIDITY</div>
                                            <div>{row.ordDuration}</div>
                                        </div>
                                        {(this.props.selectedsegment === assetClasses.spread ? '' :
                                            <div className="col-1 text-right">
                                                <div className="accordian-orderheader">DISCLOSED {qty_label}
                                                </div>
                                                <div>{row.disQty}</div></div>)}
                                        {(this.props.selectedsegment === assetClasses.spread ? '' :
                                            <div className="col-1 text-right nopadding">
                                                <div className="accordian-orderheader">PROTECTION %</div>
                                                <div>{row.protPercent}</div>
                                            </div>)}
                                        <div className="col-2 text-right nopadding">
                                            <div className="accordian-orderheader">ORDER REFERENCE NO.</div>
                                            <div>{row.orderId}</div>
                                        </div>
                                        <div className="col-2 text-right">
                                            <div className="accordian-orderheader">EXCHANGE ORDER NO.</div>
                                            <div>{row.exchOrderId}</div>
                                        </div>
                                        <div className="col-1 text-right nopadding">
                                            <div className="accordian-orderheader">ORDER PLACED BY</div>
                                            <div>{row.user}</div>
                                        </div>
                                        <div className="col-2 text-right">
                                            <div className="accordian-orderheader">DATE & TIME</div>
                                            <div>{row.ordDate}, {row.ordTime}</div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                }
                {
                    <div className="myorders-panel-body advance-orders-accordion expand-row" ref={(adcElement) => this.adcElement = adcElement}
                        style={{
                            maxHeight: (this.state.showAdcDetails ? (this.adcElement.scrollHeight +
                                this.adcDetails.expElement.scrollHeight + this.adcDetails.leg1.scrollHeight + (this.adcDetails.leg2 ? this.adcDetails.leg2.scrollHeight : 0)) : "")
                        }}>
                        {productType[row.prdType] === productType.BO ?
                            <BracketOrder ref={adcDetails => this.adcDetails = adcDetails} leg1={row.leg1} leg2={row.leg2}
                                orderId={row.orderId} {...this.props} qty={row.tradedQty} /> :
                            <CoverOrder ref={adcDetails => this.adcDetails = adcDetails} leg1={row.leg1}
                                orderId={row.orderId} {...this.props} row={row} />
                        }
                    </div>
                }
                <div className="hdivider" />
            </div>
        );
    }
};
export default Rowitem;
