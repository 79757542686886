import { StoreKeys } from '../common/constants';

export const setExpandWidget = function (activeWidget, dispatch) {
    dispatch({
        type: StoreKeys.DASH_ACTIVE_WIDGET,
        payload: activeWidget
    });
}

export const setFooterWidget = function (activeWidget, dispatch) {
    dispatch({
        type: StoreKeys.FOOTER_ACTIVE_WIDGET,
        payload: activeWidget
    });
}

export const setWatchlistExpandWidget = function (activeWidget, statedata, dispatch) {
    setWatchlistExpandWidgetState(statedata, dispatch);

    dispatch({
        type: StoreKeys.WATCHLIST_ACTIVE_WIDGET,
        payload: activeWidget
    });
}

export const setWatchlist1Selected = function (selected, dispatch) {
    dispatch({
        type: StoreKeys.WATCHLIST_SELECTED_1,
        payload: selected
    });
}

export const setWatchlist2Selected = function (selected, dispatch) {
    dispatch({
        type: StoreKeys.WATCHLIST_SELECTED_2,
        payload: selected
    });
}


export const setWatchlistExpandWidgetState = function (statedata, dispatch) {
    dispatch({
        type: StoreKeys.WATCHLIST_ACTIVE_WIDGET_STATE,
        payload: statedata
    });
}

export const setWatchlistPreSelected = function (selected, dispatch) {
    dispatch({
        type: StoreKeys.WATCHLIST_PRE_SELECTED,
        payload: selected
    });

}

export const setChartsExpandWidget = function (activeWidget, statedata, dispatch) {
    setChartsExpandWidgetState(statedata, dispatch);

    dispatch({
        type: StoreKeys.CHARTS_ACTIVE_WIDGET,
        payload: activeWidget
    });
}

export const setChartsExpandWidgetState = function(statedata, dispatch) {
    dispatch({
        type: StoreKeys.CHARTS_ACTIVE_WIDGET_STATE,
        payload: statedata 
    });
}