import React from "react";
import { FooterActions } from '../../../common/constants'
import { isEquityPermitted, isFnOPermitted, isCurrencyPermitted, isCommodityPermitted ,isShubhPlanPermitted} from "../../../common/permissions";

class Footer extends React.Component {
   

    render() {
        return (
            <footer className="navbar-fixed-bottom">
                <div id="pageFooter">
                    <div>
                        <button type="button" className="btn-footer btn-footer-left" onClick={() => this.props.executeFooterActions(FooterActions.MY_ORDERS)}>
                           MY ORDERS
                        </button>
                        <button type="button" className="btn-footer btn-footer-left" onClick={() => this.props.executeFooterActions(FooterActions.MY_TRADES)}>MY TRADES</button>
                        <button type="button" className="btn-footer btn-footer-left" onClick={() => this.props.executeFooterActions(FooterActions.NET_POSITION)}>NET POSITION</button>
                        <button type="button" className="btn-footer btn-footer-left" onClick={() => this.props.executeFooterActions(FooterActions.MARGIN)}>MARGIN</button>
                        {(isShubhPlanPermitted()===false ||( isEquityPermitted()===false && isFnOPermitted()===false && isCurrencyPermitted()===false && isCommodityPermitted()===true)) ?'':<button type="button" className="btn-footer btn-footer-left" onClick={() => this.props.executeFooterActions(FooterActions.MYTRADINGPLAN)}>MY TRADING PLAN</button>}
                    </div>
                    <div>
                        <button type="button" className="btn-footer btn-footer-right" onClick={() => this.props.executeFooterActions(FooterActions.WATCHLIST)} >
                            WATCHLIST
                        </button>
                        <button type="button" className="btn-footer btn-footer-right" onClick={() => this.props.executeFooterActions(FooterActions.PLACE_ORDER)}>
                            PLACE AN ORDER
                        </button>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;