import React from 'react';
import { ModuleKeys } from '../../../common/constants'
import { HotNewsSmall } from '../../../components/dashboard/exporter'
import { connect } from 'react-redux';
import '../../../css/theme-dark/news.css'

class News extends React.Component {

    // constructor(props) {
    //     super(props);
        
    // }

    componentArray = [
        <HotNewsSmall title="TOP NEWS" componentType={ModuleKeys.HOT_NEWS} isSmall={true} section="hot-pursuit" />,
        <HotNewsSmall title="INTERNATIONAL NEWS" componentType={ModuleKeys.INTERNATIONAL_NEWS} isSmall={true} section="foreign-markets"/>,
        <HotNewsSmall title="ECONOMIC NEWS" componentType={ModuleKeys.ECONOMIC_NEWS} isSmall={true} section="economy-news"/>,
        <HotNewsSmall title="CORPORATE NEWS" componentType={ModuleKeys.CORPORATE_NEWS} isSmall={true} section="corporate-results"/>,
     // <HotNewsSmall title="COMPANY NEWS" componentType={ModuleKeys.COMPANY_NEWS} isSmall={true} section="hot-pursuit"/>,
     // <HotNewsSmall title="SECTOR NEWS" componentType={ModuleKeys.SECTOR_NEWS} isSmall={true} section="hot-pursuit"/>,
    // <HotNewsSmall title="DISCUSSION CLOUD" componentType={ModuleKeys.DISCUSSION_CLOUD} isSmall={true} section="hot-pursuit"/>,
    //  <HotNewsSmall title="CURRENCY" componentType={ModuleKeys.CURRENCY_NEWS} isSmall={true} section="hot-pursuit"/>,
     // <HotNewsSmall title="COMMODITY" componentType={ModuleKeys.COMMODITY_NEWS} isSmall={true} section="hot-pursuit"/>
    ];

    render() {
        var rowData = [];
        return (
            this.props.activeWidget === ModuleKeys.ALL ?
                <div className="fillheight">
                    {this.componentArray.map((data, index) => {

                        rowData[index % 2] = <div className="col-6 col-lg-6 col-md-6 dashboard-row-content">
                            <div className="dashboard-componentholder dashboard-allholders hotnews-small-widget">
                                {data}
                            </div>
                        </div>
                        //to check for each row conatians 3 items
                        if ((index + 1) % 2 === 0) {
                            return <div className="row dashboard-row-holder" >
                                {rowData.map((data) => { return data })}
                            </div>
                        } else return '';
                    })
                    }
                </div>
                :
                <div className="col-12 fillheight contentpanel">
                    <div className="dashboard-parent-holder dashboard-content-holder fillheight">
                        {this.componentArray.map(data => {
                            if (data.props.componentType === this.props.activeWidget) return data;
                            else return '';
                        }
                        )}
                    </div>
                </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeWidget: state.dashboard.activeWidget,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(News); 
