import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { commonDropDownType, TradeActions, orderEntryTypes, productType, instrumentType, positionConvertionTypesEquity } from "../../common/constants"
import Dropdown from '../../controls/Dropdown'
import { showOrderDialog } from '../../actions/orderentry/orderentryaction'
import { setScripInfo } from '../../actions/scripdetails/scripdetailsaction'
import { comaSeparator, stringSort, numericSort } from '../../common/utils'
import { commonDialog, toggleDialog } from '../../actions/common'
import { isFnOIntradayPermitted } from '../../common/permissions'
import SymbolWatchlistDialog from '../../controls/symbolsearch/SymbolWatchlistDialog'
import TransferModal from './TransferModal';
import { getMyOrders } from '../../actions/orders/myordersrequests'
import Dialog from '../../controls/Dialog';

class PositionDataHolder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdownOptions: [{ name: "ADD to WATCHLIST", key: "watchlist" }, { name: "QUOTE", key: "quote" }, { name: "TRANSFER", key: "transfer" }],
            showWatchlistDlg: false,
            selectedSymbol: '',
            showTransfer: false,
            transferSymbol: '',
            positionData: this.props.positionData,
            sortAsc: {
                sym_sort: false, prd_sort: false,
                ltp_sort: false, netQty_sort: false,
                netPnl_sort: false,
                netAvg_sort: false, realPnl_sort: false, unrealPnl_sort: false,
                buyQty_sort: false, buyAvg_sort: false, sellQty_sort: false,
                sellAvg_sort: false
            }
        }
    }

    onSymbolClick = (row) => {
        this.props.setScripInfo(row.sym);
        this.props.history.push("/home/scripdetails");
    }

    onBuy = (e, data) => {
        var orderdetails = this.getOrderDetails(data, TradeActions.BUY, orderEntryTypes.NEW);
        this.props.showOrderDialog(orderdetails);
    }

    onSell = (e, data) => {
        var orderdetails = this.getOrderDetails(data, TradeActions.SELL, orderEntryTypes.NEW);
        this.props.showOrderDialog(orderdetails);
    }

     onSquareOffClick = (data) => {
        var netqty = data.netQty ? parseInt(data.netQty, 10) : 0;
        var taction;
        if (netqty > 0)
            taction = TradeActions.SELL;
        else
            taction = TradeActions.BUY;

        var orderdetails = this.getOrderDetails(data, taction, orderEntryTypes.SQUAREOFF);
        orderdetails.correspondingorder = this.getSquareOffDetails(data);
        this.props.showOrderDialog(orderdetails);
    }

    getOrderDetails(data, taction, ordentrytye) {
        var orderdetails = {
            orderentrytype: ordentrytye,
            action: taction,
            symobj: data.sym,
            displaySym: data.displaySym,
            company: '',
            last: "0.00",
            change: "0.00",
            changeper: "0.00",

        };

        return orderdetails;
    }

    getSquareOffDetails(data) {
        return {
            quantity: data.netQty.indexOf('-') === -1 ? data.netQty : data.netQty.replace('-', ''),
            producttype: data.prdType
        };
    }

    onDropdownOptionClick = (row, value) => {
        if (value === this.state.dropdownOptions[0].name) {
            this.setState({ selectedSymbol: row.sym }, () => {
                this.setState({ showWatchlistDlg: true });
            });
        } else if (value === this.state.dropdownOptions[1].name) {
            this.onSymbolClick(row);
        } else if (value === this.state.dropdownOptions[2].name) {
            console.log("transferSymbol ", row)
            this.setState({ transferSymbol: row }, () => {
                this.setState({ showTransfer: true });
            })
        }
    }

    callbackAddWacthlist = () => {
        this.setState({ showWatchlistDlg: false });
    }

    onTransferClick = (data) => {
        console.log("data", data);
    }

    closeTransfer = (result) => {
        if (result) {
            this.setState({ showTransfer: false }, () => {
                this.props.transferCallback();
            });
        } else {
            this.setState({ showTransfer: false });
        }

    }

    getTransferType(type) {
        if (type) {
            if (this.props.segment === "equity") {
                if (productType[type] === productType.intraday) {
                    return positionConvertionTypesEquity['intraday'];
                } else if (productType[type] === productType.margin) {
                    return positionConvertionTypesEquity['margin'];
                } else if (productType[type] === productType.SHORTSELL) {
                    return positionConvertionTypesEquity['SHORTSELL'];
                } else {
                    return positionConvertionTypesEquity['cash'];
                }
            } else {
                return productType[type] === productType.intraday ? [{ name: "CARRYFORWARD" }] : [{ name: "INTRADAY" }];
            }
        }
        else {
            return '';
        }
    }

    showErrorDialog(message) {
        this.props.commonDialog({
            showDialog: true, type: "small", closeBtn: false, message: message, header: "TransferLog",
            buttons: [{
                name: "ok", action: function () {
                    this.props.toggleDialog(false);
                }.bind(this)
            }]
        });
    }

    getDropOptions = (symbolData) => {
        let options = Object.assign([], this.state.dropdownOptions);
        if (productType[symbolData.prdType] === productType.CO || productType[symbolData.prdType] === productType.BO) {
            options.splice(2, 1)
            return options;
        }
        else {
            if (this.isTransferDisabled(symbolData)) {
                options.splice(2, 1)
            }
            return options;
        }
    }

    isTransferDisabled = (row) => {
        if (row.prdType === undefined || row.isTransfer === "false" || row.isTransfer === false)
            return true;

        if (row.sym.instr === instrumentType.CURRENCY_OPTIONS) {
            if (isFnOIntradayPermitted()) {
                return true;
            }
        }
        if (isNaN(Number(row.netQty)) || Number(row.netQty) === 0) {
            return true;
        }

        return false;

    }

    onSortClicked = (field) => {
        var temp = this.state.positionData, sortTemp = this.state.sortAsc;
        switch (field) {
            case "sym_sort":
                sortTemp.sym_sort = !sortTemp.sym_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.sym_sort) {
                    temp = stringSort(this.state.positionData, "displaySym", "ASC");
                } else {
                    temp = stringSort(this.state.positionData, "displaySym", "DSC");
                }
                break;
            case "prd_sort":
                sortTemp.prd_sort = !sortTemp.prd_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.prd_sort) {
                    temp = stringSort(this.state.positionData, "prdType", "ASC");
                } else {
                    temp = stringSort(this.state.positionData, "prdType", "DSC");
                }
                break;
            case "ltp_sort":
                sortTemp.ltp_sort = !sortTemp.ltp_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.ltp_sort) {
                    temp = numericSort(this.state.positionData, "ltp", "ASC");
                } else {
                    temp = numericSort(this.state.positionData, "ltp", "DSC");
                }
                break;
            case "netQty_sort":
                sortTemp.netQty_sort = !sortTemp.netQty_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.netQty_sort) {
                    temp = numericSort(this.state.positionData, "netQty", "ASC");
                } else {
                    temp = numericSort(this.state.positionData, "netQty", "DSC");
                }
                break;
            case "netAvg_sort":
                sortTemp.netAvg_sort = !sortTemp.netAvg_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.netAvg_sort) {
                    temp = numericSort(this.state.positionData, "avgPrice", "ASC");
                } else {

                    temp = numericSort(this.state.positionData, "avgPrice", "DSC");
                }
                break;
            case "realPnl_sort":
                sortTemp.realPnl_sort = !sortTemp.realPnl_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.realPnl_sort) {
                    temp = numericSort(this.state.positionData, "bookedPnl", "ASC");
                } else {

                    temp = numericSort(this.state.positionData, "bookedPnl", "DSC");
                }
                break;
            case "unrealPnl_sort":
                sortTemp.unrealPnl_sort = !sortTemp.unrealPnl_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.unrealPnl_sort) {
                    temp = numericSort(this.state.positionData, "unRealPnl", "ASC");
                } else {

                    temp = numericSort(this.state.positionData, "unRealPnl", "DSC");
                }
                break;
            case "buyQty_sort":
                sortTemp.buyQty_sort = !sortTemp.buyQty_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.buyQty_sort) {
                    temp = numericSort(this.state.positionData, "buyQty", "ASC");
                } else {

                    temp = numericSort(this.state.positionData, "buyQty", "DSC");
                }
                break;
            case "buyAvg_sort":
                sortTemp.buyAvg_sort = !sortTemp.buyAvg_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.buyAvg_sort) {
                    temp = numericSort(this.state.positionData, "buyAvgPrice", "ASC");
                } else {

                    temp = numericSort(this.state.positionData, "buyAvgPrice", "DSC");
                }
                break;
            case "sellQty_sort":
                sortTemp.sellQty_sort = !sortTemp.sellQty_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.sellQty_sort) {
                    temp = numericSort(this.state.positionData, "sellQty", "ASC");
                } else {

                    temp = numericSort(this.state.positionData, "sellQty", "DSC");
                }
                break;
            case "sellAvg_sort":
                sortTemp.sellAvg_sort = !sortTemp.sellAvg_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.sellAvg_sort) {
                    temp = numericSort(this.state.positionData, "sellAvgPrice", "ASC");
                } else {

                    temp = numericSort(this.state.positionData, "sellAvgPrice", "DSC");
                }
                break;
            default: break;
        }
        this.setState({ positionData: temp });
    }

    placeMyOrdersRequest(data) {
        console.log("placeMyOrdersRequest*********************");
        console.log(data);

        var requestData = {
            request: {
                data: {
                    filters: [
                        { key: 'ordStatus', value: 'outstanding' },
                        { key: 'exc', value: data.sym.exc },
                        { key: 'prdType', value: data.prdType },
                        { key: 'uniqueId', value: data.uniqueId }
                    ],
                    tab: this.props.segment.toLowerCase()
                }
            }
        };
        getMyOrders(requestData, this.parseMyOrdersResponse.bind(this, data), this.parseMyOrdersError.bind(this, data));
    }


    parseMyOrdersResponse(data, responseData) {
        console.log("parseMyOrdersResponse************************");
        console.log(responseData);

        this.setState({
            showDialog:true,
            squareOffMsg: "Order Entry not permitted as there are outstanding orders in this "+(this.props.segment=="equity"?"symbol":"contract")+" in "+(data.prdType).toUpperCase()+" product for this client."
        });

    }

    closePopup() {
        
        this.setState({
            showDialog: false
        });
    }

    parseMyOrdersError(data, error) {
        console.log("parseMyOrdersError************************");
        console.log(error.message);

        
        var netqty = data.netQty ? parseInt(data.netQty, 10) : 0;
        var taction;
        if (netqty > 0)
            taction = TradeActions.SELL;
        else
            taction = TradeActions.BUY;

        var orderdetails = this.getOrderDetails(data, taction, orderEntryTypes.SQUAREOFF);
        orderdetails.correspondingorder = this.getSquareOffDetails(data);
        this.props.showOrderDialog(orderdetails);
    }


    render() {
        return (
            <div style={{ height: "100%" }}>
                <div className="row contentpanel header-np" style={{ padding: "10px 5px" }} >
                    <div className="col-3 nopadding" >
                        <div className="row">
                            <div className="col-5 text-left" style={{ paddingRight: "0px" }}>
                                <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("sym_sort") }}>
                                    <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="icon"></img>
                                </span>
                                <span>SYMBOL</span>
                            </div>
                            <div className="col-4 nopadding text-right">
                                <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("prd_sort") }}>
                                    <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="icon"></img>
                                </span>
                                <span>PRODUCT</span>
                            </div>
                            <div className="col-3 text-right ">
                                <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("ltp_sort") }}>
                                    <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="icon"></img>
                                </span>
                                <span>LTP</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-9 nopadding">
                        <div className="row">
                            <div className="col-9 nopadding">
                                <div className="row">
                                    <div className="col-1 text-right nopadding">
                                        <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("netQty_sort") }}>
                                            <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="icon"></img>
                                        </span>
                                        <span>NET QTY</span>
                                    </div>
                                    <div className="col-6 nopadding">
                                        <div className="row">
                                            <div className="col-3 text-right nopadding">
                                                <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("netPnl_sort") }}>
                                                    <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="icon"></img>
                                                </span>
                                                <span> NET<br /> PROFIT/LOSS</span>
                                            </div>
                                            <div className="col-3 text-right nopadding">
                                                <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("netAvg_sort") }}>
                                                    <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="icon"></img>
                                                </span>
                                                <span>AVG. PRICE</span>
                                            </div>
                                            <div className="col-3 text-right nopadding">
                                                <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("realPnl_sort") }}>
                                                    <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="icon"></img>
                                                </span>
                                                <span>REALISED PROFIT/LOSS</span>
                                            </div>
                                            <div className="col-3 text-right nopadding">
                                                <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("unrealPnl_sort") }}>
                                                    <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="icon"></img>
                                                </span>
                                                <span>UNREALISED PROFIT/LOSS</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1 text-right nopadding">
                                        <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("buyQty_sort") }}>
                                            <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="icon"></img>
                                        </span>
                                        {
                                            this.props.selectedday === "all" ?
                                                <span>CURRENT NET QTY</span> :
                                                <span>BUY QTY</span>
                                        }
                                    </div>
                                    <div className="col-2 text-right">
                                        <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("buyAvg_sort") }}>
                                            <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="icon"></img>
                                        </span>
                                        {
                                            this.props.selectedday === "all" ?
                                                <span>CURRENT <br />AVG PRICE</span> :
                                                <span>BUY <br />AVG PRICE</span>
                                        }
                                    </div>
                                    <div className="col-1 text-right nopadding" style={{ whiteSpace: "nowrap" }}>
                                        <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("sellQty_sort") }}>
                                            <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="icon"></img>
                                        </span>
                                        {
                                            this.props.selectedday === "all" ?
                                                <span>PREVIOUS <br />QTY</span> :
                                                <span>SELL <br />QTY</span>
                                        }
                                    </div>
                                    <div className="col-1 text-right " style={{ whiteSpace: "nowrap", padding: "0px 0px 0px 5px" }}>
                                        <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("sellAvg_sort") }}>
                                            <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" alt="icon"></img>
                                        </span>
                                        {
                                            this.props.selectedday === "all" ?
                                                <span>PREVIOUS <br />AVG PRICE</span> :
                                                <span>SELL <br />AVG PRICE</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                {
                                    this.props.additionalProperties
                                }
                            </div>
                        </div>
                    </div >
                </div >
                <div className="panel-group position-holder" style={{ height: "calc(100% - 55px)", marginTop: "0px", overflowY: "auto" }}>
                    {this.state.positionData.map((row, key) => {
                        return (
                            <div className="row contentpanel data-np">
                                <div className="col-3 nopadding">
                                    <div className="row">
                                        <div className="col-5 text-left">
                                            <span className="symbol-link" onClick={(e) => { this.onSymbolClick(row) }}>{row.displaySym}</span>
                                            <span className="myorders-exc">{row.sym.exc}</span>
                                        </div>
                                        <div className="col-4 nopadding text-right">
                                            {productType[row.prdType]}
                                        </div>
                                        <div className="col-3 text-right ">
                                            {comaSeparator(row.ltp)}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-9 nopadding">
                                    <div className="row">
                                        <div className="col-9 nopadding">
                                            <div className="row">
                                                <div className="col-1 text-right nopadding">
                                                    {row.netQty}
                                                </div>
                                                <div className="col-6 nopadding">
                                                    <div className="row">
                                                        <div className="col-3 text-right nopadding">
                                                            {comaSeparator(row.netPnL)}
                                                        </div>
                                                        <div className="col-3 text-right nopadding">
                                                            {comaSeparator(row.avgPrice)}
                                                        </div>
                                                        <div className="col-3 text-right nopadding">
                                                            {comaSeparator(row.bookedPnl)}
                                                        </div>
                                                        <div className="col-3 text-right nopadding">
                                                            {comaSeparator(row.unRealPnl)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-1 text-right nopadding">
                                                    {
                                                        this.props.selectedday === "all" ?
                                                            row.currPos : row.buyQty
                                                    }
                                                </div>
                                                <div className="col-2 text-right">
                                                    {
                                                        this.props.selectedday === "all" ?
                                                            comaSeparator(row.currAvgPrice) : comaSeparator(row.buyAvgPrice)
                                                    }
                                                </div>
                                                <div className="col-1 text-right nopadding">
                                                    {
                                                        this.props.selectedday === "all" ?
                                                            row.prevPos : row.sellQty
                                                    }
                                                </div>
                                                <div className="col-1 text-right nopadding">
                                                    {
                                                        this.props.selectedday === "all" ?
                                                            comaSeparator(row.prevAvgPrice) : comaSeparator(row.sellAvgPrice)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="np-btn-parent row">
                                                {
                                                    productType[row.prdType] === productType.BO || productType[row.prdType] === productType.CO ?
                                                        <span className="col-10 nopadding">
                                                            <div className="row">
                                                                <div className="col-6" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                                                                    <button type="button" className="np-btn np-buy" onClick={(e) => this.onBuy(e, row)}>BUY</button>
                                                                </div>
                                                                <div className="col-6" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                                                                    <button type="button" className="np-btn np-sell" onClick={(e) => this.onSell(e, row)}>SELL</button>
                                                                </div></div>
                                                        </span>
                                                        : <span className="col-10 ">
                                                            {
                                                                !(Number(row.netQty) === 0 || isNaN(Number(row.netQty)))
                                                                    && productType[row.prdType] !== productType.SHORTSELL ?
                                                                    <div className="text-center">
                                                                        <button type="button" className="np-btn squareoff-btn"
                                                                            onClick={() => this.onSquareOffClick(row)}>
                                                                            SQUARE OFF
                                                                </button></div> : ''
                                                            }
                                                        </span>

                                                }
                                                <div className="col-1"></div>
                                                <div className="col-1">
                                                    <Dropdown style={"equity-pt-drop-down np-arrow"}
                                                        dropdownType={commonDropDownType.ICON}
                                                        alignDefault={true}
                                                        iconName={"fa fa-ellipsis-v"}
                                                        list={this.getDropOptions(row)}
                                                        onSelectionChanged={this.onDropdownOptionClick.bind(this, row)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="hdivider" />
                                </div>
                            </div>
                        )
                    })}
                </div>
                {
                    this.state.showWatchlistDlg && <SymbolWatchlistDialog
                        showWatchlistDlg={this.state.showWatchlistDlg}
                        symAddWatchlist={this.state.selectedSymbol}
                        callbackHandler={this.callbackAddWacthlist.bind(this)}
                        title="Add Scrips" />
                }
                {
                    this.state.showTransfer && <TransferModal
                        transferSymbol={this.state.transferSymbol}
                        callbackHandler={this.onTransferClick.bind(this)}
                        showTransfer={this.state.showTransfer}
                        closeDialog={this.closeTransfer.bind(this)}
                        transferType={this.getTransferType(this.state.transferSymbol.prdType)}
                        showErrorDialog={this.showErrorDialog.bind(this)}
                        {...this.props}
                    />
                }
                {
                    this.state.showDialog ? <Dialog header="Message" message={this.state.squareOffMsg} 
                    negativeButtonName="Close" negativeClick={this.closePopup.bind(this)} />: ''
                }
            </div >)
    }
};

const mapStateToProps = (state) => {
    return {

    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showOrderDialog: (data) => showOrderDialog(dispatch, data),
        setScripInfo: (data) => setScripInfo(dispatch, data),
        commonDialog: (payload) => commonDialog(dispatch, payload),
        toggleDialog: (payload) => toggleDialog(payload, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PositionDataHolder)); 