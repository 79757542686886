import { UrlExtensions, BaseUrl, BaseUrlShweta, localStorageKeys } from '../../common/constants';
import { httpPost } from '../base/api'
import {encryptUrl,serialiseForm,generateRandomString} from '../../../src/actions/base/api'
import {getItemByKey} from '../../../src/common/utils';

// === Get Quote ====
export const getQuote = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_QUOTE;
    httpPost(target, requestData)
        .then(function (response) {
            console.log('dddrequest succeeded with JSON response', response);
            responseCallback(response);
        }).catch(function (error) {
            console.log('dddrequest failed', error);
            errorCallback(error);
        });
}


// === Get Holdings/Positions Summary ====
export const getHoldingsPositions = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.HODLING_POSITION_SUMMARY;
    httpPost(target, requestData)
        .then(function (response) {
            console.log('dddrequest succeeded with JSON response', response);
            responseCallback(response);
        }).catch(function (error) {
            console.log('dddrequest failed', error);
            errorCallback(error);
        });
}


// ===== GET HOLDINGS SUMMARY BY SYMBOL ==== 

export const getHoldingsSummaryBySymbol = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.HOLDINGS_SUMMARY_BY_SYMBOL;
    httpPost(target, requestData)
        .then(function (response) {
            console.log('dddrequest succeeded with JSON response', response);
            responseCallback(response);
        }).catch(function (error) {
            console.log('dddrequest failed', error);
            errorCallback(error);
        });
}



// GET cover order price range 
export const getTriggerPriceRange = function (requestData, responseCallback, errorCallback) {
     var target = BaseUrl + UrlExtensions.GET_LTPRANGE_COVER;
    httpPost(target, requestData)
        .then(function (response) {
            console.log('dddrequest succeeded with JSON response', response);
            responseCallback(response);
        }).catch(function (error) {
            console.log('dddrequest failed', error);
            errorCallback(error);
        });
}

//Margin required
export const marginRequired = function(requestData, responseCallback, errorCallback){
    let target = BaseUrl + UrlExtensions.MARGIN_REQUIRED;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// forget tpin cdsl==========
export const forgetTpinCdsl = function(requestData, responseCallback, errorCallback){
    let target = BaseUrl + UrlExtensions.CDSLFORGETTPIN;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}


// dpidest------

export const DpIDSrequest = function(requestData, responseCallback, errorCallback, flag){
    // let target = BaseUrlshweta + UrlExtensions.DPID_DATA;
    // httpPost(target, requestData)
    // .then(function (response) {
    //     console.log('dddrequest succeeded with JSON response', response);        
    //     responseCallback(response);
    // }).catch(function (error) {
    //     console.log('dddrequest failed', error);
    //     errorCallback(error);
    // });

console.log("shruti -------------" + flag);
var fetchOrder = getItemByKey(localStorageKeys.USER_EDIS_DETAILS);
if(fetchOrder !== undefined && fetchOrder !== "undefined"){
    var orderType = JSON.parse(fetchOrder);
    flag  = orderType.demats[0].dpType;
}else{
    flag="";
}

console.log("requestData")
console.log(requestData)

var url = "";
if(flag === "CDSL"){
url =  BaseUrl + UrlExtensions.CDSL_URL;
}else if (flag === 'NSDL'){
    url =  BaseUrl + UrlExtensions.NSDL_URL;
}
var form = document.createElement("form");
form.setAttribute("method", "POST");
form.setAttribute("action", url, "_blank", "scrollbars=no,menubar=no,height=600,width=800,resizable=yes,toolbar=no,status=no");
form.setAttribute("id","edisForm");
var hiddenField = document.createElement("input");
var hiddenField1 = document.createElement("input");
var hiddenField2 = document.createElement("input");
var hiddenField3 = document.createElement("input");
var hiddenField4 = document.createElement("input");
var hiddenField5 = document.createElement("input");
var hiddenField6 = document.createElement("input");
var hiddenField7 = document.createElement("input");
var hiddenField8 = document.createElement("input");
var hiddenField9 = document.createElement("input");

hiddenField.setAttribute('type',"text");
hiddenField1.setAttribute('type',"text");
hiddenField2.setAttribute('type',"text");
hiddenField3.setAttribute('type',"text");
hiddenField4.setAttribute('type',"text");
hiddenField5.setAttribute('type',"text");
hiddenField6.setAttribute('type',"text");
hiddenField7.setAttribute('type',"text");
hiddenField8.setAttribute('type',"text");
hiddenField9.setAttribute('type',"text");

hiddenField.setAttribute("name", "symbolId")	
hiddenField1.setAttribute("name", "dpId")
hiddenField2.setAttribute("name", "clientId")
hiddenField3.setAttribute("name", "exchangeId")
hiddenField4.setAttribute("name", "appKey")	
hiddenField5.setAttribute("name", "appName")
hiddenField6.setAttribute("name", "companyName")
hiddenField7.setAttribute("name", "source")
hiddenField8.setAttribute("name", "reqType")	
hiddenField9.setAttribute("name", "quantity")

hiddenField.setAttribute("value", requestData.request.symbolId)	
hiddenField1.setAttribute("value", (flag === 'CDSL' ? requestData.request.clientId : JSON.parse( getItemByKey(localStorageKeys.USER_EDIS_DETAILS)).demats[0].dpID))
hiddenField2.setAttribute("value", requestData.request.clientId1)
hiddenField3.setAttribute("value", requestData.request.exchangeId)
hiddenField4.setAttribute("value", JSON.parse( getItemByKey(localStorageKeys.USER_EDIS_DETAILS)).nonPoaAppKey)	
hiddenField5.setAttribute("value", requestData.request.appName)
hiddenField6.setAttribute("value", requestData.request.companyName)
hiddenField7.setAttribute("value", requestData.request.source)
hiddenField8.setAttribute("value", requestData.request.reqType)	
hiddenField9.setAttribute("value", requestData.request.quantity)


form.appendChild(hiddenField);
form.appendChild(hiddenField1);
form.appendChild(hiddenField2);
form.appendChild(hiddenField3);
form.appendChild(hiddenField4);
form.appendChild(hiddenField5);
form.appendChild(hiddenField6);
form.appendChild(hiddenField7);
form.appendChild(hiddenField8);
form.appendChild(hiddenField9);

document.body.appendChild(form);
// form.submit();
var targetEdisForm = serialiseForm("edisForm").trim();
var key = generateRandomString(32);
console.log(url+"?source=W&"+encryptUrl(targetEdisForm, key));
window.location.href = url+"?source=W&"+encryptUrl(targetEdisForm, key)+"@@"+key;
}
