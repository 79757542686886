import React, { Component } from 'react';
import { connect } from 'react-redux';
import { commonDropDownType } from "../../common/constants"
import Dropdown from '../../controls/Dropdown'
import ToggleSwitch from '../../controls/ToggleSwitch'
import { getNetPostions } from '../../actions/netpositions/netpostionrequest'
import PositionDataHolder from './PositionDataHolder'
import { stringSort } from '../../common/utils'
import { onOrderSuccess } from '../../actions/orderentry/orderentryaction'
import { progressBar } from '../../controls/progress'

class EquityTabContent extends React.Component {
    tag = "NetpositionEquity";
    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            error: '',
            producttypes: [{ name: "ALL", key: "ALL" }, { name: "CASH", key: "CASH" }, { name: "INTRADAY", key: "INTRADAY" }, { name: "MARGIN", key: "MARGIN" },
            { name: "COVER ORDER", key: "CO" }, { name: "BRACKET ORDER", key: "BO" }, { name: "SHORT SELL", key: "SHORTSELL" }],
            selectedproducttype: "ALL",
            selectedexchange: "NSE",
            filterData: [],
            sortType: "ASC",
        }
        this.onStreamCallback = this.onStreamCallback.bind(this);
        this.onStreamCallbackTR = this.onStreamCallbackTR.bind(this);
        this.onOrderSuccess = this.onOrderSuccess.bind(this);
        this.requestEquityNP = this.requestEquityNP.bind(this);
    }

    onExchangeSelected(selected) {
        this.setState({ selectedexchange: selected === "1" ? "NSE" : "BSE" }, function () { this.requestEquityNP() });
    }

    onProductTypeSelected(selected) {
        var selectedproducttype = this.state.producttypes.filter(data => data.name === selected)[0].key;
        this.setState({ selectedproducttype: selectedproducttype }, () => {
            this.requestEquityNP();
        });
    }

    componentDidMount() {
        this.requestEquityNP();
        this.props.onOrderSuccess(this.onOrderSuccess);
    }

    onOrderSuccess() {
        this.requestEquityNP();
    }

    requestEquityNP() {
        var requestData = {
            request: {
                data: {
                    filters: [{
                        key: "prdType",
                        value: this.state.selectedproducttype
                    },
                    {
                        key: "exc",
                        value: this.state.selectedexchange,
                    }],
                    tab: "equity"
                }
            }
        };
        this.setState({ inProgress: true, error: "" });
        getNetPostions(requestData, this.parseRequestEquityNPResponse.bind(this), this.parseEquityNPError.bind(this));
    }

    parseRequestEquityNPResponse(responseData) {
        this.setState({
            inProgress: false,
            filterData: responseData.response.data.positions,
        }, () => {
            this.onSocketRegister(this.state.filterData);
        });
    }

    parseEquityNPError(error) {
        this.setState({ inProgress: false, error: error.message });
    }

    sortSymbol() {
        var sortData = stringSort(this.state.filterData, "displaySym", this.state.sortType === "ASC" ? "DSC" : "ASC")
        this.setState({ filterData: sortData, sortType: this.state.sortType === "ASC" ? "DSC" : "ASC" })
    }

    //================  Stream Functions  =================//

    onSocketRegister(data) {

        if (localStorage.getItem("TR_STREAM_FLAG") === "N") {
        data.map(value => {
            window.socketEvents.register(value.streamSym + value.sym.exc, { screenName: this.tag, sym: value.streamSym, exc: value.sym.exc }, this.onStreamCallback)
        })
        setTimeout(function () {
            window.socketEvents.subscribe();
        }, 1000)

        } else {            
            data.map(value => {
                if (value.sym.xSym && value.sym.exc_seg) {
                    return window.socketEventsTR.register(value.sym.xSym, { screenName: this.tag, sym: value.sym.xSym, exc: value.sym.exc_seg }, this.onStreamCallbackTR)
                }
            })
            setTimeout(function () {
                window.socketEventsTR.subscribe();
            }, 1000)
        }
    }

    onStreamCallback(streamData) {
        var updateCurrentList = this.state.filterData;

        updateCurrentList = updateCurrentList.map(data => {
            if (data.streamSym === streamData.data.sym && data.sym.exc === streamData.data.exc) {
                data.ltp = streamData.data.ltp ? streamData.data.ltp : data.ltp;
            }
            return data;
        })
        this.setState({ filterData: updateCurrentList })
    }

    onStreamCallbackTR(streamData) {
        var updateCurrentList = this.state.filterData;
        updateCurrentList = updateCurrentList.map(data => {
            if (data.sym.xSym === streamData.data.xSym) {
                data.ltp = streamData.data.ltp ? streamData.data.ltp : data.ltp;
            }
            return data;
        })
        this.setState({ filterData: updateCurrentList })
    }

    componentWillUnmount() {
        window.socketEvents.unregister(this.tag);
        window.socketEventsTR.unregister(this.tag);
    }

    transferCallback() {
        this.requestEquityNP();
    }

    render() {

        var errorHolder = (
            <div className="error-holder">
                {this.state.error}
            </div>
        );
        var additionalProperties = (
            <div className="row contentpanel ">
                <span className="col-6 nopadding widget-title-component order-toggle">
                    <ToggleSwitch ref="exchangeSwitch" button1="NSE" button2="BSE" onChange={this.onExchangeSelected.bind(this)}
                        selected={this.state.selectedexchange === "NSE" ? "1" : "2"} />
                </span>
                <span className="col-6 nopadding equity-pt-drop-down">
                    <Dropdown className="equity-pt-drop-down"
                        dropdownType={commonDropDownType.DEFAULT}
                        alignDefault={true}
                        list={this.state.producttypes}
                        onSelectionChanged={this.onProductTypeSelected.bind(this)}
                        selected={this.state.selectedproducttype}
                    />
                </span>
            </div>
        );
        return (
            <div id="equity" ref={equityRef => { this.equityRef = equityRef }} className="np-tab-content tab-pane in active">

                <div  style={{ height: "100%" }}>
                    {this.state.error ? errorHolder :
                        this.state.inProgress ? progressBar() : (
                            <PositionDataHolder
                                positionData={this.state.filterData}
                                showTranfer={true}
                                sortCallback={this.sortSymbol.bind(this)}
                                segment={"equity"}
                                qtylabel={"QTY"}
                                parentRef={this.equityRef}
                                transferCallback={this.transferCallback.bind(this)}
                                isBuySell={true}
                                additionalProperties={additionalProperties} />
                        )}
                </div>
            </div>
        )
    }

}


const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onOrderSuccess: (callback) => onOrderSuccess(dispatch, callback)
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(EquityTabContent);