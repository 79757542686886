import React from 'react';
import ColorSwatch from '../../Drawing/ColorSwatch'

const StudyModalParameter = (props) => {
    return (
        <div>
            {props.param.color ? <ColorSwatch isModal={true} name={props.param.heading} type='param' setColor={props.setColor} color={props.param.color} /> : <div></div>}
            {
                props.param.name === 'studyOverZones' ?
                <input type='checkbox' checked={props.param.value} onChange={(event)=>{props.updateParams(props.param.name,event)}}/>:
                <input type='number' defaultValue={props.param.value}  onChange={(event)=>{props.updateParams(props.param.name,event)}}/>
            }
            <div>
                {props.param.heading}
            </div>
        </div>
    )
}

export default StudyModalParameter