import React from 'react';
import { Popover, OverlayTrigger } from "react-bootstrap"

class DematHoldingsPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focussed: true,
        }
    }


    render() {

        const popoverHoverFocus = (

            <Popover className="products-popover" id="marginutil-positioned-top" >
                <div>
                    <div className="row contentpanel">
                        <div className="col-xs-11">
                            <div className="positive-change"> Disclaimer : </div>
                            <div>
                                <ul style={{ padding: "0", marginLeft: "15px" }}>
                                    <li> Point No. 1 </li>
                                    <li> Point No. 2 </li>
                                    <li> Point No. 3 </li>
                                    <li> Point No. 4 </li>
                                    <li> Point No. 5 </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-1">
                            <img src='assets/svg/close.svg' className="closebtn" data-toggle="tooltip" title="Close" onClick={() => { this.refs.productsPopover.hide() }} alt="close"/>
                        </div>
                    </div>
                </div>
            </Popover>
        );

        return (
            <OverlayTrigger ref="productsPopover" trigger={['click']} placement="bottom" container={this.props.parentRef} containerPadding={25} overlay={popoverHoverFocus} rootClose>
                <div className="watchlistoptions text-right">
                    <img src="assets/svg/info_selected.svg" className="info-icon" onerror="this.src='assets/images/logo.png'" alt="selected"/>
                </div>
            </OverlayTrigger>
        )
    }
}

export default DematHoldingsPopup;