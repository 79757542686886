import { StoreKeys } from '../common/constants';

const intialState = {
    activeWidget: 'ALL',
    activeWidgetState: undefined,
    watchListGroups: [],
    symAddWatchlist:{},
    watchSelected1: {},
    watchSelected2: {},
    watchlistpreselected: undefined,
}

const watchlist = (state = intialState, action) => {
    if (action.type === StoreKeys.WATCHLIST_ACTIVE_WIDGET) {
        return Object.assign({}, state, { activeWidget: action.payload });
    } else if (action.type === StoreKeys.WATCHLIST_ACTIVE_WIDGET_STATE) {
        return Object.assign({}, state, { activeWidgetState: action.payload });
    } else if (action.type === StoreKeys.WATCHLIST_GROUPS) {
        return Object.assign({}, state, { watchListGroups: action.payload });
    } else if (action.type === StoreKeys.ADD_SYMBOL_WATCHLIST) {
        return Object.assign({}, state, { symAddWatchlist: action.payload });
    } else if(action.type === StoreKeys.WATCHLIST_SELECTED_1) {
        return Object.assign({}, state, { watchSelected1: action.payload });
    } else if(action.type === StoreKeys.WATCHLIST_SELECTED_2) {
        return Object.assign({}, state, { watchSelected2: action.payload });
    } else if(action.type === StoreKeys.WATCHLIST_PRE_SELECTED) {
        return Object.assign({}, state, { watchlistpreselected: action.payload });
    } else {
        return state;
    }
};

export default watchlist;