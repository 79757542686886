import React from "react";
import { connect } from "react-redux";
import { UrlExtensions, BaseUrl } from "../../common/constants";
import Loading from "../prelogin/Loading";
import CryptoJS from "crypto-js";

class PaymentAtomDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      loading: false,
      error: "",
      params: new URLSearchParams(this.props.location.search).get(
                      "params"
                    ),
      statusCode: "",
      message: "",
      initiatePaymentResponse: "",
      decryptedParams:{},
    };
  }

  componentWillMount() {
      this.setState({...this.state, decryptedParams : this.decryptParams(this.state.params)});
}

  decryptParams(encryptedParams){
    const decryptedBytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), 'secKey');
    const decryptedParams = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
    return decryptedParams;
  }

  makePaymentRequest() {
    this.setState({ ...this.state, error: "", loading: true }, () => {
      var requestData = {
        clientName: this.state.decryptedParams.clientName,
        ibRefNo: this.state.decryptedParams.ibRefNo,
        dematAccNo: this.state.decryptedParams.dematAccNo,
        totalAmount: this.state.decryptedParams.totalAmount,
        serviceCharge: this.state.decryptedParams.serviceCharge,
        gstPerc: this.state.decryptedParams.gstPercentage,
        totalPayAmt: this.state.decryptedParams.totalPayAmount,
        email: this.state.decryptedParams.email,
        mobile: this.state.decryptedParams.mobile,
      };
      var url = BaseUrl + UrlExtensions.ATOM_PAYMENT_REQUEST;
      fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (response.status == 200) return response.json();
          else {
            throw new Error(response.statusText);
          }
        })
        .then((response) => {
          console.log(response);
          if (response.statusCode == 200) {
            const requestOptions = {
              atomTokenId: response.initiatePaymentResponse.atomTokenID,
              merchId: response.initiatePaymentResponse.merchID,
              custEmail: response.initiatePaymentResponse.email,
              custMobile: response.initiatePaymentResponse.mobile,
              returnUrl: response.initiatePaymentResponse.returnUrl,
            };
            this.openPay(requestOptions);
          }
          this.setState({ ...this.state, error: response.statusCode == 200 ? '' : response.message || 'Something went wrong', loading: false });
        })
        .catch(()=>this.setState({ ...this.state, loading: false, error: 'Something went wrong' }))
    });
  }

  openPay(requestBody) {
    let atom = new AtomPaynetz(requestBody, process.env.REACT_APP_ENV === 'prod' ? 'prod' : 'uat');
  }

  render() {
    return (
      <div className="payment-bg login-responsive">
        {this.state.loading && (
          <div className="loader-payments">
            <img src="assets/images/loader.gif" alt="loader" />
          </div>
        )}
        <div className="row contentpanel">
          <img
            src="assets/images/Indiabulls-Securities-Logo-2.jpg"
            className="header-logo"
            alt="Indiabulls"
            style={{ marginLeft: "8px" }}
          />
        </div>
        <div className="demat-heading">
          <span>Make Payment</span>
        </div>
        <div className="payment-welcome">
          Welcome to Dhani Stocks Limited Interface. The Payment Interface lets
          you pay your DP charges due for the service availed by you. This will
          normally take 3 working days (excluding Saturdays & Sundays) to get
          credited into your Demat account subject to realisation of the amount
          from your bank.
        </div>
        <div className="payment-form container">
          <div className="demat-number-details row">
            <span className="demat-text col-12 col-sm-6">Name:</span>
            <input
              autoComplete="none"
              value={this.state.decryptedParams.clientName}
              className="form-control payment_formcontrol_details col-12 col-sm-6"
              type="text"
              name="clientName"
              readOnly
            ></input>
          </div>
          <div className="demat-number-details row">
            <span className="demat-text col-12 col-sm-6">
              Demat Account No:
            </span>
            <input
              autoComplete="none"
              value={this.state.decryptedParams.dematAccNo}
              className="form-control payment_formcontrol_details col-12 col-sm-6"
              type="text"
              name="dematAccNo"
              readOnly
            ></input>
          </div>
          <div className="demat-number-details row">
            <span className="demat-text col-12 col-sm-6">
              Total Due Amount:
            </span>
            <input
              autoComplete="none"
              value={this.state.decryptedParams.totalAmount}
              className="form-control payment_formcontrol_details col-12 col-sm-6"
              type="text"
              name="dueAmount"
              readOnly
            ></input>
          </div>
          <div className="demat-number-details row">
            <span className="demat-text col-12 col-sm-6">Service Charge:</span>
            <input
              autoComplete="none"
              value={this.state.decryptedParams.serviceCharge}
              className="form-control payment_formcontrol_details col-12 col-sm-6"
              type="text"
              name="serviceCharge"
              readOnly
            ></input>
          </div>
          <div className="demat-number-details row">
            <span className="demat-text col-12 col-sm-6">GST(0%):</span>
            <input
              autoComplete="none"
              value={this.state.decryptedParams.gstPercentage}
              className="form-control payment_formcontrol_details col-12 col-sm-6"
              type="text"
              name="gstCharge"
              readOnly
            ></input>
          </div>
          <div className="demat-number-details row">
            <span className="demat-text col-12 col-sm-6">
              Total Payable Amount:
            </span>
            <input
              autoComplete="none"
              value={this.state.decryptedParams.totalPayAmount}
              className="form-control payment_formcontrol_details col-12 col-sm-6"
              type="text"
              name="totalAmount"
              readOnly
            ></input>
          </div>
          {this.state.error != "" ? (
            <div className="details-error">{this.state.error}</div>
          ) : (
            ""
          )}
          <div className="button-panel">
            <button
              className="payment-button"
              disabled={this.state.loading}
              onClick={this.makePaymentRequest.bind(this)}
            >
              NEXT
            </button>
          </div>
        </div>
        <div className="payment-notes-details">
          <ul>
            Notes:
            <li>
              On clicking Pay Now, you will be securely redirected to the
              payment interface of your respective bank chosen where you will be
              required to enter authentication details (Net Banking User ID and
              Password of your Bank Account/equivalent details).
            </li>
            <li>
              Transaction status screen will display the status as "Success" or
              "Failure" along with your transaction reference number. If your
              transaction status is "Failure", we request you to contact the
              Bank from which transaction was initiated to pay Dhani Stocks
              Limited DP Charges.
            </li>
            <li>
              For any queries call us at between 9:00 am to 6:00 pm from Monday
              to Friday (except trading holidays) or mail us at
              helpdesk@dhani.com.
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default PaymentAtomDetails;
