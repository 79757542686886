import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { getItemByKey } from '../common/utils'
import { localStorageKeys } from '../common/constants'
import { setLoginStatus } from '../actions/login/submitLogin'
import { watchListGroupAction } from '../actions/watchlist/watchlistaction'

class Authorized extends Component {

    constructor(props) {
        super(props);
        this.state = { loginStatus: this.props.loginStatus }
    }

    componentWillMount() {
        var status = getItemByKey(localStorageKeys.LOGIN_STATUS);
        if (status) {
            this.setState({ loginStatus: true }, () => {
                this.props.setLoginStatus(this.state.loginStatus);
                this.props.watchListGroupAction();
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.loginStatus !== this.state.loginStatus) {
            this.setState({ loginStatus: nextProps.loginStatus }, () => {
                this.props.setLoginStatus(this.state.loginStatus)
                if (this.state.loginStatus){
                    this.props.history.push("/home/dashboard")
                    this.props.watchListGroupAction();
                }
            })
        }
    }

    render() {
        const { component: Component, pending, logged, ...rest } = this.props
        return (
            <Route {...rest} render={props => {
                return this.state.loginStatus
                    ? <Component {...props} />
                    : <Redirect to="/base/login" />
            }} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginStatus: state.loginStatus.status,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setLoginStatus: () => setLoginStatus(dispatch),
        watchListGroupAction: () => watchListGroupAction(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorized)