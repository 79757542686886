import React, { Component } from 'react';
import { comaSeparator } from '../../common/utils'
import { instrumentType} from '../../common/constants';
class HoldingsPositions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focussed: true,
        }
    }

    render() {
        const errorHolder = (
            <div className="error-holder">
                <span> {this.props.error} </span>
            </div>
        );

        return (
            <div>
                {this.props.error ? errorHolder : (
                    <div>
                        {this.props.symobj && this.props.symobj.instr === "STK" ?
                            <div>
                                <div className="row contentpanel holdpos-header-row">
                                    <div className="col-4">
                                        Holdings
                                    </div>
                                    <div className="col-2 text-right"> DP </div>
                                    <div className="col-3 text-right nopadding"> Prev. Settlement </div>
                                    <div className="col-3 text-right"> Net </div>
                                </div>
                                <div className="col-12">
                                    <div class="order-properties-divider"></div>
                                </div>
                                <div className="holdpos-holder">
                                    {this.props.data && this.props.data.holdings && Object.keys(this.props.data.holdings).map(function (row, key) {
                                        return (
                                            <div>
                                                <div key={key} className="row contentpanel holdpos-row">
                                                    <div className="col-4">
                                                        {this.props.data.holdings[row].prdType}
                                                    </div>
                                                    <div className="col-2 text-right"> {this.props.data.holdings[row].dp} </div>
                                                    <div className="col-3 text-right nopadding"> {comaSeparator(this.props.data.holdings[row].prevSettle)} </div>
                                                    <div className="col-3 text-right"> {comaSeparator(this.props.data.holdings[row].net)} </div>
                                                </div>
                                                <div className="col-12">
                                                    <div class="order-properties-divider"></div>
                                                </div>
                                            </div>
                                        )
                                    }.bind(this))}
                                </div>
                            </div>
                            : ''
                        }
                        <div style={{ paddingTop: "6px" }}>
                            <div className="row contentpanel holdpos-header-row">
                                <div className="col-4 ">
                                    Positions
                                    </div>
                                <div className="col-2 text-right"> Qty </div>
                                <div className="col-3 text-right nopadding" style={{ whiteSpace: "nowrap" }}> Average Price (<img style={{ height: "10px" }} src="assets/svg/rupee-indian-white.svg" alt="rupee" />) </div>
                            </div>
                            <div className="col-12">
                                <div class="order-properties-divider"></div>
                            </div>
                            <div className="holdpos-holder">
                                {this.props.data && this.props.data.positions && Object.keys(this.props.data.positions).map(function (row, key) {
                                    return (
                                        <div>
                                            <div key={key} className="row contentpanel holdpos-row">
                                                <div className="col-4">
                                                    {this.props.data.positions[row].prdType}
                                                </div>
                                                <div className="col-2 text-right"> {comaSeparator(this.props.data.positions[row].qty)} </div>
                                                <div className="col-3 text-right nopadding"> {comaSeparator(this.props.data.positions[row].avgPrice)} </div>
                                            </div>
                                            <div className="col-12">
                                                <div class="order-properties-divider"></div>
                                            </div>
                                        </div>
                                    )
                                }.bind(this))}
                            </div>
                        </div>
                        <div style={{ paddingTop: "5px" }}>
                            <div className="row contentpanel holdpos-header-row">
                                <div className="col-6">
                                    Margin Available (<img style={{ height: "10px" }} src="assets/svg/rupee-indian-white.svg" alt="rupee" />)
                                    </div>
                                <div className="col-6 text-right">
                                    {this.props.data && this.props.data.margin ? comaSeparator(this.props.symobj.exc=="MCX"?this.props.data.margin.totalMarginAvail:this.props.data.margin.totMarginAvail) : ''}
                                </div>
                                <div className="col-12">
                                    <div class="order-properties-divider"></div>
                                </div>
                            </div>
                            <div className="holdpos-holder ">
                                {this.props.data && this.props.data.margin ?
                                    <div>
                                        <div className="row contentpanel holdpos-row">
                                            <div className="col-6">
                                                Cash
                                                </div>
                                            <div className="col-6 text-right">
                                           {comaSeparator(this.props.symobj.exc=="MCX"?this.props.data.margin.ledgerBalance:this.props.data.margin.cash)}
                                            </div>
                                            <div className="col-12">
                                                <div class="order-properties-divider"></div>
                                            </div>
                                        </div>
                                        <div className="row contentpanel holdpos-row">
                                            <div className="col-6">
                                                Stock
                                                </div>
                                            <div className="col-6 text-right">
                                                {(this.props.symobj && (this.props.symobj.instr == 'FUTCOM' || this.props.symobj.instr == 'OPTFUT')) ?  0 :comaSeparator(this.props.data.margin.stock)}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </div>)}
            </div>
        );
    }
};
export default HoldingsPositions;