import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
// import { Provider } from 'react-redux';
// import store from './store'
import UnsupportedBrowser from './pages/other/UnsupportedBrowser'
import Unauthorized from './router/Unauthorized'
import Authorized from './router/Authorized'
import PostLoginHome from './pages/postlogin/basecontainer'
//import PreLoginPage from './pages/prelogin/basecontainer'
import PageNotFound from './pages/other/404'
import AppDialog from './controls/AppDialog'
import LoginWarningDialog from './controls/LoginWarningDialog'
import OrderEntryWindow from './components/orderentry/OrderEntryWindow'
import ProductEntryWindow from './components/productentry/ProductEntryWindow'
import CancelOrderDialog from './components/orderentry/CancelOrderDialog'
import ChartDialog from './controls/ChartDialog'
import { clearSessionStorage, setItemByKey, getItemByKey } from './common/utils'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import browser from 'browser-detect';
import MtfDefnition from '../src/components/mtfactivation/MtfDefnition';
import MtfAlert from '../src/components/mtfactivation/MtfAlert';
import PortfolioTrackerPIBSSO from '../src/components/pibportfolioreport/PortfolioTrackerSSO';
import PaymentAtom from '../src/components/prelogin/PaymentAtom';
import PaymentAtomDetails from '../src/components/prelogin/PaymentAtomDetails';
import PaymentAtomRedirect from '../src/components/prelogin/PaymentAtomRedirect';
import { getCookie } from '../src/common/utils';
import ReactivateDormantMobile from '../src/pages/mobile/ReactivateDormantMobile';
import FindUpiIdMobile from '../src/pages/mobile/FindUpiIdMobile'
import CreateUpiIdMobile from '../src/pages/mobile/CreateUpiIdMobile'

import asyncComponent from './common/asynccomponent';
import Dialog from './controls/Dialog';
import Summary from './Summary';
import Summary_New from './Summary_New';
import Risk from './components/infoscreens/Risk';
import { v5 as uuidv5 } from 'uuid';
//const browser = require('browser-detect');
const result = browser();

const PreLoginPage = asyncComponent(() => import("./pages/prelogin/basecontainer"));

class App extends Component {
constructor(props){
  super(props);
  this.state = {
    showDialog:false
  }

  if (getCookie("userId") != null) 
    {
        window.sessionStorage.setItem('userId', getCookie("userId"));
    }
  }

  componentDidMount() {
    const appId = localStorage.getItem("app-id");
    let uuid;
    if (!appId || appId === "") {
      uuid = uuidv5("uniqueNameForRequest", window.crypto.randomUUID());
      localStorage.setItem("app-id", uuid);
    }
    window.appId = appId || uuid
  }

  closePopup() {
    this.setState({
        showDialog: false
    });
    var win = window.open("about:blank", "_self");
    win.close();
}

_onFocus()
{
  
  var userId=window.sessionStorage.getItem('userId');
  if(userId!= null && userId != getCookie("userId"))
  {
    this.setState({
      showDialog:true
    });
  }
}

  render() 
  {
    

    if(getCookie('DATA_PARAM')!=null && getCookie('DATA_PARAM')!='')
    {
        let setKey = this.setItemKeyDATAPARAM();
    }
    return this.verifyBrowser();
  }

  setItemKeyDATAPARAM(){
    setItemByKey('DATA_PARAM', getCookie('DATA_PARAM'));
  }
  verifyBrowser() {
   // if (result.mobile) {
      if (getCookie("isMobileDormant") === "true") {
        return (
          <div className="mobile-app">
            <ReactivateDormantMobile />
            <AppDialog />
          </div>
        )
      }
      else if(getCookie("isCreateMobileUPI") === "true"){
        return (
          <div className="mobile-app">
            <CreateUpiIdMobile />
          </div>
        )
      }
      else if(getCookie("isFindMobileUPI") === "true"){
        return (
          <div className="mobile-app">
            <FindUpiIdMobile />
          </div>
        )
      }
    //   return <UnsupportedBrowser browser={result} />;
    // }

    else {
      return this.checkbrowserversions();
    }
  }


  // Supported browser versions :
  //   1. Chrome: above or equal to 25
  //   2. IE: above or equal to 10
  //   3. Edge : above or equal to 12
  //   4. Firefox: above or equal to 20

  checkbrowserversions() {
    if ((result.name === "chrome" && result.versionNumber < 25) ||
      (result.name === "ie" && result.versionNumber < 10) ||
      (result.name === "edge" && result.versionNumber < 12) ||
      (result.name === "firefox" && result.versionNumber < 20)
    ) {
      // not supporting these browsers
      return <UnsupportedBrowser browser={result} />
    }
    // else if(window.innerWidth < 500) {
    //   return <UnsupportedBrowser browser={'mobile'} />
    // }
    else {
      // supporting all other browsers
      return (
        // here comes the app content

        this.state.showDialog ? 
        <Dialog header="Error" message="Your session is not valid. Please login." 
        negativeButtonName="Close" negativeClick={this.closePopup.bind(this)} />
        :
        <div className={(getCookie('loginResponsive1') == 'responsive' ? (getCookie('REDIRECT') == 'PledgeRequestDataParam' ? 'browser-width1 ' : 'browser-width ') : 'browser-width1 ') + 'fillheight ' + (location.pathname.includes('atom-payment')?'atom-width':'' )}  onFocus={this._onFocus.bind(this)}>

          <BrowserRouter>
            <Switch>
              {/* <Route path="/chartiq" component={() => window.location="http://localhost:3000/decryptor.html"} />
            <Route path="/PaymentGateway" component={() => window.location="https://uatots.indiabulls.com/decryptor.html"} /> */}
              {/* FOR_PRELOGIN: uncomment below line*/}
              <Unauthorized path="/base" component={PreLoginPage} />
              {/* FOR_PRELOGIN: comment below line*/}
              {/* <Route path="/login" exact component={Unauthorized} /> */}
              <Authorized path="/home" component={PostLoginHome} />

              {/* <Authorized path="/home" component={(props) => { return( <div> <OrderEntryWindow {...props}/>
                  <PostLoginHome {...props} />
                  </div>
                )
              }} /> */}
              {/* FOR_PRELOGIN: comment below line*/}
              {/* <Redirect to="/base/login" /> */}
              {/* FOR_PRELOGIN: uncomment below line*/}
              <Route path="/Reports" to="/PaymentGateway/payments.html" />
//              <Route path={'/atom-payment'} component={PaymentAtom} />
              <Route path="/DematPaymentDetails" component={PaymentAtomDetails} />
              
                  {(
                  (getCookie('REDIRECT')!=null && getCookie('REDIRECT')!="")?
                    <PortfolioTrackerPIBSSO data={getItemByKey('DATA_PARAM')} redirectUrl={getCookie('REDIRECT')} />
                  :
                  (
                    (getCookie('REDIRECT_TO')=='SUMMARY_PORTFOLIO')?
                    (
                      <div>
                        <Route  path="/Portfolio.html" exact component={() => <Summary/>} />
                        <Redirect to="/Portfolio.html" />
                      </div>
                    ):
                    (
                      (getCookie('REDIRECT_TO')=='SUMMARY_PORTFOLIO_NEW')?
                      (
                        <div>
                          <Route  path="/MyPortfolio.html" exact component={() => <Summary_New/>} />
                          <Redirect to="/MyPortfolio.html" />
                        </div>
                      ): (getCookie('REDIRECT_TO')=='ATOM_PAYMENT')? <PaymentAtom data={getItemByKey('DATA_PARAM')}/>:
                       (getCookie('REDIRECT_TO')=='ATOM_PAYMENT_REDIRECT')? <PaymentAtomRedirect/> :

                       <Redirect to="/base/login" />
                    )

                  )
                )}
              <Route path="*" component={PageNotFound} />
            </Switch>
          </BrowserRouter>
          <AppDialog />
          <ChartDialog />
          <ProductEntryWindow />
          <CancelOrderDialog />
          <LoginWarningDialog />
          <MtfDefnition />
          <MtfAlert />
          <Risk/>
        </div>
        

      );
    }
  }

}

const mapStateToProps = (state) => {
  return {

  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {

  };
};

export default DragDropContext(HTML5Backend)(connect(mapStateToProps, mapDispatchToProps)(App));
